export function toDateString(date: Date): string {
  const month = (date.getUTCMonth() + 1 + '').padStart(2, '0'); //months from 1-12
  const day = (date.getUTCDate() + '').padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${year}-${month}-${day} 00:00:00.000`;
}

export function convertToFullYear(shortYear): number {
  const currentDate = new Date();
  const fullYear = currentDate.getUTCFullYear();
  const thousandYears = Math.floor((fullYear / 1000)) * 1000;
  const hundredYears = Math.floor(((fullYear - thousandYears) / 100)) * 100;
  return thousandYears + hundredYears + shortYear;
}

export function convertExpirationDateToDateTimeString(dateStr: string): string|boolean {
  const monthYearRegex = new RegExp('^((0[1-9])|(1[0-2]))/\\d{2}$');
  if (!monthYearRegex.test(dateStr)) {
    return false;
  }
  const dateParts = dateStr.split('/');
  const year = convertToFullYear(parseInt(dateParts[1], 10));
  const monthZeroBased = parseInt(dateParts[0], 10) - 1;
  const date = new Date(year, monthZeroBased, 1);
  return toDateString(date);
}
