import { Component, Input } from '@angular/core';
import { Address } from '../../../../models/address.interface';

@Component({
  selector: 'jet-quick-view-ship-to',
  templateUrl: './quick-view-ship-to.component.html',
  styleUrls: ['./quick-view-ship-to.component.scss']
})
export class QuickViewShipToComponent {
  @Input() public company = '';
  @Input() public contact = '';
  @Input() public phone = '';
  @Input() public fax = '';
  @Input() public address: Address = null;
  @Input() public message = '';

  constructor() {}
}
