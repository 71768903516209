import {LoggingService} from './logging.service';
import {ErrorHandler, Injectable, Injector} from '@angular/core';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(
    private injector: Injector
    ) {}

  async handleError(error: Error | Response) {
    const loggingService = this.injector.get(LoggingService);
    if (error instanceof Response) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
      } else {
        // Handle Http Error (error.status === 403, 404...)
      }
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)
      if (error.name || error.stack || error.message) {
        const {name, stack, message} = error;
        loggingService.logger.error('unhandled_error', {name, stack, message});
      }
    }
  }
}
