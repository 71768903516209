import {CurrencyPipe} from '@angular/common';
import {Inject, Pipe, PipeTransform} from '@angular/core';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../config/domain.interface";

@Pipe({
  name: 'jetCurrency',
})
export class JetCurrencyPipe implements PipeTransform {
	constructor(private currencyPipe: CurrencyPipe,
              @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
              ) {
	}

  transform(value: any): string {
		let currencyType = 'USD';
		if (this.config.isUk) {
			currencyType = 'GBP';
		}
		return this.currencyPipe.transform(value, currencyType, 'symbol', '1.2-2');
  }
}
