import {ErrorsHandler} from '../services/errors-handler.service';
import {PentadService} from '../services/pentad.service';
import {ErrorHandler, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CookieService} from 'ngx-cookie-service';
import {LoadingModule} from 'ngx-loading';
import {AppRoutingModule} from './app-routing.module';
import {AuthService} from '../services/auth.service';
import {ResourceService} from '../services/resource.service';
import {LoadingService} from '../services/observables/loading.service';
import {QueryService} from '../services/observables/query.service';
import {CodeService} from '../services/observables/code.service';
import {CustomerSearchService} from '../services/observables/customer-search.service';
import {CustomerService} from '../services/observables/customer.service';
import {QuickViewService} from '../services/observables/quick-view.service';
import {InvoiceService} from '../services/observables/invoice.service';
import {CountryService} from '../services/observables/country.service';
import {EmployeeService} from '../services/observables/employee.service';
import {InventoryItemService} from '../services/observables/inventory-item.service';
import {ApplicationSettingService} from '../services/observables/application-setting.service';
import {UserService} from '../services/observables/user.service';
import {TaxableService} from '../services/observables/taxable.service';
import {TaxWarningService} from '../services/tax-warning.service';
import {CustomerActionsService} from '../services/customer-actions.service';
import {InvoiceActionsService} from '../services/invoice-actions.service';
import {SmartopOrderSubmissionService} from '../services/observables/smartop-order-submission.service';
import {AppComponent} from './app.component';
import {MenuService} from '../services/menu.service';
import {PentadRouterComponent} from './pentad-router/pentad-router.component';
import {NoteService} from '../services/observables/note.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {WarningService} from '../services/warning.service';
import {DenyPageComponent} from './pentad-router/deny-page.component';
import {HistoryService} from '../services/history.service';
import {ComputedFreightInfoService} from '../services/observables/computed-freight-info.service';
import {SendToShippingService} from '../services/send-to-shipping.service';
import {EventService} from '../services/event.service';
import {LoggingService} from '../services/logging.service';
import {environment} from '../environments/environment';
import {IndexService} from '../services/observables/index.service';
import {HttpInterceptorService} from '../services/interceptors/http-interceptor.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DOMAIN_CONFIG} from "../config/domain.interface";
import {US_CONFIG} from "../config/us.config";
import {UK_CONFIG} from "../config/uk.config";
import {AuthGuardService} from "../services/auth-guard.service";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {ReturnsComponent} from './help/returns/returns.component';
import {EmailComponent} from './email/email.component';
import {CTAEmailComponent} from './email/ctaemail/ctaemail.component';
import {InvoiceEmailComponent} from "./email/invoice-email/invoice-email.component";
import {SmartopPricingComponent} from './smartop-pricing/smartop-pricing.component';
import {ShippingDocumentsComponent} from './shipping-documents/shipping-documents.component';
import {ShippingTableComponent} from './shipping-documents/shipping-table/shipping-table.component';
import {ShippingTableRowComponent} from './shipping-documents/shipping-table-row/shipping-table-row.component';
import {TruckLabelModalComponent} from './shipping-documents/truck-label-modal/truck-label-modal.component';
import {LoginPageComponent} from "./user/components/login-page/login-page.component";
import {AdminPageComponent} from "./limelyte-admin/components/admin-page/admin-page.component";
import {ImpersonateComponent} from "./limelyte-admin/components/impersonate/impersonate.component";
import {BaseReportingComponent} from "./reporting/components/base-reporting/base-reporting.component";
import {InvoicePageComponent} from "./invoice/components/invoice-page/invoice-page.component";
import {CustomerSearchTabComponent} from "./customer/components/customer-search-tab/customer-search-tab.component";
import {InvoiceTabComponent} from "./invoice/components/invoice-tab/invoice-tab.component";
import {CustomerTabComponent} from "./customer/components/customer-tab/customer-tab.component";
import {CustomerNotesComponent} from "./customer/components/customer-notes/customer-notes.component";
import {HistoryTabComponent} from "./history/components/history-tab/history-tab.component";
import {CustomerContactsComponent} from "./customer/components/customer-contacts/customer-contacts.component";
import {
  CustomerDealerTypesComponent
} from "./customer/components/customer-dealer-types/customer-dealer-types.component";
import {CustomerStoresComponent} from "./customer/components/customer-stores/customer-stores.component";
import {
  CustomerPaymentTypesComponent
} from "./customer/components/customer-payment-types/customer-payment-types.component";
import {CustomerAddressesComponent} from "./customer/components/customer-addresses/customer-addresses.component";
import {CustomerInfoComponent} from "./customer/components/customer-info/customer-info.component";
import {
  CustomerContactListComponent
} from "./customer/components/customer-contact-list/customer-contact-list.component";
import {NewCustomerComponent} from "./customer/components/new-customer/new-customer.component";
import {IndividualInfoComponent} from "./customer/components/individual-info/individual-info.component";
import {HistoryInvoicesComponent} from "./history/components/history-invoices/history-invoices.component";
import {HistoryProductsComponent} from "./history/components/history-products/history-products.component";
import {LineItem} from "./invoice/common/line-item";
import {
  InvoiceModificationsComponent
} from "./invoice/components/invoice-modifications/invoice-modifications.component";
import {
  InvoiceModificationPopupComponent
} from "./invoice/components/invoice-modifications/invoice-modification-popup.component";
import {InvoicePaymentPopupComponent} from "./invoice/components/invoice-payments/invoice-payment-popup.component";
import {InvoicePaymentTypesComponent} from "./invoice/components/invoice-payment-types/invoice-payment-types.component";
import {InvoicePaymentsComponent} from "./invoice/components/invoice-payments/invoice-payments.component";
import {InvoiceFreightComponent} from "./invoice/components/invoice-freight/invoice-freight.component";
import {InvoiceUpsComponent} from "./invoice/components/invoice-ups/invoice-ups.component";
import {InvoiceShippingComponent} from "./invoice/components/invoice-shipping/invoice-shipping.component";
import {InvoiceDetailsComponent} from "./invoice/components/invoice-details/invoice-details.component";
import {
  InvoiceDetailsNotesModalComponent
} from "./invoice/components/invoice-details-notes-modal/invoice-details-notes-modal.component";
import {SmartopItemsModalComponent} from "./invoice/components/smartop-items-modal/smartop-items-modal.component";
import {InvoiceShipToComponent} from "./invoice/components/invoice-ship-to/invoice-ship-to.component";
import {InvoiceBillToComponent} from "./invoice/components/invoice-bill-to/invoice-bill-to.component";
import {InvoiceSmartopItemComponent} from "./invoice/components/invoice-smartop-item/invoice-smartop-item.component";
import {
  InvoiceInventoryItemComponent
} from "./invoice/components/invoice-inventory-item/invoice-inventory-item.component";
import {InvoiceLineItemsComponent} from "./invoice/components/invoice-line-items/invoice-line-items.component";
import {InvoiceOtherItemComponent} from "./invoice/components/invoice-other-item/invoice-other-item.component";
import {HighlightOnFocusDirective} from "./invoice/directives/highlight-on-focus/highlight-on-focus.directive";
import {
  InvoiceConfirmationsComponent
} from "./invoice/components/invoice-confirmations/invoice-confirmations.component";
import {ReturnModalComponent} from "./invoice/components/return-modal/return-modal.component";
import {QuickViewInvoiceComponent} from "./quick-view/components/quick-view-invoice/quick-view-invoice.component";
import {
  QuickViewOtherItemsComponent
} from "./quick-view/components/quick-view-other-items/quick-view-other-items.component";
import {
  QuickViewInventoryItemsComponent
} from "./quick-view/components/quick-view-inventory-items/quick-view-inventory-items.component";
import {
  QuickViewSmartopItemsComponent
} from "./quick-view/components/quick-view-smartop-items/quick-view-smartop-items.component";
import {QuickViewPaymentsComponent} from "./quick-view/components/quick-view-payments/quick-view-payments.component";
import {QuickViewFreightComponent} from "./quick-view/components/quick-view-freight/quick-view-freight.component";
import {QuickViewDetailsComponent} from "./quick-view/components/quick-view-details/quick-view-details.component";
import {QuickViewShipToComponent} from "./quick-view/components/quick-view-ship-to/quick-view-ship-to.component";
import {QuickViewBillToComponent} from "./quick-view/components/quick-view-bill-to/quick-view-bill-to.component";
import {QuickViewTabComponent} from "./quick-view/components/quick-view-tab/quick-view-tab.component";
import {WarningModalComponent} from "./shared/components/warning-modal/warning-modal.component";
import {AddressComponent} from "./shared/components/address/address.component";
import {CustomerResourceComponent} from "./shared/components/customer-resource.component";
import {ContactComponent} from "./shared/components/contact/contact.component";
import {DealerTypeComponent} from "./shared/components/dealer-type/dealer-type.component";
import {PaymentMethodComponent} from "./shared/components/payment-method/payment-method.component";
import {NoteModalComponent} from "./shared/components/note-modal/note-modal.component";
import {MenuComponent} from "./menu/menu.component";
import {
  SendToShippingModalComponent
} from "./shared/components/send-to-shipping-modal/send-to-shipping-modal.component";
import {PaginationComponent} from "./shared/components/pagination.component";
import {CustomerSearchComponent} from "./sidebar/components/customer-search/customer-search.component";
import {
  SmartopRoutingComponent
} from "./smartop-routing/smartop-routing.component";
import {CustomerNotesComponent as SidebarCustomerNotesComponent} from "./sidebar/components/customer-notes/customer-notes.component";
import {FormsModule} from "@angular/forms";
import {CurrencyPipe, DatePipe, DecimalPipe} from "@angular/common";
import {ReplacePipe} from "../pipes/replace.pipe";
import {MomentDatePipe} from "../pipes/moment-date.pipe";
import {TranslatePipe} from "../pipes/translate.pipe";
import {SafeHtmlPipe} from "../pipes/safe-html.pipe";
import {JetCurrencyPipe} from "../pipes/jet-currency-pipe";
import {PopoverModule} from "ngx-bootstrap/popover";
import { SmartopOrdersComponent } from './smartop-orders/smartop-orders.component';
import { BaseComponent } from './base/base.component';
import {SmartopAddressComponent} from "./smartop-orders/address/smartop-address.component";
import {ModalModule} from "ngx-bootstrap/modal";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {AccordionModule} from "ngx-bootstrap/accordion";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {ReportComponent} from "./sales-contest/report/report.component";


@NgModule({
    declarations: [
        AppComponent,
        AddressComponent,
        AdminPageComponent,
        BaseReportingComponent,
        CTAEmailComponent,
        ContactComponent,
        CustomerAddressesComponent,
        CustomerContactListComponent,
        CustomerContactsComponent,
        CustomerDealerTypesComponent,
        CustomerInfoComponent,
        CustomerNotesComponent,
        CustomerPaymentTypesComponent,
        CustomerResourceComponent,
        CustomerSearchComponent,
        CustomerSearchTabComponent,
        CustomerStoresComponent,
        CustomerTabComponent,
        DealerTypeComponent,
        DenyPageComponent,
        EmailComponent,
        HighlightOnFocusDirective,
        HistoryInvoicesComponent,
        HistoryProductsComponent,
        HistoryTabComponent,
        ImpersonateComponent,
        IndividualInfoComponent,
        InvoiceBillToComponent,
        InvoiceConfirmationsComponent,
        InvoiceDetailsComponent,
        InvoiceDetailsNotesModalComponent,
        InvoiceEmailComponent,
        InvoiceFreightComponent,
        InvoiceInventoryItemComponent,
        InvoiceLineItemsComponent,
        InvoiceModificationPopupComponent,
        InvoiceModificationsComponent,
        InvoiceOtherItemComponent,
        InvoicePageComponent,
        InvoicePaymentPopupComponent,
        InvoicePaymentTypesComponent,
        InvoicePaymentsComponent,
        InvoiceShipToComponent,
        InvoiceShippingComponent,
        InvoiceSmartopItemComponent,
        InvoiceTabComponent,
        InvoiceUpsComponent,
        LineItem,
        LoginPageComponent,
        MenuComponent,
        NewCustomerComponent,
        NoteModalComponent,
        PaginationComponent,
        PaymentMethodComponent,
        PentadRouterComponent,
        QuickViewBillToComponent,
        QuickViewDetailsComponent,
        QuickViewFreightComponent,
        QuickViewInventoryItemsComponent,
        QuickViewInvoiceComponent,
        QuickViewOtherItemsComponent,
        QuickViewPaymentsComponent,
        QuickViewShipToComponent,
        QuickViewSmartopItemsComponent,
        QuickViewTabComponent,
        ReturnModalComponent,
        ReturnsComponent,
        SendToShippingModalComponent,
        ShippingDocumentsComponent,
        ShippingTableComponent,
        ShippingTableRowComponent,
        SidebarCustomerNotesComponent,
        SmartopItemsModalComponent,
        SmartopRoutingComponent,
        SmartopPricingComponent,
        TruckLabelModalComponent,
        WarningModalComponent,
        JetCurrencyPipe,
        MomentDatePipe,
        ReplacePipe,
        SafeHtmlPipe,
        TranslatePipe,
        SmartopOrdersComponent,
        BaseComponent,
        SmartopAddressComponent,
        ReportComponent,
    ],
  imports: [
    BrowserModule,
    LoadingModule,
    TypeaheadModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AccordionModule.forRoot(),
    AngularEditorModule,
    TooltipModule.forRoot(),
    FormsModule,
    PopoverModule.forRoot(),
    DragDropModule,
  ],
    providers: [
        TaxWarningService,
        TaxableService,
        LoadingService,
        ResourceService,
        QueryService,
        CodeService,
        AuthService,
        CustomerSearchService,
        CustomerService,
        QuickViewService,
        InvoiceService,
        CountryService,
        EmployeeService,
        ApplicationSettingService,
        InventoryItemService,
        NoteService,
        MenuService,
        HistoryService,
        CookieService,
        CustomerActionsService,
        InvoiceActionsService,
        UserService,
        ComputedFreightInfoService,
        WarningService,
        SendToShippingService,
        EventService,
        PentadService,
        LoggingService,
        SmartopOrderSubmissionService,
        IndexService,
        AuthGuardService,
        DatePipe,
        CurrencyPipe,
        DecimalPipe,
        ReplacePipe,
        MomentDatePipe,
        TranslatePipe,
        SafeHtmlPipe,
        JetCurrencyPipe,
        {
            provide: DOMAIN_CONFIG,
            useFactory: () => {
                if (window.location.hostname.indexOf('uk.') > -1) {
                    return UK_CONFIG;
                }
                return US_CONFIG;
            }
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        {
            provide: ErrorHandler,
            useFactory: (injector: Injector) => {
                if (environment.logErrors) {
                    return new ErrorsHandler(injector);
                }
                return new ErrorHandler();
            },
            deps: [Injector]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
