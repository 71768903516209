export function allKeysAreDefined(obj: Object, keys: string[]): boolean {
  return keysNotDefined(obj, keys).length === 0;
}

export function keysNotDefined(obj: Object, keys: string[]): string[] {
  if (!obj) {
    return keys;
  }
  const keysNotDefined = [];
  for (const key of keys) {
    if  (!obj.hasOwnProperty(key)) {
      keysNotDefined.push(key);
    }
  }
  return keysNotDefined;
}

export function unsetFieldWithZero(obj, field) {
  if (obj[field] === 0) {
    delete obj[field];
  }
  return obj;
}
