import {Component, Inject, Input, OnInit} from '@angular/core';
import {ResourceService} from "../../../../services/resource.service";
import {Invoice} from "../../../../models/invoice.interface";
import {HistoryItem} from "../../../../models/history-item.interface";
import {RecordChange} from "../../../../models/record-change.interface";
import {HistoryWrapper} from "../../../../models/history-wrapper";
import {WarningModalComponent} from "../../../shared/components/warning-modal/warning-modal.component";
import {InvoiceModificationPopupComponent} from "./invoice-modification-popup.component";
import {HistoryService} from "../../../../services/history.service";
import {environment} from "../../../../environments/environment";
import {InvoiceService} from "../../../../services/observables/invoice.service";
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from "rxjs";
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";
import {BsModalService, ModalOptions} from 'ngx-bootstrap/modal';

@Component({
  selector: 'jet-invoice-modifications',
  templateUrl: './invoice-modifications.component.html',
  styleUrls: ['./invoice-modifications.component.scss']
})
export class InvoiceModificationsComponent implements OnInit {

  @Input() public invoice: Invoice = null;
  public invoiceHasPrintAll: Boolean = false;
  public modifications: HistoryWrapper[] = null;
  public dateFormat: string;

  private ngUnsubscribe = new Subject();

  constructor(private historyService: HistoryService,
              private bsModalService: BsModalService,
              private invoiceService: InvoiceService,
              @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
              ) {
    this.dateFormat = this.config.dateFormat;
  }

  ngOnInit() {
    this.invoiceService.invoice.pipe(
      filter(data => data !== null),
      takeUntil(this.ngUnsubscribe)
    ).subscribe((invoice: Invoice) => {

      if (invoice.ngState == 'load') {
        this.updateModifications();
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  updateModifications() {
    this.historyService.getHistory('invoices', this.invoice).then((items: HistoryWrapper[]) => {
      items.forEach((historyItem: HistoryWrapper) => {
        if (historyItem.has('PrintAll')) {
           this.invoiceHasPrintAll = true;
        }
      });
      this.modifications = items;
    });
  }

  popup(item: HistoryWrapper) {
    const modalsCount = this.bsModalService.getModalsCount();
    // TODO: blur event on Freight Information fields firing multiple times. Guard against opening another one for now.
    if (modalsCount === 0) {
      let m = new ModalOptions();
      m.class = 'modal-lg';

      let ref = this.bsModalService.show(InvoiceModificationPopupComponent, m);
      ref.content.history = item;
    }
  }

}
