import { CustomerToCustomer } from '../models/customer-to-customer.interface';
export const DEFAULT_CUSTOMER_TO_CUSTOMER: CustomerToCustomer = {
  id: '0',
  type: 'CustomerToCustomer',
  attributes: {
      CustomerToCustomerID: 0,
      Customers_SourceIDfk1: 0,
      Customers_SourceIDfk2: 0,
      Customers_SourceIDfk3: 0,
      LinkType: 0,
      CustomerToCustomerSequence: 0
  }
};
