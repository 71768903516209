import {catchError, take} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {createOptions} from '../../utils/http.util';
import {allKeysAreDefined, keysNotDefined} from "../../utils/object.util";
import {HttpClient} from '@angular/common/http';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../config/domain.interface";

// import {Http, Headers, RequestOptions, Response, URLSearchParams } from '@angular/http';

@Injectable()
export class CodeService {
  private url: string;
  private codesSource: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public codes: Observable<any> = this.codesSource.asObservable();

  constructor(
    private httpClient: HttpClient,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
  ) {
    this.url = this.config.apiUrl + 'codes/';
  }

  loadCodesByType(types: string[]): Observable<any> {
    const codes = this.codesSource.getValue();
    if (allKeysAreDefined(codes, types)) {
      return this.codes;
    }
    const typesConcatenated: string = keysNotDefined(codes, types).join();
    const options = createOptions();
    this.httpClient.get(`${this.url}options/${typesConcatenated}`, options).pipe(
        take(1),
        catchError(err => {
          throw 'error in source. Details: ' + err;
        }),
  )
    .subscribe((newCodes: any) => {
      this.codesSource.next({ ...this.codesSource.value, ...newCodes });
    });

    return this.codes;
  }
}
