import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import * as moment from 'moment';
import {Customer} from "../../models/customer.interface";
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../config/domain.interface";

@Injectable()
export class IndexService {
  private indexSource: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public index: Observable<number>             = this.indexSource.asObservable();
  private indexTracker                         = 0;

  private indexLoadingSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public LoadingFlag: Observable<boolean>              = this.indexLoadingSource.asObservable();
  private waitingForNames                              = false;
  private waitingForNotes                              = false;

  private titleSource: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public title: Observable<string>             = this.titleSource.asObservable();


  constructor(@Inject(DOMAIN_CONFIG) private config: DomainConfigInterface) {
  }

  public nextIndex() {
    this.indexTracker += 1;
    this.indexSource.next(this.indexTracker);
  }

  public prevIndex() {
    this.indexTracker -= 1;
    this.indexSource.next(this.indexTracker);
  }

  public setIndex(index) {
    this.indexTracker = index;
    this.indexSource.next(index);
  }

  public setLoading() {
    this.indexLoadingSource.next(true);
    this.waitingForNames = true;
    this.waitingForNotes = true;
  }

  public setLoadingManual(isLoading: boolean) {
    this.indexLoadingSource.next(isLoading);
  }

  public NamesAreDone() {
    this.waitingForNames = false;
    this.indexLoadingSource.next(this.getWaitingForNamesAndNotes());
  }

  public NotesAreDone() {
    this.waitingForNotes = false;
    this.indexLoadingSource.next(this.getWaitingForNamesAndNotes());
  }

  public getWaitingForNamesAndNotes() {
    return this.waitingForNames || this.waitingForNotes;
  }

  public setTitle(customer: Customer) {
    let selected = '';

    selected += customer.attributes.FullName;

    if (typeof customer.relationships === 'undefined') {
      this.titleSource.next(selected);
      return;
    }

    if (typeof customer.relationships.addresses !== 'undefined') {
      if (typeof customer.relationships.addresses[0] !== 'undefined') {

        selected += ' - ';
        if (customer.relationships.addresses[0].attributes.City !== '') {
          selected += customer.relationships.addresses[0].attributes.City;
        }

        if (customer.relationships.addresses[0].attributes.State !== '') {
          selected += ', ';
          selected += customer.relationships.addresses[0].attributes.State;
        }

      }
    }

    if (typeof customer.relationships.recentInvoiceAsShipTo !== 'undefined') {

      if (typeof (customer.relationships.recentInvoiceAsShipTo[0]) !== 'undefined') {
        selected += ' - LOD: ';
        const inputFormat = 'Y-MM-DD HH:mm:ss.SSS';
        const date        = moment(customer.relationships.recentInvoiceAsShipTo[0].attributes.DateShipped, inputFormat).format(this.config.dateFormat);
        selected += date;
      }
    }

    this.titleSource.next(selected);
  }
}
