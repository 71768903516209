import {Component, Input, OnInit} from '@angular/core';
import {Contact} from '../../../../models/contact.interface';
import {copyObject, singleResourceBody} from '../../../../utils/json.util';
import {LoadingService} from '../../../../services/observables/loading.service';
import {ResourceService} from '../../../../services/resource.service';
import {CustomerService} from '../../../../services/observables/customer.service';
import {CustomerResourceComponent} from '../customer-resource.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {WarningModalComponent} from "../warning-modal/warning-modal.component";
import {firstValueFrom} from "rxjs";
import {take} from "rxjs/operators";

export enum ContactValueDisplayType {
  Text,
  Hyperlink,
  Maillink,
}

@Component({
  selector: 'jet-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends CustomerResourceComponent implements OnInit {
  private resourceName = 'contacts';
  @Input() public contact: Contact;
  @Input() public contactValueDisplayType: ContactValueDisplayType = ContactValueDisplayType.Text;
  public editContact: Contact;
  public textDisplayType: ContactValueDisplayType = ContactValueDisplayType.Text;
  public hyperlinkDisplayType: ContactValueDisplayType = ContactValueDisplayType.Hyperlink;
  public maillinkDisplayType: ContactValueDisplayType = ContactValueDisplayType.Maillink;
  private bsModalRef: BsModalRef;

  constructor(
    private resourceService: ResourceService,
    private loadingService: LoadingService,
    private customerService: CustomerService,
    private bsModalService: BsModalService,
  ) {
    super(loadingService, customerService);
  }

  ngOnInit() {
    this.editContact = copyObject(this.contact);
  }

  public edit() {
    this.state = this.STATES.EDIT;
    this.onEdit.emit(true);
  }

  public cancel() {
    this.editContact = copyObject(this.contact);
    this.state = this.STATES.VIEW;
    this.onCancel.emit(true);
  }

  public update() {
    const bc = new BroadcastChannel('customer_' + this.customerId + '_customer');
    bc.postMessage({
      message: 'Source Customer Has Been Updated. Please Refresh Your Webpage',
      customerId: this.customerId,
    });

    const body: any = singleResourceBody(this.editContact.attributes);
    this.resourceChange(this.resourceService.updateResource, [this.resourceName, +this.editContact.id, body], this.onUpdate);
  }

  public async remove() {
    const shouldDelete = await this.showConfirmationModal();
    if(shouldDelete){
      const body = singleResourceBody(this.contact.attributes);
      body.data.attributes.ContactActive = 0;
      this.resourceChange(this.resourceService.updateResource, [this.resourceName, +this.contact.id, body], this.onRemove, () => {
        this.hide = true;
      });
    }
  }

  public async showConfirmationModal() {
    const initialState = {
      enableConfirm: true,
      warnings: [`Do you really want to remove ${this.contact.attributes.Description} from Contacts?`],
      title: 'Confirm Deletion',
      confirmMessage: "Delete Customer Information",
    };

    this.bsModalRef = this.bsModalService.show(WarningModalComponent, {class: 'modal-lg', initialState});

    return (await firstValueFrom(this.bsModalRef.content.onClose.pipe(take(1))))["confirm"];
  }

  public add() {
    const body: any = singleResourceBody(this.editContact.attributes);
    body.data.attributes.SourceIDfk = this.customerId;
    this.resourceChange(this.resourceService.createResource, [this.resourceName, body], this.onAdd);
  }
}

