import {ResourceService} from "./resource.service";
import {Injectable} from "@angular/core";
import {MenuItem} from "../models/menu-item.interface";

@Injectable()
export class MenuService {

  private menuItem: MenuItem = null;

  constructor(private resourceService: ResourceService) {
  }

  getRootMenu(force: boolean = false) {

    return new Promise((resolve, reject) => {

      if (this.menuItem && !force) {
        resolve(this.menuItem);

        return;
      }

      this.resourceService.getResources<MenuItem>('site_map_page', {criteria: {type: "root"}}).subscribe((menuItems: MenuItem[]) => {

        this.menuItem = menuItems.pop();

        resolve(this.menuItem);
      });
    });
  }
}
