import { Address } from '../models/address.interface';
import { copyObject } from '../utils/json.util';
import {environment} from "../environments/environment";
export const DEFAULT_ADDRESS: Address = {
  id: '0',
  type: 'Address',
  attributes: {
    AddressID: 0,
    SourceIDfk: 0,
    SourceType: -1,
    Description: '',
    Line1: '',
    Line2: '',
    City: '',
    State: '',
    ZIP: '',
    Plus4: '',
    Country: '',
    AddressSequence: 0,
    AddressActive: 1,
  }
};

export const DEFAULT_BILLING_ADDRESS: Address =  copyObject(DEFAULT_ADDRESS);
DEFAULT_BILLING_ADDRESS.attributes.Description = 'Billing';

export const DEFAULT_SHIPPING_ADDRESS: Address = copyObject(DEFAULT_ADDRESS);
DEFAULT_SHIPPING_ADDRESS.attributes.Description = 'Shipping';

export const DEFAULT_WORK_ADDRESS: Address = copyObject(DEFAULT_ADDRESS);
DEFAULT_WORK_ADDRESS.attributes.Description = 'Work';

export const ALL_DEFAULT_ADDRESSES: Address[] = [
  DEFAULT_BILLING_ADDRESS,
  DEFAULT_SHIPPING_ADDRESS,
  DEFAULT_WORK_ADDRESS
];
