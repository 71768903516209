import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Invoice} from '../../models/invoice.interface';
import {getSingleResourceRelation} from '../../utils/json.util';
import {Code} from '../../models/code.interface';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../config/domain.interface";

@Injectable()
export class TaxableService {
  private taxableSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public taxable: Observable<boolean>             = this.taxableSource.asObservable();

  constructor(@Inject(DOMAIN_CONFIG) private config: DomainConfigInterface) {
  }

  refreshTaxableByInvoice(invoice: Invoice) {
    const taxStatus: Code = getSingleResourceRelation<Code>(invoice.relationships, 'taxStatus');
    const taxable         = taxStatus && this.isTaxable(taxStatus.attributes);
    this.taxableSource.next(taxable);
  }

  refreshTaxableByCodeAttrs(codeAttrs: any) {
    const taxable = this.isTaxable(codeAttrs);
    this.taxableSource.next(taxable);
  }

  private isTaxable(codeAttrs: any) {
    return this.config.isUk || (codeAttrs.CodeValue === 1 && codeAttrs.CodeValueLabel === 'Taxed');
  }

  getTaxable(): boolean {
    return this.taxableSource.value;
  }
}
