import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {InvoiceLineItem} from '../../../models/invoice-line-item.interface';
import {CodeService} from '../../../services/observables/code.service';
import {ResourceService} from '../../../services/resource.service';
import {LoadingService} from '../../../services/observables/loading.service';
import {InvoiceService} from '../../../services/observables/invoice.service';
import {InventoryItemService} from '../../../services/observables/inventory-item.service';
import {ApplicationSettingService} from '../../../services/observables/application-setting.service';
import {
  INVENTORY_COMMODITY_CODES, INVOICE_STATUS_CANCELLED, INVOICE_STATUS_COMPLETED,
  INVOICE_TYPE_SMARTOP,
  WARRANTY_DEPARTMENT_CHARGED,
  WARRANTY_REASON_CODES
} from '../../../utils/constants.util';
import {copyObject, singleResourceBody} from '../../../utils/json.util';
import {TaxWarningService} from '../../../services/tax-warning.service';
import {WarningService} from "../../../services/warning.service";
import {Invoice} from "../../../models/invoice.interface";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {WarningModalComponent} from '../../shared/components/warning-modal/warning-modal.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../config/domain.interface";
import { ReturnModalComponent } from '../components/return-modal/return-modal.component';

// NOTE: Even though we don't use this component in the html to be rendered.
// We need the decorator for dependency injection.
@Component({
  selector: 'jet-line-item',
  template: ``,
})
export class LineItem implements OnInit, OnChanges, OnDestroy {
  @Input() public item: InvoiceLineItem;
  public editItem: InvoiceLineItem;
  public inViewMode = true;
  public isNewItem: boolean;
  public invoice: Invoice;
  public isEditable = false;

  @Input() public invoiceTaxRate: number;
  public taxed = false;
  @Input() public promoPercentage: number;

  @Output() public onAdd    = new EventEmitter<InvoiceLineItem>();
  @Output() public onRemove = new EventEmitter<InvoiceLineItem>();
  @Output() public onClone  = new EventEmitter<InvoiceLineItem>();

  public warrantyReasonCodes: any[]            = [];
  public warrantyDepartmentChargedCodes: any[] = [];
  public commodityCodes: any[]                 = [];

  public isNotSmartopInvoice = true;
  public ngUnsubscribe       = new Subject();
  public bsModalRef: BsModalRef;

  public returnAmount: number = 0;
  @Input() returns: boolean;

  @ViewChild('firstInput')
  public firstInput: ElementRef;

  constructor(
    protected httpClient: HttpClient,
    protected taxWarningService: TaxWarningService,
    protected loadingService: LoadingService,
    protected resourceService: ResourceService,
    protected codeService: CodeService,
    protected invoiceService: InvoiceService,
    protected inventoryItemService: InventoryItemService,
    protected applicationSettingService: ApplicationSettingService,
    protected warningService: WarningService,
    private bsModalService: BsModalService,
    @Inject(DOMAIN_CONFIG) protected config: DomainConfigInterface,
  ) {
  }

  ngOnInit() {
    this.inViewMode = this.item.id !== '0';
    this.isNewItem  = this.item.id === '0';
    this.editItem   = copyObject(this.item);
    this.invoiceService.invoice.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((invoice: Invoice) => {
      if (!invoice) {
        return;
      }
      this.isNotSmartopInvoice = invoice.attributes.InvoiceType !== INVOICE_TYPE_SMARTOP;
      this.invoice = invoice;

      this.isEditable = this.invoice.attributes.Codes_StatusIDfk != INVOICE_STATUS_COMPLETED &&
        this.invoice.attributes.Codes_StatusIDfk != INVOICE_STATUS_CANCELLED;

    });
    this.setupTaxed();
    this.initCodeProperties();
    this.customInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const changeKey in changes) {
      if (changeKey === 'invoiceTaxRate') {
        this.onInvoiceTaxRateChange();
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  onInvoiceTaxRateChange() {
    if (this.taxed) {
      this.editItem.attributes.TaxPercentage = this.invoiceTaxRate;
    }
  }

  private setupTaxed() {
    this.taxed = this.editItem.attributes.TaxPercentage || (this.config.isUk && this.isNewItem) ? true : false;
    this.onInvoiceTaxRateChange();
  }

  protected customInit() {
    // TODO: Implemented by Child Class
  }

  private initCodeProperties() {
    this.codeService.codes.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((codes) => {
      if (!codes) {
        return;
      }
      this.warrantyReasonCodes            = codes[WARRANTY_REASON_CODES];
      this.warrantyDepartmentChargedCodes = codes[WARRANTY_DEPARTMENT_CHARGED];
      this.commodityCodes                 = codes[INVENTORY_COMMODITY_CODES];
      this.customInitCodeProperties(codes);
    });
  }

  protected customInitCodeProperties(codes) {
    // TODO: Implemented by Child Class
  }

  private setupEditItem() {
    this.editItem = copyObject(this.item);
    this.setupTaxed();
    this.customEditItemSetup();
  }

  protected customEditItemSetup() {
    // TODO: Implemented by Child Class
  }

  protected beforeEditItem() {
    // TODO: Implemented by Child Class
  }

  private prepEditItemForSubmission() {
    const invoice                                 = this.invoiceService.currentInvoice();
    this.editItem.attributes.InvoiceIDfk          = invoice.attributes.InvoiceID;
    // NOTE: Extra attribute for calculating the Price at a discount
    this.editItem.attributes.PromoDiscountPercent = invoice.attributes.PromoDiscountPercent;
  }

  public onTaxedChange(event) {
    if (this.taxWarningService.warnIfNotTaxable(true)) {
      // NOTE: overriding checked status and tax tax percentage
      event.target.checked                   = false;
      this.taxed                             = false;
      this.editItem.attributes.TaxPercentage = 0;
    } else {
      this.editItem.attributes.TaxPercentage = this.taxed ? this.invoiceTaxRate : 0;
    }
  }

  private priceEach(lineItem: InvoiceLineItem) {
    return lineItem.attributes.OriginalPriceEach * (1 - this.promoPercentage / 100);
  }

  public subtotal(lineItem: InvoiceLineItem) {
    return lineItem.attributes.Quantity * this.priceEach(lineItem);
  }

  public total(lineItem: InvoiceLineItem) {
    return lineItem.attributes.Quantity * this.priceEach(lineItem) + this.taxAmount(lineItem);
  }

  public taxAmount(lineItem: InvoiceLineItem) {
    return lineItem.attributes.Quantity * (this.priceEach(lineItem) * (this.editItem.attributes.TaxPercentage / 100));
  }

  public edit() {
    this.inViewMode = false;
    this.setupTaxed();
    this.beforeEditItem();
  }

  public cancel() {
    this.inViewMode = true;
    this.setupEditItem();
  }

  public update() {
    const isValid = this.isValid();
    if (!isValid) {
      return;
    }
    const loader = this.loadingService.newLoader().start();
    this.prepEditItemForSubmission();
    const body = singleResourceBody(this.editItem.attributes);
    if (body.data.attributes.IsWarranty == false) {
      body.data.attributes.IsWarranty = 0;
    }
    this.resourceService.updateResource('invoice-line-items', +this.editItem.id, body)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(() => {
      this.item = this.editItem;
      this.invoiceService.updateInvoice().pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe((data) => {
        loader.stop();
        this.inViewMode = true;
      }, (error) => {
        loader.stop();
        this.inViewMode = true;
        const message   = [];
        for (const err of error.error.errors) {
          message.push(err.detail);
        }
        loader.stop();
        this.bsModalRef                  = this.bsModalService.show(WarningModalComponent);
        this.bsModalRef.content.warnings = message;
        this.bsModalRef.content.title    = 'Error On Auto Saving the Invoice';
        this.invoiceService.getInvoice(this.editItem.attributes.InvoiceIDfk, true);
      });
    }, (response: HttpErrorResponse) => {

      this.bsModalRef                  = this.bsModalService.show(WarningModalComponent);
      this.bsModalRef.content.warnings = response.error.errors.map(error => error['detail']);
      this.bsModalRef.content.title    = 'Error Saving the Invoice Line Item';

      loader.stop();
    })
    ;
  }

  public remove(isReturn = false) {
    const loader = this.loadingService.newLoader().start();
    this.prepEditItemForSubmission();
    const body                                 = singleResourceBody(this.editItem.attributes);
    body.data.attributes.InvoiceLineItemActive = 0;
    this.resourceService.updateResource('invoice-line-items', +this.item.id, body)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(() => {

        if (isReturn) {
          this.invoiceService.loadInvoice(this.editItem.attributes.InvoiceIDfk).subscribe( () => loader.stop());
          return;
        }

        this.invoiceService.updateInvoice().pipe(
          takeUntil(this.ngUnsubscribe)
        ).subscribe((data) => {
          loader.stop();
          this.inViewMode = true;
        }, (error) => {
          loader.stop();
          const message = [];
          for (const err of error.error.errors) {
            message.push(err.detail);
          }
          loader.stop();
          this.bsModalRef                  = this.bsModalService.show(WarningModalComponent);
          this.bsModalRef.content.warnings = message;
          this.bsModalRef.content.title    = 'Error On Auto Saving the Invoice';
          this.invoiceService.getInvoice(this.editItem.attributes.InvoiceIDfk, true);
        });
    }, (error: Response) => {
      loader.stop();
    })
    ;
  }

  public add() {
    const isValid = this.isValid();
    if (!isValid) {
      return;
    }
    const loader = this.loadingService.newLoader().start();
    this.prepEditItemForSubmission();
    const body = singleResourceBody(this.editItem.attributes);
    this.resourceService.createResource('invoice-line-items', body)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe((item: InvoiceLineItem) => {
      this.invoiceService.updateInvoice().pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe((data) => {
        this.setupEditItem();
        this.resetFocusToFirstInput();
        loader.stop();
      }, (error) => {
        this.resetFocusToFirstInput();
        loader.stop();
        const message = [];
        for (const err of error.error.errors) {
          message.push(err.detail);
        }
        this.customEditItemSetup();
        loader.stop();
        this.bsModalRef                  = this.bsModalService.show(WarningModalComponent);
        this.bsModalRef.content.warnings = message;
        this.bsModalRef.content.title    = 'Error On Auto Saving the Invoice';
        this.invoiceService.getInvoice(this.editItem.attributes.InvoiceIDfk, true);
      });
    }, (response: HttpErrorResponse) => {

      this.bsModalRef                  = this.bsModalService.show(WarningModalComponent);
      this.bsModalRef.content.warnings = response.error.errors.map(error => error['detail']);
      this.bsModalRef.content.title    = 'Error Saving the Invoice Line Item';

      loader.stop();
    })
    ;
  }

  public duplicate(isReturn = false) {
    const loader = this.loadingService.newLoader().start();

    let copiedAttributes = this.item.attributes;
    copiedAttributes.CIN = null;
    const invoice        = this.invoiceService.currentInvoice();

    copiedAttributes.InvoiceIDfk          = invoice.attributes.InvoiceID;
    copiedAttributes.PromoDiscountPercent = invoice.attributes.PromoDiscountPercent;

    if(isReturn) {
      if(this.returnAmount === 0) {
        copiedAttributes.InvoiceLineItemActive = 0;
      } else {
        copiedAttributes.Quantity = -this.returnAmount;
        copiedAttributes.IsInventoryAdjusted = 0;
      }
    }

    const body = singleResourceBody(copiedAttributes);

    this.resourceService.createResource('invoice-line-items', body)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe((item: InvoiceLineItem) => {

        this.onClone.emit(item);

        if (isReturn) {
          this.invoiceService.loadInvoice(this.editItem.attributes.InvoiceIDfk).subscribe( () => loader.stop());
          return;
        }
        this.invoiceService.updateInvoice().pipe(
          takeUntil(this.ngUnsubscribe)
        ).subscribe((data) => {
          this.setupEditItem();
          loader.stop();
        }, (error) => {
          loader.stop();

          const message = [];
          for (const err of error.error.errors) {
            message.push(err.detail);
          }
          if (!isReturn && message.includes('Invoice has already been completed, and cannot be updated.')) {
            loader.stop();
            this.bsModalRef                  = this.bsModalService.show(WarningModalComponent);
            this.bsModalRef.content.warnings = message;
            this.bsModalRef.content.title    = 'Error On Auto Saving the Invoice';
          }
          this.invoiceService.getInvoice(this.editItem.attributes.InvoiceIDfk, true);
      });
    }, (error) => {
      loader.stop();
      const message = [];
      for (let err of error.error.error.exception) {
        message.push(err.message);
      }
      loader.stop();
      this.bsModalRef                  = this.bsModalService.show(WarningModalComponent);
      this.bsModalRef.content.warnings = message;
      this.bsModalRef.content.title    = 'Error On Creating Line Item';
    })
    ;
  }

  public return() {
    this.bsModalRef = this.bsModalService.show(ReturnModalComponent);
    this.bsModalRef.content.returnAmount = this.returnAmount;
    this.bsModalRef.content.max = this.editItem.attributes.Quantity;
    this.bsModalRef.content.itemName = this.editItem.attributes.InventoryItemNumber + this.editItem.attributes.Description;

    this.bsModalRef.content.event.subscribe((res) => {
      this.returnAmount = res.data;

      this.invoice.relationships.lineItems.forEach(item => {
        if (item.attributes.InventoryItemIDfk === this.editItem.attributes.InventoryItemIDfk && item.attributes.Quantity <= 0) {
          item.attributes.InvoiceIDfk          = this.invoice.attributes.InvoiceID;
          item.attributes.PromoDiscountPercent = this.invoice.attributes.PromoDiscountPercent;
          const body = singleResourceBody(item.attributes);
          body.data.attributes.Quantity = -body.data.attributes.Quantity;

          body.data.attributes.IsInventoryAdjusted = 0;

          this.resourceService.updateResource('invoice-line-items', +item.id, body)
            .pipe(
              takeUntil(this.ngUnsubscribe)
            ).subscribe(() => {
            body.data.attributes.InvoiceLineItemActive = 0;
            this.resourceService.updateResource('invoice-line-items', +item.id, body)
              .pipe(
                takeUntil(this.ngUnsubscribe)
              ).subscribe(() => {
              //
            });
          })
          ;
        }
      });
      this.duplicate(true);
    });
  }

  protected resetFocusToFirstInput() {
    this.firstInput.nativeElement.focus();
  }

  private isValid(): boolean {
    const errors  = this.customIsValid();
    const isValid = !errors.length;

    if (!isValid) {
      this.warningService.openWarningModal(errors);
    }
    return isValid;
  }

  protected customIsValid(): string[] {
    const errors: string[] = [];
    if (this.editItem.attributes.IsWarranty) {
      if (this.editItem.attributes.Codes_WarrantyDepartmentAtFaultIDfk === 0) {
        errors.push('Please select a Warranty Department');
      }
      if (this.editItem.attributes.Codes_WarrantyReasonIDfk === 0) {
        errors.push('Please select a Warranty Reason');
      }
    }
    // LC-445 Need to be able to enter 0 as a quantity
    // if (this.editItem.attributes.Quantity === 0) {
    //   errors.push('Please enter a Quantity');
    // }
    return errors;
  }
}
