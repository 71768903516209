import {DomainConfigInterface} from "./domain.interface";
import {environment} from "../environments/environment";

export const US_CONFIG: DomainConfigInterface = {
  isUk: false,
  defaultCounty: "US",
  strings: {},
  apiUrl: environment.us.apiUrl,
  baseUrl: environment.us.baseUrl,
  dateFormat: "MM/DD/YYYY",
  codes: environment.us.codes
};
