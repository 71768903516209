import {Component, Inject, Input} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";

@Component({
  selector: 'jet-quick-view-invoice',
  templateUrl: './quick-view-invoice.component.html',
  styleUrls: ['./quick-view-invoice.component.scss']
})
export class QuickViewInvoiceComponent {
  @Input() public invoiceId = 0;
  @Input() public invoiceNumber = '';
  @Input() public status = '';
  @Input() public pendingReason = 'None';
  @Input() public dateInvoiced = '';
  @Input() public purchaseOrderNumber = '';
  public dateFormat: string;


  constructor(@Inject(DOMAIN_CONFIG) private config: DomainConfigInterface) {
    this.dateFormat = this.config.dateFormat;
  }
}
