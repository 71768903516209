<div class="card">
  <div class="card-heading" data-toggle="collapse" data-target="#invConfs">
    <h3 class="caps card-title">
      <span>CTA confirmations</span>
      <i class="fa fa-clipboard-check fa-lg"></i>
      <a class="caret"></a>
    </h3>
    <div class="card-subheading float-right">
      <div class="text-right">

      </div>
    </div>
  </div>
  <div class="card-body collapse show fivepad" id="invConfs">

      <div *ngIf="!confirmations" class="col-md-12 onepad">
        <i class="fa fa-spinner fa-spin fa-1x"></i> Loading ...
      </div>

      <div *ngIf="confirmations" class="col-md-12 onepad">
        <table class="small  table">
          <thead>
          <tr>
            <th>Confirmation Date</th>
            <th>Notes</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of confirmations">
            <td *ngIf="invalidDate(item)">
              Not Confirmed
            </td>
            <td *ngIf="!invalidDate(item)">
              {{ item.attributes.OrderConfirmationDate | date:'MM/dd/yy hh:mm a'}}
            </td>
            <td>{{ item.attributes.Notes.replace('PENDING - CREATED:', 'CTA Sent ') }}</td>
          </tr>
          </tbody>
        </table>
      </div>

  </div>
</div>
