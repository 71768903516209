import {filter, takeUntil} from 'rxjs/operators';
import {
  Component,
  ElementRef,
  ErrorHandler,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Invoice} from '../../../../models/invoice.interface';
import {Code} from '../../../../models/code.interface';
import {CodeService} from '../../../../services/observables/code.service';
import {InvoiceService} from '../../../../services/observables/invoice.service';
import {LoadingService} from '../../../../services/observables/loading.service';
import {WarningService} from '../../../../services/warning.service';
import {MomentDatePipe} from '../../../../pipes/moment-date.pipe';
import {Subject} from 'rxjs';
import {WarningModalComponent} from '../../../shared/components/warning-modal/warning-modal.component';
import {InvoiceDetailsNotesModalComponent} from '../invoice-details-notes-modal/invoice-details-notes-modal.component';
import {InvoiceLineItem} from '../../../../models/invoice-line-item.interface';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";
import {
  INVOICE_LINE_ITEM_TYPE_INVENTORY,
  INVOICE_PENDING_REASONS,
  INVOICE_STATUS,
  INVOICE_STATUS_CANCELLED,
  INVOICE_STATUS_COMPLETED
} from '../../../../utils/constants.util';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";



declare const document: any;

@Component({
  selector: 'jet-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss']
})
export class InvoiceDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public invoice: Invoice;
  @ViewChild('datePicker', { static: true })
  public datePicker: ElementRef;
  @ViewChild('invoiceStatus') invoiceStatus;
  public statusCodes: Code[]        = null;
  public pendingReasonCodes: Code[] = null;
  public reversedInvoice: Invoice   = null;
  public parentInvoice: Invoice     = null;
  public dateInvoiced: Date         = null;
  public dateFormat: string;
  public invoiceComplete            = false;
  private ngUnsubscribe = new Subject();
  public bsModalRef: BsModalRef;
  private previousStatus: number;

  constructor(
    private codeService: CodeService,
    private invoiceService: InvoiceService,
    private loadingService: LoadingService,
    private warningService: WarningService,
    private momentDatePipe: MomentDatePipe,
    private dateP: BsDatepickerConfig,
    private bsModalService: BsModalService,
    private errorHandler: ErrorHandler,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
  ) {
    this.dateFormat = this.config.dateFormat;
    dateP.dateInputFormat = this.dateFormat;
  }

  ngOnInit() {
    this.codeService.codes.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((codes: any) => {
      if (!codes) {
        return;
      }
      this.statusCodes = codes[INVOICE_STATUS];
      this.pendingReasonCodes = codes[INVOICE_PENDING_REASONS];
    });
    this.previousStatus = this.invoice.attributes.Codes_StatusIDfk;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.invoice !== null) {
      if (this.invoice.attributes.DateInvoiced) {
        this.dateInvoiced = new Date(this.momentDatePipe.transform(this.invoice.attributes.DateInvoiced));
      }

      this.reversedInvoice = this.invoice.relationships.reverseInvoice;
      this.parentInvoice = this.invoice.relationships.parentInvoice;

      if (this.invoice.ngState == 'load') {
        this.invoiceComplete = this.invoice.attributes.Codes_StatusIDfk == INVOICE_STATUS_COMPLETED;
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  // Because the router links only change the URL query params, were essentially at the same route.
  // So no Components get destroyed and then recreated.
  // Thus we have to manually refresh the Invoice versus having the Tab component do it's thing.
  refreshInvoice(invoiceId: number) {
    this.loadingService.refreshIsLoading(true);
    this.invoiceService.getInvoice(invoiceId).pipe(
        filter(data => data !== null),
        takeUntil(this.ngUnsubscribe)
        )
      .subscribe(
        (data) => this.loadingService.refreshIsLoading(false),
        (error) => this.loadingService.refreshIsLoading(false)
      )
    ;
  }

  // Update DateInvoiced on Invoice when date selection changes
  onDateInvoicedChange(dateInvoiced: Date) {
    if (dateInvoiced === null) {
      this.invoice.attributes.DateInvoiced = null;
    } else {
      this.invoice.attributes.DateInvoiced = this.momentDatePipe.transform(dateInvoiced, 'Y-MM-DD');
    }
  }

  /**
   * I Pentad, any time we made a change to an invoice anywhere on the invoice and we hit a blue button that has an UPDATE function,
   * it would update whatever we actually changed, not just in a particular field.
   *
   */
  async updateNotes() {
    const loader = this.loadingService.newLoader().start();

    if (this.invoice.attributes.Codes_StatusIDfk != INVOICE_STATUS_COMPLETED
      && this.invoice.attributes.Codes_StatusIDfk != INVOICE_STATUS_CANCELLED) {
      // do the whole update
      await this.invoiceService.updateInvoice({
        reverse: false,
        reopen: false,
      }).pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe((data) => {
        loader.stop();
      }, (error) => {
        loader.stop();
        const message = [];
        for (const err of error.error.errors)  {
          message.push(err.detail);
        }
        this.loadingService.refreshIsLoading(false);
        this.bsModalRef = this.bsModalService.show(WarningModalComponent);
        this.bsModalRef.content.warnings = message;
      });
    } else {
      try {
        await this.invoiceService.updateInvoice({
          updateFieldsOnly: true,
        }, ["Notes"]).toPromise();
        loader.stop();
      } catch (error) {
        this.errorHandler.handleError(error);
        loader.stop();
        const message = [];
        for (const err of error.error.errors)  {
          message.push(err.detail);
        }
        this.loadingService.refreshIsLoading(false);
        this.bsModalRef = this.bsModalService.show(WarningModalComponent);
        this.bsModalRef.content.warnings = message;
      }
    }

  }

  statusChange(event: number) {
    if(event === INVOICE_STATUS_COMPLETED) {
      let negativeQuantityLineItems: InvoiceLineItem[] = [];
      for(let item of this.invoice.relationships.lineItems) {

        if (item.attributes.LineItemType !== INVOICE_LINE_ITEM_TYPE_INVENTORY ) {
          continue;
        }

        if (item.attributes.Description.toLowerCase().indexOf('smartop') > -1) {
          continue;
        }

        if (item.attributes.Description.toLowerCase().indexOf('smartshield') > -1) {
          continue;
        }

        if (item.attributes.InventoryItemQuantity.physical - item.attributes.Quantity < 0) {
          negativeQuantityLineItems.push(item);
        }
      }

      if(negativeQuantityLineItems.length > 0) {
        const messages = {
          head: ['Item', 'Requested', 'Available'],
          rows: [],
        };
        for(let item of negativeQuantityLineItems) {
          const row = [];
          row.push(item.attributes.Description);
          row.push(item.attributes.Quantity);
          row.push(item.attributes.InventoryItemQuantity.physical);
          messages.rows.push(row);
        }

        const initialState = {
          tableData: messages,
          title: 'Cannot Complete Order - Insufficient Inventory'
        };

        this.bsModalRef = this.bsModalService.show(WarningModalComponent, {class: 'modal-lg', initialState});

        const foundCode = (this.statusCodes as any[]).find(x => x.CodeID == this.previousStatus);
        this.invoiceStatus.nativeElement.value = this.statusCodes.indexOf(foundCode) + ': ' + foundCode.CodeID;
        this.invoice.attributes.Codes_StatusIDfk = this.previousStatus;
        this.invoiceService.refreshInvoice(this.invoice);
        return;
      }
    }

    this.previousStatus = this.invoice.attributes.Codes_StatusIDfk;
    this.invoiceService.refreshInvoice(this.invoice);
  }

  showNotesModal() {
    let bsModalRef = this.bsModalService.show(InvoiceDetailsNotesModalComponent);
    bsModalRef.content.notes = this.invoice.attributes.Notes;
  }
}
