import {Inject, Injectable} from '@angular/core';
import {SmartopOrderSubmission} from '../../models/smartop-order-submission.interface';
import {ResourceService} from '../resource.service';
import {HttpClient} from '@angular/common/http';
import {createOptions} from '../../utils/http.util';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../config/domain.interface";
import {BehaviorSubject, of} from "rxjs";
import {filter, switchMap, tap} from "rxjs/operators";

@Injectable()
export class SmartopOrderSubmissionService {

  private resources = new BehaviorSubject<SmartopOrderSubmission[]>(null);

  constructor(
    private resourceService: ResourceService,
    private httpClient: HttpClient,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
  ) {
  }

  getResources(params) {
    return this.resources
      .pipe(
        tap((data) => {
          if (data === null) {
            this.resourceService
              .getResources<SmartopOrderSubmission>('smartop-order-submissions', params)
              .subscribe((data) => {
                this.resources.next(data);
              })
          }
        }),
        filter((data) => data !== null)
      )
  }

  fetchSmartopOrderSubmission(id: number) {

    if (this.resources.value) {
      const order = this.resources.value.find((o) => parseInt(o.id) === id);
      if (order) {
        return of(order);
      }
    }

    return this.resourceService.getResource<SmartopOrderSubmission>('smartop-order-submissions', id);
  }

  routeSmartopOrderSubmission(smartopOrderSubmission: SmartopOrderSubmission, country: string) {
    let body: any = {
      data: {
        country: country
      }
    };

    let options = createOptions();

    return this.httpClient
      .post(`${this.config.apiUrl}smartop-order-submissions/route/${smartopOrderSubmission.id}`, body, options)
      .pipe(
        tap(() => this.remove(smartopOrderSubmission))
      )
      ;
  }

  delete(order: SmartopOrderSubmission) {
    return this.resourceService
      .deleteResource<SmartopOrderSubmission>('smartop-order-submissions', parseInt(order.id))
      .pipe(
        tap(() => this.remove(order))
      )
      ;
  }

  salesContest(startDate = '', endDate = '') {
    let queryParamString = '';
    if (startDate !== '' || endDate !== '') {
      queryParamString = '?';
      queryParamString += 'startDate=' + startDate + '&';
      queryParamString += 'endDate=' + endDate;
    }
    return this.httpClient
      .get(`${this.config.apiUrl}smartop-order-submissions/contest${queryParamString}`)
      ;
  }

  salesContestUpdate(result) {
    let body: any = {
      data: {
        contestResult: result
      }
    };

    let options = createOptions();

    return this.httpClient
      .post(`${this.config.apiUrl}smartop-order-submissions/contest/${result.id}`, body, options)
      ;
  }

  duplicate(order: SmartopOrderSubmission) {
    return this.httpClient
      .post(`${this.config.apiUrl}smartop-order-submissions/duplicate/${order.id}`, {})
      ;
  }

  private remove(order: SmartopOrderSubmission) {
    if (!this.resources.value) {
      return;
    }

    this.resources.value.some((value, index) => {
      if (value.id === order.id) {
        this.resources.value.splice(index, 1);

        return true;
      }

      return false;
    });
  }
}
