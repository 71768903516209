import {Invoice} from '../../models/invoice.interface';
import {InvoiceService} from './invoice.service';
import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ComputedFreightInfo} from '../../models/computed-freight-info.interface';
import {getComputedFreightInfo} from '../../utils/invoice.util';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../config/domain.interface";


@Injectable()
export class ComputedFreightInfoService {
  private computedFreightInfoSource: BehaviorSubject<ComputedFreightInfo> = new BehaviorSubject<ComputedFreightInfo>(null);
  public computedFreightInfo: Observable<ComputedFreightInfo>             = this.computedFreightInfoSource.asObservable();

  constructor(invoiceService: InvoiceService,
              @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
  ) {
    invoiceService.invoice.subscribe((invoice: Invoice) => {
      if (invoice === null) {
        return;
      }
      this.computeNext(invoice);
    });
  }

  computeNext(invoice: Invoice) {
    const computed: ComputedFreightInfo   = getComputedFreightInfo(invoice, this.config.isUk);
    invoice.attributes.PromoDiscountTotal = computed.totalDiscount;
    this.computedFreightInfoSource.next(computed);
  }
}

