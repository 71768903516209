import { Component, Input} from '@angular/core';
import { PaymentMethod } from '../../../../models/payment-method.interface';
import { PaymentMethodComponent } from '../../../shared/components/payment-method/payment-method.component';
import { DEFAULT_PAYMENT_METHOD } from '../../../../mocks/payment-method.mock';
import { copyObject } from '../../../../utils/json.util';
import { SOURCE_TYPE_CUSTOMER } from '../../../../utils/constants.util';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {LoadingService} from "../../../../services/observables/loading.service";
import {ResourceService} from "../../../../services/resource.service";

@Component({
  selector: 'jet-customer-payment-types',
  templateUrl: './customer-payment-types.component.html',
  styleUrls: ['./customer-payment-types.component.scss']
})
export class CustomerPaymentTypesComponent {
  @Input() public paymentTypes: PaymentMethod[];
  @Input() public key: string;
  @Input() public customerId = 0;
  public emptyPaymentMethod: PaymentMethod = null;
  public STATE_VIEW = PaymentMethodComponent.STATIC_STATES.VIEW;
  public STATE_ADD = PaymentMethodComponent.STATIC_STATES.ADD;
  public showAddForm = false;

  constructor(
    public loadingService: LoadingService,
    private resourceService: ResourceService,
  ) {
    const resource: PaymentMethod = copyObject(DEFAULT_PAYMENT_METHOD);
    resource.attributes.SourceType = SOURCE_TYPE_CUSTOMER;
    this.emptyPaymentMethod = resource;
  }

  public toggleShowAddFormOnClick(event) {
    event.stopPropagation();
    this.toggleShowAddForm();
  }

  private toggleShowAddForm() {
    this.showAddForm = !this.showAddForm;
  }

  public newPaymentMethodAddHandler(success) {
    if (success) {
      this.toggleShowAddForm();
    }
  }

  public newPaymentMethodCancelHandler(success) {
    this.toggleShowAddForm();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.paymentTypes, event.previousIndex, event.currentIndex);
    const swapLoader = this.loadingService.newLoader();
    swapLoader.start();
    for (let i = 0 ; i < this.paymentTypes.length ; i++) {
      // pentad requires this to start at 1.
      this.paymentTypes[i].attributes.PaymentMethodSequence = i + 1;
      this.resourceService.updateResource('paymentMethods', + this.paymentTypes[i].id, this.paymentTypes[i].attributes)
        .subscribe(() => {
          if (i === this.paymentTypes.length - 1) {
            swapLoader.stop();
          }
        });
    }
  }
}
