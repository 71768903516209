<h4 class="quick-view-title">
  <a [routerLink]="['/invoice/primary']" [queryParams]="{ invoice_id: invoiceId }" queryParamsHandling="merge">
    INVOICE #{{ invoiceNumber }} <i class="fa fa-file-text"></i>
  </a>
</h4>

<hr>
<table>
  <tr>
    <th>Status:</th>
    <td>{{ status }}</td>
  </tr>
  <tr>
    <th>Pending Reason:</th>
    <td>{{ pendingReason ? pendingReason : 'None' }}</td>
  </tr>
  <tr>
    <th>Date Invoiced:</th>
    <td>{{ dateInvoiced | momentDate:dateFormat }}</td>
  </tr>
  <tr>
    <th>PO Number:</th>
    <td>{{ purchaseOrderNumber }}</td>
  </tr>
</table>
