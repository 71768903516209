<!--TODO Add/Edit Representation-->
<ng-container *ngIf="!hide">
  <ng-container *ngIf="state === STATES.ADD || state === STATES.EDIT">
    <div class="form-subgroup container-fluid" style="margin-top: 20px">
      <div class="row">
        <!-- TODO: Start Form Section -->
        <div class="col-md-12">
          <b>New Address:</b>
        </div>
        <div class="col-md-12" style="margin-bottom: 5px;">
          <div class="row row2">
            <div class="col-md-6 onepad">
              <input [(ngModel)]="editAddress.attributes.Description" type="text" class="small-form" placeholder="Location">
            </div>

            <div class="col-md-6 onepad">
              <select [(ngModel)]="editAddress.attributes.Description" class="small-form">
                <option value="">--Select--</option>
                <option value="Home">Home</option>
                <option value="Office">Office</option>
                <option value="Shipping">Shipping</option>
                <option value="Billing">Billing</option>
              </select>
            </div>
          </div>

          <div class="row row2">
            <div class="col-md-12 onepad">
              <input [(ngModel)]="editAddress.attributes.Line1" type="text" class="small-form" placeholder="Line 1">
            </div>
          </div>

          <div class="row row2">
            <div class="col-md-12 onepad">
              <input [(ngModel)]="editAddress.attributes.Line2" type="text" class="small-form" placeholder="Line 2">
            </div>
          </div>

          <div class="row row2">
            <div class="col-md-10 onepad">
              <input [(ngModel)]="editAddress.attributes.City"
                     type="text" class="small-form" placeholder="City"
              >
            </div>

            <div class="col-md-2 onepad">
              <input [(ngModel)]="editAddress.attributes.State"
                     type="text" class="small-form" placeholder="State"
              >
            </div>
          </div>
          <div class="row row2">
            <div class="col-md-9 onepad">
              <input [(ngModel)]="editAddress.attributes.ZIP"
                     type="text" class="small-form" placeholder="Zip"
              >
            </div>

            <div class="col-md-3 onepad">
              <input [(ngModel)]="editAddress.attributes.Plus4"
                     type="text" class="small-form" placeholder="+4"
              >
            </div>
          </div>

          <div class="row row2">
            <div class="col-md-12 onepad">
              <select [(ngModel)]="editAddress.attributes.Country" class="small-form">
                <option *ngFor="let country of countries"
                        [ngValue]="country.attributes.CountryCode"
                >
                  {{ country.attributes.CountryName }}
                </option>
              </select>
            </div>
          </div>

          <div class="row" style="margin-top: 10px;margin-bottom: 10px">
            <div class="col-sm-6">
              <ng-container *ngIf="state === STATES.EDIT">
                <button class="btn btn-success btn-sm btn-block" (click)="update()">
                  Save
                </button>
              </ng-container>
              <ng-container *ngIf="state === STATES.ADD">
                <button class="btn btn-success btn-sm btn-block" (click)="add()">
                  Add
                </button>
              </ng-container>
            </div>
            <div class="col-sm-6">
              <button class="btn btn-default btn-sm btn-block" (click)="cancel()">
                Cancel
              </button>
            </div>
          </div>
        </div> <!-- TODO - End Form Section-->
      </div>
    </div>
  </ng-container>
  <!--TODO - View Representation-->
  <ng-container *ngIf="state === STATES.VIEW">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 p-0">
          <ng-content select="[slot=drag-handle]"></ng-content>
          <div class="d-inline-block">
            <b>{{ address.attributes.Description }}</b><br>
            {{ address.attributes.Line1 }} {{ address.attributes.Line2 }}<br>
            {{ address.attributes.City }}, {{ address.attributes.State }} {{ address.attributes.ZIP }}<br>
          </div>
        </div>
        <div class="col-md-2 p-1">
          <div class="pull-right">
            <button class="btn p-0" (click)="edit()">
              <i class="fa fa-pencil-alt" ></i>
            </button>
            <div class="btn-group">
              <button class="btn btn-secondary  dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false"
                      style="background-color:transparent;color:black;border:none;box-shadow:none;"></button>
              <div class="dropdown-menu" style="min-width: 125px;">
                <a class="dropdown-item" (click)="remove()" style="cursor: pointer;"><i class="fa fa-trash-alt"></i>
                  Delete</a>
                <a class="dropdown-item" (click)="edit()" style="cursor: pointer;"><i class="fa fa-pencil-alt"></i>
                  Edit</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

