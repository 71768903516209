import {TranslatePipe} from '../pipes/translate.pipe';
import {Injectable} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {WarningModalComponent} from '../app/shared/components/warning-modal/warning-modal.component';
import {TaxableService} from './observables/taxable.service';
import {InvoiceService} from './observables/invoice.service';

@Injectable()
export class TaxWarningService {
  private bsModalRef: BsModalRef;
  private taxLabel: string;
  constructor(
    private bsModalService: BsModalService,
    private taxableService: TaxableService,
    private invoiceService: InvoiceService,
    private jetTranslatePipe: TranslatePipe,
  ) {
    this.taxLabel = this.jetTranslatePipe.transform('Tax');
  }

  /**
   * Returns true if not taxable. false means the Invoice is taxable.
   * @param useGenericWarning
   * @returns {boolean}
   */
  warnIfNotTaxable(useGenericWarning: boolean = false): boolean {
    const invoice = this.invoiceService.currentInvoice();
    let isNotTaxable = false;
    if (useGenericWarning) { // Check if we can show generic warning
      if (!this.taxableService.getTaxable() || invoice.attributes.TaxPercentage <= 0) {
        this.openWarningModal(`No Invoice ${this.taxLabel} Rate Set, or status not 'Retail'`);
        isNotTaxable = true;
      }
    } else { // Else check if we can show specific warnings
      if (!this.taxableService.getTaxable() && invoice.attributes.TaxPercentage > 0) {
        this.openWarningModal(`${this.taxLabel} Status not set to Retail. ${this.taxLabel} rate will have no effect.`);
        isNotTaxable = true;
      } else if (this.taxableService.getTaxable() && invoice.attributes.TaxPercentage <= 0) {
        this.openWarningModal(`${this.taxLabel} rate set to 0.0%. ${this.taxLabel} rate will have no effect.`);
        isNotTaxable = true;
      }
    }
    return isNotTaxable;
  }

  getWarningsIfNotTaxable(useGenericWarning: boolean = false): string {
    const invoice = this.invoiceService.currentInvoice();
    let isNotTaxable = false;
    if (useGenericWarning) { // Check if we can show generic warning
      if (!this.taxableService.getTaxable() || invoice.attributes.TaxPercentage <= 0) {
        return `No Invoice ${this.taxLabel} Rate Set, or status not 'Retail'`;
      }
    } else { // Else check if we can show specific warnings
      if (!this.taxableService.getTaxable() && invoice.attributes.TaxPercentage > 0) {
        return `${this.taxLabel} Status not set to Retail. ${this.taxLabel} rate will have no effect.`;
      } else if (this.taxableService.getTaxable() && invoice.attributes.TaxPercentage <= 0) {
        return `${this.taxLabel} rate set to 0.0%. ${this.taxLabel} rate will have no effect.`;
      }
    }
    return "";
  }

  private openWarningModal(message) {
    const modalsCount = this.bsModalService.getModalsCount();
    // TODO: blur event on Freight Information fields firing multiple times. Guard against opening another one for now.
    if (modalsCount === 0) {
      this.bsModalRef = this.bsModalService.show(WarningModalComponent);
      this.bsModalRef.content.warnings = [message];
    }

  }
}
