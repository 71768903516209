import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, tap} from 'rxjs';
import {Invoice} from '../../models/invoice.interface';
import {ResourceService} from '../resource.service';
import {LoadingService} from "./loading.service";

@Injectable()
export class QuickViewService {
  private invoiceSource: BehaviorSubject<Invoice> = new BehaviorSubject<Invoice>(null);
  public invoice: Observable<Invoice>             = this.invoiceSource.asObservable();

  constructor(
    private resourceService: ResourceService,
    private loadingService: LoadingService,
  ) {
  }

  refreshInvoice(invoice: Invoice): void {
    this.invoiceSource.next(invoice);
  }

  isInvoiceDefined(): boolean {
    return this.invoiceSource.getValue() ? true : false;
  }

  nextInvoice(currentInvoiceId: number, customerId: number): Observable<Invoice[]> {
    const params = {
      criteria: {
        next: {
          invoice_id: currentInvoiceId,
          customer_id: customerId
        }
      }
    };
    const loader = this.loadingService.newLoader().start();
    return this.resourceService.getResources<Invoice>('invoices', params)
      .pipe(
        tap(() => loader.stop())
      );
  }

  previousInvoice(currentInvoiceId: number, customerId: number): Observable<Invoice[]> {
    const params = {
      criteria: {
        previous: {
          invoice_id: currentInvoiceId,
          customer_id: customerId
        }
      }
    };

    const loader = this.loadingService.newLoader().start();
    return this.resourceService.getResources<Invoice>('invoices', params)
      .pipe(
        tap(() => loader.stop())
      );
  }

  currentInvoice(customerId: number, invoiceId: number): Observable<Invoice[]> {
    const params = {
      criteria: {
        current: {
          customer_id: customerId,
          invoice_id: invoiceId
        }
      }
    };
    const loader = this.loadingService.newLoader().start();
    return this.resourceService.getResources<Invoice>('invoices', params)
      .pipe(
        tap(() => loader.stop())
      );
  }

  firstInvoice(customerId: number): Observable<Invoice[]> {
    const params = {
      criteria: {
        first: {
          customer_id: customerId
        }
      }
    };
    const loader = this.loadingService.newLoader().start();
    return this.resourceService.getResources<Invoice>('invoices', params)
      .pipe(
        tap(() => loader.stop())
      );
  }

  lastInvoice(customerId: number): Observable<Invoice[]> {
    const params = {
      criteria: {
        last: {
          customer_id: customerId
        }
      }
    };
    const loader = this.loadingService.newLoader().start();
    return this.resourceService.getResources<Invoice>('invoices', params)
      .pipe(
        tap(() => loader.stop())
      );
  }
}
