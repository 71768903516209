<div *ngIf="customer" class="card">
  <div class="card-heading" data-toggle="collapse" data-target="#customerNotes">
    <h3 class="caps card-title">
      <span></span>general account notes
      <i class="fa fa-lg fa-sticky-note-o"></i><br>
      <a class="float-right caret"></a>
    </h3>
    <div class="card-subheading float-right">
      <div *ngIf="customer && user" class="text-right">
        <i class="fa fa-lg fa-plus"  (click)="newNote($event)"></i>
      </div>
    </div>
  </div>
  <div class="card-body collapse show fivepad" id="customerNotes">
    <ng-container *ngIf="notes">
      <ng-container *ngFor="let note of notes | slice:0:noteEndIndex">
        <div [attr.data-note-id]="note.id" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragstart)="onDragStart($event)" [draggable]="true" class="note-section">
          <div class="note-header">
            <b>{{ employeeName(note) }}</b>
            <span class="pull-right">{{ note?.attributes.NoteDate | momentDate:dateFormat }}</span>
          </div>
          <div class="note-body">
            <div class="row">
              <div class="col-md-10">
                <div [id]="note.id" #noteItem (load)="adjustNote(noteItem, note)" class="note-item" [class.compressed]="!note.expanded" (click)="viewNote(note)" [innerHtml]="note?.attributes.Note | safeHtml">
                </div>
              </div>
              <div class="col-md-2 note-actions">
                <span (click)="viewNote(note)" class="fa fa-pencil-alt"></span><br>

                <div class="btn-group">
                  <button class="btn btn-secondary btn-sm dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false"
                          style="background-color:transparent;color:black;border:none;box-shadow:none;padding-left:0;"></button>
                  <div class="dropdown-menu" style="min-width: 125px;">
                    <a class="dropdown-item" (click)="removeNote(note)" style="cursor: pointer;"><i class="fa fa-star"></i> Hide from sidebar</a>
                  </div>
                </div>

                <span *ngIf="note?.attributes.Note.length > 120" (click)="compressExpandNote(noteItem, note)" [ngClass]="{ 'fa': true, 'fa-expand' : !note.expanded, 'fa-compress': note.expanded }"></span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

    </ng-container>

    <div *ngIf="!notes" class="col-md-12 onepad">
      <i class="fa fa-spinner fa-spin fa-1x"></i> Loading Notes ...
    </div>

    <div *ngIf="notes && !notes.length" class="col-md-12 onepad">
      No records to display.
    </div>
    <div *ngIf="customer && user && notes?.length > 3" class="col-md-12 onepad">
      <button class="btn btn-primary btn-xs btn-block search-btns" (click)="toggleMoreNotes()">
        {{ moreNotes ? 'Less' : 'More '}} Notes
      </button>
    </div>
  </div>
</div>
