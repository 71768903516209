<ng-container *ngIf="!hide">
    <ng-container *ngIf="state === STATES.EDIT">
        <div class="form-subgroup">
            <div class="row">
                <div class="col-md-10 onepad">
                    <input [(ngModel)]="tempSelected" (typeaheadOnSelect)="onSelect($event)" [typeahead]="dealerTypeCodes" typeaheadOptionField="Name" class="small-form">
                </div>
                <div class="col-md-2 p-0">
                  <div class="float-right align-self-center">
                    <button class="btn btn-success btn-sm" (click)="update()" tooltip="Save" placement="bottom" delay="500">
                      <i tabindex="0" class="fa fa-check"></i>
                    </button>
                    <button class="btn btn-default btn-sm " (click)="cancel()" tooltip="Cancel" placement="bottom"
                            delay="500">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="state === STATES.ADD">
        <div class="form-subgroup">
            <div class="row">
                <div class="col-md-10 onepad">
                    <input [(ngModel)]="tempSelected" (typeaheadOnSelect)="onSelect($event)" [typeahead]="dealerTypeCodes" typeaheadOptionField="Name" class="small-form">
                </div>
                <div class="col-md-2 p-0">
                  <div class="float-right align-self-center">
                    <button class="btn btn-success btn-sm" (click)="add()" tooltip="Add" placement="bottom" delay="500">
                      <i tabindex="0" class="fa fa-check"></i>
                    </button>
                    <button class="btn btn-default btn-sm " (click)="cancel()" tooltip="Cancel" placement="bottom"
                            delay="500">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="state === STATES.VIEW">
        <div class="row">
            <div class="col-md-10">
                {{ dealerType.attributes.ProductCodeAlt }} - {{ dealerType.attributes.CodeDesc }}
            </div>
            <div class="col-md-2">
              <button class="btn p-0" (click)="edit()">
                <i class="fa fa-pencil-alt" ></i>
              </button>

                <div class="btn-group ml-1">
                    <button class="btn btn-secondary btn-sm dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false"
                            style="background-color:transparent;color:black;border:none;box-shadow:none;"></button>
                    <div class="dropdown-menu" style="min-width: 125px;">
                        <a class="dropdown-item" (click)="remove()" style="cursor: pointer;"><i class="fa fa-trash-alt"></i> Delete</a>
                        <a class="dropdown-item" (click)="edit()" style="cursor: pointer;"><i class="fa fa-pencil-alt"></i> Edit</a>
                    </div>
                </div>

            </div>
        </div>
    </ng-container>
</ng-container>
