<ng-container *ngIf="showRow">
<td>
  <div (click)="showItems(item.OrderId)">
    <a class="fa fa-caret-right" *ngIf="!item.expanded"></a>
    <a class="fa fa-caret-down" *ngIf="item.expanded"></a>
  </div>
</td>
<td>{{ item.WGT || "-"}}</td>
<td>{{ item.Shipper.CodeDesc || "-"}}</td>
<td>{{ item.Company || "-"}}</td>
<td>{{ item.City || "-"}}</td>
<td>{{ item.State || "-"}}</td>
<td>{{ item.OrderId || "-"}}</td>
<td>
  <button class="btn btn-link btn-xs" (click)="printInvoice()">Print</button>
</td>
<td>
  <button class="btn btn-link btn-xs" (click)="printPackList()">Print</button>
</td>
<td class="shipping-instructions">{{ item.ShippingInstructions || "-"}}</td>
<td>{{ item.RecordChange | momentDate:dateFormat }}</td>
<ng-container *ngIf="showProNumber">
  <td><input type="text" name="proNumber" [(ngModel)]="item.ProNumber"></td>
  <td *ngIf="item.Orders[0].Picked === 1">
    <button class="btn btn-primary btn-xs" (click)="complete(item.OrderId)">Complete</button>
  </td>
</ng-container>
<ng-container *ngIf="!showProNumber">
  <td *ngIf="item.Orders[0].Picked === 1">
    <button class="btn btn-primary btn-xs" (click)="complete(item.OrderId)">Complete</button>
  </td>
</ng-container>
<td *ngIf="item.Orders[0].Picked !== 1">
  <button class="btn btn-danger btn-xs" (click)="setPicked(item.OrderId)">Order Ready</button>
</td>
<td *ngIf="showPrintLabels">
  <button class="btn btn-link btn-xs" (click)="printLabel()">Print</button>
</td>
</ng-container>
