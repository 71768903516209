import {Component, Inject, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";

@Component({
  selector: 'app-base-reporting',
  templateUrl: './base-reporting.component.html',
  styleUrls: ['./base-reporting.component.scss']
})
export class BaseReportingComponent implements OnInit {

  public iframeSrc: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface
    ) { }

  ngOnInit() {

    this.loadIframe(this.config.baseUrl, this.router.url.replace('/', ''));

    document.body.style.overflow = 'hidden';
  }

  private loadIframe(base, path) {

    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl( base + path);
  }

}
