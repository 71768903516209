<div class="card">
    <div class="card-heading normal-card-heading" data-toggle="collapse" data-target="#invShipping">
        <h3 class="caps card-title">
            <span>ups rates</span> <i class="fa fa-shield fa-lg"></i> <a class="caret"></a>
        </h3>
        <div class="card-subheading float-right">
            <div class="text-right">

            </div>
        </div>
    </div>
    <div class="card-body collapse show fivepad" id="invUps">
        <div class="panel panel-default">
            <div class="panel-body">

                <div *ngIf="iframeLoading" style="text-align: center;position: absolute;top: 36%;width: 100%;">
                    <i class="fa fa-spinner fa-spin fa-1x"></i> Loading UPS
                </div>

                <iframe
                        #iframe
                        id="ups-iframe" width="100%"
                        [attr.src]="iframeSrc"
                        frameborder="0"
                        [style.visible]="!iframeLoading"
                        (load)=onLoad($event)
                ></iframe>
            </div>
        </div>
    </div>
</div>
