import {Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import {Invoice} from "../../models/invoice.interface";
import {Customer} from '../../models/customer.interface';
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {PentadService} from "../../services/pentad.service";
import {concatMap, filter, mergeMap, takeUntil} from "rxjs/operators";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {InvoiceService} from "../../services/observables/invoice.service";
import {forkJoin, Subject, of} from "rxjs";
import {CustomerService} from "../../services/observables/customer.service";
import {Contact} from "../../models/contact.interface";
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../config/domain.interface";
import {DomSanitizer} from '@angular/platform-browser';
import {copyObject} from '../../utils/json.util';
import {
  WORK_EMAIL_CONTACT,
  WORK_FAX_CONTACT,
  WORK_PHONE_CONTACT,
} from '../../mocks/contacts.mock';
import {
  CONTACT_TYPE_EMAIL,
  CONTACT_TYPE_FAX,
  CONTACT_TYPE_PHONE,
} from '../../utils/constants.util';
import {IndexService} from "../../services/observables/index.service";

const defaultWorkContacts = {
  [CONTACT_TYPE_PHONE]: WORK_PHONE_CONTACT,
  [CONTACT_TYPE_FAX]: WORK_FAX_CONTACT,
  [CONTACT_TYPE_EMAIL]: WORK_EMAIL_CONTACT,
};

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  @ViewChild('attachment') attachment: any;
  @ViewChild('submitButton') submitButton: ElementRef;


  public emailBody: string;
  public emailSubject: string;
  public fromAddress: string;
  public isCTA: boolean = true;
  public isLoading = true;
  public originalFromAddress: string;

  public report;
  public reportBlob;

  public packingList;
  public packingListBlob;

  public formError  = false;
  public error: string;
  public isUk: boolean;
  public isSmartTop = false;

  protected ngUnsubscribe      = new Subject();
  public invoice: Invoice;
  protected url: string;
  public additionalContacts: Customer[][];
  public billToCustomer      = [];
  public shipToCustomer      = [];
  public shipToContact       = [];
  public billToContact       = [];
  public additionalAddresses = [];
  protected fileList: File[] = [];
  public showFiles: any[]    = [];

  public customer: Customer;
  public companyTitle: string;

  public editorConfig = {
    'editable': true,
    'spellcheck': true,
    'height': '450px',
    'maxHeight': '600px',
    'minHeight': '0',
    'width': 'auto',
    'minWidth': '0',
    'translate': 'yes',
    'enableToolbar': true,
    'showToolbar': true,
    'imageEndPoint': '',
    'toolbarHiddenButtons': [
      [
        'insertImage',
        'insertVideo',
      ]
    ]
  };

  constructor(
    protected httpClient: HttpClient,
    protected pentadService: PentadService,
    protected route: ActivatedRoute,
    protected invoiceService: InvoiceService,
    protected customerService: CustomerService,
    @Inject(DOMAIN_CONFIG) protected config: DomainConfigInterface,
    protected domSanitizer: DomSanitizer,
    private indexService: IndexService,
  ) {
    this.url  = this.config.apiUrl;
    this.isUk = this.config.isUk;
  }

  ngOnInit() {

    this.indexService.title.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(title => {
      this.companyTitle = title;
    });

    this.customerService.customer.pipe(
      filter(data => data !== null),
      takeUntil(this.ngUnsubscribe)
    ).subscribe((customer: Customer) => {

      setTimeout(() => {
        this.customer = customer;
        this.indexService.setTitle(customer);
      }, 0);
    })
    ;

    this.route.paramMap.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((params: ParamMap) => {
      this.invoiceService.getInvoice(parseInt(params.get('id')));
    });

   let invoiceObservable = this.invoiceService.invoice.pipe(
      filter(data => data !== null),
      takeUntil(this.ngUnsubscribe),
    );

    invoiceObservable.pipe(
      concatMap((invoice: Invoice) => {
        this.invoice = invoice;

        this.customerService.getResource(parseInt(invoice.relationships.billToCustomer.id));

        const sources = [this.customerService.getChildren(invoice.relationships.billToCustomer)];

        if (invoice.relationships.billToCustomer.id != invoice.relationships.shipToCustomer.id) {
          sources.push(this.customerService.getChildren(invoice.relationships.shipToCustomer));
        }

        return forkJoin(sources);
      })
    ).subscribe((children: Customer[][]) => {

      this.invoice.relationships.billToContactPerson.relationships.emails.forEach(element => {
        const contactValue = element.attributes.ContactValue.toLowerCase();
        if (this.billToContact.indexOf(contactValue) < 0) {
          this.billToContact.push(contactValue);
        }
      });

      if (this.invoice.relationships.shipToContactPerson.id !== this.invoice.relationships.billToContactPerson.id) {
        this.invoice.relationships.shipToContactPerson.relationships.emails.forEach(element => {
          const contactValue = element.attributes.ContactValue.toLowerCase();
          if (this.shipToContact.indexOf(contactValue) < 0) {
            this.shipToContact.push(contactValue);
          }
        });
      }

      this.invoice.relationships.billToCustomer.relationships.emails.forEach(element => {
        const contactValue = element.attributes.ContactValue.toLowerCase();
        if (this.billToCustomer.indexOf(contactValue) < 0) {
          this.billToCustomer.push(contactValue);
        }
      });

      if (this.invoice.relationships.shipToCustomer.id !== this.invoice.relationships.billToCustomer.id) {
        this.invoice.relationships.shipToCustomer.relationships.emails.forEach(element => {
          const contactValue = element.attributes.ContactValue.toLowerCase();
          if (this.shipToCustomer.indexOf(contactValue) < 0) {
            this.shipToCustomer.push(contactValue);
          }
        });
      }

      this.invoice.relationships.billToCustomer.relationships.individuals.forEach(contact => {
        contact.relationships.emails.forEach(email => {
          const contactValue = email.attributes.ContactValue.toLowerCase();
          if (contactValue === "") {
            return;
          }

          if (this.billToCustomer.indexOf(contactValue) > -1) {
            return;
          }

          if (this.billToContact.indexOf(contactValue) > -1) {
            return;
          }

          if (this.shipToCustomer.indexOf(contactValue) > -1) {
            return;
          }

          if (this.shipToContact.indexOf(contactValue) > -1) {
            return;
          }

          this.additionalAddresses.push(contactValue);
        })
      });

      children.forEach(childList => {
        if (!childList.length) {
          return;
        }

        childList.forEach(child => {
          if (!child.relationships.emails.length) {
            return;
          }

          child.relationships.emails.forEach(childEmail => {
            const contactValue = childEmail.attributes.ContactValue.toLowerCase();
            if (this.additionalAddresses.includes(contactValue)) {
              return;
            }

            this.additionalAddresses.push(contactValue);
          })
        })
      });
    });
  }

  onFileChanged(event: any) {
    for (var i = 0; i <= event.target.files.length - 1; i++) {
      var selectedFile = event.target.files[i];
      this.fileList.push(selectedFile);
      this.showFiles.push(selectedFile.name)
    }

    this.attachment.nativeElement.value = '';
  }

  removeSelectedFile(index) {
    this.showFiles.splice(index, 1);
    this.fileList.splice(index, 1);
  }

  public getContactEmail(individual?: Customer): Contact {
    if (!individual.relationships.contacts) {
      individual.relationships.contacts = [];
    }
    let cont: Contact = individual.relationships.contacts.find((contact: Contact) => contact.attributes.ContactType === 4);
    if (!cont) {
      cont = copyObject(defaultWorkContacts[4]);
      individual.relationships.contacts.push(cont);
    }

    return cont;
  }

  openLegacyEmailer() {

  }

  async addPackingList(event: MouseEvent) {
    event.preventDefault();
    event.target['children'][0]['classList'].remove("d-none");

    const url = await this.pentadService.setPentadSecurity(this.config.baseUrl +
      'internal/Reporting/Reports.aspx?disposition=inline&PentadSecurity&ReportID=PACKING-LIST&PDF=true&Parameters=Invoice%3d' +
      this.invoice.attributes.InvoiceNumber)
    ;

    this.httpClient.get(url, {responseType: 'blob'})
      .subscribe((res: any) => {
        this.packingListBlob = new File([res], `Leisure Concepts Packing List ${this.invoice.attributes.InvoiceNumber}.pdf`);
        this.packingList     = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(res))
      })
    ;
  }

  submitForm($event: any) {
    this.submitButton.nativeElement.children[0].classList.remove('d-none');
  }
}
