import {Component, EventEmitter, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-return-modal',
  templateUrl: './return-modal.component.html',
  styleUrls: ['./return-modal.component.scss']
})
export class ReturnModalComponent implements OnInit {
  public onClose: Subject<boolean>;
  public enableConfirm = true;
  public returnAmount: number = 0;
  public min: number = 0;
  public max: number = 0;
  public event: EventEmitter<any> = new EventEmitter();
  public itemName = '';

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  public confirm(): void {
    this.event.emit({ data: this.returnAmount});
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  public cancel(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

}
