import {StaticLogger} from "./static-logger";


export function deserializeJson(body) {
  let {data, included} = body;
  included             = !included ? [] : included;
  data                 = !data ? {} : data;
  return deserialize(data, included);
}

// Must return null since that's the only valid empty representation for a Interface Typed variable
export function getSingleResourceRelation<T>(relationships, relation): T {
  if (relationships === undefined) {
    return null;
  }

  if (relationships[relation]) {
    return relationships[relation];
  }
  return null;
}

export function getMultipleResourceRelation<T>(relationships, relation): T[] {
  if (relationships === undefined) {
    return [];
  }

  if (relationships[relation]) {
    return relationships[relation];
  }
  return [];
}

/**
 * Return copied object or empty object is obj is falsey
 * @param obj
 * @returns {any}
 */
export function copyObject(obj: any) {
  if (!obj) {
    return {};
  }
  return JSON.parse(JSON.stringify(obj));
}

export function compareObjects(obj1, obj2): boolean {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function singleResourceBody(attributes) {
  const copiedAttrs = {...attributes};
  return {data: {attributes: copiedAttrs}};
}

function deserialize(data, included, deserialized: any = {}) {
  if (data instanceof Array) {
    return data.map((targetResource) => {

      const existingResource = getDeserialized(targetResource, deserialized);
      if (!existingResource) {
        return deserializeResource(targetResource, included, deserialized);
      }

      if (typeof targetResource.relationships == 'undefined') {
        return existingResource;
      }

      let hasBetterVersion = typeof existingResource.relationships == 'undefined';
      hasBetterVersion = hasBetterVersion || Object.keys(existingResource.relationships).length < Object.keys(targetResource.relationships).length;

      if (hasBetterVersion) {
        return deserializeResource(targetResource, included, deserialized);
      }

      return existingResource;
    }).filter((item) => item !== undefined);
  }
  const existingResource = getDeserialized(data, deserialized);
  if (!existingResource) {
    return deserializeResource(data, included, deserialized);
  }
  return existingResource;
}

function getDeserialized(resource, deserialized) {
  if (!resource) {
    return false;
  }
  const type: string = resource.type;
  const id: string   = resource.id;
  if (!deserialized[type]) {
    deserialized[type] = {};
  }
  const typeInstances: any = deserialized[type];
  const existingInstance   = typeInstances[id];
  if (existingInstance) {
    return existingInstance;
  }
  typeInstances[id] = resource;
  return false;
}

function deserializeResource(resource, included, deserialized: any) {
  const {relationships} = resource;
  if (relationships === undefined) {
    return resource;
  }
  for (const relationshipKey of Object.keys(relationships)) {

    const relationship = relationships[relationshipKey].data;
    // no need to deserialize a relationship that has already been deserialized since data does not exist on a deserialized resource
    if (relationship === undefined) {
      continue;
    }
    const data = getRelatedResources(included, relationship);
    relationships[relationshipKey] = deserialize(data, included, deserialized);
  }
  resource.relationships = relationships;
  return resource;
}

function getRelatedResources(included: Array<any>, relationship) {
  if (!(relationship instanceof Array)) {
    return getIncludedResource(included, relationship);
  }

  return relationship.map((relation) => {
    return getIncludedResource(included, relation);
  });
}

function getIncludedResource(included: Array<any>, relationship) {
  return included.find((resource) => {

    if (resource.type === relationship.type && resource.id == relationship.id && resource.id !== relationship.id) {
      StaticLogger.get().logger.info('info', {
        "message": "=== check failed for " + resource.type + " " + resource.id,
        "resource": resource,
        "relationship": relationship
      });
    }

    return resource.type === relationship.type && resource.id == relationship.id;
  });
}
