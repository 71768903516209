import {PentadService} from '../../../../services/pentad.service';
import {Component, ErrorHandler, Inject, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Invoice} from '../../../../models/invoice.interface';
import {InvoiceService} from '../../../../services/observables/invoice.service';
import {Subscription} from 'rxjs';
import {InventoryItemService} from '../../../../services/observables/inventory-item.service';
import {
  INVOICE_LINE_ITEM_TYPE_INVENTORY,
  INVOICE_LINE_ITEM_TYPE_OTHER,
  INVOICE_LINE_ITEM_TYPE_RETURN,
  INVOICE_LINE_ITEM_TYPE_SMARTTOP,
  INVOICE_STATUS_COMPLETED,
} from '../../../../utils/constants.util';
import {TaxableService} from '../../../../services/observables/taxable.service';
// import { Http, Response, RequestOptions } from '@angular/http';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {LoadingService} from '../../../../services/observables/loading.service';
import {QueryService} from '../../../../services/observables/query.service';
import {SendToShippingService} from '../../../../services/send-to-shipping.service';
import {createOptions} from '../../../../utils/http.util';
import {InvoiceConfirmation} from '../../../../models/invoice-confirmation.interface';
import {ComputedFreightInfoService} from '../../../../services/observables/computed-freight-info.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {WarningModalComponent} from '../../../shared/components/warning-modal/warning-modal.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {SmartopItemsModalComponent} from '../smartop-items-modal/smartop-items-modal.component';
import {getLineItemsByType} from '../../../../utils/invoice.util';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";

declare let jQuery: any;
declare let window: any;

@Component({
  selector: '[jet-invoice-tab]',
  templateUrl: './invoice-tab.component.html',
  styleUrls: ['./invoice-tab.component.scss']
})
export class InvoiceTabComponent implements OnInit, OnDestroy {

  public invoice: Invoice;
  public isSmartopInvoice: boolean;
  public smartopLineItemType = INVOICE_LINE_ITEM_TYPE_SMARTTOP;
  public inventoryLineItemType = INVOICE_LINE_ITEM_TYPE_INVENTORY;
  public otherLineItemType = INVOICE_LINE_ITEM_TYPE_OTHER;
  public returnedLineItemType = INVOICE_LINE_ITEM_TYPE_RETURN;
  private invoiceSubscription: Subscription;
  private alert_message: string;
  public invoiceIsLoading = true;
  public firstUPSIframeLoad = true;
  public confirmation: InvoiceConfirmation = null;
  public confirmations: InvoiceConfirmation[] = null;
  public dateFormat: string;
  public isUk: boolean;
  private upsIframe: any;
  private ngUnsubscribe = new Subject();
  public bsModalRef: BsModalRef;
  @ViewChild('invoiceModificationsComponent') invoiceModificationsComponent;
  public returns: boolean;
  public customerId: any;

  constructor(
    private route: ActivatedRoute,
    private invoiceService: InvoiceService,
    private queryService: QueryService,
    private inventoryItemService: InventoryItemService,
    private taxableService: TaxableService,
    private httpClient: HttpClient,
    private loadingService: LoadingService,
    public sendToShippingService: SendToShippingService,
    private computedFreightInfoService: ComputedFreightInfoService,
    private pentadService: PentadService,
    private router: Router,
    private bsModalService: BsModalService,
    private errorHandler: ErrorHandler,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
    ) {
    this.isUk = this.config.isUk;
    this.dateFormat = this.config.dateFormat;
  }

  ngOnInit() {
    this.inventoryItemService.loadInventoryItemsByType('smartop');
    this.listenToInvoiceSubscription();

    this.invoiceSubscription = this.invoiceService.iframe.subscribe( (iframe) => {
      if (iframe === null || this.isUk) {
        return;
      }
      this.upsIframe = iframe;
      this.upsIframe.addEventListener('load', this.UPSIframeOnLoad);
    });

    this.route.queryParams.subscribe(params => {
      this.customerId = params['customer_id'];
    });

    const bc = new BroadcastChannel('customer_' + this.customerId + '_customer');
    bc.onmessage = (ev) => {
      this.alert_message = ev.data['message'];
    };
  }

  private UPSIframeOnLoad = async() => {
    if (!this.firstUPSIframeLoad) {
      try {
        const invoice: Invoice = await this.invoiceService.fetchInvoice(this.invoice.attributes.InvoiceID).toPromise();
        this.invoice.attributes.FreightPrice = invoice.attributes.FreightPrice;
        this.invoice.attributes.FreightCost =  invoice.attributes.FreightCost;
        this.invoice.attributes.FreightTrackingNumber = invoice.attributes.FreightTrackingNumber;
        this.invoice.attributes.Codes_StatusIDfk = invoice.attributes.Codes_StatusIDfk;
        this.computedFreightInfoService.computeNext(this.invoice);
      } catch (e) {
        this.errorHandler.handleError(e);
      }
    }
    this.firstUPSIframeLoad = false;
  };

  listenToInvoiceSubscription() {
    this.invoiceSubscription = this.invoiceService.invoice.subscribe((invoice: Invoice) => {
      if (!invoice) {
        this.invoiceIsLoading = true;
        return; // can't access property of invoice if null/undefined
      }

      // Get most recent invoice confirmation request
      if (typeof invoice.relationships.confirmations !== 'undefined' && invoice.relationships.confirmations !== null) {
        this.confirmations = invoice.relationships.confirmations.sort(function(a, b) {
          return b.attributes.InvoiceConfirmationID - a.attributes.InvoiceConfirmationID;
        });
        if (this.confirmations.length > 0) {
          this.confirmation = this.confirmations[0];
        }
      }

      this.invoiceIsLoading = false;
      this.invoice = invoice;
      this.taxableService.refreshTaxableByInvoice(this.invoice);
      this.isSmartopInvoice = this.invoice.attributes.InvoiceType ? true : false;
      this.alert_message = this.loadingService.getFlash();

      // Check for returned items
      for(const item of this.invoice.relationships.lineItems) {
        if (item.attributes.Quantity < 0) {
          this.returns = true;
          return;
        }
        this.returns = false;
      }

      const bc = new BroadcastChannel('invoice_' + invoice.id);
      bc.onmessage = (ev) => {
        this.alert_message = ev.data['message'];
        if (this.alert_message === 'CTA Email Sent') {
          window.location.reload();
        }
      };
    });
  }

  ngOnDestroy() {
    if (!this.isUk) {
      this.upsIframe.removeEventListener('load', this.UPSIframeOnLoad);
    }
    this.stopListeningToInvoiceSubscription();
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  stopListeningToInvoiceSubscription() {
    if (this.invoiceSubscription && this.invoiceSubscription.unsubscribe) {
      this.invoiceSubscription.unsubscribe();
    }
  }

  async sendToShipping() {

    let canContinue = await this.sendToShippingService.negativeQuantityCheck(this.invoice);
    if (!canContinue) {
      return;
    }

    canContinue = await this.sendToShippingService.dateShippedCheck(this.invoice);
    if (!canContinue) {
      return;
    }

    canContinue = await this.sendToShippingService.balanceCheck(this.invoice);
    if (!canContinue) {
      return;
    }

    try {
      const result = await this.sendToShippingService.openSendToShippingModal();
    } catch (e) {
      this.errorHandler.handleError(e);
    }
  }

  public emailCTA() {
    if (environment.emailer === 'jet') {
      this.openPage('/invoices/' + this.invoice.id + '/emails/cta');
    } else if (environment.emailer === 'legacy') {
      this.openPage('/internal/Managing/CRMS/STM/EmailCallToAction.aspx?PentadSecurity&InvoiceID=' + this.invoice.id);
    }
  }

  public matchOrders() {
    this.openPage('/internal/Managing/CRMS/MatchOrders.aspx?PentadSecurity');
  }

  public emailInvoice() {

    if (environment.emailer === 'jet') {
      this.openPage('/invoices/' + this.invoice.id + '/emails/invoice');
    } else if (environment.emailer === 'legacy') {
      this.openPage('/internal/Managing/CRMS/STM/EmailInvoice.aspx?PentadSecurity&InvoiceID=' + this.invoice.id);
    }
  }

  public hasWebOrder(): boolean {
    if (this.invoice.relationships.webOrder) {
      return true;
    }
    return false;
  }

  public webOrder() {
    this.openPage('/internal/Managing/CRMS/STM/ViewWebOrder.aspx?PentadSecurity&WebOrderID=' + this.invoice.relationships.webOrder.id);
  }

  public printInvoice() {
    let reportId = '5102405A-F61D-48FB-ADFA-7C81805C9EAF';
    if (this.isSmartopInvoice) {
      reportId = 'F891702B-B5AA-4EBD-B3C9-3898BDA4EBB2';
    }
    this.openInvoiceRelatedReport(reportId);
  }

  public printPackingList() {
    let reportId = 'E9D4C1FD-CBF5-4C3B-B670-C0D9C31F7F84';
    if (this.isSmartopInvoice) {
      reportId = '2E0A039D-230A-45F8-BFCF-09C73374F5F7';
    }
    this.openInvoiceRelatedReport(reportId);
  }

  public printTravelers() {
    const reportId = '64E41DC7-7403-43C8-A408-94556AD62FA0';
    this.openInvoiceRelatedReport(reportId);
  }

  public openInvoiceRelatedReport(reportId: string) {
    this.openPage(this.config.baseUrl
      + 'internal/Reporting/Reports.aspx?disposition=inline&PentadSecurity&ReportID='
      + reportId
      + '&PDF=true&Parameters=Invoice%3d'
      + this.invoice.attributes.InvoiceNumber);
  }

  public printSmartopLabels() {
    this.printSmartopReport('smartop-labels');
  }

  public async printSomeSmartopLabels() {
    this.bsModalRef = this.bsModalService.show(SmartopItemsModalComponent);
    this.bsModalRef.content.canPrint = await this.canPerformInvoiceAction();
    const invoiceItems = await getLineItemsByType(this.invoice, 3);
    for (const item of invoiceItems){
      item.attributes.isChecked = false;
    }
    this.bsModalRef.content.invoiceItems = invoiceItems;
    this.bsModalRef.content.pentadService = this.pentadService;
    this.bsModalRef.content.reportType = 'smartop-some';
    this.bsModalRef.content.invoiceId = this.invoice.id;
    this.bsModalRef.content.loadingService = this.loadingService;
    this.bsModalRef.content.httpClient = this.httpClient;
  }

  public printSmartopCPL() {
    this.printSmartopReport('smartop-cpl');
  }

  public async printSmartopAll() {
    const loader = this.loadingService.newLoader();
    loader.start();
    await this.saveInvoice();
    await this.printSmartopReport('smartop-all');

    //Reload the invoice to refresh the invoice lineitems to show the new CIN
    this.invoiceService.loadInvoice(this.invoice.attributes.InvoiceID).subscribe( () => loader.stop());
  }

  public async printSmartopReport(reportType: string) {
    const canPrint = await this.canPerformInvoiceAction();
    if (!canPrint) {
      return; // don't do anything
    }
    const path = await this.pentadService.setPentadSecurity('print-reports?PentadSecurity');
    const url = this.config.apiUrl + path;
    const body = {
      reportType,
      invoiceId: this.invoice.id
    };
    const options = createOptions();
    const loader = this.loadingService.newLoader();
    loader.start();
    await this.httpClient.post<any>(url, body, options).toPromise();

    loader.stop();
  }

  public async copyInvoice() {

    const canCopyInvoice = await this.canPerformInvoiceAction();
    if (!canCopyInvoice) {
      this.loadingService.setFlash('Can\'t copy invoice.');

      return;
    }

    const loader = this.loadingService.newLoader();

    const customerId = this.invoice.attributes.Customers_BillToCustomerIDfk;

    let data: any = {};
    try {
      data = await this.httpClient.post<any>(this.config.apiUrl + 'invoices/copy/' + this.invoice.id, null)
        .toPromise()
      ;

    } catch (e) {
      this.errorHandler.handleError(e);
      loader.stop();
      return;
    }

    this.queryService.clearHistory();
    this.loadingService.setFlash('New invoice copy was created.');

    // Force reload because old invoice info was sticking around.
    window.location = this.router.createUrlTree(['invoice/primary'], {
      queryParams: {invoice_id: data.new, customer_id: customerId},
    }).toString();
  }

  public async openPage(url: string) {
    const canOpenPage = await this.canPerformInvoiceAction();
    if (!canOpenPage) {
      return;
    }
    this.pentadService.setPentadSecurityAndNewTab(url);
  }

  private saveInvoice() {
    return new Promise((resolve, reject) => {
      this.invoiceService.updateInvoice().pipe(
          takeUntil(this.ngUnsubscribe)
      ).subscribe((data) => {
        resolve(data);
      }, (error) => {
        reject(error);
      })
      ;
    });
  }

  private async canPerformInvoiceAction(): Promise<boolean> {
    const loader = this.loadingService.newLoader();
    if (this.invoiceService.currentInvoice().attributes.Codes_StatusIDfk === INVOICE_STATUS_COMPLETED) {
      return true;
    }
    loader.start();
    try {
      await this.saveInvoice();
      loader.stop();
      return true;
    } catch (error) {
      this.errorHandler.handleError(error);
      loader.stop();
      const message = [];
      for (const err of error.error.errors)  {
        message.push(err.detail);
      }
      this.loadingService.refreshIsLoading(false);
      this.bsModalRef = this.bsModalService.show(WarningModalComponent);
      this.bsModalRef.content.warnings = message;
      return false;
    }
  }

  onClickPayments($event) {
    this.openPage('/Internal/Managing/CRMS/STM/PayPalManagement.aspx?&PentadSecurity&CustomerID='
      + this.invoice.attributes.Customers_BillToCustomerIDfk
      + '&InvoiceID='
      + this.invoice.attributes.InvoiceID);
  }
}
