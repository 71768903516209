<div class="card">
  <div class="card-heading" data-toggle="collapse" data-target="#customer-contact-info">
    <h3 class="caps card-title">
      Contact Info
      <a class="caret"></a>
    </h3>
    <div class="card-subheading float-right">
      <div class="text-right">
      </div>
    </div>
  </div>
  <div class="card-body collapse show fivepad" id="customer-contact-info">
    <div class="form-subgroup">
      <div class="row">
        <label class="col-md-5 col-form-label"><b>First</b></label>
        <div class="col-md-7 zeropad">
          <input [(ngModel)]="customer.attributes.FirstName" type="text" class="small-form">
        </div>
      </div>
      <div class="row">
        <label class="col-md-5 col-form-label"><b>Middle</b></label>
        <div class="col-md-7 zeropad">
          <input [(ngModel)]="customer.attributes.MiddleName" type="text" class="small-form">
        </div>
      </div>
      <div class="row">
        <label class="col-md-5 col-form-label"><b>Last</b></label>
        <div class="col-md-7 zeropad">
          <input [(ngModel)]="customer.attributes.LastName" type="text" class="small-form">
        </div>
      </div>
      <div class="row">
        <label class="col-md-5 col-form-label"><b>Title</b></label>
        <div class="col-md-7 zeropad">
          <input [(ngModel)]="customer.attributes.Title" type="text" class="small-form">
        </div>
      </div>
    </div>
  </div>
</div>
