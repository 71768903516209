import {take} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {createOptions} from '../../utils/http.util';
import {HttpClient} from '@angular/common/http';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../config/domain.interface";


@Injectable()
export class QueryService {
  private url: string;

  private invoiceSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  private invoiceObservable: Observable<any>   = this.invoiceSubject.asObservable();

  private productSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  private productObservable: Observable<any>   = this.productSubject.asObservable();

  private lastInvoiceCustomerId: number;
  private lastProductCustomerId: number;

  constructor(
    private httpClient: HttpClient,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
  ) {
    this.url = `${this.config.apiUrl}query/`;
  }

  public clearHistory() {
    this.clearCustomerInvoiceHistory();
    this.clearCustomerProductHistory();
  }

  public isInvoiceHistoryLoading() {
    return this.lastInvoiceCustomerId == null;
  }

  public clearCustomerInvoiceHistory() {
    this.lastInvoiceCustomerId = null;
    this.invoiceSubject.next(null);
  }

  public getCustomerInvoiceHistory(customerId: number, force: boolean = false) {

    if (!force && this.invoiceSubject.value && this.lastInvoiceCustomerId === customerId) {
      return this.invoiceObservable;
    }

    this.lastInvoiceCustomerId = null;

    const options = createOptions();
    this.httpClient.get<any>(`${this.url}customer-invoice-history/${customerId}`, options).pipe(
      take(1))
      .subscribe(
        (data) => {
          this.invoiceSubject.next(data);
          this.lastInvoiceCustomerId = customerId;
        })
    ;

    return this.invoiceObservable;
  }

  public refreshInvoiceHistory(customerId: number) {
    const maxDate = new Date(Math.max.apply(null, this.invoiceSubject.value.map(function (e) {
      return new Date(e.LastUpdatedDate);
    })));

    const options = createOptions();
    this.httpClient.get<any>(`${this.url}refresh-customer-invoice-history/${customerId}/${maxDate.toISOString()}`, options).pipe(
      take(1))
      .subscribe(
        (data) => {
          if (data) {
            this.getCustomerInvoiceHistory(customerId, true);
          }
        });
  }

  public isProductHistoryLoading() {
    return this.lastProductCustomerId == null;
  }

  public clearCustomerProductHistory() {
    this.lastProductCustomerId = null;
    this.productSubject.next(null);
  }

  public getCustomerProductHistory(customerId: number) {

    if (this.productSubject.value && this.lastProductCustomerId === customerId) {
      return this.productObservable;
    }

    this.lastProductCustomerId = null;

    const options = createOptions();
    this.httpClient.get<any>(`${this.url}customer-product-history/${customerId}`, options).pipe(
      take(1))
      .subscribe(
        (data) => {
          this.productSubject.next(data);
          this.lastProductCustomerId = customerId;
        })
    ;

    return this.productObservable;
  }

  public getProductHistoryLastInvoices(customerId: number, inventoryItemId: number) {
    const options = createOptions();
    return this.httpClient.get<any>(`${this.url}customer-product-history-last-invoices/${customerId}/${inventoryItemId}`, options);
  }
}
