import { Component, OnInit } from '@angular/core';
import {HistoryWrapper} from "../../../../models/history-wrapper";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'jet-modification-modal',
  templateUrl: './modification-modal.component.html',
  styleUrls: []
})
export class InvoiceModificationPopupComponent implements OnInit {

  public title: string = 'Modification';
  public history: HistoryWrapper;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

}
