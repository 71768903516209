import { Component, OnInit } from '@angular/core';
import {LoadingService} from '../../../../services/observables/loading.service';
import {AuthService} from '../../../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'jet-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.scss']
})
export class ImpersonateComponent implements OnInit {

  constructor(
      private loadingService: LoadingService,
      private authService: AuthService,
      private router: Router,
              ) {}

  ngOnInit() {
  }

  public impersonate(userId) {
    if (userId === '') {
      return;
    }
    const loader = this.loadingService.newLoader().start();
    this.authService.impersonate(userId)
        .subscribe(() => {
          this.router.navigate(['/invoice/customer-search']);
          loader.stop();
          location.reload();
        })
    ;
  }

}
