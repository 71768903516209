import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';
import {QueryService} from '../../../../services/observables/query.service';
import {CustomerService} from '../../../../services/observables/customer.service';
import {Customer} from '../../../../models/customer.interface';
import {Subscription} from 'rxjs';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: '[jet-history-tab]',
  templateUrl: './history-tab.component.html',
  styleUrls: ['./history-tab.component.scss']
})
export class HistoryTabComponent implements OnInit, OnDestroy {
  private snapshot: ActivatedRouteSnapshot;
  public customer: Customer;
  public customerId = 0;
  private customerSubscription: Subscription;
  private ngUnsubscribe = new Subject();

  constructor(
      private route: ActivatedRoute,
      private customerService: CustomerService,
  ) {
    this.snapshot = route.snapshot;
  }

  ngOnInit() {
    this.customerSubscription = this.customerService.customer.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((newCustomer: Customer) => {
      this.customer = newCustomer;
    });
  }

  ngOnDestroy(): void {
    this.customerSubscription.unsubscribe();
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
