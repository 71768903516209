<div class="card">
  <div class="card-heading" data-toggle="collapse" data-target="#invShipTo">
    <h3 class="caps card-title">
      <span>ship to</span> <i class="fa fa-address-card-o fa-lg"></i> <a class="caret"></a>
    </h3>
  </div>
  <div class="card-body collapse show fivepad" id="invShipTo">
    <ng-container *ngIf="invoice">
      <div class="form-group row">
        <div class="col col-md-4 col-sm-12">
          <div class="row pad">
            <label class="col-md-5 col-form-label">Customer</label>
            <div class="col-md-7 zeropad">
              <ng-container *ngIf="invoice.attributes.OneTimeShip == false">
                <input
                  [(ngModel)]="customerFullName"
                  [typeahead]="dataSource"
                  (typeaheadLoading)="changeTypeaheadLoading($event)"
                  (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                  (typeaheadOnSelect)="typeaheadOnSelect($event)"
                  [typeaheadOptionsLimit]="100"
                  [typeaheadScrollable]="true"
                  [typeaheadOptionsInScrollableView]="10"
                  [typeaheadMinLength]="0"
                  [typeaheadWaitMs]="300"
                  [typeaheadOptionField]="'attributes.FullName'"
                  placeholder="Customer search by text"
                  class="small-form"
                >
              </ng-container>
              <ng-container *ngIf="invoice.attributes.OneTimeShip">
                <input class="small-form" [(ngModel)]="invoice.attributes.OneTimeShipOrganization">
              </ng-container>

            </div>
          </div>

          <div class="row pad">
            <label class="col-md-5 col-form-label">Contact</label>
            <ng-container *ngIf="invoice.attributes.OneTimeShip == false">
              <div class="col-md-7 zeropad">
                <select #contactSelect
                        [(ngModel)]="invoice.attributes.Customers_ShipToContactPersonIDfk"
                        class="small-form"
                        (ngModelChange)="updateContact($event)">
                  <option [ngValue]="0">None</option>
                  <option
                    *ngFor="let individual of contacts" [ngValue]="individual.attributes.CustomerID"
                  >
                    {{ individual.attributes.FullName }}
                  </option>
                </select>
              </div>
            </ng-container>

            <ng-container *ngIf="invoice.attributes.OneTimeShip">
              <div class="col-md-3 zeropad">
                <input class="small-form" [(ngModel)]="invoice.attributes.OneTimeShipFirstName"></div>
              <div class="col-md-3 zeropad">
                <input class="small-form" [(ngModel)]="invoice.attributes.OneTimeShipLastName"></div>
            </ng-container>
          </div>

          <div class="form-subgroup">
            <div class="row">
              <label class="col-md-5 col-form-label">Phone</label>
              <ng-container
                *ngIf="invoice.attributes.OneTimeShip == false && invoice.relationships.shipToCustomer?.relationships?.phoneNumbers">
                <div class="col-md-6 zeropad">
                  <select
                    class="small-form"
                    [compareWith]="comparebyId"
                    [ngModel]="invoice.relationships.shipToPhone"
                    (ngModelChange)="contactChange(invoice.relationships.shipToPhone, $event)"
                  >
                    <option [ngValue]="defaultPhone">New</option>
                    <option *ngIf="currentPhone.id != 0" [ngValue]="currentPhone">Current
                      - {{ currentPhone.attributes.ContactValue }}</option>
                    <optgroup label="Customer Phone Numbers">
                      <option
                        *ngFor="let phone of invoice.relationships.shipToCustomer.relationships.phoneNumbers"
                        [ngValue]="phone"
                      >
                        {{ phone.attributes.Description }} - {{ phone.attributes.ContactValue }}
                      </option>
                    </optgroup>
                    <optgroup label="Contact Phone Numbers">
                      <option
                        *ngFor="let phone of shipToContactPhoneNumbers" [ngValue]="phone"
                      >
                        {{ phone.attributes.Description }} - {{ phone.attributes.ContactValue }}
                      </option>
                    </optgroup>
                  </select>
                </div>

                <div class="col-md-1 zeropad">
                  <span class="fa fa-minus"></span>
                </div>
              </ng-container>
            </div>

            <div class="row row2">
              <label class="col-md-5 col-form-label">&nbsp;</label>

              <div class="col-md-2 zeropad">
                <ng-container *ngIf="invoice.relationships?.shipToPhone">
                  <input [(ngModel)]="invoice.relationships.shipToPhone.attributes.Description" type="text"
                         class="small-form" placeholder="Description">
                </ng-container>
              </div>

              <div class="col-md-5 onepad">
                <ng-container *ngIf="invoice.relationships?.shipToPhone">
                  <input [(ngModel)]="invoice.relationships.shipToPhone.attributes.ContactValue" type="text"
                         class="small-form" placeholder="Value">
                </ng-container>
              </div>
            </div>
          </div>

          <div class="form-subgroup">
            <div class="row">
              <label class="col-md-5 col-form-label">Fax</label>
              <ng-container
                *ngIf="invoice.attributes.OneTimeShip == false && invoice.relationships.shipToCustomer.relationships">
                <div class="col-md-6 zeropad">
                  <select
                    class="small-form"
                    [compareWith]="comparebyId"
                    [ngModel]="invoice.relationships.shipToFax"
                    (ngModelChange)="contactChange(invoice.relationships.shipToFax, $event)"
                  >
                    <option [ngValue]="defaultFax">New</option>
                    <option *ngIf="currentFax.id != 0" [ngValue]="currentFax">Current</option>
                    <option
                      *ngFor="let fax of invoice.relationships.shipToCustomer.relationships.faxNumbers"
                      [ngValue]="fax"
                    >
                      {{ fax.attributes.Description }}
                    </option>
                  </select>
                </div>

                <div class="col-md-1 zeropad">
                  <span class="fa fa-minus"></span>
                </div>
              </ng-container>
            </div>

            <div class="row row2">
              <label class="col-md-5 col-form-label">&nbsp;</label>
              <div class="col-md-2 zeropad">
                <ng-container *ngIf="invoice.relationships?.shipToFax">
                  <input [(ngModel)]="invoice.relationships.shipToFax.attributes.Description" type="text"
                         class="small-form" placeholder="Description">
                </ng-container>
              </div>

              <div class="col-md-5 onepad">
                <ng-container *ngIf="invoice.relationships?.shipToFax">
                  <input [(ngModel)]="invoice.relationships.shipToFax.attributes.ContactValue" type="text"
                         class="small-form" placeholder="Value">
                </ng-container>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="text-right">
                  <input
                    (change)="oneTimeShipChange($event)"
                    (click)="oneTimeShipClicked($event)"
                    [(ngModel)]="invoice.attributes.OneTimeShip"
                    type="checkbox" checked
                  > One Time
                  <button type="button" id="resetShip" class="btn btn-xs btn-primary" (click)="resetShipToContact()">
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col col-md-4 col-sm-12">
          <div class="form-subgroup">
            <div class="row">
              <label class="col-md-5 col-form-label">address</label>
              <ng-container
                *ngIf="invoice.attributes.OneTimeShip == false && invoice.relationships?.shipToCustomer?.relationships">
                <div class="col-md-6 zeropad">
                  <select
                    class="small-form"
                    [compareWith]="comparebyId"
                    [ngModel]="invoice.relationships.shipToAddress"
                    (ngModelChange)="addressChange(invoice.relationships.shipToAddress, $event)"
                  >
                    <option [ngValue]="defaultAddress">New</option>
                    <option *ngIf="+currentAddress.id !== 0" [ngValue]="currentAddress">Current</option>
                    <optgroup label="Customer Addresses">
                      <option
                        *ngFor="let address of invoice.relationships.shipToCustomer.relationships.addresses"
                        [ngValue]="address"
                      >
                        {{ getAddressString(address) }}
                      </option>
                    </optgroup>
                    <ng-container *ngIf="shipToContactAddresses">
                      <optgroup label="Contact Addresses">
                        <option
                          *ngFor="let address of shipToContactAddresses" [ngValue]="address"
                        >
                          {{ getAddressString(address) }}
                        </option>
                      </optgroup>
                    </ng-container>
                  </select>
                </div>

                <div class="col-md-1 zeropad">
                  <span class="fa fa-minus"></span>
                </div>
              </ng-container>
            </div>

            <div class="row row2">
              <div class="col-md-6 onepad">
                <ng-container *ngIf="invoice.relationships?.shipToAddress">
                  <input [(ngModel)]="invoice.relationships.shipToAddress.attributes.Description" type="text"
                         class="small-form" placeholder="Location">
                </ng-container>
              </div>

              <div class="col-md-6 onepad">
                <ng-container *ngIf="invoice.relationships?.shipToAddress">
                  <select [(ngModel)]="invoice.relationships.shipToAddress.attributes.Description" class="small-form">
                    <option value="">--Select--</option>
                    <option value="Home">Home</option>
                    <option value="Office">Office</option>
                    <option value="Shipping">Shipping</option>
                    <option value="Billing">Billing</option>
                  </select>
                </ng-container>
              </div>
            </div>

            <div class="row row2">
              <div class="col-md-12 onepad">
                <ng-container *ngIf="invoice.relationships?.shipToAddress">
                  <input [(ngModel)]="invoice.relationships.shipToAddress.attributes.Line1" type="text"
                         class="small-form" placeholder="Line 1">
                </ng-container>
              </div>
            </div>

            <div class="row row2">
              <div class="col-md-12 onepad">
                <ng-container *ngIf="invoice.relationships?.shipToAddress">
                  <input [(ngModel)]="invoice.relationships.shipToAddress.attributes.Line2" type="text"
                         class="small-form" placeholder="Line 2">
                </ng-container>
              </div>
            </div>

            <ng-container *ngIf="invoice.relationships?.shipToAddress">
              <div class="row row2">
                <div class="col-md-10 onepad">
                  <input
                    [(ngModel)]="invoice.relationships.shipToAddress.attributes.City" type="text" class="small-form"
                    placeholder="City"
                  >
                </div>

                <div class="col-md-2 onepad">
                  <input
                    [(ngModel)]="invoice.relationships.shipToAddress.attributes.State" type="text" class="small-form"
                    placeholder="State"
                  >
                </div>
              </div>
              <div class="row row2">
                <div class="col-md-9 onepad">
                  <input
                    [(ngModel)]="invoice.relationships.shipToAddress.attributes.ZIP" type="text" class="small-form"
                    placeholder="Zip"
                  >
                </div>

                <div class="col-md-3 onepad">
                  <input
                    [(ngModel)]="invoice.relationships.shipToAddress.attributes.Plus4" type="text" class="small-form"
                    placeholder="+4"
                  >
                </div>
              </div>

              <div class="row row2">
                <div class="col-md-12 onepad">
                  <select [(ngModel)]="invoice.relationships.shipToAddress.attributes.Country" class="small-form">
                    <option
                      *ngFor="let country of countries" [ngValue]="country.attributes.CountryCode"
                    >
                      {{ country.attributes.CountryName }}
                    </option>
                  </select>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col col-md-4 col-sm-12 lastpad">
          <div class="form-subgroup">
            <div class="row row-stacked">
              <div class="col-md-12 onepad text-right">
                <label class="col-form-label onepad">message</label>
                <button (click)="updateMessage()" class="btn btn-xs btn-primary" type="button">Update</button>
              </div>
              <div class="col-md-12 zeropad">
                <textarea rows="2" [(ngModel)]="invoice.attributes.InvoiceMessage" class="form-control"></textarea>
              </div>
            </div>

            <div class="row row-stacked">
              <label class="col-md-12 col-form-label onepad">shipping instructions</label>
              <div class="col-md-12 zeropad">
                <textarea rows="2" [(ngModel)]="invoice.attributes.ShippingInstructions"
                          class="form-control"></textarea>
              </div>
            </div>

            <div class="row row-stacked">
              <div class="col-md-12 onepad text-right">
                <label class="col-form-label onepad">special instructions</label>
                <button (click)="resetSpecialInstructions()" class="btn btn-xs btn-primary" type="button">Reset</button>
              </div>
              <div class="col-md-12 zeropad">
                <textarea #specialInstructions rows="2" [(ngModel)]="invoice.attributes.DeliveryInstructions"
                          class="form-control"></textarea>
              </div>
            </div>
            <div *ngIf="confirmation" style="color: #AC3233; font-weight: bold; font-style: italic;">
              {{ confirmation.attributes.Notes.replace('PENDING - CREATED:', 'CTA Sent ') }}
            </div>
            <div class="row row-stacked">
              <div class="col-md-12 onepad text-right">
                <button (click)="saveCustomer(invoice.relationships.shipToCustomer.id)" class="btn btn-primary btn-xs">Update Source Customer</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
