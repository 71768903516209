<table class="table table-stripe table-hover table-borderless" style="font-size: 12px;">
    <colgroup>
        <col style="width:20%">
        <col style="width:20%">
        <col style="width:20%">
        <col style="width:40%">
    </colgroup>
    <thead>
    <tr>
        <th></th>
        <th>Billing</th>
        <th>Shipping</th>
        <th>Items</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let order of orders|async; let i = index">
        <tr [ngClass]="{ odd: (i+1) % 2}">
            <td>
                <dl class="row">
                    <dt class="col-sm-4">Company</dt>
                    <dd class="col-sm-8">{{ order.attributes.company }}</dd>

                    <dt class="col-sm-4">Order Date</dt>
                    <dd class="col-sm-8">{{ order.attributes.created_at | date }}</dd>

                    <dt class="col-sm-4">PO Number</dt>
                    <dd class="col-sm-8">{{ order.attributes.data?.ponumber | slice:0:40 }}</dd>
                </dl>
            </td>
            <td>
                <jet-smartop-address style="font-size: 10px"
                                     [address]="order.attributes.data?.billAddress"
                                     [country]="order.attributes.billToCountry"
                ></jet-smartop-address>
            </td>
            <td>
                <jet-smartop-address style="font-size: 10px"
                                     [address]="order.attributes.data?.shipAddress"
                                     [country]="order.attributes.billToCountry"
                ></jet-smartop-address>
            </td>
            <td>
                <dl *ngFor="let item of order.attributes.data?.items">
                    <dt>Customer: {{ item.customerName }}</dt>
                    <dt>Completion date: {{ item.completionOption | uppercase }} {{ item.completionDate }}</dt>
                    <dd>{{ item.style }} {{ item.decoShield }}
                        {{ item.measurementA }}(A)x{{ item.measurementB }}(B) H{{ item.hingeLength }}</dd>
                </dl>
            </td>
            <td style="vertical-align: middle;">
                <div class="btn-group">
                    <a type="button" class="btn btn-primary" [routerLink]="['/smartop', 'route', order.id]">Open</a>
                    <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <div class="dropdown-menu">
                        <button type="button" class="dropdown-item" (click)="deleteOrder(order)">Delete</button>
                        <button *ngIf="order.attributes.status === 'routed'" type="button" class="dropdown-item"
                                (click)="duplicateOrder(order)">Duplicate
                        </button>
                    </div>
                </div>
            </td>
        </tr>
    </ng-container>
    <tr *ngIf="orderCount === 0">
        <td colspan="7">No records to display</td>
    </tr>
    </tbody>
</table>
