export const SOURCE_TYPE_CUSTOMER = 0;
export const SOURCE_TYPE_INVOICE = 2;

// Invoice
export const INVOICE_TYPE_STANDARD = 0;
export const INVOICE_TYPE_SMARTOP = 1;

// Invoice LIne Item
export const INVOICE_LINE_ITEM_TYPE_INVENTORY = 0;
export const INVOICE_LINE_ITEM_TYPE_OTHER = 1;
export const INVOICE_LINE_ITEM_TYPE_CUSTOM = 2;
export const INVOICE_LINE_ITEM_TYPE_SMARTTOP = 3;
export const INVOICE_LINE_ITEM_TYPE_RETURN = 4;

// App Settings
export const APP_SETTING_FREIGHT_PALLET_WEIGHT = 35;
export const APP_SETTING_UK_FREIGHT_PALLET_WEIGHT = 15;
export const APP_SETTING_NAMES = {
  MINIMUM_SMARTOP_LENGTH: 'Minimum SMARTOP Length',
  MAXIMUM_SMARTOP_LENGTH: 'Maximum SMARTOP Length',
  SMARTOP_LENGTH_INTERVAL: 'SMARTOP Length Interval',
  MINIMUM_SMARTOP_WIDTH: 'Minimum SMARTOP Width',
  MAXIMUM_SMARTOP_WIDTH: 'Maximum SMARTOP Width',
  SMARTOP_WIDTH_INTERVAL: 'SMARTOP Width Interval',
};

// Contacts
export const CONTACT_TYPE_PHONE = 2;
export const CONTACT_TYPE_FAX = 3;
export const CONTACT_TYPE_EMAIL = 4;
export const CONTACT_TYPE_URL = 5;

// Customers
export const CUSTOMER_TYPE_NONE = -999;
export const CUSTOMER_TYPE_MASTER = -2;
export const CUSTOMER_TYPE_INDIVIDUAL = -1;
export const CUSTOMER_TYPE_CUSTOMER = 0;
export const CUSTOMER_TYPE_VENDOR = 1;
export const CUSTOMER_TYPE_INVOICE_INDIVIDUAL = 2;
export const CUSTOMER_TYPE_INVOICE_CUSTOMER = 3;

// Customer Link Types
export const CUSTOMER_LINK_TYPE_AFFILIATION = 0;
export const CUSTOMER_LINK_TYPE_INDIVIDUAL_HIERARCHY = 1;
export const CUSTOMER_LINK_TYPE_COMPANY_HIERARCHY = 2;

// Codes - id values
export const INVOICE_STATUS_COMPLETED = 169;
export const INVOICE_STATUS_CANCELLED = 170;
export const INVOICE_STATUS_IN_PROGRESS = 168;
export const INVOICE_STATUS_SENT_TO_SHIPPING = 1207;
export const INVOICE_STATUS_IN_MANUFACTURING = 1783;
export const INVOICE_STATUS_MANUFACTURING_COMPLETE = 1831;

export const INVOICE_TAX_STATUS_RETAIL = 1709;
export const INVOICE_TAX_STATUS_WHOLESALE = 1710;
export const NOTE_TYPES_GENERAL = 2;

// Codes - String to PHP Constant Names
export const FREIGHT_BUCKETS = 'FREIGHT_BUCKETS';
export const FREIGHT_COMPANIES = 'FREIGHT_COMPANIES';
export const INVOICE_TAX_STATUS = 'INVOICE_TAX_STATUS';
export const CUSTOMER_PAYMENT_TERMS = 'CUSTOMER_PAYMENT_TERMS';
export const MARKETING_SOURCE_INVOICE = 'MARKETING_SOURCE_INVOICE';
export const INVOICE_STATUS = 'INVOICE_STATUS';
export const INVOICE_PENDING_REASONS = 'INVOICE_PENDING_REASONS';
export const SMARTOP_RADII = 'SMARTOP_RADII';
export const SMARTOP_RADII_METRIC = 'SMARTOP_RADII_METRIC';
export const SMARTOP_SKIRTING = 'SMARTOP_SKIRTING';
export const SMARTOP_SKIRTING_METRIC = 'SMARTOP_SKIRTING_METRIC';
export const SMARTOP_LOCKS = 'SMARTOP_LOCKS';
export const SMARTOP_MOUNTS = 'SMARTOP_MOUNTS';
export const WARRANTY_REASON_CODES = 'WARRANTY_REASON_CODES';
export const WARRANTY_DEPARTMENT_CHARGED = 'WARRANTY_DEPARTMENT_CHARGED';
export const INVENTORY_COMMODITY_CODES = 'INVENTORY_COMMODITY_CODES';
export const UNITS_OF_MEASURE = 'UNITS_OF_MEASURE';
export const PRICE_LISTS = 'PRICE_LISTS';
//// Product Line Codes
export const PRODUCT_LINES = 'PRODUCT_LINES';
export const PRODUCT_LINES_LC = 'PRODUCT_LINES_LC';
export const PRODUCT_LINES_MC = 'PRODUCT_LINES_MC';
export const PRODUCT_LINES_RV = 'PRODUCT_LINES_RV';
export const PRODUCT_LINES_MP = 'PRODUCT_LINES_MP';
export const PRODUCT_LINES_STD = 'PRODUCT_LINES_STD';
export const PRODUCT_LINES_STC = 'PRODUCT_LINES_STC';
export const PRODUCT_LINES_STP = 'PRODUCT_LINES_STP';
export const PRODUCT_LINES_STA = 'PRODUCT_LINES_STA';
////// Dealer Type Codes
export const DEALER_TYPES_LC = 'DEALER_TYPES_LC';
export const DEALER_TYPES_MC = 'DEALER_TYPES_MC';
export const DEALER_TYPES_RV = 'DEALER_TYPES_RV';
export const DEALER_TYPES_MP = 'DEALER_TYPES_MP';
export const DEALER_TYPES_STD = 'DEALER_TYPES_STD';
export const DEALER_TYPES_STC = 'DEALER_TYPES_STC';

// Additional Fields
export const ADDITIONAL_FIELD_VAT_REGISTRATION_NUMBER = 'VAT_Registration_Number';
export const ADDITIONAL_FIELD_TAX_STATUS = 'Tax Status';
export const ADDITIONAL_FIELD_TAX_RATE = 'Tax Rate';
export const ADDITIONAL_FIELD_PREVIOUS_STORE_ID = 'Previous Store ID';

// Administrative Employees
export const ADMINISTRATIVE_EMPLOYEES = [
  { employeeName: 'Debbie Olson', employeeId: -1 },
  { employeeName: 'kay howard', employeeId: -2 },
  { employeeName: 'joetta kinney', employeeId: -3 },
  { employeeName: 'leisure', employeeId: -4 },
  { employeeName: 'heather rees', employeeId: -5 },
  { employeeName: '.', employeeId: -6 },
  { employeeName: '\'', employeeId: -7 },
  { employeeName: 'breanna  mashtare', employeeId: -8 },
  { employeeName: 'bre', employeeId: -9 },
  { employeeName: 'angie taylor', employeeId: -10 },
  { employeeName: 'kirk', employeeId: -11 },
  { employeeName: 'teresa', employeeId: -12 },
  { employeeName: 'brian', employeeId: -13 },
  { employeeName: 'k-rd', employeeId: -14 },
  { employeeName: 'meme', employeeId: -15 },
  { employeeName: 'sean', employeeId: -16 },
  { employeeName: 'shawn', employeeId: -17 },
  { employeeName: 'administrator', employeeId: -18 },
];
