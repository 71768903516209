import {catchError, map, tap} from 'rxjs/operators';
import {UserService} from './observables/user.service';
import {Inject, Injectable} from '@angular/core';
import {createOptions} from '../utils/http.util';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {throwError} from 'rxjs/internal/observable/throwError';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../config/domain.interface";
import {CookieService} from "ngx-cookie-service";

export const STORAGE_TOKEN = 'token';

@Injectable()
export class AuthService {

  private loginUrl: string;
  private passwordResetUrl: string;
  private loginCheck: string;
  private impersonateUrl: string;
  public bsModalRef: BsModalRef;

  constructor(private httpClient: HttpClient,
              private userService: UserService,
              private router: Router,
              private bsModalService: BsModalService,
              private cookieService: CookieService,
              @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
              ) {
    this.loginUrl = this.config.apiUrl + 'api_token';
    this.loginCheck = this.config.apiUrl + 'check';
    this.passwordResetUrl = this.config.baseUrl + 'password-recover/send-email';
    this.impersonateUrl = this.config.apiUrl + 'impersonate';
  }

  login(email: string, password: string) {
    const options = createOptions();
    return this.httpClient.post(this.loginUrl, {email, password}, options).pipe(
        tap(data => {
          localStorage.setItem('token', data['token']);
          return data;
        }),
        catchError(err => {
          return throwError(err);
        }),
    );
  }

  impersonate(userId: number) {
    const options = createOptions();
    return this.httpClient.post(this.impersonateUrl, {userId}, options).pipe(
        map((data) => {
          localStorage.setItem('token', data['token']);
        }),
        catchError(err => {
          return throwError(err);
        }),
    );
  }

  passwordReset(username: string) {
    const options = createOptions({}, {username});
    return this.httpClient.get(this.passwordResetUrl, options).pipe(
      map((response: Response) => {
        return response.json();
      }))
      .toPromise()
      ;
  }

  logout() {
    this.cookieService.delete('Secure', '/');
    localStorage.removeItem(STORAGE_TOKEN);
    this.userService.clearResource();
  }

  private async tokenCheck() {
     return await this.httpClient.post(this.loginCheck, {}).toPromise();
  }

  private hasStorageValue(itemKey: string) {
    const value: string = localStorage.getItem(itemKey);
    if (value) {
      return value && value.length;
    }
    return false;
  }
  /**
   * TODO this should actually check with the api to determine if the token is still valid
   *
   * @returns {boolean}
   */
  async isAuthenticated(): Promise<boolean> {
    if (!this.hasStorageValue(STORAGE_TOKEN)) {
      return false;
    }
    const data = await this.tokenCheck();
    return data['valid_token'];
  }

  public tokenExists() {
    if (!this.hasStorageValue(STORAGE_TOKEN)) {
      this.router.navigate(['login']);
      return false;
    }

    return true;
  }
}
