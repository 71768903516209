<div class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <table *ngIf="tableData.rows.length" class="table">
    <thead>
    <tr >
      <td *ngFor="let cell of tableData.head" >{{ cell }}</td>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of tableData.rows">
      <td *ngFor="let cell of row">{{ cell }}</td>
    </tr>
    </tbody>
  </table>

  <ul *ngIf="warnings.length">
    <li *ngFor="let message of warnings">{{message}}</li>
  </ul>

</div>
<div class="modal-footer">
  <button *ngFor="let button of buttons" type="button" class="btn" [ngClass]="button.btnClass" (click)="buttonClick(button)">{{button.text}}</button>
</div>
