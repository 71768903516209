<div class="card">
  <div class="card-heading" data-toggle="collapse" data-target="#invBillTo">
    <h3 class="caps card-title">
      <span>bill to</span> <i class="fa fa-address-card-o fa-lg"></i> <a class="caret"></a>
    </h3>
    <div class="card-subheading float-right">
      <div class="text-right">

      </div>
    </div>
  </div>
  <div class="card-body collapse show fivepad" id="invBillTo">
    <div class="form-group row">
      <div class="col col-md-4 col-sm-12">
        <div class="row pad">
          <label class="col-md-5 col-form-label">Customer</label>
          <div class="col-md-7 zeropad">
            <input
              [(ngModel)]="customerFullName"
              [typeahead]="dataSource"
              (typeaheadLoading)="changeTypeaheadLoading($event)"
              (typeaheadNoResults)="changeTypeaheadNoResults($event)"
              (typeaheadOnSelect)="typeaheadOnSelect($event)"
              [typeaheadOptionsLimit]="25"
              [typeaheadScrollable]="true"
              [typeaheadOptionsInScrollableView]="10"
              [typeaheadMinLength]="3"
              [typeaheadWaitMs]="300"
              [typeaheadOptionField]="'attributes.FullName'"
              placeholder="Customer search by text"
              class="small-form"
            >
          </div>
        </div>

        <div class="row pad">
          <label class="col-md-5 col-form-label">Contact</label>
          <div class="col-md-7 zeropad">
            <select #contactSelect
                    [(ngModel)]="invoice.attributes.Customers_BillToContactPersonIDfk"
                    class="small-form"
                    (ngModelChange)="updateContact($event)"
            >
              <option [ngValue]="0">None</option>
              <option
                *ngFor="let individual of contacts" [ngValue]="individual.attributes.CustomerID"
              >
                {{ individual.attributes.FullName }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-subgroup">
          <div class="row">
            <label class="col-md-5 col-form-label">Phone</label>
            <div class="col-md-6 zeropad">
              <ng-container
                *ngIf="invoice.relationships?.billToPhone && invoice.relationships?.billToCustomer?.relationships?.phoneNumbers">
                <select
                  #phoneSelect
                  class="small-form"
                  [compareWith]="comparebyId"
                  [ngModel]="invoice.relationships.billToPhone"
                  (ngModelChange)="contactChange(invoice.relationships.billToPhone, $event)"
                >
                  <option [ngValue]="defaultPhone">New</option>
                  <option *ngIf="currentPhone.attributes.ContactID !== 0" [ngValue]="currentPhone">
                    Current - {{ currentPhone.attributes.ContactValue }}
                  </option>
                  <optgroup label="Customer Phone Numbers">
                    <option
                      *ngFor="let phone of invoice.relationships.billToCustomer.relationships.phoneNumbers"
                      [ngValue]="phone"
                    >
                      {{ phone.attributes.Description }} - {{ phone.attributes.ContactValue }}
                    </option>
                  </optgroup>
                  <optgroup label="Contact Phone Numbers">
                    <option
                      *ngFor="let phone of billToContactPhoneNumbers" [ngValue]="phone"
                    >
                      {{ phone.attributes.Description }} - {{ phone.attributes.ContactValue }}
                    </option>
                  </optgroup>
                </select>
              </ng-container>
            </div>

            <div class="col-md-1 zeropad">
              <span class="fa fa-minus"></span>
            </div>
          </div>

          <div class="row row2">
            <label class="col-md-5 col-form-label">&nbsp;</label>

            <ng-container *ngIf="invoice.relationships?.billToPhone">
              <div class="col-md-2 zeropad">
                <input [(ngModel)]="invoice.relationships.billToPhone.attributes.Description" type="text"
                       class="small-form" placeholder="Description">

              </div>

              <div class="col-md-5 onepad">

                <input [(ngModel)]="invoice.relationships.billToPhone.attributes.ContactValue" type="text"
                       class="small-form" placeholder="Value">
              </div>
            </ng-container>
          </div>
        </div>

        <div class="form-subgroup">
          <div class="row">
            <label class="col-md-5 col-form-label">Fax</label>
            <div class="col-md-6 zeropad">
              <ng-container
                *ngIf="invoice.relationships?.billToFax && invoice.relationships?.billToCustomer?.relationships?.faxNumbers">
                <select
                  #faxSelect
                  class="small-form" [compareWith]="comparebyId" [ngModel]="invoice.relationships.billToFax"
                  (ngModelChange)="contactChange(invoice.relationships.billToFax, $event)"
                >
                  <option [ngValue]="defaultFax">New</option>
                  <option *ngIf="currentFax.attributes.ContactID !== 0" [ngValue]="currentFax">Current
                  </option>
                  <option
                    *ngFor="let fax of invoice.relationships.billToCustomer.relationships.faxNumbers" [ngValue]="fax"
                  >
                    {{ fax.attributes.Description }}
                  </option>
                </select>
              </ng-container>
            </div>

            <div class="col-md-1 zeropad">
              <span class="fa fa-minus"></span>
            </div>
          </div>

          <div class="row row2">
            <label class="col-md-5 col-form-label">&nbsp;</label>


            <ng-container *ngIf="invoice.relationships?.billToFax">
              <div class="col-md-2 zeropad">
                <input [(ngModel)]="invoice.relationships.billToFax.attributes.Description" type="text"
                       class="small-form" placeholder="Description">
              </div>

              <div class="col-md-5 onepad">
                <input [(ngModel)]="invoice.relationships.billToFax.attributes.ContactValue" type="text"
                       class="small-form" placeholder="Value">
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col col-md-4 col-sm-12">
        <div class="form-subgroup">
          <div class="row">
            <label class="col-md-5 col-form-label">address</label>
            <div class="col-md-6 zeropad">
              <ng-container
                *ngIf="invoice.relationships?.billToAddress && invoice.relationships?.billToCustomer?.relationships?.addresses">
                <select
                  #addressSelect
                  class="small-form" [compareWith]="comparebyId" [ngModel]="invoice.relationships.billToAddress"
                  (ngModelChange)="addressChange(invoice.relationships.billToAddress, $event)"
                >
                  <option [ngValue]="defaultAddress">New</option>
                  <option *ngIf="+currentAddress.attributes.AddressID !== 0" [ngValue]="currentAddress">
                    Current
                  </option>
                  <optgroup label="Customer Addresses">
                    <option
                      *ngFor="let address of invoice.relationships.billToCustomer.relationships.addresses"
                      [ngValue]="address"
                    >
                      {{ getAddressString(address)}}
                    </option>
                  </optgroup>
                  <ng-container *ngIf="billToContactAddresses">
                    <optgroup label="Contact Addresses">
                      <option
                        *ngFor="let address of billToContactAddresses" [ngValue]="address"
                      >
                        {{ getAddressString(address) }}
                      </option>
                    </optgroup>
                  </ng-container>
                </select>
              </ng-container>
            </div>

            <div class="col-md-1 zeropad">
              <span class="fa fa-minus"></span>
            </div>
          </div>
          <ng-container *ngIf="invoice.relationships?.billToAddress">
            <div class="row row2">
              <div class="col-md-6 onepad">
                <input [(ngModel)]="invoice.relationships.billToAddress.attributes.Description" type="text"
                       class="small-form" placeholder="Location">
              </div>

              <div class="col-md-6 onepad">
                <select [(ngModel)]="invoice.relationships.billToAddress.attributes.Description" class="small-form">
                  <option value="">--Select--</option>
                  <option value="Home">Home</option>
                  <option value="Office">Office</option>
                  <option value="Shipping">Shipping</option>
                  <option value="Billing">Billing</option>
                </select>
              </div>
            </div>

            <div class="row row2">
              <div class="col-md-12 onepad">
                <input [(ngModel)]="invoice.relationships.billToAddress.attributes.Line1" type="text"
                       class="small-form"
                       placeholder="Line 1">
              </div>
            </div>

            <div class="row row2">
              <div class="col-md-12 onepad">
                <input [(ngModel)]="invoice.relationships.billToAddress.attributes.Line2" type="text"
                       class="small-form"
                       placeholder="Line 2">
              </div>
            </div>

            <div class="row row2">
              <div class="col-md-10 onepad">
                <input
                  [(ngModel)]="invoice.relationships.billToAddress.attributes.City" type="text" class="small-form"
                  placeholder="City"
                >
              </div>

              <div class="col-md-2 onepad">
                <input
                  [(ngModel)]="invoice.relationships.billToAddress.attributes.State" type="text" class="small-form"
                  placeholder="State"
                >
              </div>
            </div>
            <div class="row row2">
              <div class="col-md-9 onepad">
                <input
                  [(ngModel)]="invoice.relationships.billToAddress.attributes.ZIP" type="text" class="small-form"
                  placeholder="Zip"
                >
              </div>

              <div class="col-md-3 onepad">
                <input
                  [(ngModel)]="invoice.relationships.billToAddress.attributes.Plus4" type="text" class="small-form"
                  placeholder="+4"
                >
              </div>
            </div>

            <div class="row row2">
              <div class="col-md-12 onepad">
                <select [(ngModel)]="invoice.relationships.billToAddress.attributes.Country" class="small-form">
                  <option
                    *ngFor="let country of countries" [ngValue]="country.attributes.CountryCode"
                  >
                    {{ country.attributes.CountryName }}
                  </option>
                </select>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col col-md-4 col-sm-12 lastpad">
        <div class="form-subgroup">
          <div class="row">
            <label class="col-md-5 col-form-label">terms</label>
            <div class="col-md-7 zeropad">
              <select [(ngModel)]="invoice.attributes.Codes_CustomerTermsIDfk" class="small-form">
                <option [ngValue]="0">None</option>
                <option *ngFor="let term of paymentTermCodes" [ngValue]="term.CodeID">
                  {{ term.CodeDesc }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <label class="col-md-5 col-form-label">{{ 'tax'|jetTranslate }} status</label>
            <div class="col-md-7 zeropad">
              <select
                [(ngModel)]="invoice.attributes.Codes_TaxStatusIDfk" (ngModelChange)="changeTaxStatus($event)"
                class="small-form"
              >
                <option *ngFor="let taxStatus of taxStatusCodes" [ngValue]="taxStatus.CodeID">
                  {{ taxStatus.CodeDesc }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <label class="col-md-5 col-form-label">{{ 'tax'|jetTranslate }} rate</label>
            <div class="col-md-7 zeropad">
              <input (blur)="taxRateOnBlur()" type="number" class="small-form"
                     [(ngModel)]="invoice.attributes.TaxPercentage">
              <small #rateHelpText class="form-text text-muted mt-0" style="font-style: italic;"></small>
            </div>
          </div>

          <ng-container *ngIf="invoice.relationships?.additionalValues">
            <div *ngFor="let additionalValue of invoice.relationships?.additionalValues" class="row">
              <ng-container *ngIf="additionalValue.attributes.FieldName === fieldVatRegistrationNumber">
                <label class="col-md-5 col-form-label">VAT no</label>
                <div class="col-md-7 zeropad">
                  <input type="text" class="small-form" [(ngModel)]="additionalValue.attributes.Value">
                </div>
              </ng-container>
            </div>
          </ng-container>

          <div class="row">
            <label class="col-md-5 col-form-label">sales rep</label>
            <div class="col-md-7 zeropad">
              <ng-container *ngIf="salesEmployees">
                <select [(ngModel)]="invoice.attributes.Employees_SalesPersonIDfk" class="small-form">
                  <option [ngValue]="0">--Choose--</option>
                  <option *ngIf="adminSalesEmployee" [ngValue]="adminSalesEmployee.employeeId">
                    {{ adminSalesEmployee.employeeName }}
                  </option>
                  <option *ngFor="let employee of salesEmployees" [ngValue]="employee.attributes.EmployeeID">
                    {{ employee.attributes.FullName }}
                  </option>
                </select>
              </ng-container>
            </div>
          </div>

          <div class="row">
            <label class="col-md-5 col-form-label">processor</label>
            <div class="col-md-7 zeropad">
              <ng-container *ngIf="processorEmployees">
                <select [(ngModel)]="invoice.attributes.Employees_ProcessorIDfk" class="small-form">
                  <option [ngValue]="0">--Choose--</option>
                  <option *ngIf="adminProcessorEmployee" [ngValue]="adminProcessorEmployee.employeeId">{{
                    adminProcessorEmployee.employeeName }}
                  </option>
                  <option *ngFor="let employee of processorEmployees" [ngValue]="employee.attributes.EmployeeID">
                    {{ employee.attributes.FullName }}
                  </option>
                </select>
              </ng-container>
            </div>
          </div>

          <div class="row row-stacked">
            <label class="col-md-12 col-form-label zeropad">marketing source</label>
            <div class="col-md-12 zeropad">
              <select [(ngModel)]="invoice.attributes.MarketingSource" class="small-form">
                <option [ngValue]="0">None</option>
                <option *ngFor="let marketingSource of marketingSourceCodes" [ngValue]="marketingSource.CodeID">
                  {{ marketingSource.CodeDesc }}
                </option>
              </select>
            </div>
          </div>
          <div class="row row-stacked">
            <div class="col-md-12 onepad text-right">
             <button (click)="saveCustomer(invoice.relationships.billToCustomer.id)" class="btn btn-primary btn-xs">Update Source Customer</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
