<div class="card">
    <div class="card-heading" data-toggle="collapse" data-target="#histInvoiceHistory">
        <h3 class="caps card-title">
          <span>invoice history</span>
          <i class="fa fa-lg fa-file-text"></i><br>
          <a class="caret"></a>
        </h3>
        <div class="card-subheading float-right">
            <div class="text-right">

            </div>
        </div>
    </div>
    <div class="card-body collapse show fivepad" id="histInvoiceHistory">

        <div *ngIf="isLoading();else content_table">
            <div class="row justify-content-md-center">
                <div class="col-sm-3"><i class="fa fa-spinner fa-spin fa-1x"></i> Loading ...</div>
            </div>
        </div>

        <ng-template #content_table>
            <table class="small table table-striped table-hover table-borderless" style="margin-bottom: 0;">
                <thead>
                <tr>
                    <th>#</th>
                    <th>PO</th>
                    <th>INVOICED</th>
                    <th>CONTACT</th>
                    <th>VALUE</th>
                    <th>SHIPPER</th>
                    <th>SHIPPED</th>
                    <th>TRACKING #</th>
                    <th>STATUS</th>
                    <th>PENDING</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let invoice of invoices">
                  <tr [ngClass]="recordClass(invoice)">
                    <td style="padding:5px !important;">
                        <a target="_blank" [routerLink]="['/invoice/primary']" [queryParams]="{ invoice_id: invoice.InvoiceID, customer_id: customer ? customer.attributes.CustomerID : '' }" queryParamsHandling="merge">
                            {{ invoice.InvoiceNumber }}
                        </a>
                    </td>
                    <td style="padding:5px !important;">{{ invoice.PurchaseOrderNumber }}</td>
                    <td style="padding:5px !important;">{{ invoice.DateInvoiced | momentDate:dateFormat }}</td>
                    <td style="padding:5px !important;">{{ invoice.ShipToContactPersonName }}</td>
                    <td style="padding:5px !important;">{{ invoice.TotalValue | jetCurrency }}</td>
                    <td style="padding:5px !important;">{{ invoice.ShipperName }}</td>
                    <td style="padding:5px !important;">{{ invoice.DateShipped | momentDate:dateFormat }}</td>
                    <td style="padding:5px !important;">{{ invoice.FreightTrackingNumber }}</td>
                    <td style="padding:5px !important;">{{ invoice.StatusText }}</td>
                    <td style="padding:5px !important;">{{ invoice.PendingReasonText }}</td>
                  </tr>
                </ng-container>
                <tr *ngIf="!invoices.length">
                    <td colspan="12">No records to display</td>
                </tr>
                </tbody>
            </table>
        </ng-template>

    </div>
</div>
