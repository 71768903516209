import {Component, Input, OnInit} from '@angular/core';
import {InvoiceConfirmation} from '../../../../models/invoice-confirmation.interface';

@Component({
  selector: 'jet-invoice-confirmations',
  templateUrl: './invoice-confirmations.component.html',
  styleUrls: ['./invoice-confirmations.component.scss']
})
export class InvoiceConfirmationsComponent implements OnInit {

  @Input() public confirmations: InvoiceConfirmation[] = null;

  constructor() {}

  ngOnInit() {
  }

  private invalidDate(item: InvoiceConfirmation) {
    return isNaN(Date.parse(item.attributes.OrderConfirmationDate));
  }
}
