import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import {AuthService} from "./auth.service";

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private authService: AuthService, public router: Router) {}
  async canActivate(): Promise<boolean> {

    const isAuthenticated = await this.authService.isAuthenticated();

    if (!isAuthenticated) {
      this.router.navigate(['login']);
      return false;
    }

    return true;
  }
}
