<div class="card">
  <div class="card-heading" data-toggle="collapse" data-target="#quickViewInventory">
    <h3 class="caps card-title">
      <span>inventory items</span>
      <i class="fa fa-list fa-lg"></i>
      <a class="caret"></a>
    </h3>
    <div class="card-subheading float-right">
    </div>
  </div>
  <div class="card-body collapse show fivepad" id="quickViewInventory">
    <table class="small  table-striped table">
      <thead>
      <tr>
        <th>Description</th>
        <th>Avail</th>
        <th>Note</th>
        <th>International</th>
        <th>Wt.</th>
        <th>Qty</th>
        <th>Price Ea.</th>
        <th>Subtotal</th>
        <th>{{ 'Taxed'|jetTranslate }}</th>
        <th>Total</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of items">
        <td>
          {{ item.attributes.InventoryItemNumber }} - {{ item.attributes.Description }}
          <span *ngIf="item.attributes.IsWarranty"><br>War. {{ item.attributes.WarrantyDepartmentAtFaultText }} - {{ item.attributes.WarrantyReasonText }}</span>
        </td>
        <td>{{item.attributes.InventoryItemAvailableQuantity}}</td>
        <td>
          {{ item.attributes.Note }}
        </td>
        <td>
          Code: <b>{{ item.attributes.CommodityCode }}</b><br>
          Value: <b>{{ item.attributes.CustomsValue | jetCurrency }}</b>
        </td>
        <td title="{{ item.attributes.LineWeightCalcText}}">
          {{ item.attributes.LineItemWeightText|jetTranslate:!isSmartopInvoice }}<br>
          {{ item.attributes.LineItemWeightEachText|jetTranslate:!isSmartopInvoice }} Ea.
        </td>
        <td>{{ item.attributes.Quantity }}</td>
        <td>{{ item.attributes.PriceEach | jetCurrency }}</td>
        <td>{{ (item.attributes.Quantity * item.attributes.PriceEach) | jetCurrency }}</td>
        <td>
          {{ item.attributes.TaxPercentage }}<br>
          {{ item.attributes.TaxAmount | jetCurrency }}
        </td>
        <td>{{ item.attributes.TotalValue | jetCurrency }}</td>
      </tr>
      <tr *ngIf="!items.length">
        <td colspan="12">No records to display</td>
      </tr>
      </tbody>
    </table>

  </div>
</div>

