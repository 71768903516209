
import { InvoiceLineItem } from '../models/invoice-line-item.interface';
import { getMultipleResourceRelation, getSingleResourceRelation } from './json.util';
import { InventoryItem } from '../models/inventory-item.interface';
import {
  APP_SETTING_FREIGHT_PALLET_WEIGHT, APP_SETTING_UK_FREIGHT_PALLET_WEIGHT,
  INVOICE_LINE_ITEM_TYPE_INVENTORY, INVOICE_LINE_ITEM_TYPE_OTHER,
  INVOICE_LINE_ITEM_TYPE_SMARTTOP, INVOICE_TYPE_SMARTOP, SOURCE_TYPE_INVOICE
} from './constants.util';
import { Invoice } from '../models/invoice.interface';
import { PaymentApplication } from '../models/payment-application.interface';
import { ComputedFreightInfo } from '../models/computed-freight-info.interface';
import { environment } from '../environments/environment';

export function getComputedFreightInfo(invoice: Invoice, isUk: boolean): ComputedFreightInfo {
  let subTotal = 0;
  let totalWeight = 0;
  let totalShippingHandling = 0;
  let totalTax = 0;
  let totalDiscount = 0;
  let totalPayments = 0;
  let total = 0;
  let balance = 0;
  const lineItems: InvoiceLineItem[] = getLineItems(invoice);
  const isSmartopInvoice = invoice.attributes.InvoiceType === INVOICE_TYPE_SMARTOP;

  totalWeight = computeInvoiceWeight(lineItems, invoice.attributes.FreightNumberOfPallets, isSmartopInvoice, isUk);
  totalTax =
    invoice.attributes.FreightTaxAmount +
    invoice.attributes.HandlingTaxAmount +
    invoice.attributes.AdditionalChargesTaxAmount
  ;

  totalShippingHandling =
    invoice.attributes.FreightPrice +
    invoice.attributes.Handling +
    invoice.attributes.AdditionalCharges
  ;

  lineItems.forEach((item: InvoiceLineItem) => {
    totalTax += item.attributes.TaxAmount; // Tax Amounts never change when we update Tax Percent, so use last persisted value
    // total value and discount for each line item needs to be based off of current Promo Discount Percent.
    subTotal += item.attributes.Quantity * (item.attributes.OriginalPriceEach  * (1 - (invoice.attributes.PromoDiscountPercent / 100)));
    totalDiscount += item.attributes.Quantity * (item.attributes.OriginalPriceEach * (invoice.attributes.PromoDiscountPercent / 100));
  });

  total = subTotal + totalShippingHandling + totalTax + invoice.attributes.ShippingCredit;

  const paymentApplications: PaymentApplication[] = getMultipleResourceRelation<PaymentApplication>(invoice.relationships, 'paymentApplications');
  paymentApplications.forEach((payment: PaymentApplication) => {
    totalPayments += payment.attributes.Amount;
  });

  balance = total - totalPayments;

  balance = parseFloat(balance.toFixed(2));

  return {
    subTotal,
    totalWeight,
    totalShippingHandling,
    totalTax,
    totalDiscount,
    totalPayments,
    total,
    balance
  };
}

export function getSmartopLineItems(invoice: Invoice): InvoiceLineItem[] {
  return getLineItemsByType(invoice, INVOICE_LINE_ITEM_TYPE_SMARTTOP);
}

export function getInventoryLineItems(invoice: Invoice): InvoiceLineItem[] {
  return getLineItemsByType(invoice, INVOICE_LINE_ITEM_TYPE_INVENTORY);
}

export function getOtherLineItems(invoice: Invoice): InvoiceLineItem[] {
  return getLineItemsByType(invoice, INVOICE_LINE_ITEM_TYPE_OTHER);
}

export function getLineItems(invoice: Invoice): InvoiceLineItem[] {
  return getMultipleResourceRelation<InvoiceLineItem>(invoice.relationships, 'lineItems')
    .map(computeLineItemDisplayValues)
  ;
}

export function getLineItemsByType(invoice: Invoice, lineItemType: number): InvoiceLineItem[] {
  return getMultipleResourceRelation<InvoiceLineItem>(invoice.relationships, 'lineItems')
    .sort((item1, item2) => item1.attributes.InvoiceLineItemSequence - item2.attributes.InvoiceLineItemSequence )
    .filter(item => item.attributes.LineItemType === lineItemType)
    .map(computeLineItemDisplayValues)
  ;
}

export function computeLineItemDisplayValues(item: InvoiceLineItem): InvoiceLineItem {
  const inventoryItem: InventoryItem = getSingleResourceRelation<InventoryItem>(item.relationships, 'inventoryItem');
  if (inventoryItem) {
    item.attributes.InventoryItemAvailableQuantity = inventoryItem.attributes.AvailableQuantity;
    item.attributes.InventoryItemQuantity = inventoryItem.attributes.Quantity;
    item.attributes.LineItemWeight = computeInvoiceLineItemWeight(item);
    item.attributes.LineItemWeightText =  item.attributes.LineItemWeight + ' ' + inventoryItem.attributes.WeightUOMText;
    item.attributes.LineItemWeightEachText = inventoryItem.attributes.Weight + ' ' + inventoryItem.attributes.WeightUOMText;
    const { LineItemWeightText, Quantity,  } = item.attributes;
    const { Weight, WeightUOMText, MasterPackPackagingWeight, MasterPackPackagingWeightUOMText, MasterPackQuantity } = inventoryItem.attributes;
    item.attributes.LineWeightCalcText =
      `${Weight}${WeightUOMText} * ${Quantity} + ${MasterPackPackagingWeight}${MasterPackPackagingWeightUOMText} * (${Quantity}/${MasterPackQuantity}) = ${LineItemWeightText}`
    ;
  } else {
    item.attributes.InventoryItemAvailableQuantity = 0;
    item.attributes.InventoryItemQuantity = {pending: 0, promised: 0, physical: 0};
    item.attributes.LineItemWeightText = '0';
    item.attributes.LineItemWeightEachText = '0';
    item.attributes.LineWeightCalcText = '';
  }

  return item;
}

export function computeInvoiceLineItemWeight(invoiceLineItem: InvoiceLineItem): number {
  let weight = 0;
  if (invoiceLineItem.attributes.LineItemType === INVOICE_LINE_ITEM_TYPE_INVENTORY) {
    const inventoryItem: InventoryItem = getSingleResourceRelation<InventoryItem>(invoiceLineItem.relationships, 'inventoryItem');
    if (!inventoryItem) {
      return 0;
    }
    weight = inventoryItem.attributes.Weight * invoiceLineItem.attributes.Quantity;
    if (inventoryItem.attributes.MasterPackQuantity > 0) {
      weight += inventoryItem.attributes.MasterPackPackagingWeight * Math.floor(invoiceLineItem.attributes.Quantity / inventoryItem.attributes.MasterPackQuantity);
    } else {
      weight += inventoryItem.attributes.MasterPackPackagingWeight * invoiceLineItem.attributes.Quantity;
    }
  } else if (invoiceLineItem.attributes.LineItemType === INVOICE_LINE_ITEM_TYPE_SMARTTOP) {
    weight = invoiceLineItem.attributes.Weight;
  }

  return Number.parseFloat(weight.toFixed(2));
}

export function computeInvoiceWeight(lineItems: InvoiceLineItem[], numberOfPallets: number, isSmartopInvoice: boolean, isUk: boolean): number {
  let invoiceWeight = 0;
  for (const item of lineItems) {
    if ((item.attributes.LineItemType === INVOICE_LINE_ITEM_TYPE_SMARTTOP
      || item.attributes.LineItemType === INVOICE_LINE_ITEM_TYPE_INVENTORY)
      && item.attributes.LineItemWeight !== undefined
    ) {
      invoiceWeight += item.attributes.LineItemWeight;
    }
  }
  let palletWeight = APP_SETTING_FREIGHT_PALLET_WEIGHT;
  if (isUk && !isSmartopInvoice) {
    palletWeight = APP_SETTING_UK_FREIGHT_PALLET_WEIGHT;
  }
  return invoiceWeight + (numberOfPallets * palletWeight);
}

export function updateSourceToInvoice(attrs: any, invoiceId: number) {
  return { ...attrs, SourceType: SOURCE_TYPE_INVOICE, SourceIDfk: invoiceId };
}

export function computeMeasurements(min: number, max: number, interval: number): number[] {
  let currentNum: number = min;
  const nums: number[] = [];
  while (currentNum <= max) {
    nums.push(currentNum);
    currentNum += interval;
  }
  return nums;
}
