import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {DOMAIN_CONFIG, DomainConfigInterface} from '../../config/domain.interface';
import {LoadingService} from '../../services/observables/loading.service';
import {User} from '../../models/user.interface';
import {UserService} from '../../services/observables/user.service';
import {PentadService} from '../../services/pentad.service';

@Component({
  selector: 'app-shipping-documents',
  templateUrl: './shipping-documents.component.html',
  styleUrls: ['./shipping-documents.component.scss']
})
export class ShippingDocumentsComponent implements OnInit {
  public groundList = [];
  public truckList = [];
  public otherList = [];
  public ukList = [];

  public groundLabel = 'Ground';
  public truckLabel = 'Truck';
  public otherLabel = 'Other';
  public ukLabel = 'UK';

  public user: User;
  public isUK: boolean;

  constructor(
    private httpClient: HttpClient,
    private loadingService: LoadingService,
    private userService: UserService,
    private pentadService: PentadService,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
  ) { }

  ngOnInit() {
    this.getTableData();
    this.userService.getResource().subscribe((user: User) => { this.user = user; });
    this.isUK = this.config.isUk;
  }

  getTableData() {
    const loader = this.loadingService.newLoader();
    loader.start();

    this.httpClient.get<any>(this.config.apiUrl + 'shipping').subscribe((data) => {
      this.groundList = data[0];
      this.truckList = data[1];
      this.otherList = data[2];
      this.ukList = data[3];
      loader.stop();
    }, (error) => {
      loader.stop();
    });
  }

  endOfDayReport() {
    this.openPage('Internal/Managing/CRMS/STM/UPSEndOfDayReport.aspx?PentadSecurity');
  }

  public async openPage(url: string) {
    this.pentadService.setPentadSecurityAndNewTab(url);
  }
}
