import { OnInit } from '@angular/core';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Pagination } from '../../../interfaces/pagination';
@Component({
	selector: 'jet-pagination',
	template: `
		<style>
		</style>
		<nav aria-label="...">
			<ul class="pagination">
				<li (click)="goBack()" class="page-item" [class.disabled] = "!canGoBack">
					<span class="page-link">Previous</span>
				</li>
				<li (click)="goToFirst()" class="page-item" *ngIf="!nearFirst">
					<span class="page-link">
						1
					</span>
				</li>
				<li class="page-item disabled" *ngIf="!nearFirst">
					<span class="page-link">...</span>
				</li>
				<li (click)="goTo(pageNum)" class="page-item" [class.active]="isCurrentPage(pageNum)" *ngFor="let pageNum of pageNumbers">
					<span class="page-link">
						{{ pageNum }}
					</span>
				</li>
				<li class="page-item disabled" *ngIf="!nearLast">
					<span class="page-link">...</span>
				</li>
				<li (click)="goToLast()" class="page-item" *ngIf="!nearLast">
					<span class="page-link">
						{{ pagination.totalPages }}
					</span>
				</li>
				<li (click)="goForward()" class="page-item" [class.disabled] = '!canGoForward'>
					<span class="page-link" href="#">Next</span>
				</li>
			</ul>
		</nav>
	`
})
export class PaginationComponent implements OnInit {
	@Input() pagination: Pagination;
	@Output() nextPage = new EventEmitter<number>();
	public numOfPagesToList = 5;
	public numOfPagesBeforeAndAfter = 2;
	public firstPageToList = 1;
	public canGoForward: boolean = false;
	public canGoBack: boolean = false;
	public nearLast: boolean = false;
	public nearFirst: boolean = false;
	public pageNumbers: number[] = [];

	constructor() {}

	ngOnInit() {
		this.updateComputedProperties();
	}

	updateFirstPageToList() {
		let newFirstPageToList = this.pagination.currentPage - this.numOfPagesBeforeAndAfter;
		if (this.nearFirst) {
      newFirstPageToList = 1;
    } else if (this.nearLast) {
			newFirstPageToList = this.pagination.totalPages - this.numOfPagesToList;
		}

		if (newFirstPageToList !== this.firstPageToList) {
			this.firstPageToList = newFirstPageToList;
		}
	}

	isCurrentPage(pageNum) {
		return this.pagination.currentPage === pageNum;
	}

	getPageNumbers() {
		let num = this.firstPageToList;
		const maxPageNum = num + (this.numOfPagesToList - 1);
		const pages = [];
		while (num <= maxPageNum && num <= this.pagination.totalPages) {
			pages.push(num);
			num++;
		}
		return pages;
	}

	goToFirst() {
		this.goTo(1);
	}

	goBack() {
		this.goTo(--this.pagination.currentPage);
	}

	goToLast() {
		this.goTo(this.pagination.totalPages);
	}

	goForward() {
		this.goTo(++this.pagination.currentPage);
	}

	goTo(pageNum) {
		this.pagination.currentPage = pageNum;
    this.updateComputedProperties();
    this.nextPage.emit(this.pagination.currentPage);
  }

	private updateComputedProperties() {
    this.canGoForward = this.pagination.currentPage + 1 <= this.pagination.totalPages;
    this.canGoBack = this.pagination.currentPage - 1 > 0;
    this.nearLast = this.pagination.currentPage >= (this.pagination.totalPages - this.numOfPagesToList);
    this.nearFirst = this.pagination.currentPage <= this.numOfPagesToList;
    this.updateFirstPageToList();
    this.pageNumbers = this.getPageNumbers();
  }
}
