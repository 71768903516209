import { Component, OnInit, Inject } from '@angular/core';
import { EmailComponent } from '../email.component';
import { HttpClient } from '@angular/common/http';
import { PentadService } from '../../../services/pentad.service';
import { ActivatedRoute } from '@angular/router';
import { InvoiceService } from '../../../services/observables/invoice.service';
import { CustomerService } from '../../../services/observables/customer.service';
import { DomainConfigInterface, DOMAIN_CONFIG } from '../../../config/domain.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { filter, takeUntil, concatMap } from 'rxjs/operators';
import { Invoice } from '../../../models/invoice.interface';
import {IndexService} from "../../../services/observables/index.service";

@Component({
  selector: 'app-ctaemail',
  templateUrl: '../email.component.html',
  styleUrls: ['../email.component.scss']
})
export class CTAEmailComponent extends EmailComponent implements OnInit{

  constructor(
    httpClient: HttpClient,
    pentadService: PentadService,
    route: ActivatedRoute,
    invoiceService: InvoiceService,
    customerService: CustomerService,
    @Inject(DOMAIN_CONFIG) config: DomainConfigInterface,
    domSanitizer: DomSanitizer,
    indexService: IndexService,
  )
  {
    super(httpClient, pentadService, route, invoiceService, customerService, config, domSanitizer, indexService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isLoading = true;

    let invoiceObservable = this.invoiceService.invoice.pipe(
      filter(data => data !== null),
      takeUntil(this.ngUnsubscribe),
    );

    invoiceObservable.pipe(
      concatMap((invoice: Invoice) => {
        this.invoice = invoice;
        this.originalFromAddress = this.fromAddress = this.invoice.relationships.salesPerson.relationships.emails[0].attributes.ContactValue;

        return this.pentadService.setPentadSecurity('internal/Managing/CRMS/STM/CallToActionEmailTemplate.aspx?PentadSecurity&InvoiceID=' + this.invoice.id);
      }),
      concatMap((url: string) => {

        return this.httpClient.get(url, {responseType: 'text'});
      })
    ).subscribe((res: string) => {
      this.emailBody = res;
      if (this.invoice.relationships.salesPerson) {
        this.emailBody += this.invoice.relationships.salesPerson.attributes.FirstName + " " + this.invoice.relationships.salesPerson.attributes.LastName + " <br/>";
      }
      this.emailBody += "SMARTOP <br/>";
      this.emailBody += "800-469-2428<br/>";
      this.emailBody += "509-483-8217 Fax <br/>";
      this.emailSubject = 'Please confirm your order';
      this.isLoading = false;
    });
  }

  submitForm(event: any) {
    super.submitForm(event);
    var contacts = [];

    for (var i = 0; i < event.target.length; i++) {
      if (event.target[i].classList.contains('form-check-input')) {
        if (event.target[i].checked == true) {
          contacts.push(event.target[i].labels[0].innerText);
        }
      }
    }

    var addresses  = event.target.addresses.value;
    var addressArr = addresses.split(',');

    if (addresses.length > 0) {
      addressArr.forEach(address => {
        contacts.push(address.trim());
      });
    }

    var formData = new FormData();

    for (var i = 0; i < this.fileList.length; i++) {
      formData.append('file ' + i.toString(), this.fileList[i]);
    }

    formData.append('subject', this.emailSubject);
    formData.append('body', this.emailBody);
    formData.append('contacts', JSON.stringify(contacts));
    formData.append('from', this.fromAddress);
    formData.append('email_type', 'CTA');
    formData.append('id', this.invoice.id);

    this.httpClient.post(this.url + 'invoices/sendShippingEmail', formData).subscribe(
      success => {
        const bc = new BroadcastChannel('invoice_' + this.invoice.id);
        bc.postMessage({
          message: 'CTA Email Sent',
          invoiceId: this.invoice.id,
        });
        window.close();
      },
      error => {
        this.formError = true;
        this.error     = error.error.error.exception[0].message;
      }
    );
  }

  openLegacyEmailer() {
    this.pentadService.setPentadSecurityAndRedirect('/internal/Managing/CRMS/STM/EmailCallToAction.aspx?PentadSecurity&InvoiceID=' + this.invoice.id);
  }
}
