import {Injectable} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {WarningModalComponent} from '../app/shared/components/warning-modal/warning-modal.component';

@Injectable()
export class WarningService {
  private bsModalRef: BsModalRef;

  constructor(private bsModalService: BsModalService,) {}

  public openWarningModal(warnings) {
    const modalsCount = this.bsModalService.getModalsCount();
    // TODO: blur event on Freight Information fields firing multiple times. Guard against opening another one for now.
    if (modalsCount === 0) {
      this.bsModalRef = this.bsModalService.show(WarningModalComponent);
      this.bsModalRef.content.warnings = warnings;
    }
    return this.bsModalService.onHidden;
  }
}
