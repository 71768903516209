import {filter, first} from 'rxjs/operators';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LoadingService} from '../../../services/observables/loading.service';
import {CustomerService} from '../../../services/observables/customer.service';
import {LoadingToken} from "../../../services/observables/loading-token";
import {STATES} from "../../../config/states.config";

@Component({
  selector: 'jet-customer-resource',
  template: ``
})
export class CustomerResourceComponent {
  public static readonly STATIC_STATES = STATES;
  public readonly STATES = STATES;
  @Input() public customerId = 0;
  @Input() public state;


  @Output() public onCancel = new EventEmitter<boolean>();
  @Output() public onEdit = new EventEmitter<boolean>();
  @Output() public onAdd = new EventEmitter<boolean>();
  @Output() public onUpdate = new EventEmitter<boolean>();
  @Output() public onRemove = new EventEmitter<boolean>();

  // TODO: Ideal to set on removal since there's a delay from when the emit event is fired and the resources are refreshed
  public hide = false;

  constructor(
    private _loadingService: LoadingService,
    private _customerService: CustomerService,
  ) {
  }

  public resourceChange(resourceAction: Function, actionArguments: any[], actionEmitter: EventEmitter<boolean>,
                        onSuccess: Function = (data) => {
                          this.state = this.STATES.VIEW;
                        }) {

    const loader = this._loadingService.newLoader().start();

    resourceAction(...actionArguments)
      .subscribe((resourceData: any) => {

        /**
         * Because the customer service observable gets set with a null we need to ignore the null using filter.
         * Because this inner callback gets called every time resourceChange gets call we need to unsubscribe by using first()
         */
        this._customerService.getResource(this.customerId, true).pipe(
            filter( r => r !== null),
            first(),
            ).subscribe((data) => {

              onSuccess(data);
              this.onResponse(actionEmitter, true, resourceData, loader);
            },
            (error: Response) => this.onResponse(actionEmitter, false, resourceData, loader)
          );

        },
        (error: Response) => this.onResponse(actionEmitter, false, error, loader)
      )
    ;
  }

  protected onResponse(actionEmitter: EventEmitter<boolean>, success: boolean, resourceData: any, loader: LoadingToken) {

    actionEmitter.emit(success);
    loader.stop();
  }
}
