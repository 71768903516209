import {filter, finalize, map, switchMap, take, tap} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {copyObject, singleResourceBody} from '../../../../utils/json.util';
import {LoadingService} from '../../../../services/observables/loading.service';
import {ResourceService} from '../../../../services/resource.service';
import {CustomerService} from '../../../../services/observables/customer.service';
import {CustomerResourceComponent} from '../customer-resource.component';
import {PaymentMethod} from '../../../../models/payment-method.interface';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {WarningModalComponent} from '../warning-modal/warning-modal.component';
import {MomentDatePipe} from '../../../../pipes/moment-date.pipe';
import {Invoice} from '../../../../models/invoice.interface';
import {InvoiceService} from '../../../../services/observables/invoice.service';
import {LoadingToken} from '../../../../services/observables/loading-token';
import {combineLatest, firstValueFrom, Subject} from 'rxjs';
import {PaymentMethodService} from "../../../../services/payment-method-service.service";

@Component({
  selector: '[jet-payment-method]',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent extends CustomerResourceComponent implements OnInit, OnDestroy {
  private resourceName = 'payment-methods';
  @Input() public paymentMethod: PaymentMethod;
  @Input() public charge: number;
  @Input() public enableToggle: boolean = false;
  @Input() public invoice: Invoice = null;
  @Input() public parentType: String;

  public editPaymentMethod: PaymentMethod;

  private bsModalRef: BsModalRef;
  private ngUnsubscribe = new Subject();

  public disabled: boolean = false;

  constructor(
    private resourceService: ResourceService,
    private loadingService: LoadingService,
    private customerService: CustomerService,
    private datePipe: MomentDatePipe,
    private bsModalService: BsModalService,
    private invoiceService: InvoiceService,
    private paymentMethodService: PaymentMethodService
  ) {
    super(loadingService, customerService);
  }

  private prepEditPaymentMethod() {
    this.editPaymentMethod = copyObject(this.paymentMethod);
    this.editPaymentMethod.attributes.Date = this.datePipe.transform(this.editPaymentMethod.attributes.Date, 'MM/YY');

    if (this.charge && this.state === this.STATES.ADD) {
      this.editPaymentMethod.charge = this.charge;
    }
  }

  private showWarningModal(warnings: Array<string> = null) {

    if (warnings === null) {
      warnings = ['Please enter a date of the following formats: dd/yy or ddyy eg 01/25 or 0125 .'];
    }

    this.bsModalRef = this.bsModalService.show(WarningModalComponent);
    this.bsModalRef.content.warnings = warnings;
  }

  ngOnInit() {
    this.prepEditPaymentMethod();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public edit() {
    if (this.charge) {
      this.editPaymentMethod.charge = this.charge;
    }

    if (this.editPaymentMethod.attributes.Number.length > 0) {
      this.disabled = true;
    }

    this.state = this.STATES.EDIT;
    this.onEdit.emit(true);
  }

  public toggle() {

    if (!this.enableToggle) {
      return;
    }

    if (this.state === this.STATES.EDIT) {
      this.cancel();

      return;
    }

    this.edit();
  }

  public cancel() {
    this.prepEditPaymentMethod();
    this.disabled = false;
    this.state = this.STATES.VIEW;
    this.onCancel.emit(true);
  }

  public update() {
    const loader = this.loadingService.newLoader().start();
    this.paymentMethodService.update(this.editPaymentMethod, this.invoice)
      .pipe(
        switchMap(() => this.customerService.getResource(this.customerId, true).pipe(
          filter( r => r !== null),
          take(1)
        )),
        tap(() => {
          this.state = this.STATES.VIEW;
        }),
        finalize(() => {
          loader.stop();
        }),
      )
      .subscribe({
        error: (err) => {
          if (err instanceof Array) {
            this.showWarningModal(err);
          } else {
            this.showWarningModal(['Error Updating Card']);
          }
        }
      });
  }

  public async remove() {
    const shouldDelete = await this.showConfirmationModal();
    if(shouldDelete) {
      const body = singleResourceBody(this.paymentMethod.attributes);
      body.data.attributes.PaymentMethodActive = 0;
      this.resourceChange(this.resourceService.updateResource, [this.resourceName, +this.paymentMethod.id, body], this.onRemove, () => {
        this.hide = true;
      });
    }
  }

  public async showConfirmationModal() {
    const initialState = {
      enableConfirm: true,
      warnings: [`Do you really want to remove ${this.paymentMethod.attributes.Description} from Payment Methods?`],
      title: 'Confirm Deletion',
      confirmMessage: "Delete Customer Information",
    };

    this.bsModalRef = this.bsModalService.show(WarningModalComponent, {class: 'modal-lg', initialState});

    return (await firstValueFrom(this.bsModalRef.content.onClose.pipe(take(1))))["confirm"];
  }

  public add() {
    const loader = this.loadingService.newLoader().start();
    this.paymentMethodService
      .add(this.editPaymentMethod, this.customerId)
      .pipe(
        switchMap(() => this.customerService.getResource(this.customerId, true).pipe(
          filter( r => r !== null),
          take(1)
        )),
        tap(() => {
          this.state = this.STATES.VIEW;
        }),
        finalize(() => {
          loader.stop();
        }),
      )
      .subscribe({
        next: () => {
          this.onAdd.next(true);
        },
        error: (err) => {
          if (err instanceof Array) {
            this.showWarningModal(err);
          } else {
            this.showWarningModal(['Error Adding Card']);
          }
        }
      });
  }

  public process() {
    this.editPaymentMethod.attributes.charge = this.editPaymentMethod.charge;

    if (!this.editPaymentMethod.attributes.AddressZip) {
      this.showWarningModal(['Zip Code is Required']);

      return;
    }

    const loader = this.loadingService.newLoader().start();
    this.paymentMethodService.update(this.editPaymentMethod, this.invoice)
      .pipe(
        switchMap((response) => combineLatest([this.customerService.getResource(this.customerId, true).pipe(
          filter(r => r !== null),
          take(1)
        ), this.invoiceService.getInvoice(+this.invoice.id, true).pipe(
          filter(r => r !== null),
          take(1)
        )]).pipe(map(() => response))),
        tap(() => {
          this.state = this.STATES.VIEW;
        }),
        finalize(() => {
          loader.stop();
        }),
      )
      .subscribe({
        next: (response) => {
          if (response instanceof Array) {
            this.showWarningModal(response);
          }
        },
        error: (err) => {
          if (err instanceof Array) {
            this.showWarningModal(err);
          } else if (err instanceof Error ) {
            this.showWarningModal([
              'Error Processing Card',
              err.message
            ]);
          }
        }
      });
  }

  public addAndProcess() {
    const temp = this.editPaymentMethod.charge;
    const loader = this.loadingService.newLoader().start();
    this.paymentMethodService
      .add(this.editPaymentMethod, this.customerId)
      .pipe(
        switchMap((payment: PaymentMethod) => {
          this.editPaymentMethod = copyObject(payment);
          this.editPaymentMethod.attributes.Date = this.datePipe.transform(payment.attributes.Date, 'MM/YY');
          this.editPaymentMethod.attributes.charge = temp;
          return this.paymentMethodService.update(this.editPaymentMethod, this.invoice);
        }),
        switchMap(() => combineLatest([
          this.customerService.getResource(this.customerId, true).pipe(
            filter(r => r !== null),
            take(1)
          ),
          this.invoiceService.getInvoice(+this.invoice.id, true).pipe(
            filter(data => data !== null),
            take(1)
          )
        ])),
        tap(() => {
          this.state = this.STATES.VIEW;
        }),
        finalize(() => {
          loader.stop();
        }),
      )
      .subscribe({
        next: () => {
          this.onAdd.next(true);
        },
        error: (err) => {
          if (err instanceof Array) {
            this.showWarningModal(err);
          } else {
            this.showWarningModal(['Error Adding Card']);
          }
        }
      });
  }

  protected onResponse(actionEmitter: EventEmitter<boolean>, success: boolean, resourceData: any, loader: LoadingToken): void {
    super.onResponse(actionEmitter, success, resourceData, loader);
    const dataIsArray = resourceData && typeof resourceData === 'object' && resourceData.constructor === Array;
    if (dataIsArray) {
      this.showWarningModal(resourceData);
    }
  }
}
