<h4 class="quick-view-title"><span>Bill To</span><i class="fa fa-address-card-o fa-lg"></i></h4>
<hr/>
<table>
  <tr>
    <th>Company:</th>
    <td>{{ company }}</td>
  </tr>
  <tr>
    <th>Contact:</th>
    <td>{{ contact }}</td>
  </tr>
  <tr>
    <th>Phone:</th>
    <td>{{ phone }}</td>
  </tr>
  <tr>
    <th>Fax:</th>
    <td>{{ fax }}</td>
  </tr>
  <tr>
    <th valign="top">Address:</th>
    <td>
      {{ address?.attributes.Line1 }} {{ address?.attributes.Line2 }}
      <br>
      {{ address?.attributes.City }}, {{ address?.attributes.State }} {{ address?.attributes.ZIP }}
    </td>
  </tr>
  <tr>
    <th valign="top">Message:</th>
    <td>{{ message }}</td>
  </tr>
</table>
