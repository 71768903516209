import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'jet-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent implements OnInit {

  private outletComponent: any;

  constructor() { }

  ngOnInit() {
  }

  public onActivate(component) {
    this.outletComponent = component;
  }

}
