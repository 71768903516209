<div class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
<div style="width: 300px;margin: 0 auto;">
    <ul *ngIf="payment" style="list-style: none;text-align: left;">
<!--        <li style="margin-bottom: 10px;">TXN REF: {{ payment.relationships.payment.relationships.paypalTransactionResponse.attributes.Pnref }}</li>-->
        <li style="margin-bottom: 10px;">Original Charge: {{ payment.attributes.Amount }}</li>
        <li style="margin-bottom: 10px;">
            <div class="input-group input-group-sm">
                <span class="mr-2">Credit: </span>
                <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="credit">
            </div>
        </li>
        <li style="margin-bottom: 10px;"><button class="btn btn-primary" (click)="process()">Process </button></li>
    </ul>

    <div *ngIf="returnContent">
      <ul>
        <li *ngFor="let message of returnContent">{{message}}</li>
      </ul>
    </div>

</div>
</div>
