import {delay, map, take} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {createOptions, generateQueryParamString} from '../utils/http.util';
import {deserializeJson} from '../utils/json.util';
import {HttpClient} from '@angular/common/http';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../config/domain.interface";


@Injectable()
export class ResourceService {
  private url: string;

  constructor(
    private httpClient: HttpClient,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
  ) {
    this.url = this.config.apiUrl;
  }

  getUser = <User>(): Observable<User> => {
    const options = createOptions();
    return this.httpClient.get(`${this.url}users`, options).pipe(
      take(1),
      map(data => {
        return deserializeJson(data);
      }));
  };

  getResource = <T>(resource: string, id: number, enableLog: boolean = false): Observable<T> => {
    const options = createOptions();

    let qs = '';
    if (enableLog) {
      qs = '?log=1'
    }

    return this.httpClient.get<T>(`${this.url}${resource}/${id}` + qs, options).pipe(
      take(1),
      map(data => {
        return deserializeJson(data);
      }));
  };

  getResources = <T>(resource: string, params: any = {}, withExtraData: boolean = false): Observable<T[]> => {
    let queryParamString = generateQueryParamString(params);
    if (queryParamString.length) {
      queryParamString = '?' + queryParamString;
    }

    const options          = createOptions();

    return this.httpClient.get(`${this.url}${resource}/${queryParamString}`, options).pipe(
      delay(0),//put delay here because I was getting an error with the loading changing after it was checked
      map((body) => {

        if ('errors' in body && body['errors'][0]['code'] === 'no_data') {
          return [];
        }

        const deserializedData = deserializeJson(body);
        if (!withExtraData) {
          return deserializedData;
        }
        const keyBlacklist = ['data', 'included'];
        const responseData = {
          data: deserializedData
        };
        for (const key in body) {
          if (!body.hasOwnProperty(key)) {
            continue;
          }

          if (keyBlacklist.indexOf(key) !== -1) {
            continue;
          }

          responseData[key] = body[key];
        }

        return responseData;
      }));
  };

  createResource = <T>(resource: string, body: any): Observable<T> => {
    const options = createOptions();
    return this.httpClient.post<T>(`${this.url}${resource}`, body, options).pipe(
      map(data => {
        return deserializeJson(data);
      })
    );
  };

  updateResource = <T>(resource: string, id: number, body: any): Observable<any> => {
    const options = createOptions();
    return this.httpClient.patch(`${this.url}${resource}/${id}`, body, options);
  };

  deleteResource = <T>(resource: string, id: number): Observable<any> => {
    const options = createOptions();
    return this.httpClient.delete(`${this.url}${resource}/${id}`, options);
  }
}
