import {filter, takeUntil} from 'rxjs/operators';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MenuService} from '../../services/menu.service';
import {MenuItem} from '../../models/menu-item.interface';
import {UserService} from '../../services/observables/user.service';
import {User} from '../../models/user.interface';
import {getSingleResourceRelation} from '../../utils/json.util';
import {Employee} from '../../models/employee.interface';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {PentadService} from '../../services/pentad.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'jet-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  @ViewChild('root') root: ElementRef;

  public menu: any;
  public user: User;
  public username: string;
  public limelyteAdmin = false;
  private ngUnsubscribe = new Subject();

  constructor(
    private menuService: MenuService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private pentadService: PentadService,
  ) {
    this.userService.user.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((user: User) => {
      if (!user) {
        return;
      }
      const resource = user as any;
      this.menuService.getRootMenu(resource.forceRefreshed).then((menuItem: MenuItem) => {
        this.menu = menuItem.attributes;
      });
    });
  }

  ngOnInit() {
    this.userService.user.pipe(
        filter(data => data !== null),
        takeUntil(this.ngUnsubscribe)
    ).subscribe((user) => {
      this.user = user;
      const employee: Employee = getSingleResourceRelation<Employee>(this.user.relationships, 'employee');
      this.username = employee.attributes ? employee.attributes.FullName : 'ADMIN';
      if (this.user.attributes.email === 'limelyte') {
        this.limelyteAdmin = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public openlimelyteAdmin() {
    this.router.navigate(['admin/impersonate']);
  }

  enter() {
    this.root.nativeElement.classList.add('show');
    this.root.nativeElement.getElementsByTagName('ul').item(0).classList.add('show');
  }

  leave() {
    this.root.nativeElement.classList.remove('show');
    this.root.nativeElement.getElementsByTagName('ul').item(0).classList.remove('show');
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['login']);
  }

  goToSalesContest() {
    this.router.navigate(['sales-contest/report']);
  }

  admin() {
    this.pentadService.setPentadSecurityAndRedirect('internal/SystemsAdmin.aspx?PentadSecurity');
  }
}
