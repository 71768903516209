import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'momentDate',
})
export class MomentDatePipe implements PipeTransform {

  transform(value: Date|string, format: string = '', inputFormat: string = 'Y-MM-DD HH:mm:ss.SSS'): string {
    // Try and parse the passed value.
    const momentDate = moment(value, inputFormat);

    // If moment didn't understand the value, return it unformatted.
    if (!momentDate.isValid()) { return ''; }

    // Otherwise, return the date formatted as requested.
    return momentDate.format(format);
  }
}
