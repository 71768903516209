<div class="card">
  <div class="card-heading" data-toggle="collapse" attr.data-target="#{{key}}-{{contactTypeName}}">
    <h3 class="caps card-title">
      <span>{{ contactTypeName }}</span>
      <i class="fa {{ contactTypeIcon }} fa-lg"></i>
      <a class="caret"></a>
    </h3>
    <div class="card-subheading float-right">
      <div class="text-right">
        <i class="fa fa-lg fa-plus" (click)="toggleShowAddFormOnClick($event)"></i>
      </div>
    </div>
  </div>
  <div class="card-body collapse show fivepad" id="{{key}}-{{contactTypeName}}">
    <table class="small table table-striped table-hover table-borderless" style="margin-bottom: 0;">
      <tbody class="jet-table" cdkDropList (cdkDropListDropped)="drop($event)">
      <tr class="jet-row" *ngFor="let contact of contacts" cdkDrag>
        <jet-contact class="" [customerId]="customerId" [contact]="contact" [state]="STATE_VIEW" [contactValueDisplayType]="contactValueDisplayType">
          <div slot="drag-handle" class="jet-column-reorder d-inline align-top p-1" cdkDragHandle>
            <i class="fas fa-grip-vertical"></i>
          </div>
        </jet-contact>
      </tr>
      <tr *ngIf="contacts?.length === 0">
        <td colspan="12">No records to display</td>
      </tr>
      <tr *ngIf="showAddForm">
        <jet-contact [customerId]="customerId"
                     [contact]="emptyContact"
                     [state]="STATE_ADD"
                     (onAdd)="newContactAddHandler($event)"
                     (onCancel)="newContactCancelHandler($event)">

        </jet-contact>
      </tr>
      </tbody>
    </table>

  </div>
</div>
