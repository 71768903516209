import {take} from 'rxjs/operators';
import {SendToShippingModalComponent} from '../app/shared/components/send-to-shipping-modal/send-to-shipping-modal.component';
import {Inject, Injectable} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {InvoiceService} from './observables/invoice.service';
import {
  INVOICE_STATUS_SENT_TO_SHIPPING,
  INVOICE_STATUS_COMPLETED,
  INVOICE_STATUS_CANCELLED, INVOICE_LINE_ITEM_TYPE_INVENTORY
} from '../utils/constants.util';
import {InvoiceLineItem} from "../models/invoice-line-item.interface";
import {WarningModalComponent} from "../app/shared/components/warning-modal/warning-modal.component";
import {Invoice} from "../models/invoice.interface";
import {Button} from "../app/shared/components/warning-modal/button";
import * as moment from 'moment';
import {getComputedFreightInfo} from "../utils/invoice.util";
import {DOMAIN_CONFIG, DomainConfigInterface} from "../config/domain.interface";
import {JetCurrencyPipe} from "../pipes/jet-currency-pipe";
import {firstValueFrom} from "rxjs";


@Injectable()
export class SendToShippingService {
  private bsModalRef: BsModalRef;

  constructor(
    private bsModalService: BsModalService,
    private invoiceService: InvoiceService,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
    private currency: JetCurrencyPipe,
  ) {
  }

  public showSendToShipping(): boolean {
    const statuses = [INVOICE_STATUS_COMPLETED, INVOICE_STATUS_CANCELLED];
    return this.invoiceService.currentInvoice() && !statuses.some((status: number) => status === this.invoiceService.currentInvoice().attributes.Codes_StatusIDfk);
  }

  public async openSendToShippingModal() {
    if (!this.showSendToShipping()) {
      return;
    }

    const modalsCount = this.bsModalService.getModalsCount();
    if (modalsCount > 0) {
      // Need to wait for modal to hide before openSendToShippingModal
      await firstValueFrom(this.bsModalService.onHidden.pipe(take(1)));
    }

    this.bsModalRef = this.bsModalService.show(SendToShippingModalComponent);
  }

  async negativeQuantityCheck(invoice: Invoice) {
    const negativeQuantityLineItems: InvoiceLineItem[] = [];
    for (const item of invoice.relationships.lineItems) {

      if (item.attributes.LineItemType !== INVOICE_LINE_ITEM_TYPE_INVENTORY) {
        continue;
      }

      if (item.attributes.Description.toLowerCase().indexOf('smartop') > -1) {
        continue;
      }

      if (item.attributes.Description.toLowerCase().indexOf('smartshield') > -1) {
        continue;
      }

      if (item.attributes.InventoryItemQuantity.physical - item.attributes.Quantity < 0) {
        negativeQuantityLineItems.push(item);
      }
    }

    if (negativeQuantityLineItems.length > 0) {
      const messages = {
        head: ['Item', 'Requested', 'Available'],
        rows: [],
      };

      for (const item of negativeQuantityLineItems) {
        const row = [];
        row.push(item.attributes.Description);
        row.push(item.attributes.Quantity);
        row.push(item.attributes.InventoryItemQuantity.physical);
        messages.rows.push(row);
      }

      const initialState = {
        tableData: messages,
        title: 'Cannot Complete Order - Insufficient Inventory',
        enableConfirm: true,
        confirmMessage: 'Send To Shipping Anyways',
        cancelMessage: 'Don\'t Send To Shipping',
      };

      this.bsModalRef = this.bsModalService.show(WarningModalComponent, {class: 'modal-lg', initialState});

      return (await firstValueFrom(this.bsModalRef.content.onClose.pipe(take(1))))["confirm"];
    }

    return true;
  }

  async dateShippedCheck(invoice: Invoice) {

    const todaysDate = moment(new Date()).format('Y-MM-DD');

    if (!invoice.attributes.DateShipped) {
      invoice.attributes.DateShipped = todaysDate;
      return true;
    }

    if (invoice.attributes.DateShipped !== todaysDate) {
      const messages = [
        "The shipping date is set to a different day than today: " + moment(invoice.attributes.DateShipped, 'Y-MM-DD').format('MM/DD/YY'),
      ];

      const initialState = {
        warnings: messages,
        title: 'Date Shipped',
        enableConfirm: true,
        confirmMessage: 'Keep existing shipping date.',
        cancelMessage: 'Don\'t Send To Shipping.',
        buttons: [
          {id: 'today', text: 'Update with today\'s date.', btnClass: 'btn-success'} as Button,
        ],
      };

      this.bsModalRef = this.bsModalService.show(WarningModalComponent, {class: 'modal-lg', initialState});

      let button = (await firstValueFrom(this.bsModalRef.content.onClose.pipe(take(1)))) as Button;

      switch (button.id) {
        case 'today':
          invoice.attributes.DateShipped = todaysDate;
          return true;
        case 'confirm':
          return true;
        case 'cancel':
          return false;
      }
    }

    return true;
  }

  async balanceCheck(invoice: Invoice) {

    const terms = this.config.codes['terms'];

    //Only check the balance for specific terms type. From: Internal/Managing/CRMS/STM/Invoices.aspx.vb
    switch (invoice.attributes.Codes_CustomerTermsIDfk) {
      case terms['Credit Card - Visa']:
      case terms['Credit Card - Master Card']:
      case terms['Credit Card']:
      case terms['Credit Card - Amex']:
      case terms['Credit Card - Discover']:
        break;
      default:
        return true;
    }

    let freightInfo = getComputedFreightInfo(invoice, this.config.isUk);

    if (freightInfo.balance) {
      const messages = [
        "There is still a balance on this credit card order: " + this.currency.transform(freightInfo.balance),
      ];

      const initialState = {
        warnings: messages,
        title: 'Date Shipped',
        enableConfirm: true,
        confirmMessage: 'Send To shipping.',
        cancelMessage: 'Don\'t Send To Shipping.',
      };

      this.bsModalRef = this.bsModalService.show(WarningModalComponent, {class: 'modal-lg', initialState});

      let button = (await firstValueFrom(this.bsModalRef.content.onClose.pipe(take(1))))as Button;

      switch (button.id) {
        case 'confirm':
          return true;
        case 'cancel':
          return false;
      }
    }

    return true;
  }
}
