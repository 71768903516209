import {Component, OnInit} from '@angular/core';
import {SmartopOrderSubmissionService} from "../../services/observables/smartop-order-submission.service";
import { firstValueFrom, Observable } from "rxjs";
import {SmartopOrderSubmission} from "../../models/smartop-order-submission.interface";
import {filter, switchMap, take, tap} from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import {LoadingService} from "../../services/observables/loading.service";
import {WarningModalComponent} from "../shared/components/warning-modal/warning-modal.component";
import {Button} from "../shared/components/warning-modal/button";
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-smartop-orders',
  templateUrl: './smartop-orders.component.html',
  styleUrls: ['./smartop-orders.component.scss']
})
export class SmartopOrdersComponent implements OnInit {

  orders: Observable<SmartopOrderSubmission[]>;
  orderCount = -1;

  constructor(
    private smartopOrderService: SmartopOrderSubmissionService,
    private router: Router,
    private loader: LoadingService,
    private bsModalService: BsModalService,
    private route: ActivatedRoute,
  ) {
  }

  async ngOnInit() {
    const loader = this.loader.newLoader().start();

    const params = await firstValueFrom(this.route.queryParams);

    this.orders = this.smartopOrderService.getResources({
      criteria: {
        status: params['status'] ?? 'unrouted',
      },
      sorting: {'id': 'desc'}
    }).pipe(
      filter(data => data !== null),
      tap((data) => {
        loader.stop();
        this.orderCount = data.length;
      })
    )
  }

  deleteOrder(order: SmartopOrderSubmission) {

    const initialState = {
      enableConfirm: true,
      warnings: [`Do you really want to delete the smartop order for : ${order.attributes.company} ?`],
      title: 'Confirm Deletion',
      confirmMessage: "Delete Smartop Order",
    };

    const loader = this.loader.newLoader();
    this.bsModalService
      .show(WarningModalComponent, {class: 'modal-lg', initialState})
      .content.onClose
      .pipe(
        take(1),
        filter((button: Button) => button.confirm),
        tap(() => loader.start()),
        switchMap(() => this.smartopOrderService.delete(order))
      )
      .subscribe(() => {
        loader.stop();
      })
    ;
  }

  duplicateOrder(order: SmartopOrderSubmission) {
    const loader = this.loader.newLoader().start();
    this.smartopOrderService.duplicate(order).subscribe(() => {
      loader.stop();
    })
  }
}
