<div class="card">
  <div class="card-heading" data-toggle="collapse" data-target="#custContacts">
    <h3 class="caps card-title">
      <span>contacts</span>
      <i class="fa fa-users fa-lg"></i>
      <a class="caret"></a>
    </h3>
    <div class="card-subheading float-right">
      <div class="text-right">
        <i class="fa fa-lg fa-plus" (click)="createNewContact($event)"></i>
      </div>
    </div>
  </div>
  <div class="card-body collapse show fivepad" id="custContacts">
    <table class="small table  table-striped table-hover table-borderless" style="margin-bottom: 0;">
      <tbody cdkDropList (cdkDropListDropped)="drop($event)" class="jet-table">
      <ng-container *ngFor="let individual of individuals; let i = index">
          <tr class="jet-row" cdkDrag>
            <td>
              <div slot="drag-handle" class="jet-column-reorder " cdkDragHandle>
                <i class="fas fa-grip-vertical"></i>
              </div>
            </td>
            <td style="padding:5px !important;">
              <a target="_blank" [routerLink]="['/invoice/customer']" [queryParams]="{ customer_id: individual.attributes.CustomerID }">
                {{ individual.attributes.FirstName }}<br>
                <ng-container *ngIf="individual.attributes.MiddleName.length">{{ individual.attributes.MiddleName }}<br></ng-container>
                {{ individual.attributes.LastName }}<br>
                <ng-container *ngIf="individual.attributes.Title.length">({{ individual.attributes.Title }})<br></ng-container>
              </a>
            </td>
            <td style="padding:5px !important;">
              <ng-container *ngFor="let address of individual?.relationships?.addresses">
                <b>{{ address.attributes.Description }}</b><br>
                {{ address.attributes.Line1 }} {{ address.attributes.Line2 }}<br>
                {{ address.attributes.City }}, {{ address.attributes.State }} {{ address.attributes.ZIP }}<br>
              </ng-container>
            </td>
            <td>
              <ng-container *ngFor="let contact of individual?.relationships?.contacts">
                <b>{{ contact.attributes.Description }} -</b>
                  <a *ngIf="contact.attributes.ContactValue.indexOf('@') != -1" target="_blank" href="mailto:{{ contact.attributes.ContactValue }}">{{ contact.attributes.ContactValue }}</a>
                  <span *ngIf="contact.attributes.ContactValue.indexOf('@') == -1" >{{ contact.attributes.ContactValue }}</span>
              <br>
              </ng-container>
            </td>
            <td style="width: 50px;">
              <div *ngIf="!individual.editing">
                <button class="btn p-0" (click)="showEditFormOnClick($event, individual)">
                  <i class="fa fa-pencil-alt"></i>
                </button>
                <div class="btn-group">
                  <button class="btn btn-secondary btn-sm dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false"
                          style="background-color:transparent;color:black;border:none;box-shadow:none;"></button>
                  <div class="dropdown-menu dropdown-menu-right" style="min-width: 125px;">
                    <a class="dropdown-item" (click)="remove(individual)" style="cursor: pointer;"><i
                      class="fa fa-trash-alt"></i> Delete</a>
                    <a class="dropdown-item" (click)="showEditFormOnClick(individual)" style="cursor: pointer;"><i
                      class="fa fa-pencil-alt"></i> Edit</a>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="individual.editing">
            <td colspan="4">
              <div class="form-subgroup">
                <div class="row row2">
                  <div class="col-md-4 caps onepad">
                    <b>First</b>
                  </div>
                  <div class="col-md-8 onepad">
                    <input [(ngModel)]="individual.attributes.FirstName" type="text" class="small-form">
                  </div>
                </div>
                <div class="row row2">
                  <div class="col-md-4 caps onepad">
                    <b>middle</b>
                  </div>
                  <div class="col-md-8 onepad">
                    <input [(ngModel)]="individual.attributes.MiddleName" type="text" class="small-form">
                  </div>
                </div>
                <div class="row row2">
                  <div class="col-md-4 caps onepad">
                    <b>last</b>
                  </div>
                  <div class="col-md-8 onepad">
                    <input [(ngModel)]="individual.attributes.LastName" type="text" class="small-form">
                  </div>
                </div>
                <div class="row row2">
                  <div class="col-md-4 caps onepad">
                    <b>title</b>
                  </div>
                  <div class="col-md-8 onepad">
                    <input [(ngModel)]="individual.attributes.Title" type="text" class="small-form">
                  </div>
                </div>
                <div class="row row2">
                  <div class="col-md-4 caps onepad">
                    <b>Phone</b>
                  </div>
                  <div class="col-md-8 onepad">
                    <input [(ngModel)]="getContactByType(phone, individual).attributes.ContactValue" type="text" class="small-form">
                  </div>
                </div>
                <div class="row row2">
                  <div class="col-md-4 caps onepad">
                    <b>Fax</b>
                  </div>
                  <div class="col-md-8 onepad">
                    <input [(ngModel)]="getContactByType(fax, individual).attributes.ContactValue" type="text" class="small-form">
                  </div>
                </div>
                <div class="row row2">
                  <div class="col-md-4 caps onepad">
                    <b>Email</b>
                  </div>
                  <div class="col-md-8 onepad">
                    <input [(ngModel)]="getContactByType(email, individual).attributes.ContactValue" type="text" class="small-form">
                  </div>
                </div>
                <div class="row row2">
                  <div class="col-md-4 caps onepad">
                    <b>Address</b>
                  </div>
                  <div class="col-md-8 onepad">
                    <input [(ngModel)]="individual.relationships.addresses[0].attributes.Line1" type="text" class="small-form">
                  </div>
                </div>
                <div class="row row2">
                  <div class="col-md-4 caps onepad">
                    <b>Suite</b>
                  </div>
                  <div class="col-md-8 onepad">
                    <input [(ngModel)]="individual.relationships.addresses[0].attributes.Line2" type="text" class="small-form">
                  </div>
                </div>
                <div class="row row2">
                  <div class="col-md-4 caps onepad">
                    <b>City</b>
                  </div>
                  <div class="col-md-8 onepad">
                    <input [(ngModel)]="individual.relationships.addresses[0].attributes.City" type="text" class="small-form">
                  </div>
                </div>
                <div class="row row2">
                  <div class="col-md-4 caps onepad">
                    <b>State</b>
                  </div>
                  <div class="col-md-8 onepad">
                    <input [(ngModel)]="individual.relationships.addresses[0].attributes.State" type="text" class="small-form">
                  </div>
                </div>
                <div class="row row2">
                  <div class="col-md-4 caps onepad">
                    <b>Zip</b>
                  </div>
                  <div class="col-md-8 onepad">
                    <input [(ngModel)]="individual.relationships.addresses[0].attributes.ZIP" type="text" class="small-form">
                  </div>
                </div>
              </div>


              <div class="row row2">
                <div class="col-sm-6">
                  <button class="btn btn-success btn-sm btn-block" (click)="save(individual)">
                    Save
                  </button>
                </div>
                <div class="col-sm-6">
                  <button class="btn btn-default btn-sm btn-block" (click)="cancel(individual)">
                    Cancel
                  </button>
                </div>
              </div>



            </td>
          </tr>
      </ng-container>
      <tr *ngIf="individuals?.length === 0">
        <td colspan="12">No records to display</td>
      </tr>
      <tr *ngIf="showEditForm">
          <td colspan="4">
              <div class="form-subgroup container-fluid">
                  <div class="row row2">
                      <div class="col-md-4 caps onepad">
                          <b>First</b>
                      </div>
                      <div class="col-md-8 onepad">
                          <input [(ngModel)]="editIndividual.attributes.FirstName" type="text" class="small-form">
                      </div>
                  </div>
                  <div class="row row2">
                      <div class="col-md-4 caps onepad">
                          <b>middle</b>
                      </div>
                      <div class="col-md-8 onepad">
                          <input [(ngModel)]="editIndividual.attributes.MiddleName" type="text" class="small-form">
                      </div>
                  </div>
                  <div class="row row2">
                      <div class="col-md-4 caps onepad">
                          <b>last</b>
                      </div>
                      <div class="col-md-8 onepad">
                          <input [(ngModel)]="editIndividual.attributes.LastName" type="text" class="small-form">
                      </div>
                  </div>
                  <div class="row row2">
                      <div class="col-md-4 caps onepad">
                          <b>title</b>
                      </div>
                      <div class="col-md-8 onepad">
                          <input [(ngModel)]="editIndividual.attributes.Title" type="text" class="small-form">
                      </div>
                  </div>
                  <div class="row row2">
                      <div class="col-md-4 caps onepad">
                          <b>Phone</b>
                      </div>
                      <div class="col-md-8 onepad">
                          <input [(ngModel)]="getContactByType(phone, editIndividual).attributes.ContactValue" type="text" class="small-form">
                      </div>
                  </div>
                  <div class="row row2">
                      <div class="col-md-4 caps onepad">
                          <b>Fax</b>
                      </div>
                      <div class="col-md-8 onepad">
                          <input [(ngModel)]="getContactByType(fax, editIndividual).attributes.ContactValue" type="text" class="small-form">
                      </div>
                  </div>
                  <div class="row row2">
                      <div class="col-md-4 caps onepad">
                          <b>Email</b>
                      </div>
                      <div class="col-md-8 onepad">
                          <input [(ngModel)]="getContactByType(email, editIndividual).attributes.ContactValue" type="text" class="small-form">
                      </div>
                  </div>
                  <div class="row row2">
                      <div class="col-md-4 caps onepad">
                          <b>Address</b>
                      </div>
                      <div class="col-md-8 onepad">
                          <input [(ngModel)]="editIndividual.relationships.addresses[0].attributes.Line1" type="text" class="small-form">
                      </div>
                  </div>
                  <div class="row row2">
                      <div class="col-md-4 caps onepad">
                          <b>Suite</b>
                      </div>
                      <div class="col-md-8 onepad">
                          <input [(ngModel)]="editIndividual.relationships.addresses[0].attributes.Line2" type="text" class="small-form">
                      </div>
                  </div>
                  <div class="row row2">
                      <div class="col-md-4 caps onepad">
                          <b>City</b>
                      </div>
                      <div class="col-md-8 onepad">
                          <input [(ngModel)]="editIndividual.relationships.addresses[0].attributes.City" type="text" class="small-form">
                      </div>
                  </div>
                  <div class="row row2">
                      <div class="col-md-4 caps onepad">
                          <b>State</b>
                      </div>
                      <div class="col-md-8 onepad">
                          <input [(ngModel)]="editIndividual.relationships.addresses[0].attributes.State" type="text" class="small-form">
                      </div>
                  </div>
                  <div class="row row2">
                      <div class="col-md-4 caps onepad">
                          <b>Zip</b>
                      </div>
                      <div class="col-md-8 onepad">
                          <input [(ngModel)]="editIndividual.relationships.addresses[0].attributes.ZIP" type="text" class="small-form">
                      </div>
                  </div>

                  <div class="row row2">
                    <div class="col-sm-6">
                      <button class="btn btn-success btn-sm btn-block" (click)="save(editIndividual)">
                        Save
                      </button>
                    </div>
                    <div class="col-sm-6">
                      <button class="btn btn-default btn-sm btn-block" (click)="cancel(editIndividual)">
                        Cancel
                      </button>
                    </div>
                  </div>



              </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
