<div class="modal-header">
  <h4 class="modal-title pull-left">Print Truck Labels</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <label class="col-md-5 col-form-label">How Many Labels to Print?</label>

    <div class="col-md-2">
      <input type="number" min="0" class="small-form" [(ngModel)]="truckLabelCount">
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="confirm()">Print</button>
  <button type="button" class="btn btn-primary" (click)="close()">Close</button>
</div>
