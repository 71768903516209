import { Code } from '../models/code.interface';
export const DEFAULT_CODE: Code = {
  id: '0',
  type: 'Code',
  attributes: {
    CodeID: 0,
    CodeIDfk: 0,
    CodeDesc: '',
    CodeSeq: 0,
    CodeActive: 1,
    CodeReadOnly: 0,
    AllowUserChildren: 1,
    IsUserVisible: 1,
    UniqueCodeName: '',
    CodeAlt: '',
    CodeValue: '',
    ShowCodeAlt: 0,
    ShowCodeValue: 0,
    CodeAltLabel: '',
    CodeValueLabel: '',
    // Below are computed members that are optional
    ProductCodeAlt: '',
    CustomerToCodeID: 0,
  }
};
