<div class="card">
  <div class="card-heading" data-toggle="collapse" attr.data-target="#{{key}}-dealer-types">
    <h3 class="caps card-title">
      <span>dealer types</span>
    </h3>
    <div class="card-subheading float-right">
      <div class="text-right">
        <i class="fa fa-lg fa-plus" (click)="toggleShowAddFormOnClick($event)"></i>
      </div>
    </div>
  </div>
  <div class="card-body collapse show fivepad" id="{{key}}-dealer-types">
    <table class="small table  table-striped table-hover table-borderless" style="margin-bottom: 0;">
      <tbody>
      <tr *ngFor="let dealerType of dealerTypes">
        <jet-dealer-type [customerId]="customerId" [dealerType]="dealerType" [codes]="codes" [productLines]="productLines" [state]="STATE_VIEW"></jet-dealer-type>
      </tr>
      <tr *ngIf="dealerTypes?.length === 0">
        <td colspan="12">No records to display</td>
      </tr>
      <tr *ngIf="showAddForm">
        <jet-dealer-type [customerId]="customerId" [dealerType]="emptyDealerType" [codes]="codes" [productLines]="productLines" [state]="STATE_ADD" (onAdd)="newDealerTypeAddHandler($event)" (onCancel)="newDealerTypeCancelHandler($event)"></jet-dealer-type>
      </tr>
      </tbody>
    </table>

  </div>
</div>
