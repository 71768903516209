import {LoadingService} from "./loading.service";

export class LoadingToken {

  private loading: boolean;

  constructor(private update:Function) {

    this.loading = false;

  }

  stop() {
    this.loading = false;

    this.update();

    return this;
  }

  start() {
    this.loading = true;

    this.update();

    return this;
  }

  isLoading() {
    return this.loading;
  }
}