import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PentadService } from '../../../services/pentad.service';
import { ActivatedRoute } from '@angular/router';
import { InvoiceService } from '../../../services/observables/invoice.service';
import { CustomerService } from '../../../services/observables/customer.service';
import { DomainConfigInterface, DOMAIN_CONFIG } from '../../../config/domain.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { EmailComponent } from '../email.component';
import { filter, takeUntil, concatMap } from 'rxjs/operators';
import { Invoice } from '../../../models/invoice.interface';
import {IndexService} from "../../../services/observables/index.service";

@Component({
  selector: 'app-invoice-email',
  templateUrl: '../email.component.html',
  styleUrls: ['../email.component.scss']
})

export class InvoiceEmailComponent extends EmailComponent implements OnInit {

  public editorConfig = {
    'editable': true,
    'spellcheck': true,
    'height': '450px',
    'maxHeight': '600px',
    'minHeight': '0',
    'width': 'auto',
    'minWidth': '0',
    'translate': 'yes',
    'enableToolbar': true,
    'showToolbar': true,
    'imageEndPoint': '',
    'toolbarHiddenButtons': [
      [
        'insertImage',
        'insertVideo',
      ]
    ]
  };

  constructor(
    httpClient: HttpClient,
    pentadService: PentadService,
    route: ActivatedRoute,
    invoiceService: InvoiceService,
    customerService: CustomerService,
    @Inject(DOMAIN_CONFIG) config: DomainConfigInterface,
    domSanitizer: DomSanitizer,
    indexService: IndexService,
  )
  {
    super(httpClient, pentadService, route, invoiceService, customerService, config, domSanitizer, indexService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isLoading = true;

    let invoiceObservable = this.invoiceService.invoice.pipe(
      filter(data => data !== null),
      takeUntil(this.ngUnsubscribe),
    );

    invoiceObservable.pipe(
      concatMap((invoice: Invoice) => {
        this.invoice = invoice;

        if (this.invoice.attributes.InvoiceType === 1) {
          this.isSmartTop = true;
        }

        this.originalFromAddress = this.fromAddress = this.invoice.relationships.salesPerson.relationships.emails[0].attributes.ContactValue;
        this.buildEmail();

        return this.pentadService.setPentadSecurity(this.config.baseUrl + 'internal/Reporting/Reports.aspx?disposition=inline&PentadSecurity&ReportID=invoice&PDF=true&Parameters=Invoice%3d' + this.invoice.attributes.InvoiceNumber);
      }),
      concatMap((url: string) => {

        return this.httpClient.get(url, {responseType: 'blob'});
      })
    ).subscribe((res: any) => {
      this.emailBody  = this.buildEmail();
      this.reportBlob = new File([res], `Leisure_Concepts_Invoice_${this.invoice.attributes.InvoiceNumber}.pdf`);
      this.report     = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(res));
      this.isLoading  = false;
    });
  }

  submitForm(event: any) {
    super.submitForm(event);

    var contacts = [];

    for (var i = 0; i < event.target.length; i++) {
      if (event.target[i].classList.contains('form-check-input')) {
        if (event.target[i].checked == true) {
          contacts.push(event.target[i].labels[0].innerText);
        }
      }
    }

    var addresses  = event.target.addresses.value;
    var addressArr = addresses.split(',');

    if (addresses.length > 0) {
      addressArr.forEach(address => {
        address = address.trim();
        if (address) {
          contacts.push(address);
        }
      });
    }

    var formData = new FormData();

    for (var i = 0; i < this.fileList.length; i++) {
      formData.append('file ' + i.toString(), this.fileList[i]);
    }

    formData.append('subject', this.emailSubject);
    formData.append('body', this.emailBody);
    formData.append('contacts', JSON.stringify(contacts));
    formData.append('invoice', this.reportBlob);
    formData.append('packing_list', this.packingListBlob);
    formData.append('from', this.fromAddress);
    formData.append('email_type', 'Invoice');
    formData.append('id', this.invoice.id);

    this.httpClient.post(this.url + 'invoices/sendShippingEmail', formData).subscribe(
      success => {
        const bc = new BroadcastChannel('invoice_' + this.invoice.id);
        bc.postMessage({
          message: 'Invoice Email Sent',
          invoiceId: this.invoice.id,
        });
        window.close();
      },
      error => {
        this.formError = true;
        this.error     = error.error.error.exception[0].message;
      }
    );
  }

  buildEmail() : string {
    if (this.invoice.attributes.InvoiceType === 1) {
      this.isSmartTop = true;
    }
    this.emailSubject = "Leisure Concepts Invoice For " + this.invoice.relationships.shipToCustomer.attributes.FullName;
    if (this.isUk) {
      this.emailBody = "Thank you for your order. Attached is your proforma invoice. To make payment, you can call me at 07843931978 with your card details or pay by wire transfer using the banking details below. ";
    } else {
      this.emailBody = "Thank you for your order.  Attached is your completed invoice. ";
    }
    // add tracking info
    if (this.invoice.relationships.freightVendor.attributes.CodeDesc.indexOf("FedEx") > -1) {
      if (this.invoice.attributes.FreightTrackingNumber) {
        this.emailBody += "Your FedEx Tracking Number is: ";
        this.emailBody += "<a target='_blank' style='color:Blue;' href='http://fedex.com/Tracking?tracknumbers=" + this.invoice.attributes.FreightTrackingNumber + "&action=track&language=english&cntry_code=us&mps=y'>" + this.invoice.attributes.FreightTrackingNumber + "</a> ";
        this.emailBody += "(click the tracking number to track your order, please allow one business day for tracking results). ";
      }
    } else if (this.invoice.relationships.freightVendor.attributes.CodeDesc.indexOf("UPS") > -1) {
      if (this.invoice.attributes.FreightTrackingNumber) {
        this.emailBody += "Your UPS Tracking Number is: ";
        this.emailBody += "<a target='_blank' style='color:Blue;' href='http://wwwapps.ups.com/etracking/tracking.cgi?TypeOfInquiryNumber=T&InquiryNumber1=" + this.invoice.attributes.FreightTrackingNumber + "'>" + this.invoice.attributes.FreightTrackingNumber + "</a> ";
        this.emailBody += "(click the tracking number to track your order, please allow one business day for tracking results). ";
      }
    } else if (this.invoice.relationships.freightVendor.attributes.CodeDesc === "ABF") {
      if (this.invoice.attributes.FreightTrackingNumber) {
        this.emailBody += "Your ABF Tracking Number is: ";
        this.emailBody += "<a target='_blank' style='color:Blue;' href='https://arcb.com/tools/tracking.html#/" + this.invoice.attributes.FreightTrackingNumber + "'>" + this.invoice.attributes.FreightTrackingNumber + "</a> ";
        this.emailBody += "(click the tracking number to track your order, please allow one business day for tracking results). ";
      } else {
        this.emailBody += "Your order has been shipped via ABF. ";
      }
    } else if (this.invoice.relationships.freightVendor.attributes.CodeDesc === "Old Dominion") {
      if (this.invoice.attributes.FreightTrackingNumber) {
        this.emailBody += "Your Old Dominion Tracking Number is: ";
        this.emailBody += "<a target='_blank' style='color:Blue;' href='https://www.odfl.com/Trace/standardResult.faces?pro=" + this.invoice.attributes.FreightTrackingNumber + "'>" + this.invoice.attributes.FreightTrackingNumber + "</a> ";
        this.emailBody += "(click the tracking number to track your order, please allow one business day for tracking results). ";
      } else {
        this.emailBody += "Your order has been shipped via Old Dominion. ";
      }
    } else if (this.invoice.relationships.freightVendor.attributes.CodeDesc === "SAIA") {
      if (this.invoice.attributes.FreightTrackingNumber) {
        this.emailBody += "Your SAIA Tracking Number is: ";
        this.emailBody += "<a target='_blank' style='color:Blue;' href='https://www.saia.com/track/details;pro=" + this.invoice.attributes.FreightTrackingNumber + "'>" + this.invoice.attributes.FreightTrackingNumber + "</a> ";
        this.emailBody += "(click the tracking number to track your order, please allow one business day for tracking results). ";
      } else {
        this.emailBody += "Your order has been shipped via SAIA. ";
      }
    } else if (this.invoice.relationships.freightVendor.attributes.CodeDesc === "USF Reddaway") {
      if (this.invoice.attributes.FreightTrackingNumber) {
        this.emailBody += "Your USF Reddaway Tracking Number is: ";
        this.emailBody += "<a target='_blank' style='color:Blue;' href='https://track.aftership.com/usf-reddaway/" + this.invoice.attributes.FreightTrackingNumber + "'>" + this.invoice.attributes.FreightTrackingNumber + "</a> ";
        this.emailBody += "(click the tracking number to track your order, please allow one business day for tracking results). ";
      } else {
        this.emailBody += "Your order has been shipped via USF Reddaway. ";
      }
    } else if (this.invoice.relationships.freightVendor.attributes.CodeDesc === "XPO Logistics" || this.invoice.relationships.freightVendor.attributes.CodeDesc === "Conway") {
      if (this.invoice.attributes.FreightTrackingNumber) {
        this.emailBody += "Your XPO Logistics Tracking Number is: ";
        this.emailBody += "<a target='_blank' style='color:Blue;' href='https://app.ltl.xpo.com/appjs/tracking/details/" + this.invoice.attributes.FreightTrackingNumber + "'>" + this.invoice.attributes.FreightTrackingNumber + "</a> ";
        this.emailBody += "(click the tracking number to track your order, please allow one business day for tracking results). ";
      } else {
        this.emailBody += "Your order has been shipped via XPO Logistics. ";
      }
    } else if (this.invoice.relationships.freightVendor.attributes.CodeDesc === "MME") {
      if (this.invoice.attributes.FreightTrackingNumber) {
        this.emailBody += "Your MME Logistics Tracking Number is: ";
        this.emailBody += "<a target='_blank' style='color:Blue;' href='https://www.mmeinc.com/tracking.php?TrackNumber=" + this.invoice.attributes.FreightTrackingNumber + "&From=tracking'>" + this.invoice.attributes.FreightTrackingNumber + "</a> ";
        this.emailBody += "(click the tracking number to track your order, please allow one business day for tracking results). ";
      } else {
        this.emailBody += "Your order has been shipped via MME. ";
      }
    } else if (this.invoice.relationships.freightVendor.attributes.CodeDesc === "Oak Harbor") {
      if (this.invoice.attributes.FreightTrackingNumber) {
        this.emailBody += "Click the button to see your Oak Harbor tracking information ";
        this.emailBody += "<form action='https://www.oakh.com/tracingactions.php' method='post'><input type='hidden' name='action' value='loadTraceDetails'>";
        this.emailBody += "<input type='hidden' name='traceNum' value='" + this.invoice.attributes.FreightTrackingNumber + "'>";
        this.emailBody += "<input type='hidden' name='tracePro' value='" + this.invoice.attributes.FreightTrackingNumber + "'>";
        this.emailBody += "<input type='hidden' name='traceType' value='OHPRO'><input type='hidden' name='showPrevious' value='false'><input type='hidden' name='showNext' value='false'><input type='hidden' name='traceAccount' value='0'><input type='submit' value='Oak Harbor Tracking'></form>";
        this.emailBody += "Please allow one business day for tracking results). ";
      } else {
        this.emailBody += "Your order has been shipped via Oak Harbor. ";
      }
    } else if (this.invoice.relationships.freightVendor.attributes.CodeDesc === "YRC") {
      if (this.invoice.attributes.FreightTrackingNumber) {
        this.emailBody += "Your YRC Tracking Number is: ";
        this.emailBody += "<a target='_blank' style='color:Blue;' href='https://my.yrc.com/tools/track/shipments?referenceNumberType=PRO&referenceNumber=" + this.invoice.attributes.FreightTrackingNumber + "'>" + this.invoice.attributes.FreightTrackingNumber + "</a> ";
        this.emailBody += "(click the tracking number to track your order, please allow one business day for tracking results). ";
      } else {
        this.emailBody += "Your order has been shipped via YRC. ";
      }
    } else {
      this.emailBody += "Your order has been shipped via " + this.invoice.relationships.freightVendor.attributes.CodeDesc;
    }

    this.emailBody += "<br/><br/>";

    if (this.isUk) {
      this.emailBody += "<b>Leisure Concepts, Inc.</b><br/>";
      this.emailBody += "<b>Wire Transfer Banking Information</b><br/>";
      this.emailBody += "<style>.border-none, .border-none td, .border-none tr { border: none }</style>";
      this.emailBody += "<style>.padded-first-cells tr td:first-of-type { padding-right: 15px}</style>";
      this.emailBody += "<table class='border-none padded-first-cells'>";
      this.emailBody += "<tr align='left'><td>Address</td><td>Unit 100C Ashchurch Business Centre</td></tr>";
      this.emailBody += "<tr align='left'><td></td><td>Alexandra Way</td></tr>";
      this.emailBody += "<tr align='left'><td></td><td>Tewkesbury GL20 8TD</td></tr>";
      this.emailBody += "<tr align='left'><td>Bank</td><td>Barclays Bank PLC</td></tr>";
      this.emailBody += "<tr align='left'><td>Address</td><td>Cheltenham Manor Park branch,</td></tr>";
      this.emailBody += "<tr align='left'><td></td><td>Gloucestershire</td></tr>";
      this.emailBody += "<tr align='left'><td>Phone</td><td>0845 7555555</td></tr>";
      this.emailBody += "<tr align='left'><td>Routing</td><td></td></tr>";
      this.emailBody += "<tr align='left'><td>Account</td><td>43443485</td></tr>";
      this.emailBody += "<tr align='left'><td>SwiftBIC</td><td>BUKBGB22</td></tr>";
      this.emailBody += "<tr align='left'><td>Sort Code</td><td>20‐20‐15</td></tr>";
      this.emailBody += "<tr align='left'><td>IBAN</td><td>GB26 BUKB 2020 1543 4434 85</td></tr>";
      this.emailBody += "</table>";
      this.emailBody += "<br/>";
      this.emailBody += "Your order will be shipped after we receive payment. If you have any questions, please give me a call.<br/>";
      this.emailBody += "<br/>";
      if (this.invoice.relationships.salesPerson) {
        this.emailBody += this.invoice.relationships.salesPerson.attributes.FullName + " " + this.invoice.relationships.salesPerson.attributes.LastName + " <br/>";
      }
      this.emailBody += "Leisure Concepts UK<br/>"
      this.emailBody += "07843 931 978<br/>"
    } else {
      this.emailBody += "If you have any questions, please give me a call. <br/><br/>";
      if (this.invoice.relationships.salesPerson) {
        this.emailBody += this.invoice.relationships.salesPerson.attributes.FirstName + " " + this.invoice.relationships.salesPerson.attributes.LastName + " <br/>";
      }
      if (this.isSmartTop) {
        this.emailBody += "SMARTOP <br/>";
        this.emailBody += "800-469-2428<br/>";
        this.emailBody += "509-483-8217 Fax <br/>";
      } else {
        this.emailBody += "Leisure Concepts<br/>";
        this.emailBody += "800-469-2428<br/>";
        this.emailBody += "509-483-8217 Fax<br/>";
        if (this.fromAddress) {
          this.emailBody += this.fromAddress;
        }
      }
    }
    return this.emailBody;
  }


  openLegacyEmailer() {
    this.pentadService.setPentadSecurityAndRedirect('/internal/Managing/CRMS/STM/EmailInvoice.aspx?PentadSecurity&InvoiceID=' + this.invoice.id);
  }
}
