import {map} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';

import {Observable, Subscriber} from 'rxjs';
import {createOptions} from '../utils/http.util';
import {HttpClient} from '@angular/common/http';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../config/domain.interface";

@Injectable()
export class CustomerActionsService {
  private url: string;

  constructor(
    private httpClient: HttpClient,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
  ) {
    this.url = `${this.config.apiUrl}customer-actions`;
  }

  pendingInvoiceNumber = (customerId: number): Observable<any> => {
    const options = createOptions();
    return this.httpClient.get<any>(`${this.url}/${customerId}/pending-invoice-number`)
    ;
  }

  changeCustomerName = (customerId: number, name: string, showPreviousStoreHistory): Observable<any> => {
    const options = createOptions();
    return this.httpClient.post<any>(`${this.url}/${customerId}/change-customer-name`, { name, showPreviousStoreHistory }, options)
    ;
  }

  assignPriceListToChildren = (customerId: number): Observable<any> => {
    const options = createOptions();
    return this.httpClient.patch<any>(`${this.url}/${customerId}/assign-price-list-to-children`, {}, options)
    ;
  }

  mergeCustomers = (masterCustomerId: number, absorbedCustomerId: number): Observable<any> => {
    const options = createOptions();
    return this.httpClient.patch<any>(`${this.url}/merge-customers`, {
        Master_CustomerID: masterCustomerId,
        Absorbed_CustomerID: absorbedCustomerId,
      }, options).pipe(
      map((response: Response) => {
        return response.json();
      }))
    ;
  }
  swapCustomerContacts = (parentCustomerId: number, customerToMoveId: number, customerToSwapId: number) => {
    const options = createOptions();
    return this.httpClient.patch(`${this.url}/${parentCustomerId}/swap-customer-contacts`, {
      customerToMoveId,
      customerToSwapId,
      }, options)
    ;
  }

  dropCustomerContacts = (parentCustomerId: number,
                          customerToMoveId: number,
                          customerToSwapId: number,
                          currentIndex: number
  ) => {
    const options = createOptions();
    return this.httpClient.patch(`${this.url}/${parentCustomerId}/drop-customer-contacts`, {
      customerToMoveId,
      customerToSwapId,
      currentIndex
    }, options)
      ;
  }
}
