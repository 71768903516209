import {Component, Inject, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Invoice} from '../../../../models/invoice.interface';
import {getMultipleResourceRelation} from "../../../../utils/json.util";
import {Customer} from "../../../../models/customer.interface";
import {PaymentApplication} from "../../../../models/payment-application.interface";
import {InvoicePaymentPopupComponent} from "./invoice-payment-popup.component";
import {PentadService} from "../../../../services/pentad.service";
import {ComputedFreightInfo} from '../../../../models/computed-freight-info.interface';
import {ComputedFreightInfoService} from '../../../../services/observables/computed-freight-info.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";
import {BsModalService, ModalOptions} from 'ngx-bootstrap/modal';

declare let jQuery: any;
declare let window: any;

@Component({
  selector: 'jet-invoice-payments',
  templateUrl: './invoice-payments.component.html',
  styleUrls: ['./invoice-payments.component.scss']
})
export class InvoicePaymentsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public invoice: Invoice;
  public paymentApplications: PaymentApplication[] = [];
  private customer: Customer;
  public dateFormat: string;
  public computedFreightInfo: ComputedFreightInfo = null;
  private ngUnsubscribe = new Subject();

  constructor(private bsModalService: BsModalService,
              private pentadService: PentadService,
              private computedFreightInfoService: ComputedFreightInfoService,
              @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
              ) {
    this.dateFormat = this.config.dateFormat;
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.assignments();
    this.computedFreightInfoService.computedFreightInfo.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((computed: ComputedFreightInfo) => {
      this.computedFreightInfo = computed;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private assignments() {
    if (!this.invoice) {
      return;
    }

    this.paymentApplications = getMultipleResourceRelation<PaymentApplication>(this.invoice.relationships, 'paymentApplications');

    this.customer = this.invoice.relationships.billToCustomer;
  }

  handleCredit(payment: PaymentApplication) {
    const modalsCount = this.bsModalService.getModalsCount();
    // TODO: blur event on Freight Information fields firing multiple times. Guard against opening another one for now.
    if (modalsCount === 0) {
      const m = new ModalOptions();
      m.class = 'modal-lg';

      const ref = this.bsModalService.show(InvoicePaymentPopupComponent, m);
      ref.content.setPayment(payment, this.computedFreightInfo);
    }
  }

  openPaypalManagement() {
    this.pentadService.setPentadSecurityAndNewTab(`/Internal/Managing/CRMS/STM/PayPalManagement.aspx${window.location.search}&PentadSecurity`);
  }
}
