<div class="card">
  <div class="card-heading" data-toggle="collapse" data-target="#custStores">
    <h3 class="caps card-title">
      <span>stores</span>
      <i class="fa fa-building fa-lg"></i>
      <a class="caret"></a>
    </h3>
    <div class="card-subheading float-right">
      <div class="text-right">
        <i *ngIf="canAdd" class="fa fa-lg fa-plus" (click)="toggleShowAddFormOnClick($event)"></i>
      </div>
    </div>
  </div>
  <div class="card-body collapse show fivepad" id="custStores">
    <table class="small table  table-striped table-hover table-borderless" style="margin-bottom: 0;">
      <tbody>
      <tr *ngFor="let store of stores">
        <td style="padding:5px !important;">
          <b>
            <a target="_blank" [routerLink]="['/invoice/customer']" [queryParams]="{ customer_id: store.attributes.CustomerID }">
              {{ store.attributes.FullName }}
            </a>
          </b>
          <ng-container *ngIf="!store.isChild">
            <br>
            <b>Parent Dealer</b>
          </ng-container>
          <ng-container *ngIf="store.id === parentPreviousStoreId">
            <br>
            <span style="color: #AC3233; font-weight: bold; font-style: italic;">
              Previous Dealer Name
            </span>
          </ng-container>
          <br>
          <div style="padding-left: 15px">
            <ng-container *ngFor="let individual of store?.relationships?.individuals">
              <a target="_blank" [routerLink]="['/invoice/customer']" [queryParams]="{ customer_id: individual.attributes.CustomerID }">
                {{ individual.attributes.FullName }}{{ individual.attributes.Title !== '' ? ' - ' + individual.attributes.Title  : '' }}
              </a><br>
            </ng-container>
          </div>
        </td>
        <td style="padding:5px !important;">
          <ng-container *ngFor="let address of store?.relationships?.addresses">
            <b>{{ address.attributes.Description }}</b><br>
            {{ address.attributes.Line1 }} {{ address.attributes.Line2 }}<br>
            {{ address.attributes.City }}, {{ address.attributes.State }} {{ address.attributes.ZIP }}<br>
          </ng-container>
        </td>
        <td>
          <ng-container *ngFor="let contact of store?.relationships?.contacts">
            <b>{{ contact.attributes.Description }} -</b>
              <a *ngIf="contact.attributes.ContactValue.indexOf('@') != -1" target="_blank" href="mailto:{{ contact.attributes.ContactValue }}">{{ contact.attributes.ContactValue }}</a>
              <span *ngIf="contact.attributes.ContactValue.indexOf('@') == -1" >{{ contact.attributes.ContactValue }}</span>
            <br>
          </ng-container>
        </td>
        <td style="padding:5px !important;">

          <div class="btn-group">
            <button class="btn btn-secondary btn-sm dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false"
                    style="background-color:transparent;color:black;border:none;box-shadow:none;"></button>
            <div class="dropdown-menu" style="min-width: 125px;">
              <a class="dropdown-item" (click)="remove(store)" style="cursor: pointer;"><i class="fa fa-trash-alt"></i> Delete</a>
            </div>
          </div>

          <!--<i class="fa fa-pencil-alt" (click)="edit()"></i>-->
          <!--<i class="fa fa-minus" (click)="remove(store)"></i>-->
        </td>
      </tr>
      <tr *ngIf="stores?.length === 0">
        <td colspan="12">No records to display</td>
      </tr>
      <ng-container *ngIf="showAddForm">
        <tr>
          <td colspan="3">
            <div class="form-subgroup">
              <div class="row row2">
                <div class="col-md-4 caps onepad">
                  <b>dealer</b>
                </div>
                <div class="col-md-8 onepad">
                  <input [(ngModel)]="editStore.attributes.Organization" type="text" class="small-form">
                </div>
              </div>
            </div>
          </td>
          <td>
            <div class="float-right align-self-center">
              <button class="btn btn-success btn-sm" (click)="add()" tooltip="Save" placement="bottom" delay="500">
                <i tabindex="0" class="fa fa-check"></i>
              </button>
              <button class="btn btn-default btn-sm " (click)="cancel()" tooltip="Cancel" placement="bottom"
                      delay="500">
                <i class="fa fa-times"></i>
              </button>
            </div>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>

  </div>
</div>
