
import {mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ObservableResource } from './observable-resource.interface';
import { Note } from '../../mocks/note.interface';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { copyObject, singleResourceBody } from '../../utils/json.util';
import { DEFAULT_GENERAL_NOTE } from '../../mocks/note.mocks';
import { ResourceService } from '../resource.service';

@Injectable()
export class NoteService {
  public notesSource: BehaviorSubject<Note[]> = new BehaviorSubject<Note[]>(null);
  public notes: Observable<Note[]> = this.notesSource.asObservable();
  public resourceName = 'notes';

  constructor(private resourceService: ResourceService) {}

  clearResource() {
    this.refreshResource(null);
  }

  refreshResource(resource: Note[]) {
    this.notesSource.next(resource);
  }

  getResources(customerId: number, forceRefresh: boolean = true): Observable<Note[]> {
    if (this.isResourceDefined() && !forceRefresh) {
      return this.notes;
    }
    this.clearResource();
    const params = {
      criteria: {SourceIDfk: customerId, SourceTable: 'Customers', NoteActive: 1},

      // sorting: { 'Notes.NoteSequence': 'asc'}
      sorting: { 'Notes.NoteDate': 'desc'}
    };
    this.resourceService.getResources<Note>(this.resourceName, params)
      .subscribe((notes: Note[]) => this.refreshResource(notes))
    ;
    return this.notes;
  }

  addResource(note: Note): Observable<any> {
    const body = singleResourceBody(note.attributes);
    return this.resourceService.createResource(this.resourceName, body).pipe(
      mergeMap((data: any) => {
        return this.getResources(note.attributes.SourceIDfk, true);
        // TODO: Should update after adding for NoteSequence to be updated by triggers. Doesn't work outside Navicat for some reason.
        // return this.updateResource(data);
      }))
    ;
  }

  // NOTE: Should only be setting NoteActive to 0 for removing a Note
  deleteResource(note: Note): Observable<any> {
    note.attributes.NoteActive = 0;
    return this.updateResource(note);
  }

  updateResource(note: Note): Observable<any> {
    const body = singleResourceBody(note.attributes);
    return this.resourceService.updateResource<Note>(this.resourceName, +note.id, body).pipe(
      mergeMap((data: any) => {
        return this.getResources(note.attributes.SourceIDfk, true);
      }))
    ;
  }

  isResourceDefined(): boolean {
    return this.notesSource.value !== null;
  }
}
