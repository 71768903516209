import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PentadRouterComponent} from "./pentad-router/pentad-router.component";
import {DenyPageComponent} from "./pentad-router/deny-page.component";
import {ReturnsComponent} from './help/returns/returns.component';
import {CTAEmailComponent} from './email/ctaemail/ctaemail.component';
import {InvoiceEmailComponent} from "./email/invoice-email/invoice-email.component";
import {SmartopPricingComponent} from "./smartop-pricing/smartop-pricing.component";
import {ShippingDocumentsComponent} from './shipping-documents/shipping-documents.component';
import {InvoicePageComponent} from "./invoice/components/invoice-page/invoice-page.component";
import {AuthGuardService} from "../services/auth-guard.service";
import {CustomerSearchTabComponent} from "./customer/components/customer-search-tab/customer-search-tab.component";
import {InvoiceTabComponent} from "./invoice/components/invoice-tab/invoice-tab.component";
import {CustomerTabComponent} from "./customer/components/customer-tab/customer-tab.component";
import {CustomerNotesComponent} from "./customer/components/customer-notes/customer-notes.component";
import {HistoryTabComponent} from "./history/components/history-tab/history-tab.component";
import {QuickViewTabComponent} from "./quick-view/components/quick-view-tab/quick-view-tab.component";
import {AdminPageComponent} from "./limelyte-admin/components/admin-page/admin-page.component";
import {ImpersonateComponent} from "./limelyte-admin/components/impersonate/impersonate.component";
import {BaseReportingComponent} from "./reporting/components/base-reporting/base-reporting.component";
import {LoginPageComponent} from "./user/components/login-page/login-page.component";
import {
  SmartopRoutingComponent
} from "./smartop-routing/smartop-routing.component";
import {SmartopOrdersComponent} from "./smartop-orders/smartop-orders.component";
import {BaseComponent} from "./base/base.component";
import {ReportComponent} from "./sales-contest/report/report.component";

const appRoutes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginPageComponent},
  {path: 'internal/SystemsLogin.aspx', component: LoginPageComponent},
  {
    path: 'admin', component: AdminPageComponent,
    children: [
      {
        path: 'impersonate', component: ImpersonateComponent
      }
    ]
  },
  {
    path: 'internal/Reporting/Reports.aspx',
    component: BaseReportingComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'invoice',
    component: InvoicePageComponent,
    canActivate: [AuthGuardService],
    children: [
      {path: '', redirectTo: 'customer-search', pathMatch: 'full'},
      {path: 'customer-search', component: CustomerSearchTabComponent},
      {path: 'primary', component: InvoiceTabComponent},
      {path: 'customer', component: CustomerTabComponent},
      {path: 'customer-notes', component: CustomerNotesComponent},
      {path: 'history', component: HistoryTabComponent},
      {path: 'quick-view', component: QuickViewTabComponent},
    ]
  },
  {
    path: 'invoices/:id/emails/cta',
    component: CTAEmailComponent
  },
  {
    path: 'invoices/:id/emails/invoice',
    component: InvoiceEmailComponent
  },
  {
    path: 'help/invoices/returns',
    component: ReturnsComponent
  },
  {
    path: 'sales-contest/report',
    component: ReportComponent
  },
  {
    path: 'shipping-documents',
    component: ShippingDocumentsComponent
  },
  {
    path: 'smartop-order-submission', redirectTo: 'smartop/route/0', pathMatch: 'full',
  },
  {
    path: 'smartop',
    component: BaseComponent,
    canActivate: [AuthGuardService],
    children: [
      {path: '', redirectTo: 'orders', pathMatch: 'full'},
      {
        path: 'orders', component: SmartopOrdersComponent, data: {
          tabs: [
            {name: "Smartop Orders", active: true}
          ]
        }
      },
      {
        path: 'route/:id', component: SmartopRoutingComponent, data: {
          tabs: [
            {name: "Smartop Orders", route: "orders"},
            {name: "New Smartop Order", active: true, exitTo: "orders"},
          ]
        }
      },
      {path: 'pricing', redirectTo: 'pricing/US/default', pathMatch: 'full'},
      {
        path: 'pricing/:country/:urlsource', component: SmartopPricingComponent, data: {
          tabs: [
            {name: "Smartop Pricing US", route: "pricing/US/default"},
            {name: "Smartop Pricing UK", route: "pricing/UK/default"},
            {name: "Smartop Pricing CA", route: "pricing/CA/default"},
            {name: "Smartop Pricing Bullfrog US", route: "pricing/US/bullfrog"}
          ]
        }
      },
    ]
  },
  {
    path: "DenyPage",
    component: DenyPageComponent
  }, {
    path: "**",
    component: PentadRouterComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false, relativeLinkResolution: 'legacy' } // <-- debugging purposes only
 // <-- debugging purposes only
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
