import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {createOptions} from '../utils/http.util';
import {HttpClient} from '@angular/common/http';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../config/domain.interface";
import {map} from "rxjs/operators";

@Injectable()
export class InvoiceActionsService {
  private url: string;

  constructor(
    private httpClient: HttpClient,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
  ) {
    this.url = `${this.config.apiUrl}invoice-actions`;
  }

  updateTrackingNumber = (invoiceId: number, TrackingNumber: string): Observable<any> => {
    const options = createOptions();
    return this.httpClient.patch(`${this.url}/${invoiceId}/update-tracking-number`, { TrackingNumber }, options)
    ;
  }

  sendToShipping = (invoiceId: number, confirmed: boolean = false, confirmDate = false, confirmTerms = false): Observable<any> => {
    const options = createOptions({});
    return this.httpClient.patch(`${this.url}/${invoiceId}/send-to-shipping`, {'confirmed': confirmed, 'confirmDate': confirmDate, 'confirmTerms': confirmTerms}, options)
      .pipe(
        map((response: Response) => {
          return response;
        }))
      ;
  }


}
