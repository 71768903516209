<div class="card">
    <div class="card-heading normal-card-heading" data-toggle="collapse" data-target="#invShipping">
        <h3 class="caps card-title">
          <span>shipping rates</span>
          <i class="fa fa-truck fa-lg"></i>
          <a class="caret"></a>
        </h3>
        <div class="card-subheading float-right">
            <div class="text-right">

            </div>
        </div>
    </div>
    <div class="card-body collapse show fivepad" id="invShipping">
      <div class="row">
          <div class="col-md-3">
              <div class="form-group">
                  <label for="description">Description</label>
                  <input [(ngModel)]="description" id="description" type="text" class="small-form">
              </div>
          </div>
          <div class="col-md-2">
              <div class="form-group">
                  <label for="weight">Weight</label>
                  <input [(ngModel)]="invoiceWeight" id="weight" type="text" class="small-form">
              </div>
          </div>
          <div class="col-md-4">
              <div class="form-group">
                  <label for="zip">Destination Zip</label>
                  <input [(ngModel)]="zip" id="zip" type="text" class="small-form col-md-9">
              </div>
          </div>
          <div class="col-md-3">
              <div class="form-group">
                  <label>&nbsp;</label>
                  <button type="button" class="btn btn-primary btn-sm btn-block" (click)="getRates();">Get
                      Rates
                  </button>
              </div>
          </div>
      </div>

      <div *ngIf="carriers.length">
        <table class="table  table-striped text-center">
          <thead>
          <tr>
            <th>Carrier</th>
            <th>Base</th>
            <th>Fuel SC</th>
            <th>Total</th>
            <th>TT</th>
          </tr>
          </thead>
          <tbody>

          <tr *ngFor="let carrier of carriers">
            <td width="20%">
              <div class="dropdown carrier">
                <button class="btn btn-default btn-block dropdown-toggle" type="button">
                  {{carrier.name}}
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="{{carrier.links.BOLURL}}">BOL</a>
                  <a class="dropdown-item" href="{{carrier.links.AccessorialURL}}">Accessorials</a>
                  <a class="dropdown-item" href="{{carrier.links.RatesURL}}">Rates</a>
                  <a class="dropdown-item" href="{{carrier.links.TrackingURL}}">Tracking</a>
                </div>
              </div>
            </td>

            <td class="align-middle" *ngIf="carrier.hasData && !carrier.error">
              {{carrier.base}}
            </td>
            <td class="align-middle" *ngIf="carrier.hasData && !carrier.error">
              {{carrier.fuel}}
            </td>
            <td class="align-middle" *ngIf="carrier.hasData && !carrier.error">
              {{carrier.total}}
            </td>
            <td class="align-middle" *ngIf="carrier.hasData && !carrier.error">
              {{carrier.tt}}
            </td>
            <td class="align-middle" *ngIf="!carrier.hasData && loading" colspan="4">
              <div><i class="fa fa-spinner fa-spin fa-1x"></i> Loading ...</div>
            </td>
            <td class="align-middle" *ngIf="!carrier.hasData && !loading" colspan="4">
              No Data
            </td>
            <td class="align-middle" *ngIf="carrier.error" colspan="4">
              No Data Returned
            </td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>
</div>
