import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {InvoiceLineItem} from '../../../../models/invoice-line-item.interface';
import {createOptions} from '../../../../utils/http.util';
import {PentadService} from '../../../../services/pentad.service';
import {LoadingService} from '../../../../services/observables/loading.service';
import {HttpClient} from '@angular/common/http';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'smartop-items-modal',
    templateUrl: './smartop-items-modal.component.html',
    styleUrls: ['./smartop-items-modal.component.scss']
})
export class SmartopItemsModalComponent implements OnInit, OnDestroy {

    public invoiceItems: InvoiceLineItem[];
    public canPrint: boolean;
    public pentadService: PentadService;
    public reportType: string;
    public invoiceId;
    public loadingService: LoadingService;
    public httpClient: HttpClient;

    constructor(
        public bsModalRef: BsModalRef,
        @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
    ) {
    }

    async ngOnInit() {}

    ngOnDestroy(): void {}

    public async print(reportType: string) {
        let lineItemIds = this.invoiceItems
            .filter(x=>x.attributes.isChecked == true)
            .map(x => x.id)
            .join(',');
        if (!this.canPrint) {
            return; // don't do anything
        }
        const path = await this.pentadService.setPentadSecurity('print-reports?PentadSecurity');
        const url = this.config.apiUrl + path;
        const body = {
            reportType,
            invoiceId: this.invoiceId,
            lineItemIds: lineItemIds
        };
        const options = createOptions();
        const loader = this.loadingService.newLoader();
        loader.start();
        await this.httpClient.post<any>(url, body, options).toPromise();
        loader.stop();
        this.bsModalRef.hide();
    }
}
