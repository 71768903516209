<div class="card">
    <div class="card-heading" data-toggle="collapse" data-target="#invPayments">
        <h3 class="caps card-title">
          <span>payments</span>
          <i class="fa fa-dollar fa-lg"></i>
          <a class="caret"></a>
        </h3>
        <div class="card-subheading float-right">
            <div class="text-right">

            </div>
        </div>
    </div>
    <div class="card-body collapse show fivepad" id="invPayments">
        <table class="small  table-striped table">
            <thead>
            <tr>
                <th>Date</th>
                <th>Card #</th>
                <th>Exp. Date</th>
                <th>Amount</th>
                <th>Credit</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let payment of paymentApplications">
                <td style="cursor: pointer;" (click)="openPaypalManagement()">{{ payment.attributes.DateEntered|momentDate:dateFormat + ' h:mm a' }}</td>
                <td style="cursor: pointer;" (click)="openPaypalManagement()">{{ payment.relationships.payment.attributes.CreditCardNumber.slice(-4) }}</td>
                <td style="cursor: pointer;" (click)="openPaypalManagement()">{{ payment.relationships.payment.attributes.CreditCardExpirationDate | momentDate:'MM/YY' }}</td>
                <td style="cursor: pointer;" (click)="openPaypalManagement()">{{ payment.attributes.Amount | jetCurrency }}</td>
                <td>
                    <button class="btn btn-default btn-xs" (click)="handleCredit(payment)">Credit</button>
                </td>
            </tr>
            <tr *ngIf="!paymentApplications.length">
                <td colspan="5">No records to display</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
