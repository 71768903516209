import { ADDITIONAL_FIELD_TAX_RATE, ADDITIONAL_FIELD_TAX_STATUS } from './../../../../utils/constants.util';
import { TranslatePipe } from '../../../../pipes/translate.pipe';
import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { Code } from '../../../../models/code.interface';
import {
  CUSTOMER_PAYMENT_TERMS, INVOICE_TAX_STATUS, PRICE_LISTS,
  PRODUCT_LINES,
  ADDITIONAL_FIELD_VAT_REGISTRATION_NUMBER
} from '../../../../utils/constants.util';
import { Customer } from '../../../../models/customer.interface';
import { copyObject, getMultipleResourceRelation } from '../../../../utils/json.util';
import { CustomerActionsService } from '../../../../services/customer-actions.service';
import { LoadingService } from '../../../../services/observables/loading.service';
import { CustomerService } from '../../../../services/observables/customer.service';
import { Router } from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
@Component({
  selector: 'jet-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss']
})
export class CustomerInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public customer: Customer = null;
  @Input() public codes: any = null;
  public editable = false;
  public editCustomer: Customer = null;
  public productLineCodes: any[] = [];
  public paymentTermCodes: any[] = [];
  public taxStatusCodes: any[] = [];
  public priceListCodes: any[] = [];

  public priceListFieldName = 'Price List';
  public taxStatusFieldName = ADDITIONAL_FIELD_TAX_STATUS;
  public taxRateFieldName = ADDITIONAL_FIELD_TAX_RATE;
  public deliveryInstructionsFieldName = 'Delivery Instructions';
  public borderBrokerFieldName = 'Border Broker';

  public showRenameCustomerForm = false;
  public newName = '';
  public showPreviousStoreHistory = false;

  public showMergeCustomerForm = false;
  public mergeCustomerID: number;
  private ngUnsubscribe = new Subject();

  constructor(
    private customerService: CustomerService,
    private customerActionsService: CustomerActionsService,
    private loadingService: LoadingService,
    private router: Router,
    private jetTranslatePipe: TranslatePipe,
  ) { }
  ngOnInit() {}

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.codes) {
      this.paymentTermCodes = this.codes[CUSTOMER_PAYMENT_TERMS];
      this.taxStatusCodes = this.codes[INVOICE_TAX_STATUS];
      this.priceListCodes = this.codes[PRICE_LISTS].sort((a, b) => {
        if (a.CodeDesc < b.CodeDesc) {
          return -1;
        } else if (a.CodeDesc > b.CodeDesc) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

  public toggleEditableOnClick(event) {
    event.stopPropagation();
    this.toggleEditable();
    if (this.editable) {
      event.target.innerHTML = 'Cancel';
      event.target.classList.remove('btn-success');
      this.editCustomer = copyObject(this.customer);
      this.setupProductLineCodes();
    } else {
      event.target.innerHTML = 'Edit';
      event.target.classList.add('btn-success');
      this.editCustomer = null;
    }
  }

  public toggleShowRenameCustomerForm() {
    this.showRenameCustomerForm = !this.showRenameCustomerForm;
    if (this.showRenameCustomerForm) {
      this.showMergeCustomerForm = false;
    }
    this.newName = '';
    this.showPreviousStoreHistory = false;
  }

  public renameCustomer() {
    if (!this.showRenameCustomerForm || !this.newName) {
      return;
    }
    this.loadingService.refreshIsLoading(true);
    this.customerActionsService.changeCustomerName(this.customer.attributes.CustomerID, this.newName, this.showPreviousStoreHistory)
      .pipe(
          takeUntil(this.ngUnsubscribe)
      ).subscribe((body) => {
        this.resetAfterAction();
        this.router.navigate(['/invoice/customer'], { queryParams: { 'customer_id': body.CustomerID } });
        }, this.resetAfterAction
      );
  }

  public resetAfterAction = () => {
    // 1. Stop loading animation
    this.loadingService.refreshIsLoading(false);
    // 2. Reset all toggleable sub form fields
    this.newName = '';
    this.showPreviousStoreHistory = false;
    this.showRenameCustomerForm = false;
    this.showMergeCustomerForm = false;
    this.mergeCustomerID = undefined;
    // 3. Toggle section to be non-editable
    this.toggleEditable();
  }

  public cancelRenameCustomer() {
    this.toggleShowRenameCustomerForm();
  }

  public toggleShowMergeCustomerForm() {
    this.showMergeCustomerForm = !this.showMergeCustomerForm;
    if (this.showMergeCustomerForm) {
      this.showRenameCustomerForm = false;
    }
    this.mergeCustomerID = undefined;
  }

  public mergeCustomers() {
    if (!this.showMergeCustomerForm) {
      return;
    }
    this.loadingService.refreshIsLoading(true);
    this.customerActionsService.mergeCustomers(this.customer.attributes.CustomerID, this.mergeCustomerID)
      .pipe(
          takeUntil(this.ngUnsubscribe)
      ).subscribe(() => {
          this.customerService.getResource(this.customer.attributes.CustomerID, true)
            .subscribe(this.resetAfterAction, this.resetAfterAction)
          ;
        }, this.resetAfterAction
      )
    ;
  }

  public cancelMergeCustomer() {
    this.toggleShowMergeCustomerForm();
  }

  public assignPriceListToChildren() {
    this.loadingService.refreshIsLoading(true);
    this.customerActionsService.assignPriceListToChildren(this.customer.attributes.CustomerID)
      .pipe(
          takeUntil(this.ngUnsubscribe)
      ).subscribe(
        (data) => this.loadingService.refreshIsLoading(false),
        (error) => this.loadingService.refreshIsLoading(false)
      )
    ;
  }

  public fieldName(fieldName: string) {
    if (fieldName === ADDITIONAL_FIELD_VAT_REGISTRATION_NUMBER) {
      return 'VAT no';
    } else if (fieldName === 'Tax Status') {
      return this.jetTranslatePipe.transform('Tax') + ' Status';
    } else if (fieldName === ADDITIONAL_FIELD_TAX_RATE) {
      return this.jetTranslatePipe.transform('Tax') + ' Rate';
    }
    return fieldName;
  }

  public actualValue(fieldName: string, fieldValue: string) {
    if (fieldName === 'Price List') {
      const codeAttrs = this.priceListCodes.find((code) => code.CodeID === +fieldValue);
      return codeAttrs ? codeAttrs.CodeDesc : '';
    } else if ( fieldName === this.taxStatusFieldName) {
      const codeAttrs = this.taxStatusCodes.find((code) => code.CodeID === +fieldValue);
      return codeAttrs ? codeAttrs.CodeDesc : '';
    } else {
      return fieldValue;
    }
  }

  public stringified(value): string {
    return `${value}`;
  }

  private toggleEditable() {
    this.editable = !this.editable;
  }

  private setupProductLineCodes() {
    this.productLineCodes = [];
    const productLines: Code[] = getMultipleResourceRelation<Code>(this.editCustomer.relationships, 'productLines');
    const allProductLineCodes: any = this.codes[PRODUCT_LINES];
    for (const productLineCode of allProductLineCodes) {
      const attrs: any = { ...productLineCode };
      const activeProductLine = productLines.find((code: Code) => {
        return code.attributes.CodeID === attrs.CodeID;
      });
      if (activeProductLine) {
        attrs.ProductLineActive = true;
        attrs.CustomerToCodeID = activeProductLine.attributes.CustomerToCodeID;
      }
      this.productLineCodes.push(attrs);
    }
  }

  public updateDealerName() {
    this.customer.attributes.Organization = this.editCustomer.attributes.Organization;
    this.customerService.updateCustomer(this.customer, this.productLineCodes).toPromise().then();
  }
}
