<div class="card">
    <div class="card-heading" data-toggle="collapse" data-target="#histProductHistory">
        <h3 class="caps card-title">
          <span>product history</span>
          <i class="fa fa-lg fa-archive"></i><br>
          <a class="caret"></a>
        </h3>
        <div class="card-subheading float-right">
            <div class="text-right">

            </div>
        </div>
    </div>
    <div class="card-body collapse show fivepad" id="histProductHistory">

        <div *ngIf="isLoading();else content_table">
            <div class="row justify-content-md-center">
                <div class="col-sm-3"><i class="fa fa-spinner fa-spin fa-1x"></i> Loading ...</div>
            </div>
        </div>

        <ng-template #content_table>
            <table class="small table  table-striped table-hover table-borderless" style="margin-bottom: 0;">
                <thead>
                <tr>
                    <th>NUMBER</th>
                    <th>DESC.</th>
                    <th>QTY</th>
                    <th>VALUE</th>
                    <th>LAST INV.</th>
                    <th>LAST ORDER</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let product of products">
                    <td style="padding:5px !important;">{{ product.InventoryNumber }}</td>
                    <td style="padding:5px !important;">{{ product.Description }}</td>
                    <td style="padding:5px !important;">{{ product.Qty }}</td>
                    <td style="padding:5px !important;">{{ product.Value | jetCurrency}}</td>
                    <td style="padding:5px !important;">
                        <ng-template #popTemplate><div [innerHtml]="invoiceNumbers"></div></ng-template>
                        <a target="_blank" (mouseover)="getInvoiceNumbers(product)" (mouseleave)="cancelRequest(product)" [popover]="popTemplate" placement="bottom" [isOpen]="product.popUpOpen" triggers="" [routerLink]="['/invoice/primary']" [queryParams]="{ invoice_id: product.LastInvoiceID , customer_id: product.CustomerIDfk }" queryParamsHandling="merge">
                            {{ product.LastInvoiceNumber }}
                        </a>
                    </td>
                    <td style="padding:5px !important;">{{ product.DateShipped | momentDate:dateFormat }}</td>
                </tr>
                <tr *ngIf="!products.length">
                    <td colspan="12">No records to display</td>
                </tr>
                </tbody>
            </table>
        </ng-template>
    </div>
</div>
