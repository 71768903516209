import {Observable, of as observableOf} from 'rxjs';
import {catchError, mergeMap, take} from 'rxjs/operators';
import {Component, Input, ViewChild} from '@angular/core';
import {LineItem} from '../../common/line-item';
import {createOptions} from '../../../../utils/http.util';
import {getSingleResourceRelation} from '../../../../utils/json.util';
import {InventoryItem} from '../../../../models/inventory-item.interface';
import {TypeaheadMatch} from "ngx-bootstrap/typeahead";

@Component({
  selector: 'jet-invoice-inventory-item',
  templateUrl: './invoice-inventory-item.component.html',
  styleUrls: ['./invoice-inventory-item.component.scss']
})
export class InvoiceInventoryItemComponent extends LineItem {
  public asyncSelected: string;
  public typeaheadLoading: boolean;
  public typeaheadNoResults: boolean;
  public dataSource: Observable<any>;
  public url: string;
  public justAdded: boolean = false;

  @Input() lineItemType: number;

  @ViewChild("firstInput") firstInput;

  customInit() {
    this.url = `${this.config.apiUrl}query/inventory-item-text-search/`;
    this.dataSource = Observable.create((observer: any) => {
      observer.next(this.asyncSelected);
    })
      .pipe(
        mergeMap((text: string) => this.inventoryItemTextSearch(text)),
        catchError(error => observableOf([]))
      )
    ;
  }

  private inventoryItemTextSearch(text: string): Observable<any> {
    if (text === '' || text === undefined || text === null) {
      return observableOf([]);
    }
    const options = createOptions();
    return this.httpClient.get<any>(`${this.url}${encodeURIComponent(text)}`, options).pipe(
      take(1))
    ;
  }

  protected beforeEditItem() {
    const inventoryItem: InventoryItem = getSingleResourceRelation(this.editItem.relationships, 'inventoryItem');
    if (!inventoryItem) {
      return;
    }
    let selectedText: string = `${inventoryItem.attributes.Description} - ${inventoryItem.attributes.InventoryNumber}`;
    if (inventoryItem.attributes.ExternalName) {
      selectedText = `${inventoryItem.attributes.ExternalName} - ${inventoryItem.attributes.InventoryNumber}`;
    }
    this.asyncSelected = selectedText;
  }

  protected  customEditItemSetup() {
    this.asyncSelected = '';
    this.editItem.attributes.Quantity = 0;
    this.editItem.attributes.OriginalPriceEach = 0;
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  typeaheadOnSelect(match: TypeaheadMatch): void {
    this.editItem.attributes.InventoryItemIDfk = match.item.InventoryItemID;
  }

  protected customIsValid(): string[] {
    const errors: string[] = super.customIsValid();
    if (this.editItem.attributes.InventoryItemIDfk === 0) {
      errors.push('Please select an Inventory Item');
    }
    return errors;
  }

  adjustScreen() {
    if(this.justAdded) {
      this.justAdded = false;

      let distance = this.getElementDistanceFromBottom(this.firstInput.nativeElement);
      if(distance < 300){
        window.scrollBy(0, 300 - distance);
      }
    }
  }

  getElementDistanceFromBottom(element) {
    let domRect = element.getBoundingClientRect();
    return window.innerHeight - domRect.bottom;
  }

  added() {
    this.justAdded = true;
  }
}
