
<div class="row">
  <div class="col-sm-12">
    <ul class="nav nav-tabs">
      <li class="nav-item"><a class="nav-link" routerLink="impersonate" routerLinkActive="active" queryParamsHandling="preserve">Impersonate</a></li>
    </ul>
  </div>
</div>
<br>

<div class="tab-content">
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>
