<div class="card">
  <div class="card-heading" data-toggle="collapse" data-target="#quickviewSmartopItems">
    <h3 class="caps card-title">
      <span>smartop items</span>
      <i class="fa fa-list fa-lg"></i>
      <a class="caret"></a>
    </h3>
    <div class="card-subheading float-right">
      <div class="text-right">

      </div>
    </div>
  </div>
  <div class="card-body collapse show fivepad" id="quickviewSmartopItems">
    <table class="small table  table-striped table-hover table-borderless" style="margin-bottom: 0;">
      <thead>
      <tr>
        <th>Description</th>
        <th>Avail</th>
        <th style="width: 200px;">Note</th>
        <th>International</th>
        <th>Wt.</th>
        <th>Qty</th>
        <th>Price Ea.</th>
        <th>Subtotal</th>
        <th>{{ 'Taxed'|jetTranslate }}</th>
        <th>Total</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of items">
        <td style="padding:5px !important;">
          {{ item.attributes.InventoryItemNumber }}<br>
          {{ item.attributes.Description }}<br>
          <span *ngIf="item.attributes.IsWarranty">War. {{ item.attributes.WarrantyDepartmentAtFaultText }} - {{ item.attributes.WarrantyReasonText }}</span>
          <br>
          <b>MEASUREMENTS</b><br>
          <table style="min-width:300px;">
            <tr>
              <th>A</th>
              <td>{{ item.attributes.CustomLength.toFixed(2) }}</td>
              <th>Acrylic ht.</th>
              <td>{{ item.attributes.SkirtingText }}</td>
            </tr>
            <tr>
              <th>B</th>
              <td>{{ item.attributes.CustomWidth.toFixed(2) }}</td>
              <th>Hinge Len.</th>
              <td>{{ item.attributes.HingeLength.toFixed(2) }}</td>
            </tr>
            <tr>
              <th>Radius</th>
              <td>{{ item.attributes.RadiusText }}</td>
              <th>Mount</th>
              <td>{{ item.attributes.MountText }}</td>
            </tr>
          </table>
        </td>
        <td style="padding:5px !important;">{{item.attributes.InventoryItemAvailableQuantity}}</td>
        <td style="padding:5px !important;">
          <b>Note: </b><span>{{ item.attributes.Note }}</span><br>
          <b>CIN: </b><span>{{ item.attributes.CIN }}</span><br>
          <b>Production Notes</b>:
          <div [innerHtml]="item.attributes.ProductionNotes | safeHtml ">
          </div>
          <b>Consumer: </b><span>{{ item.attributes.ConsumerFirstName }} {{ item.attributes.ConsumerLastName }}</span>
        </td>
        <td style="padding:5px !important;"><b>Commodity Code: {{ item.attributes.CommodityCode }}</b><br><b>Customs
          Value:</b> {{ item.attributes.CustomsValue | jetCurrency }}
        </td>
        <td style="padding:5px !important;">{{ item.attributes.LineItemWeightText|jetTranslate }}<br>{{ item.attributes.LineItemWeightEachText|jetTranslate }} Ea.</td>
        <td style="padding:5px !important;">{{ item.attributes.Quantity }}</td>
        <td style="padding:5px !important;">{{ item.attributes.PriceEach | jetCurrency }}</td>
        <td style="padding:5px !important;">{{ (item.attributes.Quantity * item.attributes.PriceEach) | jetCurrency }}</td>
        <td style="padding:5px !important;">{{ item.attributes.TaxPercentage }}<br>{{ item.attributes.TaxAmount | jetCurrency}}</td>
        <td style="padding:5px !important;"><b>{{ item.attributes.TotalValue | jetCurrency }}</b></td>
      </tr>
      <tr *ngIf="!items.length">
        <td colspan="12">No records to display</td>
      </tr>
      </tbody>
    </table>

  </div>
</div>
