import {Component, Inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { ComputedFreightInfo } from '../../../../models/computed-freight-info.interface';
import {environment} from "../../../../environments/environment";
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";

@Component({
  selector: 'jet-quick-view-freight',
  templateUrl: './quick-view-freight.component.html',
  styleUrls: ['./quick-view-freight.component.scss']
})
export class QuickViewFreightComponent implements OnChanges, OnInit {
  @Input() company = '';
  @Input() shipVia = '';
  @Input() freightCost = 0;
  @Input() handlingCharge = 0;
  @Input() additionalCharge = 0;
  @Input() shippingCredit = 0;
  @Input() dateShipped = 0;
  @Input() trackingNumber = '';
  @Input() promoCode = '';
  @Input() promoDiscountTotal = 0;
  @Input() numberOfPallets = 0;
  @Input() computedFreightInfo: ComputedFreightInfo = null;
  @Input() isSmartopInvoice: boolean;
  public isTotalNegative: boolean = false;
  public dateFormat: string;


  constructor(@Inject(DOMAIN_CONFIG) private config: DomainConfigInterface) {
    this.dateFormat = this.config.dateFormat;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['computedFreightInfo']) {
      this.updateTotal();
    }
  }

  ngOnInit() {
    this.updateTotal();
  }

  updateTotal() {
    if (!this.computedFreightInfo) { return; }
    const total = this.computedFreightInfo.total;
    this.isTotalNegative = total && total < 0;
  }

}
