import { Contact } from '../models/contact.interface';
import { CONTACT_TYPE_PHONE, CONTACT_TYPE_FAX, CONTACT_TYPE_EMAIL, CONTACT_TYPE_URL } from '../utils/constants.util';
import {copyObject} from "../utils/json.util";

const baseAttributes = {
  ContactID: 0,
  SourceIDfk: 0,
  SourceType: -1,
  Description: '',
  ContactValue: '',
  ContactSequence: 0,
  ContactActive: 1,
};

let attrs: any = {
  ContactType: CONTACT_TYPE_PHONE,
  ...baseAttributes
};
export const DEFAULT_PHONE_CONTACT: Contact = {
  id: '0',
  type: 'Contact',
  attributes: attrs
};
export const WORK_PHONE_CONTACT: Contact = copyObject(DEFAULT_PHONE_CONTACT);
WORK_PHONE_CONTACT.attributes.Description = 'Phone';

attrs = {
  ContactType: CONTACT_TYPE_FAX,
  ...baseAttributes
};
export const DEFAULT_FAX_CONTACT: Contact = {
  id: '0',
  type: 'Contact',
  attributes: attrs
};
export const WORK_FAX_CONTACT: Contact = copyObject(DEFAULT_FAX_CONTACT);
WORK_FAX_CONTACT.attributes.Description = 'Fax';

attrs = {
  ContactType: CONTACT_TYPE_EMAIL,
  ...baseAttributes
};
export const DEFAULT_EMAIL_CONTACT: Contact = {
  id: '0',
  type: 'Contact',
  attributes: attrs
};
export const WORK_EMAIL_CONTACT: Contact = copyObject(DEFAULT_EMAIL_CONTACT);
WORK_EMAIL_CONTACT.attributes.Description = 'Email';

attrs = {
  ContactType: CONTACT_TYPE_URL,
  ...baseAttributes
};
export const DEFAULT_URL_CONTACT: Contact = {
  id: '0',
  type: 'Contact',
  attributes: attrs
};
export const WORK_URL_CONTACT: Contact = copyObject(DEFAULT_URL_CONTACT);
WORK_URL_CONTACT.attributes.Description = 'Website';

export const ALL_DEFAULT_CONTACTS: Contact[] = [
  DEFAULT_EMAIL_CONTACT,
  DEFAULT_FAX_CONTACT,
  DEFAULT_PHONE_CONTACT,
  DEFAULT_URL_CONTACT,
  WORK_PHONE_CONTACT,
  WORK_FAX_CONTACT,
  WORK_EMAIL_CONTACT,
  WORK_URL_CONTACT,
];

