<div *ngIf="smartopData">
    <div *ngFor="let products of [smartopData.products, smartopData.jacuzzi400products]; let i = index">
        <div *ngFor="let product of products">
            <table class="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Size Min</th>
                    <th>Size Max</th>
                    <th>OverSize A</th>
                    <th>OverSize B</th>
                    <th>OverSize Price</th>
                </tr>
                </thead>
                <tbody>
                <tr class="parent-row">
                    <td class="name"><h2><span *ngIf="i == 1">jacuzzi400 - </span> {{ product.name }}</h2></td>
                    <td>{{ smartopData.currency }}{{ product.price }}</td>
                    <td>{{ product.allowable_size.min }}</td>
                    <td>{{ product.allowable_size.max }}</td>
                    <td>{{ product.oversize.a }}</td>
                    <td>{{ product.oversize.b }}</td>
                    <td>{{ smartopData.currency }}{{ product.oversize.price }}</td>
                </tr>
                </tbody>
            </table>
            <fieldset>
                <legend>Decos</legend>
                <div class="decos">
                    <div class="deco" *ngFor="let deco of product.decos">
                        <img src="https://smartopdealer.com/{{ deco.image }}" height="100px" width="100px" alt="">
                        {{ deco.sku }}<br>{{ deco.name }}

                        <div class="trim">
                            <fieldset *ngFor="let trim of deco.trims">
                                <legend>Trims</legend>
                                • {{ trim.id }} {{ trim.name }} {{ smartopData.currency }}{{ trim.price }}
                            </fieldset>
                        </div>
                    </div>
                </div>
                <fieldset *ngFor="let option of product.options">
                    <legend>{{ option.name }}</legend>
                    <div *ngFor="let value of option.values">• {{ value.id }} {{ value.name }}
                        - {{ smartopData.currency }}{{ value.price }}</div>
                </fieldset>
                <fieldset>
                    <legend>Completion Date</legend>
                    <div *ngFor="let option of product.completion_date">• {{ option.value }}
                        - {{ smartopData.currency }}{{ option.price }}</div>
                </fieldset>
            </fieldset>
        </div>
    </div>
</div>
