import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[highlightOnFocus]'
})
export class HighlightOnFocusDirective {

  private element: ElementRef;

  constructor(element: ElementRef) {
    this.element = element;
  }

  @HostListener('focus') onFocus() {
    this.element.nativeElement.select();
  }
}
