<div class="modal-header">
    <h4 class="modal-title pull-left">Invoice Details Notes</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngFor="let item of invoiceItems" class="form-check">
        <input class="form-check-input" type="checkbox"
               [checked]="item.attributes.isChecked"
               (change)="item.attributes.isChecked = !item.attributes.isChecked">
        <label>{{ item.attributes.Description}}</label>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-xs btn-danger" (click)="print(reportType)">Print</button>
    <button type="button" class="btn btn-xs btn-danger" (click)="bsModalRef.hide()">Close</button>
</div>
