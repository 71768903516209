import {catchError, delay, filter, switchMap, takeUntil} from 'rxjs/operators';
import {Component, ErrorHandler, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';

import {
  CUSTOMER_TYPE_CUSTOMER,
  DEALER_TYPES_LC,
  INVOICE_TYPE_SMARTOP,
  INVOICE_TYPE_STANDARD,
} from '../../../../utils/constants.util';
import {InvoiceService} from '../../../../services/observables/invoice.service';
import {CustomerSearchService} from '../../../../services/observables/customer-search.service';
import {Invoice} from '../../../../models/invoice.interface';
import {Customer} from '../../../../models/customer.interface';
import {LoadingService} from '../../../../services/observables/loading.service';
import {CustomerService} from '../../../../services/observables/customer.service';
import {CodeService} from '../../../../services/observables/code.service';
import {ResourceService} from '../../../../services/resource.service';
import {CustomerActionsService} from '../../../../services/customer-actions.service';
import {WarningService} from '../../../../services/warning.service';
import {getMultipleResourceRelation} from '../../../../utils/json.util';
import {IndexService} from '../../../../services/observables/index.service';
import {Subject} from 'rxjs';
import {Observable, of as observableOf} from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'jet-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.scss']
})
export class CustomerSearchComponent implements OnInit, OnDestroy {

  @ViewChild('customerNameTypeahead') customerNameTypeahead;

  public dealerType = 'Dealer';
  public customerId = 0;
  public selectedCustomer: Customer;
  public affiliates: string = '';
  public searching: boolean;
  public spaLineCodes: any[] = [];
  public selectedSpaLine: any = null;
  public performedSearch = false;
  private ngUnsubscribe = new Subject();

  public customerFullName: string;
  public dataSource: Observable<Customer[]>;
  public typeaheadLoading: boolean;
  public typeaheadNoResults: boolean;

  public searchContext = {
    value: '',
    type: '',
    sorting: {},
    pageNum: 1,
    isValid: function () {

      if (typeof this.value === 'number') {
        return true;
      }

      if (this.type !== 'name') {
        return typeof this.value === 'string' && this.value.trim().length >= 0;
      }

      return typeof this.value.term === 'string' && this.value.term.trim().length >= 0;
    },
    getParams: function () {
      const pageNum = this.pageNum;
      const sorting = this.sorting || {column: 'FullName', dir: 'ASC'};

      const params = {
        criteria: {},
        sorting: {},
        page: pageNum
      };

      params.sorting[sorting.column] = sorting.dir;
      params.criteria[this.type] = this.value;

      return params;
    }
  };


  constructor(
    private loadingService: LoadingService,
    private customerSearchService: CustomerSearchService,
    private customerActionsService: CustomerActionsService,
    private warningService: WarningService,
    private invoiceService: InvoiceService,
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    private codeService: CodeService,
    private resourceService: ResourceService,
    private bsModalService: BsModalService,
    private indexService: IndexService,
    private errorHandler: ErrorHandler,
  ) {
  }

  ngOnInit() {
    this.customerService.customer.pipe(
        filter(data => data !== null),
        takeUntil(this.ngUnsubscribe)
    ).subscribe((customer: Customer) => {
        this.selectedCustomer = customer;
        const individuals = getMultipleResourceRelation(this.selectedCustomer.relationships, 'individuals');
        this.affiliates = individuals.map((individual: Customer) => individual.attributes.FullName).join(', ');
        this.indexService.NamesAreDone();
      })
    ;

    this.codeService.loadCodesByType([DEALER_TYPES_LC]).pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((codes) => {
      if (!codes) {
        return;
      }
      this.spaLineCodes = codes[DEALER_TYPES_LC];
      this.selectedSpaLine = this.spaLineCodes[0];
    });

    // NOTE: Create Invoice Actions predicated upon Customer being selected
    this.route.queryParamMap.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((queryParams: ParamMap) => {

      const customerIdParam = queryParams.get('customer_id');
      if (queryParams !== null) {
        this.customerId = +customerIdParam;
      }

      const searchParam: string = queryParams.get('search');
      if (searchParam) {

        const searchParts = searchParam.split(':::');
        const type: string = searchParts[0];
        const value: string = JSON.parse(searchParts[1]);
        const sortParam: string = queryParams.get('sorting');
        const page: string = queryParams.get('page') ? queryParams.get('page') : '1';

        this.searchContext.value = value;
        this.searchContext.type = type;
        this.searchContext.sorting = JSON.parse(sortParam);
        this.searchContext.pageNum = parseInt(page);

        if (!type) {
          return;
        }

        if (type === 'invoice_number' || type === 'cin') {
          this.searchForInvoiceByField(this.searchContext);
          return;
        }

        this.searchForTerm(this.searchContext);
      }
    });

    this.customerSearchService.nextPage.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((pageNum: number) => {

      this.searchContext.pageNum = pageNum;

      this.searchForTerm(this.searchContext);
    });

    this.dataSource = Observable.create((observer: any) => observer.next(this.customerFullName))
      .pipe(
        switchMap((text: string) => {
          return this.customerSearch(text);
        }),
        catchError(error => observableOf([]))
      );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public search(termType: any, term: any) {

    //I want the popup to always disappear, I think it looks cleaner.
    //Sometimes the popup shows up after the search, calling "hide()" does not work.
    //This is the best solution I came up with.
    this.customerNameTypeahead.ngOnDestroy();
    this.customerNameTypeahead.ngOnInit();

    const termValue = JSON.stringify(term);

    const url: string = this.router.createUrlTree(['invoice/customer-search'], {
      queryParams: {search: `${termType}:::${termValue}`}
    }).toString();

    // router.url will contain query parameters which is good.
    // if we are on an empty customer search page don't open a new tab.
    if (this.router.url === '/invoice/customer-search') {
      this.router.navigateByUrl(url);

      return;
    }

    window.open(url, '_blank');
  }

  public searchForTerm(context: any) {

    if (this.searching) {
      return;
    }

    this.searching = true;

    if (!context.isValid()) {
      return;
    }
    delay(0);
    const searchLoader = this.loadingService.newLoader();
    searchLoader.start();
    this.customerSearchService.getResources(context.getParams()).then(() => {
      searchLoader.stop();
      this.searching = false;
    }).catch(() => {

      searchLoader.stop();
      this.searching = false;
    });
  }

  public async searchForInvoiceByField(searchContext: any) {
    if (this.searching) {
      return;
    }

    this.searching = true;
    this.customerSearchService.loading = true;

    const searchLoader = this.loadingService.newLoader();
    searchLoader.start();
    try {
      const results: Invoice[] = await this.resourceService.getResources<Invoice>('invoices', {criteria: {[searchContext.type]: searchContext.value}}).toPromise();
      searchLoader.stop();
      this.searching = false;
      this.customerSearchService.loading = false;

      // no invoices or more than one
      if (results.length === 0 || results.length > 1) {
        this.searchForTerm(this.searchContext);
        return;
      }

      const invoice: Invoice = results[0];

      this.router.navigate(['invoice/primary'], {
        queryParams: {
          invoice_id: invoice.attributes.InvoiceID,
          customer_id: invoice.attributes.Customers_BillToCustomerIDfk,
        }
      });
    } catch (error) {
      this.errorHandler.handleError(error);
      searchLoader.stop();
      this.searching = false;
      this.customerSearchService.loading = false;
    }
  }

  public newCustomer() {
    window.open('invoice/customer');
  }

  public async newInvoice() {
    /* Checking Customer Type */
    let customerId = this.customerId;

    // Get customer type and check if is a type of -1 (contact)
    let customerType = this.selectedCustomer.attributes.CustomerType;
    if(customerType == -1) {
      // Try to get parent, reassign customerId if there is
      let parentDealers = this.selectedCustomer.relationships.parentDealers;
      if(parentDealers.length > 0) {
        customerId = parentDealers[0].attributes.CustomerID;
      }
    }

    /* Creating New Invoice */
    this.loadingService.refreshIsLoading(true);
    const canCreateInvoice = await this.canCreateInvoice();

    if (!canCreateInvoice) {
      return;
    }

    this.invoiceService.createInvoice(customerId, INVOICE_TYPE_STANDARD)
      .pipe(
          takeUntil(this.ngUnsubscribe)
      ).subscribe(this.gotoInvoice, this.onError)
    ;
  }

  public async newSmartopInvoice() {
    /* Checking Customer Type */
    let customerId = this.customerId;

    // Get customer type and check if is a type of -1 (contact)
    let customerType = this.selectedCustomer.attributes.CustomerType;
    if(customerType == -1) {
      // Try to get parent, reassign customerId if there is
      let parentDealers = this.selectedCustomer.relationships.parentDealers;
      if(parentDealers.length > 0) {
        customerId = parentDealers[0].attributes.CustomerID;
      }
    }

    /* Creating new invoice */
    this.loadingService.refreshIsLoading(true);

    const canCreateInvoice = await this.canCreateInvoice();
    if (!canCreateInvoice) {
      return;
    }

    this.invoiceService.createInvoice(customerId, INVOICE_TYPE_SMARTOP)
      .pipe(
          takeUntil(this.ngUnsubscribe)
      ).subscribe(this.gotoInvoice, this.onError)
    ;
  }

  private async canCreateInvoice() {

    const result = await this.customerActionsService.pendingInvoiceNumber(this.customerId).toPromise();

    if (result['InvoiceNumber'] && this.router.url.indexOf('/invoice/history') === -1) {
      this.loadingService.refreshIsLoading(false);
      const invoiceNumber: string = result['InvoiceNumber'];
      this.warningService.openWarningModal([`There are one or more current invoices (${invoiceNumber}) for this Customer. Redirecting you to the history page.`]);
      this.router.navigate(['invoice/history'], { queryParamsHandling: 'preserve' });
      return false;
    }

    return true;
  }

  private gotoInvoice = (invoice: Invoice) => {
    this.loadingService.refreshIsLoading(false);

    const url: string = this.router.createUrlTree(['invoice/primary'], {
      queryParams: {invoice_id: invoice.id, customer_id: this.customerId},
      queryParamsHandling: 'merge'
    }).toString();

    if (this.router.url.indexOf('invoice_id=') === -1) {
      this.invoiceService.refreshInvoice(invoice);
      this.router.navigateByUrl(url);
    } else {
      window.open(url, '_blank');
    }
  };

  private onError = (errorMessage) => {
    this.loadingService.refreshIsLoading(false);
  };

  /** Methods for auto-complete suggestions **/

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  typeaheadOnSelect(match: TypeaheadMatch): void {
    this.search('name', {'term': match.value, 'dealer_type': this.dealerType } )
  }

  private customerSearch(text): Observable<Customer[]> {
    if (text === '' || text === undefined || text === null) {
      return observableOf([]);
    }

    const params = {
      criteria: {
        fullName: text,
        customerType: CUSTOMER_TYPE_CUSTOMER,
        customerActive: 1
      },
      sorting: {'Customers.FullName': 'asc'}
    };

    return this.resourceService.getResources<Customer>('customers', params);
  }

  /** End of auto-complete Methods **/
}
