<div class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

    <table *ngIf="history" class="table">
        <thead>
            <tr>
                <th>User</th>
                <th>Action</th>
                <th>Field</th>
                <th>Old Value</th>
                <th>New Value</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of history.items">
                <td>{{ item.attributes.messageArray['source'] }}</td>
                <td>{{ item.attributes.messageArray['verb'] }}</td>
                <td>{{ item.attributes.messageArray['key'] }}</td>
                <td>{{ item.attributes.messageArray['old_value'] }}</td>
                <td>{{ item.attributes.messageArray['new_value'] }}</td>
            </tr>
        </tbody>
    </table>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Ok</button>
</div>
