<div class="modal-header">
  <h4 class="modal-title pull-left">Send To Shipping</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p *ngIf="!invoice"><i class="fa fa-spinner fa-spin fa-1x"></i> Saving invoice...</p>
  <p #modalMessage *ngIf="invoice"> System Ready. Click Send To Shipping to confirm.</p>
</div>
<div *ngIf="invoice" class="modal-footer">
  <ng-container *ngIf="this.confirmDate || this.confirmTerms">
    <button type="button" class="btn btn-xs btn-danger" (click)="bsModalRef.hide()">Cancel</button>
    <button type="button" class="btn btn-xs btn-warning" (click)="sendToShipping(true)">Confirm</button>
  </ng-container>
  <ng-container *ngIf="!this.confirmDate && !this.confirmTerms">
    <button type="button" class="btn btn-xs btn-danger" (click)="bsModalRef.hide()">Cancel</button>
    <button type="button" class="btn btn-xs btn-primary" (click)="sendToShipping()">Send To Shipping</button>
  </ng-container>
</div>
