import {ADDITIONAL_FIELD_VAT_REGISTRATION_NUMBER} from './../../../../utils/constants.util';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Invoice} from '../../../../models/invoice.interface';
import {QuickViewService} from '../../../../services/observables/quick-view.service';
import {ComputedFreightInfo} from '../../../../models/computed-freight-info.interface';
import {
  getComputedFreightInfo,
  getInventoryLineItems,
  getOtherLineItems,
  getSmartopLineItems
} from '../../../../utils/invoice.util';
import {InvoiceLineItem} from '../../../../models/invoice-line-item.interface';
import {getMultipleResourceRelation} from '../../../../utils/json.util';
import {AdditionalValue} from '../../../../models/additional-value.interface';
import {InvoiceService} from "../../../../services/observables/invoice.service";
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";

@Component({
  selector: '[jet-quick-view-tab]',
  templateUrl: './quick-view-tab.component.html',
  styleUrls: ['./quick-view-tab.component.scss']
})
export class QuickViewTabComponent implements OnInit, OnDestroy {

  private customerId = -1;
  public invoice: Invoice = null;
  public computedFreightInfo: ComputedFreightInfo;
  public isSmartopInvoice: boolean;
  public smartopItems: InvoiceLineItem[] = [];
  public inventoryItems: InvoiceLineItem[] = [];
  public otherItems: InvoiceLineItem[] = [];
  public vatNumber: string = undefined;
  private ngUnsubscribe = new Subject();

  constructor(
    private route: ActivatedRoute,
    private quickViewService: QuickViewService,
    private invoiceService: InvoiceService,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
  ) {}

  ngOnInit() {
    this.route.queryParams.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((params: any) => {
      const newCustomerId = +params.customer_id;
      if (this.customerId === -1 || newCustomerId === this.customerId) {
        this.customerId = newCustomerId;
        this.initialInvoice();
      } else {
        this.customerId = newCustomerId;
        this.first();
      }
      this.quickViewService.invoice.pipe(
          takeUntil(this.ngUnsubscribe)
      ).subscribe(this.onInvoiceRefresh);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private initialInvoice = () => {
    // Update current Invoice if one is not defined.
    if (!this.quickViewService.isInvoiceDefined()) {
      const invoice: Invoice = this.invoiceService.currentInvoice();
      if (!invoice) {
        this.quickViewService.firstInvoice(this.customerId).pipe(
              takeUntil(this.ngUnsubscribe)
          ).subscribe(this.refreshWithFirstInvoice);
        return;
      }
      this.quickViewService.currentInvoice(this.customerId, invoice.attributes.InvoiceID)
        .subscribe(this.refreshWithFirstInvoice)
      ;
    }
  }

  public first() {
    this.quickViewService.firstInvoice(this.customerId).pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe(this.refreshWithFirstInvoice);
  }

  public next() {
    if (!this.invoice) {
      return;
    }
    this.quickViewService.nextInvoice(this.invoice.attributes.InvoiceID, this.customerId).pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe(this.refreshWithFirstInvoice);
  }

  public previous() {
    if (!this.invoice) {
      return;
    }
    this.quickViewService.previousInvoice(this.invoice.attributes.InvoiceID, this.customerId).pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe(this.refreshWithFirstInvoice);
  }

  public last() {
    this.quickViewService.lastInvoice(this.customerId).pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe(this.refreshWithFirstInvoice);
  }

  private refreshWithFirstInvoice = (invoices: Invoice[]): void => {
    const invoice = invoices[0] ? invoices[0] : null;
    this.quickViewService.refreshInvoice(invoice);
  }

  private onInvoiceRefresh = (invoice: Invoice) => {
    if (invoice) {
      this.invoice = invoice;
      const invAddValues = getMultipleResourceRelation<AdditionalValue>(this.invoice.relationships, 'additionalValues');
      const invVatNumberValue = invAddValues.find((value: AdditionalValue) => value.attributes.FieldName === ADDITIONAL_FIELD_VAT_REGISTRATION_NUMBER);
      if (invVatNumberValue && !invVatNumberValue.attributes.Value) {
        this.vatNumber = invVatNumberValue.attributes.Value;
      }
      this.isSmartopInvoice = this.invoice.attributes.InvoiceType ? true : false;
      this.computedFreightInfo = getComputedFreightInfo(invoice, this.config.isUk);
      this.smartopItems = getSmartopLineItems(invoice);
      this.inventoryItems = getInventoryLineItems(invoice);
      this.otherItems = getOtherLineItems(invoice);
    }
  }
}
