<ng-container *ngIf="inViewMode">
  <ng-content select="[slot=drag-handle]"></ng-content>
  <div class="jet-col center-col jet-column-description">
    {{ editItem.attributes.InventoryItemNumber }} - {{ editItem.attributes.Description }}<br>
    <ng-container *ngIf="editItem.attributes.IsWarranty">War. {{ editItem.attributes.WarrantyDepartmentAtFaultText }} - {{ editItem.attributes.WarrantyReasonText }}</ng-container>
  </div>
  <div class="jet-col center-col jet-column-avail">{{ editItem.attributes.InventoryItemAvailableQuantity }}</div>
  <div class="jet-col center-col jet-column-note">
    {{ editItem.attributes.Note }}
  </div>
  <div class="jet-col jet-column-international" style="padding-top: 0.5%">
    Code: <b>{{ editItem.attributes.CommodityCode }}</b><br>
    Value: <b>{{ editItem.attributes.CustomsValue | jetCurrency }}</b>
  </div>
  <div class="jet-col center-col jet-column-wt" title="{{ editItem.attributes.LineWeightCalcText}}">
    {{ editItem.attributes.LineItemWeightText|jetTranslate:isNotSmartopInvoice }}<br>
    {{ editItem.attributes.LineItemWeightEachText|jetTranslate:isNotSmartopInvoice }} Ea.
  </div>
  <div *ngIf="editItem.calculatedQuantity === undefined || lineItemType === 4" class="jet-col center-col jet-column-qty">{{ editItem.attributes.Quantity }}</div>
  <div *ngIf="editItem.calculatedQuantity !== undefined && lineItemType !== 4" class="jet-col center-col jet-column-qty"><s class="mr-1">{{ editItem.attributes.Quantity }}</s>{{editItem.calculatedQuantity}}</div>
  <div class="jet-col center-col jet-column-price">{{ editItem.attributes.OriginalPriceEach | jetCurrency }}</div>
  <div class="jet-col center-col jet-column-subtotal">{{ subtotal(editItem) | jetCurrency }}</div>
  <div class="jet-col center-col jet-column-taxed">
    {{ editItem.attributes.TaxPercentage }}%<br>
    {{ taxAmount(editItem) | jetCurrency }}
  </div>
  <div class="jet-col center-col jet-column-total">{{ total(editItem) | jetCurrency}}</div>
  <div *ngIf="!returns && lineItemType !== 4 && isEditable" class="jet-col center-col jet-column-buttons">
      <button class="btn p-0" (click)="edit()" tooltip="Edit" delay="500">
        <i class="fa fa-pencil-alt" ></i>
      </button>
      <div class="btn-group ml-1">
        <button class="btn btn-secondary btn-sm dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"
                style="background-color:transparent;color:black;border:none;box-shadow:none;"></button>
        <div class="dropdown-menu" style="min-width: 125px;">
          <a class="dropdown-item" (click)="duplicate()" style="cursor: pointer;"><i class="fa fa-clone"></i> Clone</a>
          <a class="dropdown-item" (click)="remove()" style="cursor: pointer;"><i class="fa fa-trash-alt"></i> Delete</a>
          <a class="dropdown-item" (click)="edit()" style="cursor: pointer;"><i class="fa fa-pencil-alt"></i> Edit</a>
        </div>
    </div>
  </div>
  <div *ngIf="returns && lineItemType === 4" class="jet-col center-col jet-column-buttons">
    <button class="btn btn-sm btn-block" (click)="remove(true)" tooltip="Remove" placement="right" delay="500">
      <i tabindex="0" class="fa fa-trash"></i>
    </button>
  </div>
  <div *ngIf="returns && lineItemType !== 4" class="jet-col center-col jet-column-buttons">
    <button class="btn btn-danger btn-xs" (click)="return()">Return</button>
  </div>
</ng-container>
<ng-container *ngIf="!inViewMode">
  <div class="jet-column-reorder"></div>
    <div class="jet-col jet-column-description">
      <div class="row">
         <div class="col-md-12">
            <input  [(ngModel)]="asyncSelected"
                   [typeahead]="dataSource"
                   (typeaheadLoading)="changeTypeaheadLoading($event)"
                   (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                   (typeaheadOnSelect)="typeaheadOnSelect($event)"
                   [typeaheadOptionsLimit]="100"
                   [typeaheadScrollable]="true"
                   [typeaheadOptionsInScrollableView]="10"
                   [typeaheadMinLength]="2"
                   [typeaheadWaitMs]="300"
                   [typeaheadOptionField]="'Description'"
                   placeholder="Inventory Item search by text"
                   class="small-form"
                   (focus)="adjustScreen()"
                   #firstInput />
         </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <input tabindex="-1" type="checkbox" [(ngModel)]="editItem.attributes.IsWarranty"> War.
        </div>
        <div class="col-md-8">
          <ng-container *ngIf="editItem.attributes.IsWarranty">
            <select tabindex="-1" class="small-form" [(ngModel)]="editItem.attributes.Codes_WarrantyReasonIDfk">
              <option [ngValue]="0">Reason</option>
              <option *ngFor="let code of warrantyReasonCodes" [ngValue]="code.CodeID">
                {{ code.CodeDesc }}
              </option>
            </select>
            <select tabindex="-1" class="small-form" [(ngModel)]="editItem.attributes.Codes_WarrantyDepartmentAtFaultIDfk">
              <option [ngValue]="0">Department</option>
              <option *ngFor="let code of warrantyDepartmentChargedCodes" [ngValue]="code.CodeID">
                {{ code.CodeDesc }}
              </option>
            </select>
          </ng-container>
        </div>
      </div>
    </div>
  <div class="jet-col jet-column-avail">

  </div>

  <div class="jet-col jet-column-note">
    <input  [(ngModel)]="editItem.attributes.Note" type="text" class="small-form">
  </div>
  <div class="jet-col jet-column-international">
    <div class="row row-stacked">
      <label class="col-md-12 col-form-label">commodity code</label>
      <div class="col-md-12">
        <select tabindex="-1" class="small-form" [(ngModel)]="editItem.attributes.CommodityCode">
          <option [ngValue]="''">--Choose--</option>
          <option *ngFor="let code of commodityCodes" [ngValue]="code.CodeDesc">
            {{ code.CodeDesc }}
          </option>
        </select>
      </div>
    </div>

    <div class="row row-stacked">
      <label class="col-md-12 col-form-label">customs value</label>
      <div class="col-md-12">
        <input tabindex="-1" [(ngModel)]="editItem.attributes.CustomsValue" type="number" class="small-form">
      </div>
    </div>
  </div>
  <div class="jet-col jet-column-wt">
    <!--TODO: Cannot set weight for Inventory Item-->
  </div>
  <div class="jet-col jet-column-qty">
    <div class="row">
      <div class="col-md-12">
        <input  [(ngModel)]="editItem.attributes.Quantity" type="number" class="small-form" min="0">
      </div>
    </div>
  </div>
  <div class="jet-col jet-column-price">
    <div class="row">
      <div class="col-md-12">
        <input  [(ngModel)]="editItem.attributes.OriginalPriceEach" type="number" class="small-form">
      </div>
    </div>
  </div>
  <div class="jet-col jet-column-subtotal">
    {{ subtotal(editItem) | jetCurrency }}
  </div>
  <div class="jet-col jet-column-taxed">
    <div class="row">
      <div class="col-md-12">
        <input tabindex="-1" (change)="onTaxedChange($event)" [(ngModel)]="taxed" type="checkbox">{{ 'Taxed'|jetTranslate }}
        <div>{{ taxAmount(editItem) | jetCurrency }}</div>
      </div>
    </div>
  </div>
  <div class="jet-col jet-column-total">
    {{ total(editItem) | jetCurrency}}
  </div>
  <div class="text-right jet-col center-col vert-col jet-column-buttons">
    <button *ngIf="isNewItem" class="btn btn-sm p-0"  (click)="added();add()" tabindex="0" tooltip="Add" delay="500">
      <i class="fa fa-2x fa-plus"></i>
    </button>
    <ng-container *ngIf="!isNewItem">
      <button class="btn btn-success btn-sm btn-block" (click)="update()" tooltip="Save" placement="right" delay="500">
        <i tabindex="0" class="fa fa-check"></i>
      </button>
      <button class="btn btn-default btn-sm btn-block" (click)="cancel()" tooltip="Cancel" placement="right" delay="500">
        <i class="fa fa-times"></i>
      </button>
    </ng-container>
  </div>
</ng-container>
