import {TranslatePipe} from '../../../../pipes/translate.pipe';
import {Component, ElementRef, Input, NgZone, OnInit} from '@angular/core';
import {InvoiceLineItem} from '../../../../models/invoice-line-item.interface';
import {Invoice} from '../../../../models/invoice.interface';
import {getLineItemsByType} from '../../../../utils/invoice.util';
import {
  DEFAULT_INVENTORY_INVOICE_LINE_ITEM,
  DEFAULT_OTHER_INVOICE_LINE_ITEM,
  DEFAULT_RETURN_INVOICE_LINE_ITEM,
  DEFAULT_SMARTOP_INVOICE_LINE_ITEM,
} from '../../../../mocks/invoice-line-item.mock';
import {
  INVOICE_LINE_ITEM_TYPE_INVENTORY,
  INVOICE_LINE_ITEM_TYPE_OTHER,
  INVOICE_LINE_ITEM_TYPE_RETURN,
  INVOICE_LINE_ITEM_TYPE_SMARTTOP,
  INVOICE_STATUS_CANCELLED,
  INVOICE_STATUS_COMPLETED,
} from '../../../../utils/constants.util';
import {InvoiceService} from '../../../../services/observables/invoice.service';
import {LoadingService} from '../../../../services/observables/loading.service';
import {InvoiceActionsService} from '../../../../services/invoice-actions.service';
import {ResourceService} from '../../../../services/resource.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {WarningModalComponent} from '../../../shared/components/warning-modal/warning-modal.component';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'jet-invoice-line-items',
  templateUrl: './invoice-line-items.component.html',
  styleUrls: ['./invoice-line-items.component.scss']
})
export class InvoiceLineItemsComponent implements OnInit {

  @Input() invoice: Invoice;
  // NOTE: Should correspond to the constants for line item types
  @Input() lineItemType: number;
  public lineItems: InvoiceLineItem[] = [];
  public newItem: InvoiceLineItem = null;
  public bsModalRef: BsModalRef;
  public isEditable = false;

  public reverseInvoice: any;
  public ngUnsubscribe = new Subject();
  public returnItems: InvoiceLineItem[] = [];
  public lineSTItems: InvoiceLineItem[] = [];
  public lineOtherItems: InvoiceLineItem[] = [];

  private typesToDefaultItems: any = {
    [INVOICE_LINE_ITEM_TYPE_SMARTTOP]: DEFAULT_SMARTOP_INVOICE_LINE_ITEM,
    [INVOICE_LINE_ITEM_TYPE_INVENTORY]: DEFAULT_INVENTORY_INVOICE_LINE_ITEM,
    [INVOICE_LINE_ITEM_TYPE_OTHER]: DEFAULT_OTHER_INVOICE_LINE_ITEM,
    [INVOICE_LINE_ITEM_TYPE_RETURN]: DEFAULT_RETURN_INVOICE_LINE_ITEM,
  };

  public columns: string[] = [];
  public taxLabel = `${this.jetTranslatePipe.transform('Taxed')}`;
  private typesToColumns: any = {
    [INVOICE_LINE_ITEM_TYPE_SMARTTOP]: ['Description', 'Avail', 'Note', 'International', 'Wt.', 'Qty', 'Price Ea.', 'Subtotal', this.taxLabel],
    [INVOICE_LINE_ITEM_TYPE_INVENTORY]: ['Description', 'Avail', 'Note', 'International', 'Wt.', 'Qty', 'Price Ea.', 'Subtotal', this.taxLabel],
    [INVOICE_LINE_ITEM_TYPE_OTHER]: ['Description', 'Note', 'International', 'UOM', 'Qty', 'Price Ea.', 'Subtotal', this.taxLabel],
    [INVOICE_LINE_ITEM_TYPE_RETURN]: ['Description', 'Avail', 'Note', 'International', 'Wt.', 'Qty', 'Price Ea.', 'Subtotal', this.taxLabel],
  };

  public name: string;
  private typesToNames: any = {
    [INVOICE_LINE_ITEM_TYPE_SMARTTOP]: 'smartop',
    [INVOICE_LINE_ITEM_TYPE_INVENTORY]: 'inventory',
    [INVOICE_LINE_ITEM_TYPE_OTHER]: 'other',
    [INVOICE_LINE_ITEM_TYPE_RETURN]: 'returned',
  };

  constructor(
    public invoiceService: InvoiceService,
    public loadingService: LoadingService,
    public jetTranslatePipe: TranslatePipe,
    private invoiceActionsService: InvoiceActionsService,
    private zone: NgZone,
    private selfElement: ElementRef,
    private resourceService: ResourceService,
    private bsModalService: BsModalService,
  ) {}

  isTypeSmartop() {
    return INVOICE_LINE_ITEM_TYPE_SMARTTOP === this.lineItemType;
  }

  isTypeInventory() {
    return INVOICE_LINE_ITEM_TYPE_INVENTORY === this.lineItemType;
  }

  isTypeOther() {
    return INVOICE_LINE_ITEM_TYPE_OTHER === this.lineItemType;
  }

  isTypeReturned() {
    return INVOICE_LINE_ITEM_TYPE_RETURN === this.lineItemType;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.lineItems, event.previousIndex, event.currentIndex);
    this.resortLineItems();
  }

  private resortLineItems() {
    const swapLoader = this.loadingService.newLoader();
    swapLoader.start();
    for (let i = 0; i < this.lineItems.length; i++) {
      // pentad requires this to start at 1.
      this.lineItems[i].attributes.InvoiceLineItemSequence = i + 1;
      this.lineItems[i].attributes.PromoDiscountPercent    = this.invoice.attributes.PromoDiscountPercent;
      this.resourceService.updateResource('invoice-line-items', +this.lineItems[i].id, this.lineItems[i].attributes)
        .subscribe(() => {
          if (i === this.lineItems.length - 1) {
            swapLoader.stop();
          }
        });
    }
  }

  ngOnInit() {
    this.newItem = this.typesToDefaultItems[this.lineItemType];
    this.columns = this.typesToColumns[this.lineItemType];
    this.name = this.typesToNames[this.lineItemType];

    this.invoiceService.invoice.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((invoice: Invoice) => {
      if (!invoice) {
        return;
      }
      this.invoice = invoice;

      this.isEditable = this.invoice.attributes.Codes_StatusIDfk != INVOICE_STATUS_COMPLETED &&
        this.invoice.attributes.Codes_StatusIDfk != INVOICE_STATUS_CANCELLED;

      if(this.lineItemType === INVOICE_LINE_ITEM_TYPE_RETURN) {
        this.calculateReturns();
      } else {
        this.lineItems = getLineItemsByType(this.invoice, this.lineItemType);
        this.lineItems = this.lineItems.filter(item => item.attributes.Quantity >= 0);
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private calculateReturns() {
    this.lineItems = getLineItemsByType(this.invoice, INVOICE_LINE_ITEM_TYPE_INVENTORY);
    this.lineOtherItems = getLineItemsByType(this.invoice, INVOICE_LINE_ITEM_TYPE_OTHER);
    this.returnItems = [];

    this.lineItems.forEach(item => {
      if(item.attributes.Quantity < 0 && this.returnItems.indexOf(item) < 0) {
        this.returnItems.push(item);
      }
    });

    this.lineOtherItems.forEach(item => {
      if(item.attributes.Quantity < 0 && this.returnItems.indexOf(item) < 0) {
        this.returnItems.push(item);
      }
    });

    if(this.invoice.attributes.InvoiceType === 1) {
      this.lineSTItems = getLineItemsByType(this.invoice, INVOICE_LINE_ITEM_TYPE_SMARTTOP);
      this.lineSTItems.forEach(item => {
        if(item.attributes.Quantity < 0 && this.returnItems.indexOf(item) < 0) {
          this.returnItems.push(item);
        }
      });
    }

    this.lineItems.forEach(lItem => {
      this.returnItems.forEach(rItem => {
        if (lItem.attributes.InventoryItemIDfk === rItem.attributes.InventoryItemIDfk && lItem.attributes.Quantity > 0) {
          if (lItem.calculatedQuantity) {
            lItem.calculatedQuantity += rItem.attributes.Quantity;
          } else {
            lItem.calculatedQuantity = lItem.attributes.Quantity + rItem.attributes.Quantity;
          }
        }
      });
    });

    this.lineOtherItems.forEach(lItem => {
      this.returnItems.forEach(rItem => {
        if (lItem.attributes.InventoryItemIDfk === rItem.attributes.InventoryItemIDfk && lItem.attributes.Quantity > 0) {
          if (lItem.calculatedQuantity) {
            lItem.calculatedQuantity += rItem.attributes.Quantity;
          } else {
            lItem.calculatedQuantity = lItem.attributes.Quantity + rItem.attributes.Quantity;
          }
        }
      });
    });

    this.lineSTItems.forEach(lItem => {
      this.returnItems.forEach(rItem => {
        if (lItem.attributes.InventoryItemIDfk === rItem.attributes.InventoryItemIDfk && lItem.attributes.Quantity > 0) {
          if (lItem.calculatedQuantity) {
            lItem.calculatedQuantity += rItem.attributes.Quantity;
          } else {
            lItem.calculatedQuantity = lItem.attributes.Quantity + rItem.attributes.Quantity;
          }
        }
      });
    });
  }

  public calcPrices(event: Event, keepExisting: boolean = false) {
    event.stopPropagation();
    this.loadingService.refreshIsLoading(true);
    this.invoiceService.updateInvoice({
        fixPricingType: this.lineItemType,
        keepExistingPrices: keepExisting
      })
      .subscribe((data) => {
        this.loadingService.refreshIsLoading(false);
      }, (error) => {
        this.loadingService.refreshIsLoading(false);
        const message = [];
        for (const err of error.error.errors)  {
          message.push(err.detail);
        }
        this.loadingService.refreshIsLoading(false);
        this.bsModalRef = this.bsModalService.show(WarningModalComponent);
        this.bsModalRef.content.title = 'Error On Auto Saving the Invoice';
        this.bsModalRef.content.warnings = message;
      })
    ;
  }

  public checkColumns(column: string) {
    if (column === 'Wt.') {
      return 'wt';
    } else if (column === 'Price Ea.') {
      return 'price';
    } else if (this.isTypeOther() && column === 'Note') {
      return 'note-other';
    }

    return column;
  }

  public onAdd(item: InvoiceLineItem) {
    this.lineItems.push(item);
  }

  public onRemove(removedItem: InvoiceLineItem) {
    const index = this.lineItems.findIndex((item) => item.id === removedItem.id);
    this.lineItems.splice(index, 1);
  }

  public onClone(item: InvoiceLineItem) {
    this.lineItems.splice(item.attributes.InvoiceLineItemSequence, 0, item);
    this.resortLineItems();
  }
}
