import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { DOMAIN_CONFIG, DomainConfigInterface } from "../../config/domain.interface";
import { ActivatedRoute } from "@angular/router";
import { of, switchMap } from "rxjs";
import { take, tap } from "rxjs/operators";
import { LoadingService } from "../../services/observables/loading.service";

@Component({
  selector: 'app-smartop-pricing',
  templateUrl: './smartop-pricing.component.html',
  styleUrls: ['./smartop-pricing.component.scss']
})
export class SmartopPricingComponent implements OnInit {
  public smartopData: SmartopData;
  public apiData = new Map<string, SmartopData>();

  constructor(private httpClient: HttpClient,
              @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
              private route: ActivatedRoute,
              private loader: LoadingService,
  ) {
  }

  ngOnInit() {
    const loader = this.loader.newLoader();
    this.route.params.pipe(
      tap(() => {
        loader.start();
        this.smartopData = undefined;
      }),
      switchMap((params) => {
        const key = params['country'] + params['urlsource'];

        if (this.apiData.has(key)) {
          return of(this.apiData.get(key));
        }

        return this.httpClient
          .get(this.config.apiUrl + 'smartop-dealer/pricing/' + params['country'] + '?urlsource=' + params['urlsource'])
          .pipe(tap((data: SmartopData) => {
              this.apiData.set(key, data)
            })
          );
      }),
    ).subscribe((data: SmartopData) => {
      this.smartopData = data;
      loader.stop();
    });
  }
}

interface Trim {
  id: string;
  name: string;
  price: number;
}

interface Deco {
  trims: Trim[];
  sku: string;
  name: string;
  image: string;
}

interface Option {
  values: Value[];
  name: string;
}

interface Value {
  id: string;
  name: string;
  price: number;
}

interface Product {
  completion_date: any[];
  options: Option[];
  decos: Deco[];
  oversize: {
    price: number;
    a: number,
    b: number,
  };
  price: number;
  allowable_size: {
    max: number;
    min: number;
  };
  name: string;
}

interface SmartopData {
  currency: string;
  products: Product[];
  jacuzzi400products: Product[];
}
