<div class="tab-pane">
    <form>
        <!--Customer Edit Form-->
        <ng-container  *ngIf="!isNewCustomer">

          <div class="alert alert-info alert-dismissible" role="alert" *ngIf="alert_message">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span> <span class="sr-only">Close</span>
            </button>
            <strong>{{alert_message}}</strong>
          </div>

            <div *ngIf="customerIsLoading">
              <div class="row justify-content-md-center">
                <div class="col-sm-3"><i class="fa fa-spinner fa-spin fa-1x"></i> Loading ...</div>
              </div>
            </div>
            <ng-container *ngIf="!customerIsLoading">
              <div class="row">
                  <div class="col-md-4 col-sm-12">
                      <ng-container *ngIf="!isContactCustomer">
                        <div style="margin-bottom: 4px;" class="clearfix">
                          <h4 style="float: left">
                            {{ customer?.attributes.Organization }} #{{ customer?.attributes.CustomerID }}
                          </h4>
                        </div>
                        <jet-customer-info [codes]="codes" [customer]="customer"></jet-customer-info>
                        <jet-customer-dealer-types [dealerTypes]="customer?.relationships?.dealerTypes"
                                                   [productLines]="customer?.relationships?.productLines"
                                                   [key]="'customer-root'"
                                                   [customerId]="customer?.attributes.CustomerID"
                        ></jet-customer-dealer-types>
                      </ng-container>
                      <ng-container *ngIf="isContactCustomer">
                        <jet-individual-info [customer]="customer"></jet-individual-info>
                      </ng-container>
                  </div>
                  <div class="col-md-4 col-sm-12 col">
                      <jet-customer-addresses [key]="'customer-root'" [customerId]="customer?.attributes.CustomerID" [addresses]="customer?.relationships?.addresses"></jet-customer-addresses>
                      <jet-customer-contact-list [key]="'customer-root'"
                                                 [contacts]="customer?.relationships?.phoneNumbers"
                                                 [customerId]="customer?.attributes.CustomerID"
                                                 [contactType]="contactTypePhone"
                                                 [contactTypeName]="'phones'"
                                                 [contactTypeIcon]="'fa-phone'"
                      >
                      </jet-customer-contact-list>
                      <jet-customer-contact-list [key]="'customer-root'"
                                                 [contacts]="customer?.relationships?.faxNumbers"
                                                 [customerId]="customer?.attributes.CustomerID"
                                                 [contactType]="contactTypeFax"
                                                 [contactTypeName]="'faxes'"
                                                 [contactTypeIcon]="'fa-fax'"
                      >
                      </jet-customer-contact-list>
                      <jet-customer-contact-list [key]="'customer-root'"
                                                 [contacts]="customer?.relationships?.emails"
                                                 [customerId]="customer?.attributes.CustomerID"
                                                 [contactType]="contactTypeEmail"
                                                 [contactTypeName]="'emails'"
                                                 [contactValueDisplayType]="maillinkDisplayType"
                                                 [contactTypeIcon]="'fa-envelope'"
                      >
                      </jet-customer-contact-list>
                      <jet-customer-contact-list [key]="'customer-root'"
                                                 [contacts]="customer?.relationships?.websites"
                                                 [customerId]="customer?.attributes.CustomerID"
                                                 [contactType]="contactTypeUrl"
                                                 [contactTypeName]="'websites'"
                                                 [contactValueDisplayType]="hyperlinkDisplayType"
                                                 [contactTypeIcon]="'fa-globe'"
                      >
                      </jet-customer-contact-list>
                  </div>
                  <div class="col-md-4 col-sm-12 col">
                    <jet-customer-payment-types [customerId]="customer?.attributes.CustomerID" [paymentTypes]="customer?.relationships?.paymentTypes"></jet-customer-payment-types>
                    <ng-container *ngIf="!isContactCustomer">
                      <jet-customer-contacts [parentCustomerType]="customer?.attributes.CustomerType" [parentCustomerId]="customer?.attributes.CustomerID"
                                             [individuals]="customer?.relationships?.individuals"></jet-customer-contacts>
                    </ng-container>
                  </div>
              </div>
              <div class="row">
                <div class="col-md-8 co-sm-12">
                      <jet-customer-stores [parentCustomerType]="customer?.attributes.CustomerType"
                                           [parentCustomerId]="customer?.attributes.CustomerID"
                                           [parentPreviousStoreId]="previousStoreId"
                                           [stores]="stores"
                                           [canAdd]="!isContactCustomer">
                      </jet-customer-stores>
                </div>
              </div>
              <div class="row">
                  <div class="col-md-2 offset-md-2 col-sm-6 offset-sm-0 col">
                      <input [ngModelOptions]="{standalone: true}"
                             [(ngModel)]="storeFullName"
                             [typeahead]="dataSource"
                             (typeaheadLoading)="changeTypeaheadLoading($event)"
                             (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                             (typeaheadOnSelect)="typeaheadOnSelect($event)"
                             [typeaheadOptionsLimit]="25"
                             [typeaheadScrollable]="true"
                             [typeaheadOptionsInScrollableView]="10"
                             [typeaheadMinLength]="3"
                             [typeaheadWaitMs]="300"
                             [typeaheadOptionField]="'attributes.FullName'"
                             placeholder="Store search by text"
                             class="small-form">
                  </div>
                  <div class="col-md-2 offset-md-2 col-sm-6 offset-sm-0 col">
                      <button class="btn btn-primary btn-xs" (click)="addStoreAsChild()">Add Existing Store As Child</button>
                  </div>
              </div>
            </ng-container>
        </ng-container>
        <!-- End Customer Edit Form-->
        <!-- Customer New Form-->
        <ng-container *ngIf="isNewCustomer">
            <jet-new-customer></jet-new-customer>
        </ng-container>
        <!-- End Customer New Form-->
    </form>
</div>
