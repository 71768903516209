<div class="modal-header">
  <h5 class="modal-title">{{ !isNew ? 'Displaying' : 'Compose New'}} Note</h5>
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-block">
    <h5 class="d-inline-block">Note by <strong>{{ employeeName }}</strong>
      <span *ngIf="editNote?.attributes.NoteDate"> submitted {{ editNote?.attributes.NoteDate | momentDate: dateFormat }}</span>
    </h5>

    <button class="btn btn-sm float-right" [ngClass]="{'star-favorite': editNote?.attributes.Codes_NoteTypeIDfk === generalNoteCode, 'btn-default': editNote?.attributes.Codes_NoteTypeIDfk !== generalNoteCode}"
            (click)="toggleNoteType()" tooltip="Show In Sidebar" placement="below" delay="500">
      <i class="fa fa-star"></i>
    </button>
  </div>

  <div class="d-block">
    <label>Category: </label>
    <input type="text" name="category" [(ngModel)]="editNote?.attributes.Category" [typeahead]="categories">
  </div>

    <br>
    <!--<app-ngx-editor [config]="editorConfig" [(ngModel)]="editNote?.attributes.Note"></app-ngx-editor>-->
    <angular-editor [config]="editorConfig" [(ngModel)]="editNote?.attributes.Note"></angular-editor>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Cancel</button>
  <button *ngIf="!isNew" type="button" class="btn btn-primary" (click)="update()">Save</button>
  <button *ngIf="isNew" type="button" class="btn btn-primary" (click)="add()">Submit</button>
</div>
