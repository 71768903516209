<div class="card">
  <div class="card-heading" data-toggle="collapse" data-target="#quickViewFreight">
    <h3 class="caps card-title">
      <span>Freight</span>
      <i class="fa fa-archive fa-lg"></i>
      <a class="caret"></a>
    </h3>
    <div class="card-subheading float-right">
      <div class="text-right">

      </div>
    </div>
  </div>
  <div class="card-body collapse show fivepad" id="quickViewFreight">
    <div class="container-fluid">
      <div class="row">
        <div class="col col-md-6 col-sm-12">
          <table>
            <tr>
              <th>Total Weight</th>
              <td>{{ computedFreightInfo?.totalWeight +  'lbs.' | jetTranslate:!isSmartopInvoice }}</td>
            </tr>
            <tr>
              <th>Company</th>
              <td>{{ company }}</td>
            </tr>
            <tr>
              <th>Ship Via</th>
              <td>{{ shipVia }}</td>
            </tr>
            <tr>
              <th>Freight Cost</th>
              <td>{{ freightCost | jetCurrency }}</td>
            </tr>
            <tr>
              <th>Handling Charge</th>
              <td>{{ handlingCharge | jetCurrency }}</td>
            </tr>
            <tr>
              <th>Add. Charge</th>
              <td>{{ additionalCharge | jetCurrency }}</td>
            </tr>
            <tr>
              <th>Shipping Credit</th>
              <td>{{ shippingCredit | jetCurrency }}</td>
            </tr>
            <tr>
              <th>Date Shipped</th>
              <td>{{ dateShipped | momentDate:dateFormat }}</td>
            </tr>
            <tr>
              <th>Tracking #</th>
              <td>{{ trackingNumber }}</td>
            </tr>
            <tr>
              <th>Promo Code</th>
              <td>{{ promoCode }}% {{ promoDiscountTotal | jetCurrency }}</td>
            </tr>
          </table>
        </div>
        <div class="col col-md-6 col-sm-12">
          <table>
            <tr>
              <th colspan="2" align="center">{{ numberOfPallets }} # Pallets</th>
            </tr>
            <tr>
              <th>SUBTOTAL</th>
              <td>{{ computedFreightInfo?.subTotal | jetCurrency }}</td>
            </tr>
            <tr>
              <th>S & H</th>
              <td>{{ computedFreightInfo?.totalShippingHandling | jetCurrency }}</td>
            </tr>
            <tr>
              <th>{{ 'Tax'|jetTranslate|uppercase }}</th>
              <td>{{ computedFreightInfo?.totalTax | jetCurrency }}</td>
            </tr>
            <tr>
              <th>CREDIT</th>
              <td>{{ shippingCredit | jetCurrency }}</td>
            </tr>
            <tr>
              <th>TOTAL</th>
              <td *ngIf="isTotalNegative">({{ computedFreightInfo?.total * -1 | jetCurrency }})</td>
              <td *ngIf="isTotalNegative">{{ computedFreightInfo?.total | jetCurrency }}</td>
            </tr>
            <tr>
              <td colspan="2">
                <hr>
              </td>
            </tr>
            <tr>
              <th>Payments</th>
              <td><b>{{ computedFreightInfo?.totalPayments | jetCurrency }}</b></td>
            </tr>
            <tr>
              <th>Balance</th>
              <td><b>{{ computedFreightInfo?.balance | jetCurrency }}</b></td>
            </tr>
          </table>
        </div>
      </div>
    </div>

  </div>
</div>
