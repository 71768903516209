import {environment} from '../environments/environment';
import {Inject, Injectable, Injector} from '@angular/core';
import * as beaver from 'beaver-logger';
import {STORAGE_TOKEN} from './auth.service';
import {JetVersion} from "../version";
import {UserService} from "./observables/user.service";
import {Router} from "@angular/router";
import {DOMAIN_CONFIG, DomainConfigInterface} from "../config/domain.interface";

interface BeaverLogger {
  info: (event: string, payload: any) => void;
  debug: (event: string, payload: any) => void;
  error: (event: string, payload: any) => void;
  warn: (event: string, payload: any) => void;
  addHeaderBuilder: (callback: Function) => any;
  addPayloadBuilder: (callback: Function) => any;
  addMetaBuilder: (callback: Function) => any;
}

@Injectable()
export class LoggingService {
  public readonly logger: BeaverLogger;

  constructor(private injector: Injector,
              @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
              ) {
    this.logger = beaver.Logger({
      // Url to send logs to
      url: `${this.config.apiUrl}log`,
      // Prefix to prepend to all events
      prefix: '',
      // Log level to display in the browser console
      logLevel: beaver.LOG_LEVEL.ERROR,
      // Interval to flush logs to server
      flushInterval: environment.loggingInterval
    });

    const userService = this.injector.get(UserService);
    const router = this.injector.get(Router);

    this.logger.addHeaderBuilder(() => {
      const token = localStorage.getItem(STORAGE_TOKEN);
      return {
        'Content-Type': 'application/vnd.api+json',
        'Accept': 'application/json',
        'token': token,
        'X-jet-revision': JetVersion.revision,
        'X-jet-date': JetVersion.date,
      };
    });

    this.logger.addMetaBuilder(function() {
      const user = userService.currentUser();
      const user_id = user ? user.id : '0';
      const user_name = user ? user.attributes.fname + ' ' + user.attributes.lname : '';
      const url = router.url;

      return {user_id, user_name, url};
    });

  }
}
