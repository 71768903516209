import {Component, Input, OnInit} from '@angular/core';
import {SmartopOrderAddress} from "../../../models/smartop-order-submission.interface";

@Component({
  selector: 'jet-smartop-address',
  templateUrl: './smartop-address.component.html',
  styleUrls: ['./smartop-address.component.scss']
})
export class SmartopAddressComponent implements OnInit {

  @Input()
  address: SmartopOrderAddress

  @Input()
  country: string

  constructor() {
  }

  ngOnInit() {
  }

}
