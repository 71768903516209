import {Component, Input, OnInit} from '@angular/core';
import {Customer} from "../../../../models/customer.interface";

@Component({
  selector: 'jet-individual-info',
  templateUrl: './individual-info.component.html',
  styleUrls: ['./individual-info.component.scss']
})
export class IndividualInfoComponent implements OnInit {
  @Input() public customer: Customer = null;
  constructor() { }

  ngOnInit() {
  }

}
