import { Component, Input, OnInit } from '@angular/core';
import { Contact } from '../../../../models/contact.interface';
import { ContactComponent, ContactValueDisplayType } from '../../../shared/components/contact/contact.component';
import {
  CONTACT_TYPE_EMAIL, CONTACT_TYPE_FAX, CONTACT_TYPE_PHONE, CONTACT_TYPE_URL,
  SOURCE_TYPE_CUSTOMER
} from '../../../../utils/constants.util';
import {
  DEFAULT_EMAIL_CONTACT, DEFAULT_FAX_CONTACT, DEFAULT_PHONE_CONTACT,
  DEFAULT_URL_CONTACT
} from '../../../../mocks/contacts.mock';
import { copyObject } from '../../../../utils/json.util';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {ResourceService} from "../../../../services/resource.service";

@Component({
  selector: 'jet-customer-contact-list',
  templateUrl: './customer-contact-list.component.html',
  styleUrls: ['./customer-contact-list.component.scss']
})
export class CustomerContactListComponent implements OnInit{
  @Input() public key: string;
  @Input() public contacts: Contact[] = [];
  @Input() public contactTypeName: string;
  @Input() public contactTypeIcon: string;
  @Input() public contactType: number;
  @Input() public customerId = 0;
  @Input() public contactValueDisplayType: ContactValueDisplayType = ContactValueDisplayType.Text;
  public emptyContact: Contact = null;
  public STATE_VIEW = ContactComponent.STATIC_STATES.VIEW;
  public STATE_ADD = ContactComponent.STATIC_STATES.ADD;
  public showAddForm = false;

  private contactMocks: any = {
    [CONTACT_TYPE_PHONE]: DEFAULT_PHONE_CONTACT,
    [CONTACT_TYPE_FAX]: DEFAULT_FAX_CONTACT,
    [CONTACT_TYPE_EMAIL]: DEFAULT_EMAIL_CONTACT,
    [CONTACT_TYPE_URL]: DEFAULT_URL_CONTACT,
  };

  constructor(
    private resourceService: ResourceService,
  ) {

  }

  ngOnInit() {
    const resource: Contact = copyObject(this.contactMocks[this.contactType]);
    resource.attributes.SourceType = SOURCE_TYPE_CUSTOMER;
    this.emptyContact = resource;
  }

  public toggleShowAddFormOnClick(event) {
    event.stopPropagation();
    this.toggleShowAddForm();
  }

  private toggleShowAddForm() {
    this.showAddForm = !this.showAddForm;
  }

  public newContactAddHandler(success) {
    if (success) {
      this.toggleShowAddForm();
    }
  }

  public newContactCancelHandler(success) {
    this.toggleShowAddForm();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.contacts, event.previousIndex, event.currentIndex);
    // const swapLoader = this.loadingService.newLoader();
    // swapLoader.start();
    for (let i = 0 ; i < this.contacts.length ; i++) {
      // pentad requires this to start at 1.
      this.contacts[i].attributes.ContactSequence = i + 1;
      this.resourceService.updateResource('contacts', + this.contacts[i].id, this.contacts[i].attributes)
        .subscribe(() => {
          if (i === this.contacts.length - 1) {
            // swapLoader.stop();
          }
        });
    }
  }

}
