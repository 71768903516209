import {Component, OnInit, ViewChild} from '@angular/core';
import { LineItem } from '../../common/line-item';
import { Code } from '../../../../models/code.interface';
import { UNITS_OF_MEASURE } from '../../../../utils/constants.util';

@Component({
  selector: 'jet-invoice-other-item',
  templateUrl: './invoice-other-item.component.html',
  styleUrls: ['./invoice-other-item.component.scss']
})
export class InvoiceOtherItemComponent extends LineItem {

  public unitsOfMeasure: Code[] = [];
  public justAdded: boolean = false;

  @ViewChild("firstInput") firstInput;

  protected customInitCodeProperties(codes: any) {
    this.unitsOfMeasure = codes[UNITS_OF_MEASURE];
  }

  protected customIsValid(): string[] {
    const errors = super.customIsValid();
    if (this.editItem.attributes.Description === '') {
      errors.push('Please enter a Description');
    }
    return errors;
  }

  adjustScreen() {
    if(this.justAdded) {
      this.justAdded = false;

      let distance = this.getElementDistanceFromBottom(this.firstInput.nativeElement);
      if(distance < 300){
        window.scrollBy(0, 300 - distance);
      }
    }
  }

  getElementDistanceFromBottom(element) {
    let domRect = element.getBoundingClientRect();
    return window.innerHeight - domRect.bottom;
  }

  added() {
    this.justAdded = true;
  }
}
