import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {LoadingService} from '../../../../services/observables/loading.service';
import {NoteService} from '../../../../services/observables/note.service';
import {Note} from '../../../../mocks/note.interface';
import {getEmployeeName} from '../../../../utils/user.util';
import {HuebeeConfig} from '../../../../config/huebee.config';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";
import {UserService} from "../../../../services/observables/user.service";
import {User} from "../../../../models/user.interface";
import {IndexService} from '../../../../services/observables/index.service';
import {HttpClient} from '@angular/common/http';
import {BsModalRef} from 'ngx-bootstrap/modal';

declare const document: any;
declare const Huebee: any;
@Component({
  selector: 'jet-note-modal',
  templateUrl: './note-modal.component.html',
  styleUrls: ['./note-modal.component.scss']
})
export class NoteModalComponent implements OnInit, OnDestroy {
  // NOTE: should be a copy. Not an original of the resource.
  public categories: string[] = [];
  public notes: Note[] = [];
  public note: Note;
  public editNote: Note;
  public user: User = null;
  public editorConfig = {
    'editable': true,
    'spellcheck': true,
    'height': 'auto',
    'minHeight': '400',
    'maxHeight': '400',
    'width': 'auto',
    'minWidth': '0',
    'translate': 'yes',
    'enableToolbar': true,
    'showToolbar': true,
    'placeholder': 'Enter text here...',
    'imageEndPoint': '',
    'toolbar': [
      ['bold', 'italic', 'underline', 'color', 'undo', 'redo']
    ]
  };

  public dateFormat: string;
  private ngUnsubscribe = new Subject();
  public generalNoteCode: number;
  public customerNoteCode: number;

  public url: string;

  constructor(
    private loadingService: LoadingService,
    private noteService: NoteService,
    public bsModalRef: BsModalRef,
    private userService: UserService,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
    private indexService: IndexService,
    private httpClient: HttpClient,
  ) {
    this.dateFormat = this.config.dateFormat;
    this.generalNoteCode = this.config.codes['noteTypes']['general'];
    this.customerNoteCode = this.config.codes['noteTypes']['customer'];
    this.url  = this.config.apiUrl;
  }

  public ngOnInit() {
    const observer: MutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (!mutation.addedNodes) { return; }
        for (let i = 0; i < mutation.addedNodes.length; i++) {
          // do things to your newly added nodes here
          const node: Node = mutation.addedNodes[i];
          if (node.nodeName === 'popover-container') {
            const hexInput =  document.getElementById('hexInput');
            hexInput.placeholder = 'Click here to pick color...';
            const hueb = new Huebee(hexInput, HuebeeConfig);
            hueb.on( 'change', function( color, hue, sat, lum ) {
              hexInput.value = color;
              hexInput.dispatchEvent(new Event('input'));
            });
          }
        }
      });
    });
    observer.observe(document.body, {childList: true});

    this.userService.user.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((user: User) => {
      this.user = user;
    });

    this.getCustomerNotes();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  /** TODO: Following getters are necessary since the properties set by BSModalService are not reactive **/
  public get employeeName(): string {
    return this.isNew? `${this.user.attributes.lname}, ${this.user.attributes.fname}`: getEmployeeName(this.note);
  }

  public get isNew(): boolean {
    return this.note && this.note.id === '0';
  }

  public add() {
    this.loadingService.refreshIsLoading(true);
    this.noteService.addResource(this.editNote).pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe(this.onActionComplete, this.onActionComplete);
  }

  public update() {
    this.loadingService.refreshIsLoading(true);
    this.noteService.updateResource(this.editNote).pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe(this.onActionComplete, this.onActionComplete);
  }

  public remove() {
    this.loadingService.refreshIsLoading(true);
    this.noteService.deleteResource(this.editNote).pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe(this.onActionComplete, this.onActionComplete);
  }

  public onActionComplete = () => {
    this.loadingService.refreshIsLoading(false);
    this.bsModalRef.hide();
  }

  public toggleNoteType() {
    if (this.editNote.attributes.Codes_NoteTypeIDfk === this.generalNoteCode) {
      this.editNote.attributes.Codes_NoteTypeIDfk = this.customerNoteCode;
    } else {
      this.editNote.attributes.Codes_NoteTypeIDfk = this.generalNoteCode;
    }
  }

  private getCustomerNotes() {
    this.noteService.notes.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((notes: Note[]) => {
      if (!notes) { return; }
      if (Object.keys(notes).length === 0) {
        this.notes = [];
        this.categories = [];
      } else {
        this.notes = notes;
        this.collectCategories();
      }
      this.indexService.NotesAreDone();
    });
  }

  public collectCategories() {
    this.httpClient.get(this.url + 'notes/getCategories').subscribe(res => {
      res['categories'].forEach(item => {
        this.categories.push(item['category']);
      });
    });
  }
}
