import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
    selector: 'invoice-details-notes-modal',
    templateUrl: './invoice-details-notes-modal.component.html',
    styleUrls: ['./invoice-details-notes-modal.component.scss']
})
export class InvoiceDetailsNotesModalComponent implements OnInit, OnDestroy {

    public notes: string;

    constructor(
        public bsModalRef: BsModalRef,
    ) { }

    async ngOnInit() {}

    ngOnDestroy(): void {}
}
