import {Component, OnInit} from "@angular/core";
declare let jQuery:any;
declare let window:any;

@Component({
  styles:[
    'body {background: white;}'
  ],
  template: ''
})
export class DenyPageComponent implements OnInit {
  ngOnInit(): void {
    jQuery(function(){
        window.parent.postMessage({
          page: 'denypage',
          height: 0 + "px"
        }, '*');
      });
  }
}
