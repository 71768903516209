<div>
  <h4>{{ label }}</h4>
  <table class="outer-table small table  table-striped table-hover ">
    <colgroup>
      <col style="width:20px">                          <!-- caret -->
      <col style="width:50px">                          <!-- WGT -->
      <col style="width:120px">                         <!-- Shipper -->
      <col style="width:200px">                         <!-- Company -->
      <col style="width:150px">                         <!-- City -->
      <col style="width:40px">                          <!-- State -->
      <col style="width:60px">                          <!-- Order Id -->
      <col style="width:80px">                          <!-- Invoice -->
      <col style="width:80px">                          <!-- Packing List -->
      <col>                                             <!-- Shipping instructions -->
      <col style="width:80px">                          <!-- STS Date *NEW TO JET* -->
      <col *ngIf="showProNumber" style="width:90px">    <!-- Pro Number -->
      <col style="width:100px">                         <!-- button -->
      <col *ngIf="showPrintLabels" style="width:80px">  <!-- Print -->
    </colgroup>
    <thead>
    <tr class="outer-row">
      <th></th>
      <th>WGT</th>
      <th>Shipper</th>
      <th>Company</th>
      <th>City</th>
      <th>State</th>
      <th>Order ID</th>
      <th>Invoice</th>
      <th title="Packing List">Pac. List</th>
      <th title="Shipping Instructions">Shipping Instructions</th>
      <th title="Sent to shipping date">STS Date</th>
      <th *ngIf="showProNumber">Pro Number</th>
      <th></th>
      <th *ngIf="showPrintLabels"></th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let row of shippingList">
      <tr jet-shipping-table-row [item]="row" [showProNumber]="showProNumber"
          [showPrintLabels]="showPrintLabels" [user]="user" [label]="label" class="outer-row"></tr>

      <tr *ngIf="row.expanded">
        <td colspan="12">
          <table class="inner-table small table table-striped table-hover">
            <thead>
            <tr class="inner-row">
              <th>QTY</th>
              <th>SKU</th>
              <th>Description</th>
              <th>Warehouse</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let lineItem of row.LineItems" class="inner-row">
              <td>{{ lineItem.Quantity }}</td>
              <td>{{ lineItem.InventoryItemNumber }}</td>
              <td>{{ lineItem.Description }}</td>
              <td>
                <ng-container *ngIf="row.loading">
                  <i class="fa fa-spinner fa-spin fa-1x"></i> Loading ...
                </ng-container>
                <select *ngIf="!row.loading">
                  <ng-container *ngFor="let warehouse of row.Warehouses">
                    <option *ngIf="lineItem.InventoryItemIDfk === warehouse[0]">{{ warehouse[1] }} ({{ warehouse[2] }})
                    </option>
                  </ng-container>
                </select>
              </td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>

    </ng-container>
    </tbody>
  </table>
</div>
