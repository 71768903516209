<jet-menu></jet-menu>
<div class="container-fluid">

  <div class="header"></div><!-- /header -->

  <div class="content row">
    <div id="sidebar" class="col-lg-2 col-custom col-sm-12 order-lg-0 order-2">
      <div class="hidden-sm hidden-xs row">
        <div class="col-md-12">
          <jet-customer-search></jet-customer-search>
          <jet-customer-notes></jet-customer-notes>
        </div>
      </div> <!-- Desktop sidebar - hides on mobile -->
    </div> <!-- /sidebar -->

    <div class="tabs col-lg-10 col-custom col-sm-12">

      <div class="row">
        <div class="col-sm-8">
          <ul class="nav nav-tabs">
            <li class="nav-item"><a href="#" class="nav-link active">Smartop Sales Contest Report</a></li>
          </ul>
        </div>
      </div>

      <div class="tab-content">

        <div class="container-fluid" style="margin-left:5%">
          <br/>
          <h1>Smartop Sales Contest</h1>
          <div class="row">
            <div class="col-sm-2">
              <label>Start Date</label><input placeholder="Pick Start Date" type="text" class="small-form" #datePicker
                                              bsDatepicker [bsConfig]="{ dateInputFormat: dateFormat }"
                                              [(bsValue)]="startDate" (bsValueChange)="updateStartDate($event)">
            </div>
            <div class="col-sm-2">
              <label>End Date</label><input placeholder="Pick End Date" type="text" class="small-form" #datePicker
                                            bsDatepicker [bsConfig]="{ dateInputFormat: dateFormat }"
                                            [(bsValue)]="endDate" (bsValueChange)="updateEndDate($event)">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2" style="padding: 15px">
              <button type="button" class="btn btn-sm btn-primary" (click)="getContestRangeResults()">Generate Report
              </button>
            </div>
          </div>

          <div *ngIf="contestResults.length > 0" class="col-sm-11">
            <div style="display: flex; position: relative; float: right; padding: 15px">
              <button type="button" class="btn btn-sm btn-success" (click)="exportToCsv()">Export to CSV</button>
            </div>
            <table class="small table  table-striped table-hover table-borderless" style="margin-bottom: 0;">
              <thead>
              <tr>
                <th>Salesperson Last</th>
                <th>Salesperson First</th>
                <th>Company Name</th>
                <th>Order Date</th>
                <th>Customer Name</th>
                <th>Style</th>
                <th>Reason</th>
                <th>CIN</th>
                <th>Store Location</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let result of contestResults">
                <td>{{result.editing ? '' : result.salespersonLastName}}
                <input *ngIf="result.editing" [(ngModel)]="result.salespersonLastName">
                </td>
                <td>{{result.editing ? '' : result.salespersonFirstName}}
                  <input *ngIf="result.editing" [(ngModel)]="result.salespersonFirstName">
                </td>
                <td>{{result.editing ? '' : result.companyName}}
                  <input *ngIf="result.editing" [(ngModel)]="result.companyName">
                </td>
                <td>{{updateDate(result.orderDate)}}</td>
                <td>{{result.customerName}}</td>
                <td>{{result.style}}</td>
                <td>{{result.reason}}</td>
                <td>{{result.CIN}}</td>
                <td>{{result.editing ? '' : result.location}}
                  <input *ngIf="result.editing" [(ngModel)]="result.location">
                </td>
                <div *ngIf="!result.editing; else saveLogo">
                <i class="fas fa-edit"
                  (click)="editResult(result)"
                ></i>
                </div>
                  <ng-template #saveLogo>
                    <i class="fas fa-check-circle"
                       (click)="saveEdit(result)"
                    ><button type="button" style="margin-left: 5px" class="btn btn-sm btn-success">Save Changes</button></i>
                  </ng-template>
              </tr>
              </tbody>

            </table>
          </div>
        </div>


      </div>
    </div> <!-- /tabs -->
  </div><!-- /row -->
</div> <!-- /container -->

