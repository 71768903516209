import {Component, HostBinding, Inject, Input, OnInit} from '@angular/core';
import {INVOICE_TYPE_SMARTOP} from '../../../utils/constants.util';
import {PentadService} from '../../../services/pentad.service';
import {HttpClient} from '@angular/common/http';
import {DOMAIN_CONFIG, DomainConfigInterface} from '../../../config/domain.interface';
import {LoadingService} from '../../../services/observables/loading.service';
import {WarningModalComponent} from '../../shared/components/warning-modal/warning-modal.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {User} from '../../../models/user.interface';
import {createOptions} from '../../../utils/http.util';
import {TruckLabelModalComponent} from '../truck-label-modal/truck-label-modal.component';
import {firstValueFrom} from "rxjs";
import {take} from "rxjs/operators";

@Component({
  selector: '[jet-shipping-table-row]',
  templateUrl: './shipping-table-row.component.html',
  styleUrls: ['../shipping-table/shipping-table.component.scss']
})
export class ShippingTableRowComponent implements OnInit {

  @Input() item;
  @Input() showProNumber: boolean;
  @Input() showPrintLabels: boolean;
  @Input() label: string;
  @Input() user: User;

  public isSmartopInvoice: boolean;
  public dateFormat;
  public showRow: boolean;

  @HostBinding('class.shaded') shaded: boolean = false;

  private bsModalRef: BsModalRef;

  constructor(
    private pentadService: PentadService,
    private httpClient: HttpClient,
    private loadingService: LoadingService,
    private bsModalService: BsModalService,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
  ) { }

  ngOnInit() {
    this.isSmartopInvoice = this.item.InvoiceType === INVOICE_TYPE_SMARTOP;
    this.item.expanded = false;
    this.item.loading = true;
    this.dateFormat = this.config.dateFormat;
    this.showRow = true;

    if (this.showProNumber) {
      this.item.ProNumber = '';
    }

    if (this.item.RecordChange) {
      const now = new Date();
      const changeDate = new Date(this.item.RecordChange);
      changeDate.setDate(changeDate.getDate() + 3);

      if (now.getTime() > changeDate.getTime()) {
        this.shaded = true;
      }
    }
  }

  showItems(orderId) {
    this.item.expanded = !this.item.expanded;

    if (!this.item.expanded || this.item.Warehouses.length > 0) {
      return;
    }

    const url = this.config.apiUrl + 'shipping/warehouse-inventory-counts';
    const body = {orderId: orderId};

    this.httpClient.post(url, body).subscribe(
      (data) => {
        this.item.Warehouses = data['Warehouses'];
        this.orderWarehouses();
        this.item.loading = false;
      },
      (error) => {
        this.item.loading = false;
      }
    );
  }

  async setPicked(orderId) {
    if (false === await this.printLabel()) {
      return;
    }

    const url = this.config.apiUrl + 'shipping/set-picked';
    const body = {orderId: orderId};

    const loader = this.loadingService.newLoader();
    loader.start();

    this.httpClient.post(url, body).subscribe(
      (data) => {
        this.item = data;
        loader.stop();
      },
      (error) => {
        loader.stop();
      }
    );
  }

  async complete(orderId) {
    const shouldComplete = await this.showConfirmationModal();

    if (!shouldComplete) {
      return;
    }

    const url = this.config.apiUrl + 'shipping/complete';
    const body = {orderId: orderId, userId: this.user.id, proNumber: null};
    const loader = this.loadingService.newLoader();
    const proNumber = this.item.ProNumber;

    if (proNumber) {
      body.proNumber = proNumber;
    }

    loader.start();

    this.httpClient.post(url, body).subscribe(
      (data) => {
        this.showRow = false;
        loader.stop();
      },
      (error) => {
        const warnings = error.error;

        this.bsModalRef = this.bsModalService.show(WarningModalComponent);
        this.bsModalRef.content.warnings = warnings;
        loader.stop();
      }
    );
  }

  printInvoice() {
    let reportId = '5102405A-F61D-48FB-ADFA-7C81805C9EAF';

    if (this.isSmartopInvoice) {
      reportId = 'F891702B-B5AA-4EBD-B3C9-3898BDA4EBB2';
    }

    this.openInvoiceRelatedReport(reportId);
  }

  printPackList() {
    let reportId = 'E9D4C1FD-CBF5-4C3B-B670-C0D9C31F7F84';

    if (this.isSmartopInvoice) {
      reportId = '2E0A039D-230A-45F8-BFCF-09C73374F5F7';
    }

    this.openInvoiceRelatedReport(reportId);
  }

  async printLabel() {
    let reportType = '';
    let truckLabelCount = 0;

    if (this.label === 'Ground') {
      return true;
    }

    if (this.label === 'Truck') {
      reportType = 'truck-label';
      truckLabelCount = await this.showTruckLabelModal();
    }

    const path = await this.pentadService.setPentadSecurity('print-reports?PentadSecurity');
    const url = this.config.apiUrl + path;
    const body: any = {
      reportType,
      invoiceId: this.item.InvoiceId,
    };

    if (reportType === 'truck-label') {
      body.truckLabelCount = truckLabelCount;
    }

    const options = createOptions();
    const loader = this.loadingService.newLoader();

    loader.start();
    this.httpClient.post<any>(url, body, options).subscribe(
      (success) => {
        this.showWarningModal('Label(s) Printed');
        loader.stop();
      },
      (error) => {
        this.showWarningModal('Error Printing Labels');
        loader.stop();
      }
    );

    return true;
  }

  public openInvoiceRelatedReport(reportId: string) {
    this.openPage(this.config.baseUrl
      + 'internal/Reporting/Reports.aspx?disposition=inline&PentadSecurity&ReportID='
      + reportId
      + '&PDF=true&Parameters=Invoice%3d'
      + this.item.OrderId);
  }

  public async openPage(url: string) {
    this.pentadService.setPentadSecurityAndNewTab(url);
  }

  private async showConfirmationModal() {
    const initialState = {
      enableConfirm: true,
      warnings: [`WGT: ${this.item.WGT}`, `Shipper: ${this.item.Shipper.CodeDesc}`,
        `Company: ${this.item.Company}`,  `City: ${this.item.City}`, `State: ${this.item.State}`, `Order ID: ${this.item.OrderId}`],
      title: 'Are you sure you want to complete the order?',
      confirmMessage: 'Complete Order',
    };

    this.bsModalRef = this.bsModalService.show(WarningModalComponent, {class: 'modal-lg', initialState});

    return (await firstValueFrom(this.bsModalRef.content.onClose.pipe(take(1))))["confirm"];
  }

  private async showTruckLabelModal() {
    this.bsModalRef = this.bsModalService.show(TruckLabelModalComponent);

    return (await firstValueFrom(this.bsModalRef.content.onClose.pipe(take(1)))) as number;
  }

  private showWarningModal(message) {
    this.bsModalRef = this.bsModalService.show(WarningModalComponent);
    this.bsModalRef.content.warnings = [message];
  }

  public orderWarehouses() {
    const warehouseOrder = [
      'SHIPPING WH1',
      'SMARTOP',
      'BLOW MOLDED PRODUCTS',
      'SHIPPING WH2',
      'ROBINSON WINWORD',
      'Tipke',
      'Plastics',
      'Spokane'
    ];

    this.item.Warehouses.sort(function(a, b) {
      return warehouseOrder.indexOf(a[1]) - warehouseOrder.indexOf(b[1]);
    });
  }

}
