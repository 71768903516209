import {Component, Input, OnInit} from '@angular/core';
import {Customer} from '../../../../models/customer.interface';
import {copyObject} from '../../../../utils/json.util';
import {DEFAULT_STORE_CUSTOMER} from '../../../../mocks/customer.mock';
import {WarningModalComponent} from '../../../shared/components/warning-modal/warning-modal.component';
import {CustomerService} from '../../../../services/observables/customer.service';
import {LoadingService} from '../../../../services/observables/loading.service';
import {CUSTOMER_TYPE_INDIVIDUAL} from '../../../../utils/constants.util';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {firstValueFrom} from "rxjs";
import {take} from "rxjs/operators";


@Component({
  selector: 'jet-customer-stores',
  templateUrl: './customer-stores.component.html',
  styleUrls: ['./customer-stores.component.scss']
})
export class CustomerStoresComponent implements OnInit {
  @Input() public stores: Customer[] = [];
  @Input() public parentCustomerId = 0;
  @Input() public parentCustomerType = 0;
  @Input() public parentPreviousStoreId: string;
  @Input() public canAdd = true;
  public areParentDealers: boolean;
  public editStore: Customer = null;
  public showAddForm = false;
  public bsModalRef: BsModalRef;

  constructor(private bsModalService: BsModalService, private customerService: CustomerService, private loadingService: LoadingService) { }

  public ngOnInit () {
    this.areParentDealers = this.parentCustomerType === CUSTOMER_TYPE_INDIVIDUAL;
  }

  public toggleShowAddFormOnClick(event) {
    event.stopPropagation();
    this.showAddForm = !this.showAddForm;
    if (this.showAddForm) {
      this.editStore = copyObject(DEFAULT_STORE_CUSTOMER);
    }
  }

  public add() {
    if (!this.editStore.attributes.Organization) {
      this.bsModalRef = this.bsModalService.show(WarningModalComponent);
      this.bsModalRef.content.warnings = ['Organization name required.'];
      return;
    }
    this.loadingService.refreshIsLoading(true);
    this.customerService.createStoreCustomer(this.editStore, this.parentCustomerId, this.parentCustomerType, this.parentCustomerId, true)
      .subscribe((data) => {
        this.loadingService.refreshIsLoading(false);
        this.showAddForm = false;
      }, (error) => {
        this.loadingService.refreshIsLoading(false);
      });
  }

  public cancel() {
    this.showAddForm = false;
  }

  public async remove(store: Customer) {
    const shouldDelete = await this.showConfirmationModal(store);
    if(shouldDelete) {
      this.loadingService.refreshIsLoading(true);
      this.customerService.removeCustomerLink(store.attributes.CustomerToCustomerID, this.parentCustomerId)
        .subscribe((data) => {
          this.loadingService.refreshIsLoading(false);
        }, (error) => {
          this.loadingService.refreshIsLoading(false);
        });
    }
  }

  public async showConfirmationModal(store: Customer) {
    const initialState = {
      enableConfirm: true,
      warnings: [`Do you really want to remove ${store.attributes.FullName} from Stores?`],
      title: 'Confirm Deletion',
      confirmMessage: "Delete Customer Information",
    };

    this.bsModalRef = this.bsModalService.show(WarningModalComponent, {class: 'modal-lg', initialState});

    return (await firstValueFrom(this.bsModalRef.content.onClose.pipe(take(1))))["confirm"];
  }

}
