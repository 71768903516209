import {Component, OnDestroy, ViewChild} from '@angular/core';
import {InvoiceLineItem} from '../../../../models/invoice-line-item.interface';
import {InventoryItem} from '../../../../models/inventory-item.interface';
import {APP_SETTING_NAMES, SMARTOP_MOUNTS, SMARTOP_RADII, SMARTOP_SKIRTING} from '../../../../utils/constants.util';
import {allKeysAreDefined} from '../../../../utils/object.util';
import {computeMeasurements} from '../../../../utils/invoice.util';
import {LineItem} from '../../common/line-item';
import {takeUntil} from 'rxjs/operators';
import {TypeaheadMatch} from "ngx-bootstrap/typeahead";

@Component({
  selector: 'jet-invoice-smartop-item',
  templateUrl: './invoice-smartop-item.component.html',
  styleUrls: ['./invoice-smartop-item.component.scss']
})
export class InvoiceSmartopItemComponent extends LineItem implements OnDestroy {
  public smartopInventoryItems: InventoryItem[] = [];
  public smartopRadiiCodes: any[] = [];
  public smartopSkirtingCodes: any[] = [];
  public smartopMountCodes: any[] = [];
  public lengthMeasurements: number[] = [];
  public widthMeasurements: number[] = [];
  public hingeMeasurements: number[] = [];
  public justAdded: boolean = false;
  public selectedRadius: string = '';
  public selectedSkirting: string = '';
  public selectedMount: string = '';
  public selectedCommodityCode: string = '';
  public editorConfig = {
    'editable': true,
    'spellcheck': true,
    'height': 'auto',
    'minHeight': '400',
    'maxHeight': '400',
    'sanitize': false,
    'width': 'auto',
    'minWidth': '0',
    'translate': 'no',
    'enableToolbar': true,
    'showToolbar': false,
    'placeholder': 'Enter text here...',
    'imageEndPoint': '',
    'outline': true,
    'toolbarHiddenButtons': [
      [
        'undo',
        'redo',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

  @ViewChild("firstInput") firstInput;

  protected customInit() {
    this.initInventoryItems();
    this.initMeasurements();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected customInitCodeProperties(codes) {
    this.smartopRadiiCodes = codes[SMARTOP_RADII];
    this.smartopSkirtingCodes = codes[SMARTOP_SKIRTING];
    this.smartopMountCodes = codes[SMARTOP_MOUNTS];
    this.editItemCodeDefaults();
    this.setTypeaheadValues();
  }

  protected customEditItemSetup() {
    this.editItemMeasurementDefaults();
    this.editItemCodeDefaults();
  }

  private initInventoryItems() {
    this.inventoryItemService.getObservableByType('smartop').pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((inventoryItems: InventoryItem[]) => {
      if (!inventoryItems) {
        return;
      }
      this.smartopInventoryItems = inventoryItems.sort((a, b) => {
        return a.attributes.Description.localeCompare(b.attributes.Description);
      });
    });
  }

  private initMeasurements() {
    const appSettingNames: string[] = [
      APP_SETTING_NAMES.MAXIMUM_SMARTOP_LENGTH,
      APP_SETTING_NAMES.MAXIMUM_SMARTOP_WIDTH,
      APP_SETTING_NAMES.MINIMUM_SMARTOP_LENGTH,
      APP_SETTING_NAMES.MINIMUM_SMARTOP_WIDTH,
      APP_SETTING_NAMES.SMARTOP_LENGTH_INTERVAL,
      APP_SETTING_NAMES.SMARTOP_WIDTH_INTERVAL
    ];

    this.applicationSettingService.applicationSettings.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((appSettings) => {
      if (allKeysAreDefined(appSettings, appSettingNames)) {
        this.widthMeasurements = computeMeasurements(
          +appSettings[APP_SETTING_NAMES.MINIMUM_SMARTOP_WIDTH].attributes.Value,
          +appSettings[APP_SETTING_NAMES.MAXIMUM_SMARTOP_WIDTH].attributes.Value,
          +appSettings[APP_SETTING_NAMES.SMARTOP_WIDTH_INTERVAL].attributes.Value
        );
        this.lengthMeasurements = computeMeasurements(
          +appSettings[APP_SETTING_NAMES.MINIMUM_SMARTOP_LENGTH].attributes.Value,
          +appSettings[APP_SETTING_NAMES.MAXIMUM_SMARTOP_LENGTH].attributes.Value,
          +appSettings[APP_SETTING_NAMES.SMARTOP_LENGTH_INTERVAL].attributes.Value
        );
        this.editItemMeasurementDefaults();
      }
    });
  }

  public setLength(item: InvoiceLineItem, length: string) {
    this.updateHingeLength(item.attributes.CustomLength, parseFloat(length), item.attributes.CustomWidth);
    item.attributes.CustomLength = parseFloat(length);
  }

  public setWidth(item: InvoiceLineItem, width: string) {
    this.updateHingeLength(item.attributes.CustomWidth, parseFloat(width), item.attributes.CustomLength);
    item.attributes.CustomWidth = parseFloat(width);
  }

  // Radius, Skirting, Mount, and Commodity fields do not have their model bound to editItem directly
  // due to these fields using the Typeahead functionality. The typeahead uses objects as values instead
  // of just an ID field, so when the typeahead fields register a change these functions are called to
  // update the editItem

  public setRadius(match: TypeaheadMatch) {
    this.editItem.attributes.Codes_RadiusIDfk = match.item.CodeID;
  }

  public setSkirting(match: TypeaheadMatch) {
    this.editItem.attributes.Codes_SkirtingIDfk = match.item.CodeID;
  }

  public setMount(match: TypeaheadMatch) {
    this.editItem.attributes.Codes_MountIDfk = match.item.CodeID;
  }

  public setCommodityCode(match: TypeaheadMatch) {
    this.editItem.attributes.CommodityCode = match.item.CodeDesc;
  }

  private updateHingeLength(oldNum: number, newNum: number, otherNum: number) {

    this.hingeMeasurements = [newNum, otherNum];

    this.hingeMeasurements = this.hingeMeasurements.sort((num1, num2) => {
      return num1 - num2;
    });

    if (newNum === otherNum) {
      this.editItem.attributes.HingeLength = newNum;

      return;
    }

    if (this.editItem.attributes.HingeLength === otherNum && otherNum !== oldNum) {
      return;
    }

    if (oldNum == newNum) {
      return;
    }

    this.editItem.attributes.HingeLength = 0;
  }

  private editItemCodeDefaults() {
    if (this.editItem.attributes.Codes_RadiusIDfk === 0) {
      this.editItem.attributes.Codes_RadiusIDfk = this.smartopRadiiCodes[0].CodeID;
    }

    if (this.editItem.attributes.Codes_SkirtingIDfk === 0) {
      this.editItem.attributes.Codes_SkirtingIDfk = this.smartopSkirtingCodes[0].CodeID;
    }

    if (this.editItem.attributes.Codes_MountIDfk === 0) {
      this.editItem.attributes.Codes_MountIDfk = this.smartopMountCodes[0].CodeID;
    }
  }

  private editItemMeasurementDefaults() {
    if (this.editItem.id === '0') {
      this.editItem.attributes.CustomWidth = this.widthMeasurements[0];
    }

    if (this.editItem.id === '0') {
      this.editItem.attributes.CustomLength = this.lengthMeasurements[0];
    }

    this.updateHingeLength(this.editItem.attributes.CustomWidth, this.editItem.attributes.CustomWidth, this.editItem.attributes.CustomLength);
    this.updateHingeLength(this.editItem.attributes.CustomLength, this.editItem.attributes.CustomLength, this.editItem.attributes.CustomWidth);
  }

  private setTypeaheadValues() {
    this.selectedRadius = this.findMatchingCode(this.smartopRadiiCodes, 'Codes_RadiusIDfk');
    this.selectedSkirting = this.findMatchingCode(this.smartopSkirtingCodes, 'Codes_SkirtingIDfk');
    this.selectedMount = this.findMatchingCode(this.smartopMountCodes, 'Codes_MountIDfk');
    this.selectedCommodityCode = this.editItem.attributes.CommodityCode;
  }

  /**
   * Given a set of Codes with CodeID and CodeDesc properties, find the matching one based on a
   * foreign key ID. Return the CodeDesc if we find a match.
   *
   * @param codes
   * @param codeFK
   */
  private findMatchingCode(codes: any[], codeFK: string)
  {
    let matches = codes.filter((item) => {
      return item.CodeID == this.editItem.attributes[codeFK];
    });

    if (matches.length) {
      return matches[0].CodeDesc;
    }

    return '';
  }

  protected customIsValid(): string[] {
    const errors: string[] = super.customIsValid();

    if (this.editItem.attributes.InventoryItemIDfk === 0) {
      errors.push('Please select a color');
    }

    if (this.editItem.attributes.HingeLength === 0) {
      errors.push('Please select a hinge length');
    }

    return errors;
  }

  adjustScreen() {
    if(this.justAdded) {
      this.justAdded = false;

      let distance = this.getElementDistanceFromBottom(this.firstInput.nativeElement);
      if(distance < 400){
        window.scrollBy(0, 400 - distance);
      }
    }
  }

  getElementDistanceFromBottom(element) {
    let domRect = element.getBoundingClientRect();
    return window.innerHeight - domRect.bottom;
  }

  added() {
    this.justAdded = true;
  }

  makeVisible() {
    this.editorConfig.showToolbar = true;
  }
}
