import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';

import { allKeysAreDefined, keysNotDefined } from '../../utils/object.util';
import { ResourceService } from '../resource.service';
import { ApplicationSetting } from '../../models/application-setting.interface';

@Injectable()
export class ApplicationSettingService {
  private applicationSettingsSource: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public applicationSettings: Observable<any> = this.applicationSettingsSource.asObservable();

  constructor(private resourceService: ResourceService) {}

  loadApplicationSettingsByName(names: string[]) {
    let currentAppSettings: any = this.applicationSettingsSource.getValue();
    if (allKeysAreDefined(currentAppSettings, names)) {
      return this.applicationSettings;
    }
    const settingsToFetch: string[] = keysNotDefined(currentAppSettings, names);
    const params: any = {
      criteria: { name: settingsToFetch }
    };
    this.resourceService.getResources<ApplicationSetting>('application-settings', params)
      .subscribe((newApplicationSettings: ApplicationSetting[]) => {
        if (currentAppSettings === null) {
          currentAppSettings = {};
        }
        newApplicationSettings.forEach((appSetting: ApplicationSetting) => {
          currentAppSettings[appSetting.attributes.Name] = appSetting;
        });
        this.applicationSettingsSource.next({...currentAppSettings});
      })
    ;

    return this.applicationSettings;
  }
}
