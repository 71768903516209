<div class="tab-pane">
  <form>
    <div class="row">
      <div class="col-md-7 col-sm-12 col">
        <jet-history-invoices [customer]="customer"></jet-history-invoices>
      </div>
      <div class="col-md-5 col-sm-12 col">
       <jet-history-products [customer]="customer"></jet-history-products>
      </div>
    </div>
  </form>
</div>