import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Customer } from '../../models/customer.interface';
import { ResourceService } from '../resource.service';
import { Pagination } from '../../interfaces/pagination';

export interface CustomerSearchResponse {
  data: Customer[];
  pagination: Pagination;
}

@Injectable()
export class CustomerSearchService {
  private resultsSource: BehaviorSubject<CustomerSearchResponse> = new BehaviorSubject<CustomerSearchResponse>(null);
  public results: Observable<CustomerSearchResponse>             = this.resultsSource.asObservable();
  public nextPage                                                = new EventEmitter<number>();

  public loading = false;

  constructor(private resourceService: ResourceService) {
  }

  getResources(params) {

    return new Promise((resolve, reject) => {

      this.loading = true;


      this.resourceService.getResources('customers', params, true)
        .subscribe({
          next: (results: any) => {

            if (results instanceof Array && results.length === 0) {
              reject();
            } else if (Object.entries(results.data).length === 0 && results.data.constructor === Object) {
              this.resultsSource.next({data: [], pagination: {currentPage: 0, totalPages: 0}});
            } else {
              this.resultsSource.next(results);
            }

            this.loading = false;
            resolve(results);
          },
          error: (error: Response) => {
            this.loading = false;

            reject();
          }
        });
    });
  }

  nextPageEvent(page: number) {
    this.resultsSource.next({
      data: [],
      pagination: this.resultsSource.getValue().pagination,
    });
    this.nextPage.emit(page);
  }
}
