
import {of as observableOf, BehaviorSubject, Observable} from 'rxjs';

import {filter, mergeMap} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {Customer} from '../../models/customer.interface';
import {ResourceService} from '../resource.service';
import { toDateString } from '../../utils/date.util';
import {
  CUSTOMER_LINK_TYPE_COMPANY_HIERARCHY, CUSTOMER_LINK_TYPE_INDIVIDUAL_HIERARCHY, CUSTOMER_TYPE_CUSTOMER,
  CUSTOMER_TYPE_INDIVIDUAL, CUSTOMER_TYPE_VENDOR, SOURCE_TYPE_CUSTOMER
} from '../../utils/constants.util';
import { copyObject } from '../../utils/json.util';
import { DEFAULT_INDIVIDUAL_CUSTOMER, DEFAULT_STORE_CUSTOMER } from '../../mocks/customer.mock';
import { CustomerToCustomer } from '../../models/customer-to-customer.interface';
import { DEFAULT_CUSTOMER_TO_CUSTOMER } from '../../mocks/customer-to-customer.mock';
import { ObservableResource } from './observable-resource.interface';
import { Address } from '../../models/address.interface';
import { Contact } from '../../models/contact.interface';
import { PaymentMethod } from '../../models/payment-method.interface';
import {filterOutMockAddresses, filterOutMockContacts, filterOutMockPaymentMethods} from '../../utils/mock.util';
import {ALL_DEFAULT_ADDRESSES} from "../../mocks/address.mock";
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../config/domain.interface";
declare let document: Document;

@Injectable()
export class CustomerService implements ObservableResource<Customer> {
  private customerSource: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(null);
  public customer: Observable<Customer> = this.customerSource.asObservable();
  constructor(private resourceService: ResourceService,
              @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
              ) {}

  clearResource = (): void => {
    this.refreshResource(null);
  };

  refreshResource = (resource: Customer): void => {
    if (resource) {
      document.title = resource.attributes.Organization;
    }
    this.customerSource.next(resource);
  };

  isResourceDefined = () => {
    return this.customerSource.getValue() !== null;
  };

  /**
   * Fetches the Customer, returning the observable as is if the last emitted value was non-null and the invoice id matches.
   * Unless that is you want to force a refresh.
   * @param id
   * @param forceRefresh
   * @returns {Observable<Invoice>}
   */
  getResource = (id: number, forceRefresh: boolean = false): Observable<Customer> => {

    if (!forceRefresh && this.isResourceDefined() && +this.customerSource.value.id === id) {
      return this.customer;
    }

    this.clearResource();

    this.resourceService.getResource<Customer>('customers', id).subscribe((customer: Customer) => {
      this.refreshResource(customer);
    });

    return this.customer;
  };

  getOneCustomer = (id: number) => {
    return this.resourceService.getResource<Customer>('customers', id);
  };

  getChildren(customer:Customer): Observable<Customer[]> {
    return this.resourceService.getResources<Customer>('customers/'+ customer.id+ '/children');
  }

  // Based off of Pentad Customer.AddAffiliate for CustomersToCustomers creation
  saveIndividualCustomer(individual: Customer, parentCustomerId: number, parentCustomerType: number, rootCustomerId, forceRefresh = false): Observable<Customer> {
    const { addresses, contacts } = individual.relationships;
    return this.saveCustomer(individual, [], addresses, contacts, []).pipe(
      mergeMap((customer: Customer) => {
        if (customer) {
          return this.addAffiliate(customer, parentCustomerId, parentCustomerType, rootCustomerId);
        }
        return observableOf(null);
      }),
      // Had to return Observable<Customer> even in the case of no forced refresh. Should find a different operator that doesn't require this.
      mergeMap((customerLink: CustomerToCustomer) => forceRefresh ? this.getResource(rootCustomerId, true) : observableOf(copyObject(DEFAULT_INDIVIDUAL_CUSTOMER))),);
  }

  createStoreCustomer(store: Customer, parentCustomerId: number, parentCustomerType: number, rootCustomerId, forceRefresh = false): Observable<Customer> {
    const body: any = {
      data: { attributes:  store.attributes }
    };
    return this.resourceService.createResource<Customer>('customers', body).pipe(
      mergeMap((createdStore: Customer) => this.addAffiliate(createdStore, parentCustomerId, parentCustomerType, rootCustomerId)),
      // Had to return Observable<Customer> even in the case of no forced refresh. Should find a different operator that doesn't require this.
      mergeMap((customerLink: CustomerToCustomer) => forceRefresh ? this.getResource(rootCustomerId, true) : observableOf(copyObject(DEFAULT_STORE_CUSTOMER))),);
  }

  public addAffiliate = (newAffiliate: Customer, parentCustomerId: number, parentCustomerType: number, rootCustomerId): Observable<CustomerToCustomer> => {
    const affiliateIsIndividual = newAffiliate.attributes.CustomerType === CUSTOMER_TYPE_INDIVIDUAL;
    if (parentCustomerType === CUSTOMER_TYPE_INDIVIDUAL) {
      const computedParentCustomerId = affiliateIsIndividual ? parentCustomerId : newAffiliate.attributes.CustomerID;
      const computedChildCustomerId = affiliateIsIndividual ? newAffiliate.attributes.CustomerID : parentCustomerId;
      return this.createCustomerLink(computedParentCustomerId, computedChildCustomerId, rootCustomerId, CUSTOMER_LINK_TYPE_INDIVIDUAL_HIERARCHY);
    } else if (parentCustomerType === CUSTOMER_TYPE_CUSTOMER || parentCustomerType === CUSTOMER_TYPE_VENDOR) {
      const computedRootCustomerId = affiliateIsIndividual ? parentCustomerId : rootCustomerId;
      const computedLinkType = affiliateIsIndividual ? CUSTOMER_LINK_TYPE_INDIVIDUAL_HIERARCHY : CUSTOMER_LINK_TYPE_COMPANY_HIERARCHY;
      return this.createCustomerLink(parentCustomerId, newAffiliate.attributes.CustomerID, computedRootCustomerId, computedLinkType);
    } else {
      console.error('There was an invalid CustomerType');
      return observableOf(DEFAULT_CUSTOMER_TO_CUSTOMER);
    }
  };

  public createCustomerLink = (source1: number, source2: number, source3: number, linkType: number): Observable<CustomerToCustomer> => {
    return this.resourceService.createResource<CustomerToCustomer>('customer-to-customers', {
      Customers_SourceIDfk1: source1,
      Customers_SourceIDfk2: source2,
      Customers_SourceIDfk3: source3,
      LinkType: linkType,
    });
  };

  public removeCustomerLink(customerToCustomerID: number, customerIdToRefetch: number) {
    return this.resourceService.deleteResource('customer-to-customers', customerToCustomerID).pipe(
      mergeMap((data) => this.getResource(customerIdToRefetch, true)),
      filter((data) => {
        return data !== null;
      }),)
    ;
  }

  updateCustomer(customer: Customer, productLineCodes: any[], forceRefresh: boolean = true): Observable<Customer> {
    const body = this.prepareCustomerBody(customer, productLineCodes);
    return this.resourceService.updateResource<Customer>('customers', +customer.id, body).pipe(
      mergeMap(() => this.getResource(+customer.id, true)),
      filter(data => data !== null),)
    ;
  }

  saveCustomer(customer: Customer, productLineCodes: any[], addresses: Address[] = [], contactTypes: Contact[] = [], paymentMethods: PaymentMethod[] = [], phoneNumbers: Contact[] = [], faxNumbers: Contact[] = []): Observable<Customer> {
    const body = this.prepareCustomerBody(customer, productLineCodes, addresses, contactTypes, paymentMethods, phoneNumbers, faxNumbers);
    if (+customer.id) {
      return this.resourceService.updateResource<Customer>('customers', +customer.id, body);
    }
    return this.resourceService.createResource<Customer>('customers', body);
  }

  private prepareCustomerBody(customer: Customer, productLineCodes: any[], addresses: Address[] = [], contactTypes: Contact[] = [], paymentMethods: PaymentMethod[] = [], phoneNumbers: Contact[] = [], faxNumbers: Contact[] = []) {
    const body: any = {
      data: { attributes: copyObject(customer.attributes) }
    };
    delete(body.data.attributes.CustomerToCustomerID);
    delete(body.data.attributes.CustomerToCustomerSequence);
    const date = new Date();
    const endDateTime = toDateString(date);
    const customerToCodeResources = productLineCodes
      .filter((code) => code.ProductLineActive || code.CustomerToCodeID)
      .map((code: any) => this.prepCustomerToCode(customer.attributes.CustomerID, endDateTime, code))
    ;
    body.data.attributes.activeProductLineCustomersToCodes = customerToCodeResources;
    if (customer.relationships && customer.relationships.additionalValues) {
      body.data.attributes.additionalValues = customer.relationships.additionalValues.map((resource) => {
        return resource.attributes;
      });
    }
    const allDefaultAddresses = ALL_DEFAULT_ADDRESSES;
    allDefaultAddresses[0].attributes.Country = this.config.defaultCounty;
    allDefaultAddresses[1].attributes.Country = this.config.defaultCounty;
    allDefaultAddresses[2].attributes.Country = this.config.defaultCounty;

    const filteredAddresses: Address[] = filterOutMockAddresses(addresses, allDefaultAddresses);
    if (filteredAddresses.length) {
      body.data.attributes.addresses = filteredAddresses.map(resource => this.prepResourceRelatedToSource(resource));
    }
    const filteredContacts: Contact[] = filterOutMockContacts(contactTypes);
    if (filteredContacts.length) {
      body.data.attributes.contacts = filteredContacts.map(resource => this.prepResourceRelatedToSource(resource));
    }
    const filteredPaymentMethods: PaymentMethod[] = filterOutMockPaymentMethods(paymentMethods);
    if (filteredPaymentMethods) {
      body.data.attributes.paymentTypes = filteredPaymentMethods.map(resource => this.prepResourceRelatedToSource(resource));
    }
    if (phoneNumbers) {
      body.data.attributes.phoneNumbers = phoneNumbers.map(resource => this.prepResourceRelatedToSource(resource));
    }
    if (faxNumbers) {
      body.data.attributes.faxNumbers = faxNumbers.map(resource => this.prepResourceRelatedToSource(resource));
    }
    body.data.attributes.CustomerActive = body.data.attributes.CustomerActive ? '1' : '0';
    return body;
  }

  private prepResourceRelatedToSource(resource) {
    const attrs: any = { ...resource.attributes };
    attrs.SourceType = SOURCE_TYPE_CUSTOMER;
    delete(attrs.SourceIDfk);
    return attrs;
  }

  private prepCustomerToCode(customerId: number, endDateTime: string, code: any) {
    const attrs: any = {
      CodeIDfk: code.CodeID,
      Codes_LinkTypeIDfk: code.CodeIDfk,
      // CustomerIDfk: customerId, TODO: Should be handled by Symfony Form
    };
    if (!code.ProductLineActive) {
      attrs.EndDate = endDateTime;
    }
    if (code.CustomerToCodeID) {
      attrs.CustomerToCodeID = code.CustomerToCodeID;
    }
    return attrs;
  }


}
