import {Contact} from "../models/contact.interface";
import {Address} from "../models/address.interface";
import {ALL_DEFAULT_CONTACTS} from "../mocks/contacts.mock";
import {ALL_DEFAULT_ADDRESSES} from "../mocks/address.mock";
import {compareObjects} from "./json.util";
import {PaymentMethod} from "../models/payment-method.interface";
import {ALL_DEFAULT_PAYMENT_METHODS} from "../mocks/payment-method.mock";

export function filterOutMockContacts(contacts: Contact[]) {
  return contacts.filter((contact: Contact) => {
    return !ALL_DEFAULT_CONTACTS.some((defaultContact: Contact) => {
      return compareObjects(defaultContact, contact);
    });
  });
}

export function filterOutMockAddresses(addresses: Address[], allDefaultAddresses) {
  return addresses.filter((address: Address) => {
    return !allDefaultAddresses.some((defaultAddress: Address) => {
      return compareObjects(defaultAddress, address);
    });
  });
}

export function filterOutMockPaymentMethods(methods: PaymentMethod[]) {
  return methods.filter((method: PaymentMethod) => {
    return !ALL_DEFAULT_PAYMENT_METHODS.some((defaultPaymentMethod: PaymentMethod) => {
      return compareObjects(defaultPaymentMethod, method);
    });
  });
}
