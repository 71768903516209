<div class="tab-pane">
    <ng-container *ngIf="invoice">
        <div>
            <button class="btn btn-primary" (click)="first()">
                <i class="fa fa-step-backward text-white"></i>
            </button>
            <button class="btn btn-primary" (click)="previous()">
                <i class="fa fa-play text-white fa-flip-horizontal"></i>
            </button>
            <button class="btn btn-primary" (click)="next()">
                <i class="fa fa-play text-white"></i>
            </button>
            <button class="btn btn-primary" (click)="last()">
                <i class="fa fa-step-forward text-white"></i>
            </button>
            <span>
        {{ invoice?.attributes.CurrentPosition }} of {{ invoice?.attributes.NumberOfInvoices }}
      </span>
        </div>
        <div class="row">
            <div class="col-md-8 col-sm-12">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-4">
                            <jet-quick-view-bill-to
                                    [company]="invoice?.relationships?.billToCustomer?.attributes.FullName"
                                    [contact]="invoice?.relationships?.billToContactPerson?.attributes.FullName"
                                    [phone]="invoice?.relationships?.billToPhone?.attributes.ContactValue"
                                    [fax]="invoice?.relationships?.billToFax?.attributes.ContactValue"
                                    [address]="invoice?.relationships?.billToAddress"
                                    [message]="invoice?.attributes.InvoiceMessage"
                            >
                            </jet-quick-view-bill-to>
                        </div>
                        <div class="col-md-4">
                            <jet-quick-view-ship-to
                                    [company]="invoice?.relationships?.shipToCustomer?.attributes.FullName"
                                    [contact]="invoice?.relationships?.shipToContactPerson?.attributes.FullName"
                                    [phone]="invoice?.relationships?.shipToPhone?.attributes.ContactValue"
                                    [fax]="invoice?.relationships?.shipToFax?.attributes.ContactValue"
                                    [address]="invoice?.relationships?.shipToAddress"
                                    [message]="invoice?.attributes.ShippingInstructions"
                            >
                            </jet-quick-view-ship-to>
                        </div>
                        <div class="col-md-4">
                            <jet-quick-view-details
                                    [terms]="invoice?.relationships?.terms?.attributes.CodeDesc"
                                    [taxStatus]="invoice?.relationships?.taxStatus?.attributes.CodeDesc"
                                    [taxRate]="invoice?.attributes.TaxPercentage"
                                    [vatNumber]="vatNumber"
                                    [salesPerson]="invoice?.relationships?.salesPerson?.attributes.FullName"
                                    [processor]="invoice?.relationships?.processor?.attributes.FullName"
                                    [marketingSource]="invoice?.relationships?.marketingSourceCode?.attributes.CodeDesc"
                            >
                            </jet-quick-view-details>
                        </div>
                        <div class="col-md-4">
                            <jet-quick-view-invoice
                                    [invoiceId]="invoice?.attributes.InvoiceID"
                                    [invoiceNumber]="invoice?.attributes.InvoiceNumber"
                                    [status]="invoice?.relationships?.status?.attributes.CodeDesc"
                                    [pendingReason]="invoice?.relationships?.pendingReason?.attributes.CodeDesc"
                                    [dateInvoiced]="invoice?.attributes.DateInvoiced"
                                    [purchaseOrderNumber]="invoice?.attributes.PurchaseOrderNumber"
                            >
                            </jet-quick-view-invoice>
                        </div>
                        <div class="col-md-8">
                            <h4 class="quick-view-title"><span>NOTES</span><i class="fa fa-lg fa-sticky-note-o"></i></h4>
                            <hr>
                            <p>
                              {{ invoice?.attributes.Notes }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-sm-12">
                <jet-quick-view-freight
                        [company]="invoice?.relationships?.freightVendor?.attributes.CodeDesc"
                        [shipVia]="invoice?.relationships?.freightBucket?.attributes.CodeDesc"
                        [freightCost]="invoice?.attributes.FreightCost"
                        [handlingCharge]="invoice?.attributes.Handling"
                        [additionalCharge]="invoice?.attributes.AdditionalCharges"
                        [shippingCredit]="invoice?.attributes.ShippingCredit"
                        [dateShipped]="invoice?.attributes.DateShipped"
                        [trackingNumber]="invoice?.attributes.FreightTrackingNumber"
                        [promoCode]="invoice?.attributes.PromoDiscountPercent"
                        [promoDiscountTotal]="invoice?.attributes.PromoDiscountTotal"
                        [numberOfPallets]="invoice?.attributes.FreightNumberOfPallets"
                        [computedFreightInfo]="computedFreightInfo"
                        [isSmartopInvoice]="isSmartopInvoice"
                >
                </jet-quick-view-freight>
                <jet-quick-view-payments [payments]="invoice?.relationships?.payments"></jet-quick-view-payments>
            </div>
            <div class="col-md-12 col-sm-12">
                <jet-quick-view-smartop-items *ngIf="isSmartopInvoice" [items]="smartopItems"></jet-quick-view-smartop-items>
                <jet-quick-view-inventory-items [isSmartopInvoice]="isSmartopInvoice" [items]="inventoryItems"></jet-quick-view-inventory-items>
                <jet-quick-view-other-items [items]="otherItems"></jet-quick-view-other-items>
            </div>
            <br style="clear:both;">

            <div *ngIf="invoice?.relationships?.status && invoice?.relationships?.status.attributes.CodeDesc === 'Cancelled'" id="canceled_order">Cancelled</div>
        </div>
    </ng-container>

    <div *ngIf="!invoice">
        <div class="row justify-content-md-center">
            <div class="col-sm-3"><i class="fa fa-spinner fa-spin fa-1x"></i> Loading ...</div>
        </div>
    </div>

</div>
