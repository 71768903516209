<div class="modal-header">
  <h4 class="modal-title pull-left">Return Items</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h4 style="margin-left: 0;">{{itemName}}</h4>
  <div class="row">
    <div class="col-sm-12"><label style="margin-right: 5px;">Return quantity: </label> <input type="number" [(ngModel)]="returnAmount" min="{{min}}" max="{{max}}" style="width: 70px;text-align: right;"></div>
  </div>
</div>
<div class="modal-footer">
  <button *ngIf="enableConfirm" type="button" class="btn btn-danger" (click)="confirm()">Confirm</button>
  <button *ngIf="enableConfirm" type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
</div>
