import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import { ResourceService } from '../resource.service';
import { User } from '../../models/user.interface';
import * as moment from "moment";

@Injectable()
export class UserService {
  private userSource: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public user: Observable<User> = this.userSource.asObservable();

  constructor(private resourceService: ResourceService) {}

  getResource(forceRefresh: boolean = false): Observable<User> {

    if (this.pentadTokenIsValid() && !forceRefresh) {
      return this.user;
    }

    this.clearResource();

    this.resourceService.getUser().subscribe((user: User) => {
      const resource = user as any;
      resource.forceRefreshed = forceRefresh;
      this.userSource.next(user);
    });

    return this.user;
  }

  clearResource(): void {
    this.refreshResource(null);
  }

  refreshResource(user: User): void {
    this.userSource.next(user);
  }

  isResourceDefined() {
    return this.userSource.getValue() !== null;
  }

  currentUser(): User {
    return this.userSource.getValue();
  }

  pentadTokenIsValid() {
    const currentUser: User = this.currentUser();
    if (!currentUser) {
      return false;
    }

    const userFetchedAtMoment: moment.Moment = moment(new Date(currentUser.attributes.fetched_at));
    const todayMoment: moment.Moment = moment(new Date());

    return todayMoment.isSame(userFetchedAtMoment, 'day');
  }
}
