<div class="modal-header">
    <h4 class="modal-title pull-left">Invoice Details Notes</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>
        {{ notes }}
    </p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-xs btn-danger" (click)="bsModalRef.hide()">Close</button>
</div>
