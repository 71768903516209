import { Component, OnInit } from '@angular/core';
import {Button} from '../../shared/components/warning-modal/button';
import {Subject} from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-truck-label-modal',
  templateUrl: './truck-label-modal.component.html',
  styleUrls: ['./truck-label-modal.component.scss']
})
export class TruckLabelModalComponent implements OnInit {
  public onClose: Subject<number>;
  public buttons: Array<Button> = [];

  public truckLabelCount = 0;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.onClose = new Subject();

    const cancel = new Button();
    cancel.id = 'cancel';
    cancel.text = 'Close';
    cancel.cancel = true;
    cancel.btnClass = 'btn-primary';

    const print = new Button();
    print.id = 'print';
    print.text = 'Print';
    print.confirm = true;
    print.btnClass = 'btn-danger';

    this.buttons.push(print, cancel);
  }

  confirm() {
    this.onClose.next(this.truckLabelCount);
    this.bsModalRef.hide();
  }

  close() {
    this.bsModalRef.hide();
  }

}
