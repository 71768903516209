export const DEFAULT_PAYMENT_METHOD = {
  id: '0',
  type: 'PaymentMethod',
  attributes: {
    PaymentMethodID: 0,
    SourceIDfk: 0,
    SourceType: -1,
    Description: '',
    Number: '',
    Date: '',
    NameOn: '',
    CreditCardSecurityCode: '',
    CheckRoutingNumber: '',
    CheckAccountNumber: '',
    PaymentMethodType: 0,
    StartDate: '',
    EndDate: '',
    AddressLine1: '',
    AddressLine2: '',
    AddressCity: '',
    AddressState: '',
    AddressZip: '',
    AddressCountry: '',
    PhoneNumber: '',
    PaymentMethodSequence: 0,
    PaymentMethodActive: 1,
  }
};

export const ALL_DEFAULT_PAYMENT_METHODS = [
  DEFAULT_PAYMENT_METHOD,
];
