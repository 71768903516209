import {Component, ErrorHandler, Inject, Input, ViewChild} from '@angular/core';
import {CountryService} from '../../../../services/observables/country.service';
import {ResourceService} from '../../../../services/resource.service';
import {ToSection} from '../../common/to-section';
import {copyObject, getSingleResourceRelation} from '../../../../utils/json.util';
import {
  CUSTOMER_TYPE_INVOICE_CUSTOMER,
  INVOICE_STATUS_CANCELLED,
  INVOICE_STATUS_COMPLETED
} from '../../../../utils/constants.util';
import {Customer} from '../../../../models/customer.interface';
import {DEFAULT_FAX_CONTACT, DEFAULT_PHONE_CONTACT} from '../../../../mocks/contacts.mock';
import {DEFAULT_ADDRESS} from '../../../../mocks/address.mock';
import {InvoiceConfirmation} from '../../../../models/invoice-confirmation.interface';
import {CustomerService} from '../../../../services/observables/customer.service';
import {InvoiceService} from '../../../../services/observables/invoice.service';
import {LoadingService} from '../../../../services/observables/loading.service';
import {WarningModalComponent} from '../../../shared/components/warning-modal/warning-modal.component';
import {Contact} from '../../../../models/contact.interface';
import {LoggingService} from "../../../../services/logging.service";
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";
import {takeUntil} from 'rxjs/operators';
import {Address} from "../../../../models/address.interface";
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';


@Component({
  selector: 'jet-invoice-ship-to',
  templateUrl: './invoice-ship-to.component.html',
  styleUrls: ['./invoice-ship-to.component.scss']
})
export class InvoiceShipToComponent extends ToSection {
  @Input() public confirmation: InvoiceConfirmation = null;
  public bsModalRef: BsModalRef;

  @ViewChild('contactSelect') contactSelect;
  public shipToContactPhoneNumbers: Contact[];
  public shipToContactAddresses: Address[];

  public parentCustomer: Customer;

  constructor(
    private countryService: CountryService,
    private resourceService: ResourceService,
    private customerService: CustomerService,
    private invoiceService: InvoiceService,
    private loadingService: LoadingService,
    private bsModalService: BsModalService,
    private logService: LoggingService,
    private errorHandler: ErrorHandler,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
  ) {
    super('ship', countryService, resourceService, customerService, logService, config);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected customAssignments() {
    // TODO: Shipping specific should put somewhere in the child class
    if (this.to === 'ship') {
      const toCustomer = getSingleResourceRelation<Customer>(this.invoice.relationships, this.toCustomer);
      if (toCustomer && toCustomer.attributes.CustomerType === CUSTOMER_TYPE_INVOICE_CUSTOMER) {
        this.invoice.attributes.OneTimeShip = true;
        const shipToCustomer = getSingleResourceRelation<Customer>(this.invoice.relationships, 'shipToCustomer');
        const shipToContactPerson = getSingleResourceRelation<Customer>(this.invoice.relationships, 'shipToContactPerson');
        if (shipToCustomer) {
          this.invoice.attributes.OneTimeShipOrganization = this.invoice.relationships.shipToCustomer.attributes.Organization;
        }
        if (shipToContactPerson) {
          this.invoice.attributes.OneTimeShipFirstName = this.invoice.relationships.shipToContactPerson.attributes.FirstName;
          this.invoice.attributes.OneTimeShipLastName = this.invoice.relationships.shipToContactPerson.attributes.LastName;
        }
      } else {
        this.invoice.attributes.OneTimeShip = false;
        this.invoice.attributes.OneTimeShipOrganization = '';
        this.invoice.attributes.OneTimeShipFirstName = '';
        this.invoice.attributes.OneTimeShipLastName = '';
      }
    }

    // Add current shipToContact's phone numbers
    if (this.invoice.relationships.shipToContactPerson) {
      this.shipToContactPhoneNumbers = this.invoice.relationships.shipToContactPerson.relationships.phoneNumbers;
      this.shipToContactAddresses = this.invoice.relationships.shipToContactPerson.relationships.addresses;
    }
    // Add invoices shipToContact individual to contacts list
    if (this.invoice.relationships.shipToContactPerson !== undefined && this.contacts.indexOf(this.invoice.relationships.shipToContactPerson) === -1) {
      this.contacts.push(this.invoice.relationships.shipToContactPerson);
    }
  }

  public oneTimeShipChange(event) {
    // Only contacts and address change. Customer and Contact Person fields remain the same.
    if (event.target.checked) {
      this.contactChange(this.invoice.relationships.shipToPhone, DEFAULT_PHONE_CONTACT);
      this.contactChange(this.invoice.relationships.shipToFax, DEFAULT_FAX_CONTACT);
      const defaultAddress = copyObject(DEFAULT_ADDRESS);
      defaultAddress['attributes']['Country'] = this.config.defaultCounty;
      this.addressChange(this.invoice.relationships.shipToAddress, defaultAddress);
    }
  }

  public resetShipToContact() {
    this.invoice.attributes.OneTimeShip = false;
    this.customerFullName = this.customer.attributes.FullName;
    this.assignContact(this.customer);
  }

  public oneTimeShipClicked(event) {
    event.stopPropagation();
  }

  /**
   * In Pentad, any time we made a change to an invoice anywhere on the invoice and we hit a blue button that has an UPDATE function,
   * it would update whatever we actually changed, not just in a particular field.
   *
   */
  async updateMessage() {
    const loader = this.loadingService.newLoader().start();

    if (this.invoice.attributes.Codes_StatusIDfk != INVOICE_STATUS_COMPLETED
      && this.invoice.attributes.Codes_StatusIDfk != INVOICE_STATUS_CANCELLED) {
      // do the whole update
      await this.invoiceService.updateInvoice({
        reverse: false,
        reopen: false,
      }).pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe((data) => {
        loader.stop();
      }, (error) => {
        loader.stop();
        const message = [];
        for (const err of error.error.errors)  {
          message.push(err.detail);
        }
        this.loadingService.refreshIsLoading(false);
        this.bsModalRef = this.bsModalService.show(WarningModalComponent);
        this.bsModalRef.content.warnings = message;
      });
    } else {
      try {
        await this.invoiceService.updateInvoice({
          updateFieldsOnly: true,
        }, ["InvoiceMessage"]).toPromise();
        loader.stop();
      } catch (error) {
      this.errorHandler.handleError(error);
        loader.stop();
        const message = [];

        for (const err of error.error.errors)  {
          message.push(err.detail);
        }

        this.loadingService.refreshIsLoading(false);
        this.bsModalRef = this.bsModalService.show(WarningModalComponent);
        this.bsModalRef.content.warnings = message;
      }
    }

  }

  async resetSpecialInstructions() {

    const loader = this.loadingService.newLoader().start();

    this.resourceService.getResource<Customer>('customers', parseInt(this.invoice.relationships.billToCustomer.id) ).subscribe((customer: Customer) => {

      // Grab special instructions - Delivery instructions and/or Border Broker
      let specialInstructions = '';
      let additionalValues = customer.relationships.additionalValues;

      for(let additionalValue of additionalValues) {
        if(additionalValue.attributes.FieldName == "Delivery Instructions" && additionalValue.attributes.Value != null) {
          specialInstructions += additionalValue.attributes.Value + "\n";
        } else if(additionalValue.attributes.FieldName == "Border Broker" && additionalValue.attributes.Value != null) {
          specialInstructions += additionalValue.attributes.Value + "\n";
        }
      }

      this.invoice.attributes.DeliveryInstructions = specialInstructions;

      this.invoiceService.updateInvoice().toPromise();

      loader.stop();
    });
  }

  public async updateContact($event) {
    // await this.changeNumbers($event);
    let newCustomer = await this.customerService.getOneCustomer($event).toPromise();
    this.shipToContactPhoneNumbers = newCustomer.relationships.phoneNumbers;
    this.shipToContactAddresses = newCustomer.relationships.addresses;
  }

  async saveCustomer(id) {
    const loader = this.loadingService.newLoader();
    loader.start();
    await this.getCustomer(id);

    if (this.parentCustomer) {
      for (let i = 0; i < this.parentCustomer.relationships.addresses.length; i++) {
        if (this.parentCustomer.relationships.addresses[i].attributes.AddressID === this.invoice.relationships.shipToAddress.attributes.AddressID) {
          this.parentCustomer.relationships.addresses[i] = this.invoice.relationships.shipToAddress;
        }
      }

      for (let i = 0; i < this.parentCustomer.relationships.phoneNumbers.length; i++) {
        if (this.parentCustomer.relationships.phoneNumbers[i].attributes.ContactID === this.invoice.relationships.shipToPhone.attributes.ContactID) {
          this.parentCustomer.relationships.phoneNumbers[i] = this.invoice.relationships.shipToPhone;
        }
      }

      for (let i = 0; i < this.parentCustomer.relationships.faxNumbers.length; i++) {
        if (this.parentCustomer.relationships.faxNumbers[i].attributes.ContactID === this.invoice.relationships.shipToFax.attributes.ContactID) {
          this.parentCustomer.relationships.faxNumbers[i] = this.invoice.relationships.shipToFax;
        }
      }

      this.customerService.saveCustomer(this.parentCustomer, this.parentCustomer.relationships.productLines, this.parentCustomer.relationships.addresses, [], [], this.parentCustomer.relationships.phoneNumbers, this.parentCustomer.relationships.faxNumbers).pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(resp => {
        loader.stop();
        const bc = new BroadcastChannel('customer_' + id + '_invoice');
        bc.postMessage({
          message: 'Source Customer Has Been Updated From an Invoice. Please Refresh Your Webpage',
          customerId: id,
        });
        window.location.reload();
      }, error => {
        loader.stop();
      });
    }
  }

  async getCustomer(id) {
    this.customerService.getResource(parseInt(id)).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(data => {
      this.parentCustomer = data;
    });
  }

}
