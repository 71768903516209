<div class="card">
  <div class="card-heading" data-toggle="collapse" attr.data-target="#{{key}}-addresses">
    <h3 class="caps card-title">
      <span>addresses</span>
      <i class="fa fa-address-card-o fa-lg"></i>
      <a class="caret"></a>
    </h3>
    <div class="card-subheading float-right">
      <div class="text-right">
        <i class="fa fa-lg fa-plus" (click)="toggleShowAddFormOnClick($event)"></i>
      </div>
    </div>
  </div>
  <div class="card-body collapse show fivepad" id="{{key}}-addresses">
    <div cdkDropList (cdkDropListDropped)="drop($event)" class="jet-table">
      <ng-container *ngFor="let address of addresses" >
        <jet-address
          class="small jet-row row "
          [customerId]="customerId"
          [address]="address"
          [state]="STATE_VIEW" cdkDrag>
          <div slot="drag-handle" class="jet-column-reorder d-inline align-top p-1" cdkDragHandle>
            <i class="fas fa-grip-vertical"></i>
          </div>
        </jet-address>
      </ng-container>
      <ng-container *ngIf="showAddForm">
          <jet-address
            class="jet-row row"
            [customerId]="customerId"
            [address]="emptyAddress"
            [state]="STATE_ADD"
            (onAdd)="newAddressAddHandler($event)"
            (onCancel)="newAddressCancelHandler($event)">
          </jet-address>
      </ng-container>
    </div>
  </div>
</div>
