import { Pipe, PipeTransform  } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform (input: any, pattern: any, replacement: any): any {

    if (!this.isString(input) || this.isUndefined(pattern) || this.isUndefined(replacement)) {
      return input;
    }

    return input.replace(pattern, replacement);
  }

  isUndefined(value) {
    return value === null || typeof value === 'undefined';
  }

  isString (value) {
    return typeof value === 'string' || value instanceof String;
  }
}
