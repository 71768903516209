import { InvoiceLineItem } from '../models/invoice-line-item.interface';
import {
  INVOICE_LINE_ITEM_TYPE_INVENTORY, INVOICE_LINE_ITEM_TYPE_OTHER,
  INVOICE_LINE_ITEM_TYPE_SMARTTOP, INVOICE_LINE_ITEM_TYPE_RETURN
} from '../utils/constants.util';

const defaultAttrs: any = {
  InvoiceLineItemID: 0,
  InvoiceLineItemIDfk: 0,
  InvoiceIDfk: 0,
  InventoryItemIDfk: 0,
  InventoryItemAvailableQuantity: 0,
  InventoryItemNumber: '',
  Description: '',
  Note: '',
  LineItemType: 0,
  IsWarranty: 0,
  CommodityCode: '',
  CustomsValue: 0,
  Quantity: 0,
  PriceEach: 0,
  OriginalPriceEach: 0,
  TaxIDfk: 0,
  TaxPercentage: 0,
  TaxAmount: 0,
  TotalValue: 0,
  IsInventoryAdjusted:  0,
  InvoiceLineItemSequence: 0,
  InvoiceLineItemActive: 1,
  CIN: '',
  Weight: 0,
  HingeLength: 0,
  ProductionNotes: '',
  ConsumerFirstName: '',
  ConsumerLastName: '',
  Codes_UnitOfMeasureIDfk: 0,
  Codes_WarehouseIDfk: 0,
  Codes_WarrantyDepartmentAtFaultIDfk: 0,
  Codes_WarrantyReasonIDfk: 0,
  Codes_RadiusIDfk: 0,
  Codes_SkirtingIDfk: 0,
  Codes_LockIDfk: 0,
  Codes_MountIDfk: 0,
  LineItemWeight: 0,
  LineItemWeightText: '',
  LineItemWeightEachText: '',
  LineWeightCalcText: '',
  UnitOfMeasureText: '',
  WarehouseText: '',
  WarrantyDepartmentAtFaultText: '',
  WarrantyReasonText: '',
  RadiusText: '',
  SkirtingText: '',
  LockText: '',
  MountText: '',
};
const smartopAttrs: any = {
  ...defaultAttrs,
  LineItemType: INVOICE_LINE_ITEM_TYPE_SMARTTOP,
  CustomLength: 0,
  CustomWidth: 0,
  HingeLength: 0,
  Codes_RadiusIDfk: 0,
  Codes_SkirtingIDfk: 0,
  Codes_LockIDfk: 0,
  Codes_MountIDfk: 0,
};
export const DEFAULT_SMARTOP_INVOICE_LINE_ITEM: InvoiceLineItem = {
  id: '0',
  type: 'InvoiceLineItem',
  relationships: {},
  attributes: smartopAttrs
};

const inventoryAttrs: any = {
  ...defaultAttrs,
  LineItemType: INVOICE_LINE_ITEM_TYPE_INVENTORY
};
export const DEFAULT_INVENTORY_INVOICE_LINE_ITEM: InvoiceLineItem = {
  id: '0',
  type: 'InvoiceLineItem',
  relationships: {},
  attributes: inventoryAttrs
};

const otherAttrs: any = {
  ...defaultAttrs,
  LineItemType: INVOICE_LINE_ITEM_TYPE_OTHER
};
export const DEFAULT_OTHER_INVOICE_LINE_ITEM: InvoiceLineItem = {
  id: '0',
  type: 'InvoiceLineItem',
  relationships: {},
  attributes: otherAttrs
};

const returnAttrs: any = {
  ...defaultAttrs,
  LineItemType: INVOICE_LINE_ITEM_TYPE_RETURN
};
export const DEFAULT_RETURN_INVOICE_LINE_ITEM: InvoiceLineItem = {
  id: '0',
  type: 'InvoiceLineItem',
  relationships: {},
  attributes: returnAttrs
};



