<div class="card">
  <div class="card-heading normal-card-heading" data-toggle="collapse" attr.data-target="#invoice-{{name}}-items" [ngClass]="{'bg-danger': isTypeReturned(),
                                                                                                                              'text-light': isTypeReturned()
                                                                                                                            }">
    <h3 class="caps card-title">
      <span>{{ name }} items</span>
      <i class="fa fa-list fa-lg"></i>
      <a class="caret"></a>
    </h3>
    <div class="card-subheading float-right">
      <div class="text-right">
        <ng-container *ngIf="!isTypeOther() && !isTypeReturned() && isEditable">
          <button (click)="calcPrices($event, false)" type="button" class="h3 btn btn-xs btn-primary">
            Calc. Prices
          </button>
          <button (click)="calcPrices($event, true)" type="button" class="h3 btn btn-xs btn-primary">
            Calc. Prices Keep Existing
          </button>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="card-body collapse show" id="invoice-{{name}}-items" style="padding: 0 1px 0 0">
    <div class="jet-row row">
      <div class="jet-headers jet-column-reorder"></div>
      <div *ngFor="let column of columns" class="jet-headers jet-column-{{checkColumns(column) | lowercase}}{{ isTypeSmartop() ? '-smartop':''}}">{{column}}</div>
      <div class="jet-headers jet-column-total">Total</div>
      <div class="jet-headers jet-column-buttons"></div>
    </div>
    <ng-container *ngIf="isTypeSmartop()">
      <div cdkDropList (cdkDropListDropped)="drop($event)" class="jet-table">
        <ng-container *ngFor="let item of lineItems">
          <jet-invoice-smartop-item [attr.data-id]="item.id"
                                      class="invoice-line-item jet-row row"
                                      (onRemove)="onRemove($event)"
                                      (onClone)="onClone($event)"
                                      [item]="item"
                                      [returns]="invoice.attributes.InReturnMode"
                                      [invoiceTaxRate]="invoice?.attributes.TaxPercentage"
                                      [promoPercentage]="invoice?.attributes.PromoDiscountPercent" cdkDrag>
            <div slot="drag-handle" class="jet-column-reorder " cdkDragHandle>
              <i class="fas fa-grip-vertical"></i>
            </div>
          </jet-invoice-smartop-item>
        </ng-container>
        <jet-invoice-smartop-item
                (onAdd)="onAdd($event)"
                [item]="newItem"
                [invoiceTaxRate]="invoice?.attributes.TaxPercentage"
                [promoPercentage]="invoice?.attributes.PromoDiscountPercent"
                *ngIf="isEditable && !invoice.attributes.InReturnMode"
                class="invoice-line-item-new jet-row row">
        </jet-invoice-smartop-item>
      </div>
    </ng-container>
    <ng-container *ngIf="isTypeInventory()">
      <div cdkDropList (cdkDropListDropped)="drop($event)" class="jet-table">
        <ng-container *ngFor="let item of lineItems">
          <jet-invoice-inventory-item [attr.data-id]="item.id"
                                  class="invoice-line-item jet-row row"
                                  (onRemove)="onRemove($event)"
                                  [item]="item"
                                  [returns]="invoice.attributes.InReturnMode"
                                  [invoiceTaxRate]="invoice?.attributes.TaxPercentage"
                                  [promoPercentage]="invoice?.attributes.PromoDiscountPercent" cdkDrag>
            <div slot="drag-handle" class="jet-column-reorder " cdkDragHandle>
              <i class="fas fa-grip-vertical"></i>
            </div>
          </jet-invoice-inventory-item>
        </ng-container>
        <jet-invoice-inventory-item
                (onAdd)="onAdd($event)"
                [item]="newItem"
                [invoiceTaxRate]="invoice?.attributes.TaxPercentage"
                [promoPercentage]="invoice?.attributes.PromoDiscountPercent"
                *ngIf="isEditable && !invoice.attributes.InReturnMode"
                class="invoice-line-item-new jet-row row">
        </jet-invoice-inventory-item>
      </div>
    </ng-container>
    <ng-container *ngIf="isTypeReturned()">
      <div cdkDropList (cdkDropListDropped)="drop($event)" class="jet-table">
        <ng-container *ngFor="let item of returnItems">
          <jet-invoice-inventory-item [attr.data-id]="item.id"
                                  class="invoice-line-item jet-row row"
                                  (onRemove)="onRemove($event)"
                                  [returns]="invoice.attributes.InReturnMode"
                                  [item]="item"
                                  [lineItemType]="4"
                                  [invoiceTaxRate]="invoice?.attributes.TaxPercentage"
                                  [promoPercentage]="invoice?.attributes.PromoDiscountPercent" cdkDrag>
            <div slot="drag-handle" class="jet-column-reorder " cdkDragHandle>
              <i class="fas fa-grip-vertical"></i>
            </div>
          </jet-invoice-inventory-item>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="isTypeOther()">
      <div cdkDropList (cdkDropListDropped)="drop($event)" class="jet-table">
        <ng-container *ngFor="let item of lineItems">
          <jet-invoice-other-item [attr.data-id]="item.id"
                                      class="invoice-line-item jet-row row"
                                      (onRemove)="onRemove($event)"
                                      [item]="item"
                                      [returns]="invoice.attributes.InReturnMode"
                                      [invoiceTaxRate]="invoice?.attributes.TaxPercentage"
                                      [promoPercentage]="invoice?.attributes.PromoDiscountPercent" cdkDrag>
            <div slot="drag-handle" class="jet-column-reorder " cdkDragHandle>
              <i class="fas fa-grip-vertical"></i>
            </div>
          </jet-invoice-other-item>
        </ng-container>
        <jet-invoice-other-item
                (onAdd)="onAdd($event)"
                [item]="newItem"
                [invoiceTaxRate]="invoice?.attributes.TaxPercentage"
                [promoPercentage]="invoice?.attributes.PromoDiscountPercent"
                *ngIf="isEditable && !invoice.attributes.InReturnMode"
                class="invoice-line-item-new jet-row row">
        </jet-invoice-other-item>
      </div>
    </ng-container>
  </div>

</div>
