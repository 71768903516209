import { Component, Input } from '@angular/core';
import { Payment } from '../../../../models/payment.interface';


@Component({
  selector: 'jet-quick-view-payments',
  templateUrl: './quick-view-payments.component.html',
  styleUrls: ['./quick-view-payments.component.scss']
})
export class QuickViewPaymentsComponent {
  @Input() public payments: Payment[] = [];
}
