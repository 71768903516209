<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">

      <table class="table table-borderless customer-notes-table">
        <thead>
         <input placeholder="Category Search" class="my-2 ml-2 small-form col-5" type="text" name="search" [(ngModel)]="filter" (keyup.enter)="filterNotes(notes, filter)">
         <button type="button" class="btn btn-xs btn-primary ml-1" (click)="filterNotes(notes, filter)">
           <span class="fa fa-search"></span>
         </button>
        <tr>
          <th class="pl-4">Category <i *ngIf="!catAsc" class="fas fa-angle-up" (click)="orderByAsc('Category')"></i><i *ngIf="catAsc" class="fas fa-angle-down" (click)="orderByDec('Category')"></i></th>
          <th class="pl-3">Date <i *ngIf="!dateAsc" class="fas fa-angle-up" (click)="orderByAsc('NoteDate')"></i><i *ngIf="dateAsc" class="fas fa-angle-down" (click)="orderByDec('NoteDate')"></i></th>
          <th class="pl-4">User <i *ngIf="!userAsc" class="fas fa-angle-up" (click)="orderByAsc('User')"></i><i *ngIf="userAsc" class="fas fa-angle-down" (click)="orderByDec('User')"></i></th>
        </tr>
        </thead>
        <tbody class="jet-table">
          <ng-container *ngFor="let note of notes">
            <tr class="customer-notes-row">
              <td class="pl-4">
                <strong>{{ note.attributes.Category }}</strong>
              </td>
              <td>
                <strong>{{ note.attributes.NoteDate | momentDate:dateFormat }}</strong>
              </td>
              <td>
                <strong>{{ employeeName(note) }}</strong>
              </td>
              <td class="text-right">
                <button class="btn btn-xs ml-1" [ngClass]="{'star-favorite': note.attributes.Codes_NoteTypeIDfk !== customerNoteCode, 'btn-default': note.attributes.Codes_NoteTypeIDfk === customerNoteCode}"
                        (click)="toggleNoteType(note)" tooltip="Show In Sidebar" placement="below" delay="500">
                  <i class="fa fa-star"></i>
                </button>
                <button class="btn btn-xs btn-primary ml-1" (click)="viewNote(note)" tooltip="Edit" placement="below" delay="500">
                  <i class="fa fa-pencil-alt"></i>
                </button>

                <button *ngIf="note.attributes.Codes_NoteTypeIDfk === customerNoteCode" class="btn btn-xs btn-danger ml-1" (click)="removeNote(note)" tooltip="Delete" placement="below" delay="500">
                  <i class="fa fa-trash"></i>
                </button>

                <button *ngIf="note.attributes.Codes_NoteTypeIDfk !== customerNoteCode" class="btn btn-xs btn-disabled ml-1" tooltip="The note must be un-stared before deleting." placement="below" delay="500">
                  <i class="fa fa-trash"></i>
                </button>

              </td>
            </tr>
            <tr class="customer-notes-row">
              <td colspan="4">
                <p class="my-3 pl-4" [innerHtml]="note?.attributes.Note | safeHtml">
                </p>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
