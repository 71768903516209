import { Component, Input } from '@angular/core';
@Component({
  selector: 'jet-quick-view-details',
  templateUrl: './quick-view-details.component.html',
  styleUrls: ['./quick-view-details.component.scss']
})
export class QuickViewDetailsComponent {
  @Input() public terms = 'None';
  @Input() public taxStatus = '';
  @Input() public taxRate = 0;
  @Input() public vatNumber = undefined;
  @Input() public salesPerson = '';
  @Input() public processor = '';
  @Input() public marketingSource = 'None';

  constructor() {}

}
