import {Inject, Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {DOMAIN_CONFIG, DomainConfigInterface} from "../config/domain.interface";

@Pipe({
  name: 'jetTranslate',
})
export class TranslatePipe implements PipeTransform {

  constructor(private sanitizer:DomSanitizer,
              @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
              ) {}

  transform(value: string, searchEnd: boolean = false, type: string = "string") {

    if (typeof this.config.strings[value] !== 'undefined') {

        if (type == 'html') {
          return this.sanitizer.bypassSecurityTrustHtml(this.config.strings[value]);
        }

        return this.config.strings[value];


    } else if (searchEnd) {

      for (const key of Object.keys(this.config.strings)) {

        const start = value.length - key.length;

        if (value.indexOf(key, start) !== -1) {

          const characters = value.split('');
          const replacementChars = this.config.strings[key].split('');
          characters.splice(start, key.length, ...replacementChars);

          return characters.join('');
        }
      }
    }

    return value;
  }
}
