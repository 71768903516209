<jet-menu></jet-menu>
<div class="container-fluid">

  <div class="header"></div><!-- /header -->

  <div class="content row">
    <div id="sidebar" class="col-lg-2 col-custom col-sm-12 order-lg-0 order-2">
      <div class="hidden-sm hidden-xs row">
        <div class="col-md-12">
          <jet-customer-search></jet-customer-search>
          <jet-customer-notes></jet-customer-notes>
        </div>
      </div> <!-- Desktop sidebar - hides on mobile -->
    </div> <!-- /sidebar -->

    <div class="tabs col-lg-10 col-custom col-sm-12">

      <div class="row">
        <div class="col-sm-8">
          <ul class="nav nav-tabs">
            <li class="nav-item"><a href="#" class="nav-link active">Returns</a></li>
          </ul>
        </div>
      </div>

      <div class="tab-content">

        <div class="container-fluid" style="margin-left:5%">
          <br/>
          <h1>Processing returns in JET</h1>
          <br/>
          <p>Returns can only be processed for a completed invoice.</p>
          <h3>1. Begin <span style="">return mode</span> by clicking the
            <button type="button" class="btn btn-sm btn-danger">Returns</button>
            button at the top right of the invoice page.
          </h3>
          <img src="assets/images/returns/returns_step1.png" style="width:50%;">
          <ul>
            <li>The invoice status will switch to "Return Mode"</li>
            <li>Return buttons will appear for each line item.</li>
          </ul>

          <br>
          <br>
          <h3>2. Scroll down to the item(s) being returned. Click the
            <button class="btn btn-danger btn-xs">Return</button>
            button.
          </h3>
          <img src="assets/images/returns/returns_step2.png" style="width:75%;">
          <ul>
            <li>Clicking this new return button will open the return item dialog</li>
          </ul>

          <br><br>
          <h4>3. Enter return quantity</h4>
          <img src="assets/images/returns/returns_step3.png" style="">
          <ul>
            <li>Use this Return Dialog to select how many of an item to return.</li>
            <li>Enter positive values. eg. When 2 items are returned enter "2"</li>
<!--            <li>Selecting 0 will undo the return and remove the line item from the returned items section.</li>-->
            <li>The form will not allow you to return more than the original quantity.</li>
          </ul>

          <br><br>
          <h4>4. Review returned item.</h4>
          <img src="assets/images/returns/returns_step4.png" style="width:75%;">
          <ul>
            <li>After confirmation there will be a new returned items section to the invoice.</li>
            <li>The item will show it's new quantity as well as the original quantity crossed out.</li>
            <li>The returned item will show how many are being returned.</li>
            <li>This section will contain: LC items, Smartop items and other items.</li>
          </ul>

          <br><br>
          <h4>4. Exit returns</h4>
          <ul>
            <li>The original returns button will show as: <button type="button" class="btn btn-sm btn-danger">Exit Returns</button></li>
            <li>It's also safe to close your browser or browser tab. You will exit returns mode automatically</li>
            <li>If you leave the page accidentally you will exit returns mode automatically</li>
          </ul>
        </div>


      </div>
    </div> <!-- /tabs -->
  </div><!-- /row -->
</div> <!-- /container -->

