<!--TODO Add/Edit Representation-->
<ng-container *ngIf="!hide">

    <ng-container *ngIf="state === STATES.ADD || state === STATES.EDIT">
        <td colspan="5">
            <div (click)="toggle()" class="row" style="margin: 0;font-size: 14px;background: #e4efff;font-weight: bold;">
                <div class="col-sm-12" style="font-size: 16px;">
                    Editing:
                </div>
                <div class="col-sm-auto">
                    <span>{{ paymentMethod.attributes.Description }}</span>
                </div>
                <div class="col-sm-auto">
                    <span>{{ paymentMethod.attributes.Number.slice(-4) }}</span>
                </div>
                <div class="col-sm-auto">
                    <span>{{ paymentMethod.attributes.Date | momentDate:'MM/YY'}}</span>
                </div>
            </div>
            <div class="form-subgroup">
                <div class="row row2">
                    <div class="col-md-4 caps onepad">
                        <b>description</b>
                    </div>
                    <div class="col-md-8 onepad">
                        <input [(ngModel)]="editPaymentMethod.attributes.Description" type="text" class="small-form">
                    </div>
                </div>

                <div class="row row2">
                    <div class="col-md-4 caps onepad">
                        <b>name on card</b>
                    </div>
                    <div class="col-md-8 onepad">
                        <input [(ngModel)]="editPaymentMethod.attributes.NameOn" type="text" class="small-form">
                    </div>
                </div>

                <div class="row row2">
                    <div class="col-md-4 caps onepad">
                        <b>card number</b>
                    </div>
                    <div class="col-md-8 onepad">
                        <input [(ngModel)]="editPaymentMethod.attributes.Number" type="text" class="small-form form-control" [disabled]="disabled">
                    </div>
                </div>
                <div class="row row2">
                    <div class="col-md-4 caps onepad">
                        <b>expiration date</b>
                    </div>
                    <div class="col-md-8 onepad">
                        <input [(ngModel)]="editPaymentMethod.attributes.Date" type="text" class="small-form" placeholder="01/25">
                    </div>
                </div>
                <div class="row row2">
                    <div class="col-md-4 caps onepad">
                        <b>security code</b>
                    </div>
                    <div class="col-md-8 onepad">
                        <input [(ngModel)]="editPaymentMethod.attributes.CreditCardSecurityCode" type="text" class="small-form form-control" placeholder="111" [disabled]="disabled">
                    </div>
                </div>
                <div class="row row2">
                    <div class="col-md-4 caps onepad">
                        <b>address</b>
                    </div>
                    <div class="col-md-8 onepad">
                        <input [(ngModel)]="editPaymentMethod.attributes.AddressLine1" type="text" class="small-form">
                    </div>
                </div>
                <div class="row row2">
                    <div class="col-md-4 caps onepad">
                        <b>zip</b>
                    </div>
                    <div class="col-md-8 onepad">
                        <input [(ngModel)]="editPaymentMethod.attributes.AddressZip" type="text" class="small-form" placeholder="Zip">
                    </div>
                </div>
              <div class="row row2" style="margin-bottom: 5px;">
                <ng-container *ngIf="state === STATES.EDIT">
                  <div class="col-sm-6">
                    <button class="btn btn-success btn-sm btn-block" (click)="update()">
                      Save
                    </button>
                  </div>
                  <div class="col-sm-6">
                    <button class="btn btn-default btn-sm btn-block" (click)="cancel()">
                      Cancel
                    </button>
                  </div>
                </ng-container>
                <ng-container *ngIf="state === STATES.ADD">
                  <div class="col-sm-6">
                    <button class="btn btn-success btn-sm btn-block" (click)="add()">
                      Add
                    </button>
                  </div>
                  <div class="col-sm-6">
                    <button class="btn btn-default btn-sm btn-block" (click)="cancel()">
                      Cancel
                    </button>
                  </div>
                </ng-container>
              </div>
                <div class="row row2" *ngIf="charge" style="margin: 10px 0;">
                    <div class="col-md-4 caps onepad">
                        <b>Charge</b>
                    </div>
                    <div class="col-md-8 onepad">
                        <input [(ngModel)]="editPaymentMethod.charge" type="text" class="small-form" placeholder="Charge">
                    </div>

                    <div class="col align-self-center" style="margin: 5px" *ngIf="state !== STATES.ADD">
                        <button class="btn btn-warning btn-block btn-sm" (click)="process()">Process</button>
                    </div>

                    <div class="col align-self-center" style="margin: 5px" *ngIf="state === STATES.ADD">
                      <button class="btn btn-warning btn-block btn-sm" (click)="addAndProcess()">Add and Process</button>
                    </div>

                </div>
            </div>
        </td>
    </ng-container>

    <ng-container *ngIf="state === STATES.VIEW">
        <td *ngIf="parentType === 'customer'">
          <ng-content select="[slot=drag-handle]"></ng-content>
        </td>
        <td style="padding:5px !important;" (click)="toggle()">{{ paymentMethod.attributes.Description }}</td>
        <td style="padding:5px !important;" (click)="toggle()">{{ paymentMethod.attributes.Number.slice(-4) }}</td>
        <td style="padding:5px !important;" (click)="toggle()">{{ paymentMethod.attributes.Date | momentDate:'MM/YY'}}
        </td>
      <td style="width: 50px;">
        <button class="btn p-0" (click)="edit()">
          <i class="fa fa-pencil-alt" ></i>
        </button>
        <div class="btn-group ml-1">
          <button class="btn btn-secondary btn-sm dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false"
                  style="background-color:transparent;color:black;border:none;box-shadow:none;"></button>
          <div class="dropdown-menu dropdown-menu-right" style="min-width: 125px;">
            <a class="dropdown-item" (click)="remove()" style="cursor: pointer;"><i class="fa fa-trash-alt"></i> Delete</a>
            <a class="dropdown-item" (click)="edit()" style="cursor: pointer;"><i class="fa fa-pencil-alt"></i> Edit</a>
          </div>
        </div>
      </td>
    </ng-container>
</ng-container>
