import { Customer } from '../models/customer.interface';
import { copyObject } from '../utils/json.util';
import { CUSTOMER_TYPE_CUSTOMER, CUSTOMER_TYPE_INDIVIDUAL } from '../utils/constants.util';
const defaultCustomer = {
  id: '0',
  type: 'Customer',
  attributes: {
    CustomerID: 0,
    CustomerType: -999,
    CustomerActive: 1,
    Organization: '',
    LastName: '',
    FirstName: '',
    MiddleName: '',
    Title: '',
    TaxNumber: '',
    QBID: '',
    Username: '',
    Password: '',
    Codes_PaymentTermsIDfk: 0,
    FullName: '',
    Admin: 0,
  },
  relationships: {}
};

const defaultIndividualCustomer: Customer = copyObject(defaultCustomer);
defaultIndividualCustomer.attributes.CustomerType = CUSTOMER_TYPE_INDIVIDUAL;
export const DEFAULT_INDIVIDUAL_CUSTOMER: Customer = defaultIndividualCustomer;

const defaultStoreCustomer: Customer = copyObject(defaultCustomer);
defaultStoreCustomer.attributes.CustomerType = CUSTOMER_TYPE_CUSTOMER;
export const DEFAULT_STORE_CUSTOMER: Customer = defaultStoreCustomer;
export const DEFAULT_CUSTOMER: Customer = defaultStoreCustomer;
