import {HttpHeaders, HttpParams} from '@angular/common/http';

export function createOptions(headerPairs= {}, paramPairs = {}) {
  const headers = new HttpHeaders({
    'Content-Type': 'application/vnd.api+json',
    'Accept': 'application/json'
  });
  // const headers = new Headers(Object.assign(
  //   {'Content-Type': 'application/vnd.api+json', 'Accept': 'application/json'},
  //   headerPairs
  // ));
  const params = new HttpParams();
  for (const paramKey of  Object.keys(paramPairs)) {
    const paramValue = paramPairs[paramKey];
    params.set(paramKey, paramValue);

  }

  return { headers, params };
}

export function getCookie(name): string {
    const cookieKey = `${name}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieListSegments = decodedCookie.split(';');
    for (let cookieSegment of cookieListSegments) {
      // 1. Remove leading whitespace from cookie segment
      while (cookieSegment.charAt(0) === ' ') {
        cookieSegment = cookieSegment.substring(1);
      }
      // 2. Check if cookie segment has the cookie key
      if (cookieSegment.indexOf(cookieKey) === 0) {
        return cookieSegment.substring(cookieKey.length, cookieSegment.length);
      }
    }
    return undefined;
}

/**
 * Will take an object of query param data, transforming it into a query param string.
 * Each value will be encoded using encodeURIComponent.
 * key: value -> key=value
 * key: array -> key[]=value1&key[]=value2&...
 * key: object -> key[subKey1]=subValue1&key[subKey2]=subValue2&...
 *    subKey: array -> key[subKey][]=item1&key[subKey][]=item2&...
 *
 * NOTE: You can nest objects and arrays as deep as you want so { key1: { key2: { key3: 1} } -> key1[key2][key3]=1
 * @param queryParamData - You can't pass an array by itself as queryParamData. You must pass an object.
 * @param queryKeyParts - Should not be passed by original caller
 */
export function generateQueryParamString(queryParamData, queryKeyParts = []) {
  let queryString = '';
  if (queryParamData instanceof Array) {
    queryParamData.forEach((value) => {
      queryString += generateQueryParamString(value, [...queryKeyParts, { type: 'array' }]);
    });
  } else if (queryParamData instanceof Object) {
    Object.keys(queryParamData).forEach((key) => {
      const value = queryParamData[key];
      queryString += generateQueryParamString(value, [...queryKeyParts, { type: 'object', key }]);
    });
  } else {
    const key = generateQueryParamKey(queryKeyParts);
    queryString += `${key}=${encodeURIComponent(queryParamData)}&`;
  }
  return queryString;
}

function generateQueryParamKey(queryKeyParts = []) {
  let queryKey = '';
  queryKeyParts.forEach((part, index) => {
    if (part.type === 'array') {
      queryKey += '[]';
    } else if (part.type === 'object') {
      if (index === 0) {
        queryKey += part.key;
      } else {
        queryKey += `[${part.key}]`;
      }
    }
  });
  return queryKey;
}
