import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {merge} from "rxjs";

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  public tabs = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    merge(
      this.router.events.pipe(filter(data => data instanceof NavigationEnd)),
      this.route.url,
    ).pipe(
      filter((data) => this.route.snapshot.firstChild !== null)
    ).subscribe((data) => {

      this.tabs = this.route.snapshot.firstChild.data['tabs'].map((tab) => {
        if (!tab.route) {
          return tab;
        }

        tab.active = tab.route == this.route.snapshot.firstChild.url.join('/')

        return tab;
      });
    });
  }

}
