<div class="card" id="jet-invoice-freight">
  <div class="card-heading" data-toggle="collapse" data-target="#invFreight">
    <h3 class="caps card-title">
      <span>freight</span>
      <i class="fa fa-archive fa-lg"></i>
      <a class="caret"></a>
    </h3>
    <div class="card-subheading float-right">
      <div class="text-right">

      </div>
    </div>
  </div>
  <div class="card-body collapse show fivepad" id="invFreight">
    <div class="row pad">
        <label>Total Weight {{ computedFreightInfo?.totalWeight + 'lbs.' |jetTranslate:isNotSmartopInvoice }}</label>
      <span class="float-right">
                <input type="text" [(ngModel)]="invoice?.attributes.FreightNumberOfPallets" class="xsmall-form" style="width: 40px;padding: 5px;margin: 0;border-radius: 0;"> <strong># Pallets</strong>
            </span>
    </div>

    <hr>

    <div class="row pad">
      <label class="col-md-5 col-form-label">company</label>
      <div class="col-md-7 zeropad">
        <select [(ngModel)]="invoice?.attributes.Customers_FreightVendorIDfk" class="small-form">
          <option value="0">--Choose--</option>
          <option *ngFor="let company of freightCompanies" [ngValue]="company.CodeID">{{ company.CodeDesc }}</option>
        </select>
      </div>
    </div>

    <div class="row pad">
      <label class="col-md-5 col-form-label">ship via</label>
      <div class="col-md-7 zeropad">
        <select [compareWith]="compareByNumber" [(ngModel)]="invoice?.attributes.Codes_FreightBucketIDfk" class="small-form">
            <option value="0">--Choose--</option>
            <option *ngFor="let bucket of freightBuckets" [ngValue]="bucket.CodeID">
              {{ bucket.CodeDesc }}
            </option>
        </select>
      </div>
    </div>

    <div class="row pad">
      <label class="col-md-5 col-form-label">Freight cost <span [innerHTML]="'$' | jetTranslate:false:'html' "></span> </label>
      <div class="col-md-7 zeropad">
        <input type="number" class="small-form" (blur)="setInvoiceAttribute($event, 'FreightCost')" [ngModel]="invoice?.attributes.FreightCost | number: '1.2' | replace:',':''" highlightOnFocus>
      </div>
    </div>

    <div class="row pad">
      <label class="col-md-5 col-form-label">freight crg. <span [innerHTML]="'$' | jetTranslate:false:'html' "></span></label>
      <div class="col-md-3 zeropad">
        <input (blur)="chargeOnBlur();setInvoiceAttribute($event, 'FreightPrice')" type="number" class="small-form" [ngModel]="invoice?.attributes.FreightPrice | number: '1.2' | replace:',':''" highlightOnFocus>
      </div>

      <label class="col-md-2 text-center col-form-label">{{ 'tax'|jetTranslate }}</label>
      <div class="col-md-2 zeropad">
        <input readonly type="text" class="small-form" [value]="chargeTax(invoice?.attributes.FreightPrice, 'FreightPrice')">
      </div>
    </div>

    <div class="row pad">
      <label class="col-md-5 col-form-label">handling crg. <span [innerHTML]="'$' | jetTranslate:false:'html' "></span></label>
      <div class="col-md-3 zeropad">
        <input (blur)="chargeOnBlur();setInvoiceAttribute($event, 'Handling')" type="number" class="small-form" [ngModel]="invoice?.attributes.Handling | number: '1.2' | replace:',':''" highlightOnFocus>
      </div>

      <label class="col-md-2 text-center col-form-label">{{ 'tax'|jetTranslate }}</label>
      <div class="col-md-2 zeropad">
        <input readonly type="text" class="small-form" [value]="chargeTax(invoice?.attributes.Handling, 'Handling')">
      </div>
    </div>

    <div class="row pad">
      <label class="col-md-5 col-form-label">add. crg. <span [innerHTML]="'$' | jetTranslate:false:'html' "></span></label>
      <div class="col-md-3 zeropad">
        <input (blur)="chargeOnBlur();setInvoiceAttribute($event, 'AdditionalCharges');" type="number" class="small-form" [ngModel]="invoice?.attributes.AdditionalCharges | number: '1.2' | replace:',':''" highlightOnFocus>
      </div>

      <label class="col-md-2 text-center col-form-label">{{ 'tax'|jetTranslate }}</label>
      <div class="col-md-2 zeropad">
        <input readonly type="text" class="small-form" [value]="chargeTax(invoice?.attributes.AdditionalCharges, 'AdditionalCharges')">
      </div>
    </div>

    <div class="row pad">
      <label class="col-md-5 col-form-label">shipping credit</label>
      <div class="col-md-7 zeropad">
        <input type="number" class="small-form" (blur)="setInvoiceAttribute($event, 'ShippingCredit')" [ngModel]="invoice?.attributes.ShippingCredit | number: '1.2' | replace:',':''"
               data-toggle="tooltip" data-placement="bottom" title="Negative Values Only" highlightOnFocus
        >
      </div>
    </div>

    <div class="row pad">
      <label class="col-md-5 col-form-label">date shipped</label>
      <div class="col-md-7 zeropad">
        <input placeholder="Pick date" type="text" class="small-form" #datePicker bsDatepicker [bsConfig]="{ dateInputFormat: dateFormat, showWeekNumbers: false, containerClass: 'theme-blue date-shipped' }" [(bsValue)]="dateShipped" (bsValueChange)="onDateShippedChange($event)">
      </div>
    </div>

    <div class="row pad">
      <label class="col-md-5 col-form-label">tracking number</label>
      <div class="col-md-7 zeropad">
        <input type="text" class="small-form" [(ngModel)]="invoice?.attributes.FreightTrackingNumber">
      </div>
    </div>

    <div class="row pad" *ngIf="invoiceCompleted">
      <div class="col-md">
        <button class="btn btn-primary btn-xs btn-block" (click)="updateTrackingNumber()">Update Tracking #</button>
      </div>
    </div>

    <div class="row pad">
      <label class="col-md-5 col-form-label">promo code</label>
      <div class="col-md-3 zeropad">
        <input type="text" class="small-form" [(ngModel)]="invoice?.attributes.PromoCode">
      </div>

      <div class="col-md-2 zeropad">
        <input (blur)="updateFreightInfo()" type="text" class="small-form" [(ngModel)]="invoice?.attributes.PromoDiscountPercent">
      </div>

      <div class="col-md-2 zeropad">
        <input readonly type="text" class="small-form" [value]="usDollarFormat(invoice?.attributes.PromoDiscountTotal)">
      </div>
    </div>

    <hr>

    <div class="row pad">
      <div style="padding-left: 0" class="col-md-6">
        <button [class.invisible]="invoiceCompleted" (click)="updateInvoice()" type="button" class="btn btn-primary form-control" id="recalculate">
          Recalculate
        </button>

        <table width="100%">
          <tr>
            <td><button class="zeropad btn btn-link" href="#" (click)="clickPayments.emit($event)"><b>Payments</b></button></td>
            <td class="small text-right">{{ computedFreightInfo?.totalPayments | jetCurrency }}</td>
          </tr>
          <tr>
            <td><b>Balance</b></td>
            <td class="small text-right">{{ computedFreightInfo?.balance | jetCurrency }}</td>
          </tr>
        </table>
      </div>
      <div class="col-md-6">
        <table width="100%" class="small">
          <tr>
            <td><b>Subtotal</b></td>
            <td class="text-right">{{ computedFreightInfo?.subTotal | jetCurrency }}</td>
          </tr>
          <tr>
            <td><b>S&H</b></td>
            <td class="text-right">{{ computedFreightInfo?.totalShippingHandling | jetCurrency }}</td>
          </tr>
          <tr>
            <td><b>{{ 'tax'|jetTranslate }}</b></td>
            <td class="text-right">{{ computedFreightInfo?.totalTax | jetCurrency }}</td>
          </tr>
          <tr>
            <td><b>Credit</b></td>
            <td class="text-right">({{ shippingCredit() | jetCurrency }})</td>
          </tr>
          <tr>
            <td><b>Total</b></td>
            <td class="text-right">{{ computedFreightInfo?.total | jetCurrency }}</td>
          </tr>
        </table>
      </div>
    </div>

  </div>
</div>
