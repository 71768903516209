<nav class="navbar navbar-default navbar-expand">
    <a class="navbar-brand" href="#"><img src="/assets/images/nav-logo.png" width="120" alt="Leisure Concepts"></a>

    <div class="collapse navbar-collapse">
        <ul class="navbar-nav mr-auto d-none d-md-inline-flex">
            <li #root *ngIf="menu" class="nav-item dropdown" (mouseenter)="enter()" (mouseleave)="leave();" style="padding: 0;">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" data-toggle="dropdown" style="color: white;padding: 0;">
                    {{ menu.Title }}
                </a>

                <ng-container *ngTemplateOutlet="dropdown_menu; context: {menu: menu}"></ng-container>
            </li>
        </ul>
        <ul class="navbar-nav ml-auto d-none d-md-inline-flex">
            <ng-container *ngIf="user">
                <li class="nav-item mr-3">
                    <span class="navbar-text">{{ username }}</span>
                </li>
<!--              <li class="nav-item mr-3">-->
<!--                <button class="btn btn-sm btn-light navbar-text" (click)="goToSalesContest()">Smartop Sales Contest</button>-->
<!--              </li>-->
                <li class="nav-item mr-3">
                    <button class="btn btn-sm btn-light navbar-text" (click)="logout()">Logout</button>
                </li>
                <li class="nav-item mr-3">
                    <button class="btn btn-sm btn-light navbar-text" (click)="admin()">Admin</button>
                </li>
                <li class="nav-item" *ngIf="limelyteAdmin">
                    <button class="btn btn-sm btn-light navbar-text" (click)="openlimelyteAdmin()">Limelyte Admin</button>
                </li>
            </ng-container>
        </ul>
        <ul *ngIf="menu" class="navbar-nav ml-auto d-md-none">
          <li class="nav-item">
            <button class="btn btn-default btn-sm d-md-none"
                    style="margin-top: -5px;"
                    onClick="$('#mobileNavbar').css('display', 'block')">
              {{ menu.Title }}
            </button>
          </li>
        </ul>
    </div>
</nav>


<nav *ngIf="menu" class="mobile-nav d-md-none" id="mobileNavbar">
  <h4>System Menu</h4>
  <button class="btn btn-primary btn-sm float-right"
          style="margin-top: -17px;"
          onClick="$('#mobileNavbar').css('display', 'none')">X
  </button>
  {{ username }}
  <hr/>

<!--  <button class="btn btn-sm btn-primary mb-2" (click)="goToSalesContest()">-->
<!--    Smartop Sales Contest-->
<!--  </button>-->
  <button class="btn btn-sm btn-primary mb-2" (click)="logout()">
    Logout
  </button>
  <button class="btn btn-sm btn-default mb-2" (click)="admin()">
    Admin
  </button>
  <button *ngIf="limelyteAdmin" class="btn btn-sm btn-default mb-2" (click)="openlimelyteAdmin()">
    Limelyte Admin
  </button>

  <ng-container *ngTemplateOutlet="top_mobile_dropdown_menu; context: {menu: menu}"></ng-container>

</nav>

<ng-template #dropdown_menu let-menu="menu">
    <ul class="dropdown-menu">
        <ng-container *ngFor="let child of menu.menu_children">

            <ng-container *ngIf="!child.menu_children.length;">
                <ng-container *ngTemplateOutlet="dropdown_item; context: {menu: child}"></ng-container>
            </ng-container>

            <ng-container *ngIf="child.menu_children.length">
                <ng-container *ngTemplateOutlet="dropdown_submenu; context: {menu: child}"></ng-container>
            </ng-container>
        </ng-container>
    </ul>
</ng-template>

<ng-template #dropdown_item let-menu="menu">
    <li class="dropdown-item">
        <a href="{{menu.full_url}}">{{ menu.Title }}</a>
    </li>
</ng-template>

<ng-template #dropdown_submenu let-menu="menu">
    <li class="dropdown-submenu">
        <a class="dropdown-item" tabindex="-1" href="{{menu.full_url}}">{{ menu.Title }}</a>
        <ng-container *ngTemplateOutlet="dropdown_menu; context: {menu: menu}"></ng-container>
    </li>
</ng-template>

<ng-template #top_mobile_dropdown_menu let-menu="menu">
  <accordion [closeOthers]="false" [isAnimated]="true">
    <ng-container *ngFor="let child of menu.menu_children">
      <accordion-group heading="{{ child.Title }}" [panelClass]="'mobile-panel'">
        <ng-container *ngTemplateOutlet="mobile_dropdown_submenu; context: {menu: child}"></ng-container>
      </accordion-group>
    </ng-container>
  </accordion>
</ng-template>

<ng-template #mobile_dropdown_menu let-menu="menu">
    <ng-container *ngFor="let child of menu.menu_children">

      <ng-container *ngIf="!child.menu_children.length;">
        <ng-container *ngTemplateOutlet="mobile_dropdown_item; context: {menu: child}"></ng-container>
      </ng-container>

      <ng-container *ngIf="child.menu_children.length">
        <hr/>
        <h6>{{child.Title}}</h6>
        <ng-container *ngTemplateOutlet="mobile_dropdown_submenu; context: {menu: child}"></ng-container>
        <hr/>
      </ng-container>

    </ng-container>
</ng-template>

<ng-template #mobile_dropdown_item let-menu="menu">
  <a href="{{menu.full_url}}" class="btn btn-link d-block text-left">{{ menu.Title }}</a>
</ng-template>

<ng-template #mobile_dropdown_submenu let-menu="menu">
    <ng-container *ngTemplateOutlet="mobile_dropdown_menu; context: {menu: menu}"></ng-container>
</ng-template>
