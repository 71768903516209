import {LoggingService} from "../services/logging.service";

export class StaticLogger {

  private static logger: LoggingService;

  static set(logger: LoggingService) {
    StaticLogger.logger = logger;
  }

  static get()
  {
    return StaticLogger.logger;
  }
}
