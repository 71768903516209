import { Component, Input, OnInit } from '@angular/core';
import { Customer } from '../../../../models/customer.interface';
import { Code } from '../../../../models/code.interface';
import { DealerTypeComponent } from '../../../shared/components/dealer-type/dealer-type.component';
import { copyObject } from '../../../../utils/json.util';
import { DEFAULT_CODE } from '../../../../mocks/codes.mock';
import { CodeService } from '../../../../services/observables/code.service';

@Component({
  selector: 'jet-customer-dealer-types',
  templateUrl: './customer-dealer-types.component.html',
  styleUrls: ['./customer-dealer-types.component.scss']
})
export class CustomerDealerTypesComponent implements OnInit {
  @Input() public key: string;
  @Input() public dealerTypes: Code[] = [];
  @Input() public productLines: Code[] = null;
  @Input() public customerId = 0;
  public codes: any = null;
  public emptyDealerType: Code = null;
  public STATE_VIEW = DealerTypeComponent.STATIC_STATES.VIEW;
  public STATE_ADD = DealerTypeComponent.STATIC_STATES.ADD;
  public showAddForm = false;

  constructor(private codeService: CodeService) {}

  ngOnInit() {
    this.emptyDealerType = copyObject(DEFAULT_CODE);
    this.codeService.codes.subscribe((codes) => {
      this.codes = codes;
    });
  }

  public toggleShowAddFormOnClick(event) {
    event.stopPropagation();
    this.toggleShowAddForm();
  }

  private toggleShowAddForm() {
    this.showAddForm = !this.showAddForm;
  }

  public newDealerTypeAddHandler(success) {
    if (success) {
      this.toggleShowAddForm();
    }
  }

  public newDealerTypeCancelHandler(success) {
    this.toggleShowAddForm();
  }
}
