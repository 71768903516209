import {filter, takeUntil} from 'rxjs/operators';
import {
  APP_SETTING_NAMES,
  CUSTOMER_PAYMENT_TERMS,
  DEALER_TYPES_LC,
  DEALER_TYPES_MC,
  DEALER_TYPES_MP,
  DEALER_TYPES_RV,
  DEALER_TYPES_STC,
  DEALER_TYPES_STD,
  FREIGHT_BUCKETS,
  FREIGHT_COMPANIES,
  INVENTORY_COMMODITY_CODES,
  INVOICE_PENDING_REASONS,
  INVOICE_STATUS,
  INVOICE_STATUS_CANCELLED,
  INVOICE_STATUS_COMPLETED,
  INVOICE_TAX_STATUS,
  MARKETING_SOURCE_INVOICE,
  PRICE_LISTS,
  PRODUCT_LINES,
  SMARTOP_LOCKS,
  SMARTOP_MOUNTS,
  SMARTOP_RADII,
  SMARTOP_RADII_METRIC,
  SMARTOP_SKIRTING,
  SMARTOP_SKIRTING_METRIC,
  UNITS_OF_MEASURE,
  WARRANTY_DEPARTMENT_CHARGED,
  WARRANTY_REASON_CODES
} from '../../../../utils/constants.util';
import {UserService} from '../../../../services/observables/user.service';
import {Component, ErrorHandler, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {CountryService} from '../../../../services/observables/country.service';
import {CodeService} from '../../../../services/observables/code.service';
import {EmployeeService} from '../../../../services/observables/employee.service';
import {LoadingService} from '../../../../services/observables/loading.service';
import {InvoiceService} from '../../../../services/observables/invoice.service';
import {ApplicationSettingService} from '../../../../services/observables/application-setting.service';
import {Invoice} from '../../../../models/invoice.interface';
import {CustomerService} from '../../../../services/observables/customer.service';
import {WarningModalComponent} from '../../../shared/components/warning-modal/warning-modal.component';
import {NoteService} from '../../../../services/observables/note.service';
import {CustomerInfoComponent} from '../../../customer/components/customer-info/customer-info.component';
import {Customer} from '../../../../models/customer.interface';
import {IndividualInfoComponent} from '../../../customer/components/individual-info/individual-info.component';
import {IndexService} from '../../../../services/observables/index.service';
import {Subject} from 'rxjs';
import {JetVersion} from "../../../../version";
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";
import {NoteModalComponent} from "../../../shared/components/note-modal/note-modal.component";
import {Note} from "../../../../mocks/note.interface";
import {copyObject, getSingleResourceRelation} from "../../../../utils/json.util";
import {DEFAULT_GENERAL_NOTE} from "../../../../mocks/note.mocks";
import {Employee} from "../../../../models/employee.interface";
import {User} from "../../../../models/user.interface";
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'jet-invoice-page',
  templateUrl: './invoice-page.component.html',
  styleUrls: ['./invoice-page.component.scss']
})
export class InvoicePageComponent implements OnInit, OnDestroy {

  private outletComponent: any;
  private bsModalRef: BsModalRef;

  public customerSearchDisabled = false;
  public primaryDisabled        = false;
  public customerDisabled       = false;
  public historyDisabled        = false;
  public quickViewDisabled      = false;
  public arrowsDisabled         = false;
  public arrowsHidden           = false;
  public canShowInvoiceReopen   = false;
  public canShowInvoiceUpdate   = false;
  public canShowInvoiceReturn   = false;
  public canShowCustomerUpdate  = false;
  public canShowAddNote         = false;
  public version                = JetVersion;
  public returns                = false;

  public dateFormat: string;
  public companyTitle: string;

  public customer: Customer;
  private invoice: Invoice;
  public user: User = null;
  private queryParams: any;
  private ngUnsubscribe = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private countryService: CountryService,
    private codeService: CodeService,
    private employeeService: EmployeeService,
    private loadingService: LoadingService,
    private invoiceService: InvoiceService,
    private customerService: CustomerService,
    private noteService: NoteService,
    private userService: UserService,
    private applicationSettingService: ApplicationSettingService,
    private bsModalService: BsModalService,
    private indexService: IndexService,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
    private errorHandler: ErrorHandler,
  ) {
    this.dateFormat = config.dateFormat;
  }

  ngOnInit() {

    this.route.queryParams.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((queryParams: any) => {
      if (queryParams.DenyPage) {
        this.router.navigate(['DenyPage']);

        return;
      }

      this.queryParams = queryParams;

      this.determineState();

      //When leslea is loading this page after creating a smartoporder she claims the page won't load.
      //I'm adding a timeout incase there is come kind of race condition.
      let timeout = 0;
      if (queryParams.weborder_id) {
        timeout = 2000;
        this.invoiceService.enableLog = true;
      }

      setTimeout(() => {

        if (queryParams.customer_id) {
          this.customerService.getResource(queryParams.customer_id);
        }

        if (queryParams.invoice_id) {
          this.invoiceService.getInvoice(queryParams.invoice_id);
        }
      }, timeout);
    });

    this.invoiceService.invoice.pipe(
      filter(data => data !== null),
      takeUntil(this.ngUnsubscribe)
      ).subscribe((invoice: Invoice) => {
        this.invoice = invoice;

        this.determineState();
      });

    this.loadObservables();
    this.userService.getResource();

    this.userService.user.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((user: User) => {
      this.user = user;
    });

    this.customerService.customer.pipe(
        filter(data => data !== null),
        takeUntil(this.ngUnsubscribe)
    ).subscribe((customer: Customer) => {

        setTimeout(() => {
          this.customer = customer;
          this.indexService.setTitle(customer);
        }, 0);
      })
    ;

    this.indexService.LoadingFlag.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe(LoadingFlag => {
      this.arrowsDisabled = LoadingFlag;
    });

    this.indexService.title.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe(title => {
      this.companyTitle = title;
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
        takeUntil(this.ngUnsubscribe)
    ).subscribe((event: NavigationEnd) => {
        this.determineState();
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private loadObservables() {
    this.countryService.loadCountries();
    this.employeeService.loadEmployeesByTypes(['sales', 'processor']);
    const appSettingNames: string[] = [
      APP_SETTING_NAMES.MAXIMUM_SMARTOP_LENGTH,
      APP_SETTING_NAMES.MAXIMUM_SMARTOP_WIDTH,
      APP_SETTING_NAMES.MINIMUM_SMARTOP_LENGTH,
      APP_SETTING_NAMES.MINIMUM_SMARTOP_WIDTH,
      APP_SETTING_NAMES.SMARTOP_LENGTH_INTERVAL,
      APP_SETTING_NAMES.SMARTOP_WIDTH_INTERVAL
    ];
    this.applicationSettingService.loadApplicationSettingsByName(appSettingNames);
    const codeCategoriesToLoad = [
      FREIGHT_BUCKETS,
      FREIGHT_COMPANIES,
      INVOICE_TAX_STATUS,
      CUSTOMER_PAYMENT_TERMS,
      MARKETING_SOURCE_INVOICE,
      INVOICE_STATUS,
      INVOICE_PENDING_REASONS,
      SMARTOP_LOCKS,
      SMARTOP_MOUNTS,
      WARRANTY_DEPARTMENT_CHARGED,
      WARRANTY_REASON_CODES,
      INVENTORY_COMMODITY_CODES,
      UNITS_OF_MEASURE,
      PRODUCT_LINES,
      PRICE_LISTS,
      DEALER_TYPES_LC,
      DEALER_TYPES_MC,
      DEALER_TYPES_MP,
      DEALER_TYPES_RV,
      DEALER_TYPES_STC,
      DEALER_TYPES_STD,
      SMARTOP_RADII_METRIC,
      SMARTOP_SKIRTING_METRIC,
      SMARTOP_RADII,
      SMARTOP_SKIRTING,
    ];
    this.codeService.loadCodesByType(codeCategoriesToLoad);
  }

  private determineState() {


    if (this.queryParams) {
      this.canShowCustomerUpdate  = this.router.isActive(this.router.parseUrl('/invoice/customer'), false);

      this.canShowAddNote = this.router.isActive(this.router.parseUrl('invoice/customer-notes'), false);

      if (this.queryParams.invoice_id !== undefined) {

        this.customerSearchDisabled = true;
        this.primaryDisabled        = false;
        this.historyDisabled        = false;
        this.quickViewDisabled      = false;
        this.arrowsHidden           = true;

      } else if (this.queryParams.customer_id !== undefined) {

        this.customerSearchDisabled = false;
        this.primaryDisabled        = true;
        this.historyDisabled        = false;
        this.quickViewDisabled      = false;
        this.arrowsHidden           = false;

      } else {

        this.canShowCustomerUpdate  = false;
        this.customerSearchDisabled = false;
        this.primaryDisabled        = true;
        this.historyDisabled        = true;
        this.quickViewDisabled      = true;
        this.arrowsHidden           = true;
      }
    }

    if (this.invoice ) {
      let isInvoicePage = this.router.isActive(this.router.parseUrl('/invoice/primary'), false);

      if (this.invoice.ngState == 'load') {
        let isReversed    = typeof this.invoice.relationships.reverseInvoice !== 'undefined';
        let isReversal    = this.invoice.attributes.InvoiceIDfk !== 0;
        let isCancelled   = this.invoice.attributes.Codes_StatusIDfk === INVOICE_STATUS_CANCELLED;
        let isComplete    = this.invoice.attributes.Codes_StatusIDfk === INVOICE_STATUS_COMPLETED;

        this.canShowInvoiceReopen = isInvoicePage && !isReversed && !isReversal && isComplete;
        this.canShowInvoiceUpdate = isInvoicePage && !isComplete && !isCancelled;
        this.canShowInvoiceReturn = isInvoicePage && !isReversed && !isReversal && isComplete;

        if(this.returns) {
          this.invoice.attributes.InReturnMode = true;
        }

      } else {
        //This will allow the case when a "cancelled" invoice status is changed, it can be updated.
        this.canShowInvoiceUpdate = isInvoicePage
      }
    }
  }

  public newNote() {
    const note: Note = copyObject(DEFAULT_GENERAL_NOTE);
    note.attributes.Codes_NoteTypeIDfk = this.config.codes['noteTypes']['customer'];
    note.attributes.SourceIDfk = this.customer.attributes.CustomerID;
    note.attributes.SourceTable = 'Customers';

    // TODO: We assume that the user is an Admin if they don't have an associated Employee
    const employee: Employee = getSingleResourceRelation<Employee>(this.user.relationships, 'employee');
    note.attributes.Employees_AuthorIDfk = employee ? employee.attributes.EmployeeID : -1;

    this.bsModalRef = this.bsModalService.show(NoteModalComponent, { class: 'zIndexOverride wider-modal', backdrop: 'static'});
    this.bsModalRef.content.note = note;
    this.bsModalRef.content.editNote = copyObject(note);
  }

  public onActivate(component) {
    this.outletComponent = component;
  }

  public reverseInvoice() {
    this.updateInvoice(true);
  }

  public reopenInvoice() {
    this.updateInvoice(false, true);
  }

  public returnInvoice() {
    if (this.returns === false) {
      this.returns = true;
      this.invoice.attributes.InReturnMode = true;
    } else {
      this.returns = false;
      this.invoice.attributes.InReturnMode = false;
    }
  }

  public updateInvoice(reverse = false, reopen = false, returns = false) {
    const loader = this.loadingService.newLoader().start();

    this.invoiceService.updateInvoice({
      reverse: reverse,
      reopen: reopen,
          }).pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((data) => {
      if(returns) {
        this.invoice.attributes.InReturnMode = true;
      }
      loader.stop();
    }, (error) => {
      loader.stop();
      const message = [];
      for (const err of error.error.errors)  {
        message.push(err.detail);
      }
      this.loadingService.refreshIsLoading(false);
      this.bsModalRef = this.bsModalService.show(WarningModalComponent);
      this.bsModalRef.content.warnings = message;
    })
    ;
  }

  public updateCustomer() {
    let customer, editCustomer, editable = true, productLineCodes = [];
    const childCustomerComponent         = this.getChildCustomerComponent();
    if (childCustomerComponent instanceof CustomerInfoComponent) {
      editCustomer     = childCustomerComponent.editCustomer;
      customer         = childCustomerComponent.customer;
      editable         = childCustomerComponent.editable;
      productLineCodes = childCustomerComponent.productLineCodes;
      if (this.failedDealerChecks(editable, customer, editCustomer)) {
        return;
      }
    } else {
      customer     = childCustomerComponent.customer;
      editCustomer = customer;
    }

    this.loadingService.refreshIsLoading(true);
    this.customerService.updateCustomer(editCustomer, productLineCodes)
      .pipe(
          takeUntil(this.ngUnsubscribe)
      ).subscribe((data) => {
        if (childCustomerComponent instanceof CustomerInfoComponent) {
          childCustomerComponent.editable = false;
        }
        this.loadingService.refreshIsLoading(false);
      }, (error: Response) => {
        this.loadingService.refreshIsLoading(false);
      })
    ;
  }

  private getChildCustomerComponent(): CustomerInfoComponent | IndividualInfoComponent {
    // TODO: We are doing this because we want the button to exist outside the CustomerInfoComponent.
    // TODO: And because want the state of the Customer in the observable to be separate from the one in the CustomerInfoComponent.
    const customerInfoComponent: CustomerInfoComponent = this.outletComponent.customerInfoComponent;
    if (customerInfoComponent) {
      return customerInfoComponent;
    }
    return this.outletComponent.individualInfoComponent;
  }

  private failedDealerChecks(editable: boolean, customer: Customer, editCustomer: Customer) {
    if (!editable) {
      this.bsModalRef                  = this.bsModalService.show(WarningModalComponent);
      this.bsModalRef.content.warnings = ['Please click Edit. Customer is not currently in edit mode.'];
      return true;
      // NOTE: Implementation of NameNotChanged
    } else if (this.xPercentMatch(customer.attributes.Organization, editCustomer.attributes.Organization) < 75) {
      this.bsModalRef                  = this.bsModalService.show(WarningModalComponent);
      this.bsModalRef.content.warnings = ['If this is a Company Name change, and you want to keep the history on the original name, please change the name back and use the Name change button.'];
      return true;
    }
    return false;
  }

  private xPercentMatch(previousName: string, newName: string): number {
    const trimmedPreviousName: string = previousName.trim();
    const trimmedNewName: string      = newName.trim();
    const leastNumOfChar: number      = trimmedPreviousName.length <= trimmedNewName.length ? trimmedPreviousName.length : trimmedNewName.length;
    const mostNumOfChar: number       = trimmedPreviousName.length >= trimmedNewName.length ? trimmedPreviousName.length : trimmedNewName.length;
    let numOfDiffChar                 = 0;
    for (let index = 0; index < leastNumOfChar; index++) {
      if (trimmedPreviousName[index] !== trimmedNewName[index]) {
        numOfDiffChar++;
      }
    }
    numOfDiffChar += mostNumOfChar - leastNumOfChar;
    if (mostNumOfChar > 0) {
      return +(((mostNumOfChar - numOfDiffChar) / mostNumOfChar).toFixed(2)) * 100;
    } else if (trimmedNewName.length === 0 && trimmedPreviousName.length === 0) {
      return 100;
    }
    return 0;
  }

  public backCustomer() {
    this.indexService.setLoading();
    this.indexService.prevIndex();
  }

  public forwardCustomer() {
    this.indexService.setLoading();
    this.indexService.nextIndex();
  }

}
