import { Pagination } from './../../../../interfaces/pagination';
import { Component, OnInit, SecurityContext } from '@angular/core';
import { CustomerSearchService, CustomerSearchResponse } from '../../../../services/observables/customer-search.service';
import { Customer } from '../../../../models/customer.interface';
import { Invoice } from '../../../../models/invoice.interface';
import { getMultipleResourceRelation } from '../../../../utils/json.util';
import { Address } from '../../../../models/address.interface';
import { Code } from '../../../../models/code.interface';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Contact } from '../../../../models/contact.interface';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { CustomerService } from '../../../../services/observables/customer.service';
import { QuickViewService } from '../../../../services/observables/quick-view.service';
import { NoteService } from '../../../../services/observables/note.service';
import { MomentDatePipe } from '../../../../pipes/moment-date.pipe';
import {IndexService} from '../../../../services/observables/index.service';

@Component({
  selector: '[jet-customer-search-tab]',
  templateUrl: './customer-search-tab.component.html',
  styleUrls: ['./customer-search-tab.component.scss']
})
export class CustomerSearchTabComponent implements OnInit {

  public customers: Customer[] = [];
  public pagination: Pagination = { currentPage: 0, totalPages: 0};
  public sorting: any = {
    FullName: 'DESC',
  };
  constructor(
    private customerService: CustomerService,
    private noteService: NoteService,
    private quickViewService: QuickViewService,
    private customerSearchService: CustomerSearchService,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: MomentDatePipe,
    private activatedRoute: ActivatedRoute,
    private indexService: IndexService
  ) { }

  ngOnInit() {
    this.customerSearchService.results.subscribe((response: CustomerSearchResponse) => {
      if (response === null) {
        return;
      }
      this.customers = response.data;
      this.pagination = response.pagination;
      if (this.customers.length === 1) {
        this.loadCustomer(this.customers[0]);
        this.indexService.setIndex(0);
      }
    });
    this.indexService.index.subscribe(index => {
      this.setCustomer(index);
    });
  }

  public setCustomer(index) {
    if (index === null) {
      return;
    } else if (index < 0) {
      this.indexService.setIndex(0);
      this.indexService.NamesAreDone();
      this.indexService.NotesAreDone();
      return;
    } else if (index >= this.customers.length) {
      this.indexService.setIndex(this.customers.length - 1);
      this.indexService.NamesAreDone();
      this.indexService.NotesAreDone();
      return;
    }
    this.loadCustomer(this.customers[index]);
  }

  public sortByColumn(column: string, dir: string) {

    const queryParams: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);

    // Do something with the params
    queryParams['sorting'] = JSON.stringify({column: column, dir: dir});
    queryParams['page'] = 1;

    this.sorting = {};

    this.sorting[column] = dir !== 'ASC' ? 'ASC' : 'DESC';

    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams });
  }

  public onNextPage(page: number) {

    const queryParams: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);

    queryParams['page'] = page;

    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams });
  }

  public mostRecentInvoice(customer: Customer) {
    const billTo: Invoice = getMultipleResourceRelation<Invoice>(customer.relationships, 'recentInvoiceAsBillTo')[0];
    const shipTo: Invoice = getMultipleResourceRelation<Invoice>(customer.relationships, 'recentInvoiceAsShipTo')[0];
    if (billTo && shipTo) {
      return billTo.attributes.InvoiceID > shipTo.attributes.InvoiceID ? billTo : shipTo;
    } else if (billTo) {
      return billTo;
    } else if (shipTo) {
      return shipTo;
    } else {
      return null;
    }
  }

  // TODO: Billing Address is always considered to be the Address with sequence of 1
  public billingAddress(customer: Customer): SafeHtml {
    const addresses: Address[] = getMultipleResourceRelation<Address>(customer.relationships, 'addresses');
    // TODO: We assume we can grab the first one since they should be sorted by sequence number ascending
    const billingAddress: Address = addresses[0];
    return this.addressRepresentation(billingAddress);
  }

  // TODO: Shipping Address is always considered to be the Address that is not sequence 1 that contains 'ship' in Description
  public shippingAddress(customer: Customer): SafeHtml {
    const addresses: Address[] = getMultipleResourceRelation<Address>(customer.relationships, 'addresses');
    let shippingAddress: Address = null;
    for (const address of addresses) {
      const addressHasShipDesc = address.attributes.Description.toLowerCase().indexOf('ship') !== -1;
      if (address.attributes.AddressSequence !== 1 && addressHasShipDesc) {
        shippingAddress = address;
        break;
      }
    }
    return this.addressRepresentation(shippingAddress);
  }

  private addressRepresentation(address: Address): SafeHtml {
    let representation = '';
    if (!address) {
      return representation;
    }
    const { Line1, Line2, City, State, ZIP, Plus4 } = address.attributes;
    representation += `${Line1}<br>`;
    if (Line2.length > 0) {
      representation += `${Line2}<br>`;
    }
    representation += `${City}, ${State} ${ZIP}`;
    if (Plus4.length > 0) {
      representation += `-${Plus4}`;
    }
    return this.domSanitizer.bypassSecurityTrustHtml(representation);
  }

  // TODO: CodeAlt is always the representation shown for each Product Line the Customer has access to.
  public productLines(customer: Customer): SafeHtml {
    const productLines: Code[] = getMultipleResourceRelation<Code>(customer.relationships, 'productLines');
    let productLinesStr = '';
    productLines.forEach((productLine, index) => {
      if (index !== 0) {
        productLinesStr += '<br>';
      }
      productLinesStr += productLine.attributes.CodeAlt;
    });
    return this.domSanitizer.bypassSecurityTrustHtml(productLinesStr);
  }

  public phone(customer: Customer): string {
    return this.contactRepresentation(customer, 'phoneNumbers');
  }

  public fax(customer: Customer): string {
    return this.contactRepresentation(customer, 'faxNumbers');
  }

  public contactRepresentation(customer: Customer, relationship: string): string {
    const contacts: Contact[] = getMultipleResourceRelation<Contact>(customer.relationships, relationship);
    const contact: Contact = this.getFirstOfContacts(contacts);
    if (!contact) {
      return '';
    }
    const { Description, ContactValue } = contact.attributes;
    if (Description.length > 0) {
      return `${Description} - ${ContactValue}`;
    }
    return ContactValue;
  }

  public getFirstOfContacts(contacts: Contact[]): Contact {
    return contacts.find((contact) => {
      return contact.attributes.ContactSequence === 1;
    });
  }

  public invoice(customer: Customer): string {
    const mostRecentInvoice = this.mostRecentInvoice(customer);
    if (!mostRecentInvoice) {
      return '';
    }
    const { InvoiceNumber, DateShipped } = mostRecentInvoice.attributes;
    const formattedDate = this.datePipe.transform(DateShipped, 'MM/DD/YY');
    return `${InvoiceNumber} / ${formattedDate}`;
  }

  public gotoInvoice(customer: Customer) {
    const mostRecentInvoice = this.mostRecentInvoice(customer);
    if (!mostRecentInvoice) {
      return;
    }
    this.router.navigate(['invoice/primary'], {
      queryParams: { invoice_id: mostRecentInvoice.attributes.InvoiceID, customer_id: customer.attributes.CustomerID },
      queryParamsHandling: 'merge'
    });
  }

  // NOTE: Any observables that depend on the selected customer should be updated.
  public loadCustomer(customer: Customer) {
    // If different customer reset different Observables
    if (!this.isSelectedCustomer(customer)) {
      this.quickViewService.refreshInvoice(null);
    }

    this.indexService.setTitle(customer);

    this.router.navigate([this.router.url.split('?')[0]], {
      queryParams: {
        customer_id: customer.attributes.CustomerID
      },
      queryParamsHandling: 'merge'
    }).then();
  }

  public setCustomerIndex(customer: Customer) {
    this.indexService.setLoading();
    for (let i = 0 ; i < this.customers.length ; i++) {
      if (customer.attributes.CustomerID === this.customers[i].attributes.CustomerID) {
        this.indexService.setIndex(i);
      }
    }
  }

  public isSelectedCustomer(customer: Customer): boolean {
    return +this.route.snapshot.queryParamMap.get('customer_id') === customer.attributes.CustomerID;
  }

  public isLoading() {
    return this.customerSearchService.loading;
  }

  public spaTypes(customer: Customer): string {
    const dealerTypes = getMultipleResourceRelation<Code>(customer.relationships, 'dealerTypes');
    if (dealerTypes.length > 0) {
      return dealerTypes.map((dealerType) => dealerType.attributes.CodeDesc).join(', ');
    }
    return '';
  }
}
