export const environment = {
  production: true,
  loggingInterval: 30 * 1000,
  logErrors: true,
  emailer: 'jet',
  us: {
    apiUrl: window.location.protocol + '//us-api.lc-jet.com/api/',
    baseUrl: window.location.protocol + '//us-api.lc-jet.com/',
    codes: {
      noteTypes: {
        general: 2,
        customer: 1993,
      },
      terms: {
        "Credit Card - Visa":	175,
        "Credit Card - Master Card":	179,
        "Credit Card":	203,
        "Credit Card - Amex":	1296,
        "Credit Card - Discover":	1716
      }
    },
  },
  uk: {
    apiUrl: window.location.protocol + '//uk-api.lc-jet.com/api/',
    baseUrl: window.location.protocol + '//uk-api.lc-jet.com/',
    codes: {
      noteTypes: {
        general: 2,
        customer: 2100,
      },
      terms: {
        "Credit Card - Visa":	175,
        "Credit Card - Master Card":	179,
        "Credit Card":	203,
        "Credit Card - Amex":	1296,
        "Credit Card - Discover":	1716
      }
    },
  }
};
