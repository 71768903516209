import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import { ResourceService } from '../resource.service';
import { Country } from '../../models/country.interface';


@Injectable()
export class CountryService {
  private countriesSource: BehaviorSubject<Country[]> = new BehaviorSubject<Country[]>(null);
  public countries: Observable<Country[]> = this.countriesSource.asObservable();

  constructor(private resourceService: ResourceService) {}

  loadCountries(): Observable<Country[]> {

    if (this.areCountriesDefined()) {
      return this.countries;
    }

    const params = {
      sorting: {
        Preferred: 'desc',
        CountryName: 'asc'
      },
      criteria: {
        exists: true
      }
    };
    this.resourceService.getResources<Country>('countries', params).subscribe((countries: Country[]) => {
      this.countriesSource.next(countries);
    });

    return this.countries;
  }

  clearCountries(): void {
    this.refreshCountries(null);
  }

  refreshCountries(countries: Country[]): void {
    this.countriesSource.next(countries);
  }

  areCountriesDefined() {
    return this.countriesSource.getValue() !== null;
  }
}

