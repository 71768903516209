<jet-menu></jet-menu>
<div class="container-fluid">

    <div class="header"></div><!-- /header -->

    <div class="content row">
        <div id="sidebar" class="col-lg-2 col-custom col-sm-12 order-lg-0 order-2">
            <div class="hidden-sm hidden-xs row">
                <div class="col-md-12">
                    <jet-customer-search></jet-customer-search>
                </div>
            </div> <!-- Desktop sidebar - hides on mobile -->
        </div> <!-- /sidebar -->

        <div class="tabs col-lg-10 col-custom col-sm-12">
            <ul class="nav nav-tabs">
                <li class="nav-item" *ngFor="let tab of tabs">
                    <span class="nav-link" [ngClass]="{ active: tab.active}">
                        <a
                                [routerLink]="tab.route ? tab.route: '#'"
                                [ngClass]="{ disabled: tab.active}"
                        >{{ tab.name }}</a>
                        <a *ngIf="tab.exitTo" [routerLink]="tab.exitTo" class="close" type="button" >×</a>
                    </span>
                </li>
            </ul>

            <div class="tab-content">
                <router-outlet></router-outlet>
            </div>
        </div> <!-- /tabs -->
    </div><!-- /row -->
</div> <!-- /container -->
