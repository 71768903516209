
import {Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { STORAGE_TOKEN } from '../auth.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modified = req.clone({setHeaders: {'token': localStorage.getItem(STORAGE_TOKEN) ? localStorage.getItem(STORAGE_TOKEN) : ''}});
    return next.handle(modified);
  }

}


