import {InjectionToken} from "@angular/core";

export const DOMAIN_CONFIG = new InjectionToken<DomainConfigInterface>('domain.config');

export interface DomainConfigInterface {
  isUk: boolean;
  defaultCounty: string,
  strings: Object,
  apiUrl: string,
  baseUrl: string,
  dateFormat: string,
  codes: Object,
}
