import {Component, Inject, Input} from '@angular/core';
import {Address} from '../../../../models/address.interface';
import {AddressComponent} from '../../../shared/components/address/address.component';
import {copyObject} from '../../../../utils/json.util';
import {DEFAULT_ADDRESS} from '../../../../mocks/address.mock';
import {SOURCE_TYPE_CUSTOMER} from '../../../../utils/constants.util';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {LoadingService} from '../../../../services/observables/loading.service';
import {ResourceService} from "../../../../services/resource.service";

@Component({
  selector: 'jet-customer-addresses',
  templateUrl: './customer-addresses.component.html',
  styleUrls: ['./customer-addresses.component.scss']
})
export class CustomerAddressesComponent {
  @Input() public key: string;
  @Input() public addresses: Address[] = [];
  @Input() public customerId = 0;
  public emptyAddress: Address = null;
  public STATE_VIEW = AddressComponent.STATIC_STATES.VIEW;
  public STATE_ADD = AddressComponent.STATIC_STATES.ADD;
  public showAddForm = false;

  constructor(
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
    public loadingService: LoadingService,
    private resourceService: ResourceService,
  ) {
    const resource: Address = copyObject(DEFAULT_ADDRESS);
    resource.attributes.Country = this.config.defaultCounty;
    resource.attributes.SourceType = SOURCE_TYPE_CUSTOMER;
    this.emptyAddress = resource;
  }

  public toggleShowAddFormOnClick(event) {
    event.stopPropagation();
    this.toggleShowAddForm();
  }

  private toggleShowAddForm() {
    this.showAddForm = !this.showAddForm;
  }

  public newAddressAddHandler(success) {
    if (success) {
      this.toggleShowAddForm();
    }
  }

  public newAddressCancelHandler(success) {
    this.toggleShowAddForm();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.addresses, event.previousIndex, event.currentIndex);
    const swapLoader = this.loadingService.newLoader();
    swapLoader.start();
    for (let i = 0 ; i < this.addresses.length ; i++) {
      // pentad requires this to start at 1.
      this.addresses[i].attributes.AddressSequence = i + 1;
      this.resourceService.updateResource('addresses', + this.addresses[i].id, this.addresses[i].attributes)
        .subscribe(() => {
          if (i === this.addresses.length - 1) {
            swapLoader.stop();
          }
        });
    }
  }
}
