import {take, takeUntil} from 'rxjs/operators';
import {LoadingService} from '../../../../services/observables/loading.service';
import {PentadService} from '../../../../services/pentad.service';
import {Component, ElementRef, ErrorHandler, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {InvoiceService} from '../../../../services/observables/invoice.service';
import {Invoice} from '../../../../models/invoice.interface';
import {UserService} from "../../../../services/observables/user.service";
import {Subject} from 'rxjs';
import {WarningModalComponent} from '../warning-modal/warning-modal.component';
import {ResourceService} from "../../../../services/resource.service";
import {InvoiceActionsService} from "../../../../services/invoice-actions.service";

@Component({
  selector: 'jet-send-to-shipping-modal',
  templateUrl: './send-to-shipping-modal.component.html',
  styleUrls: ['./send-to-shipping-modal.component.scss']
})
export class SendToShippingModalComponent implements OnInit, OnDestroy {

  @ViewChild('send-to-shipping') iframe: ElementRef;
  @ViewChild('modalMessage') modalMessage: ElementRef;

  public sendToShippingUrl: SafeResourceUrl;
  public loadingIframe  = true;
  public invoice: Invoice;
  private ngUnsubscribe = new Subject();
  public confirmDate    = false;
  public confirmTerms   = false;
  private doc: any;

  constructor(
    public sanitizer: DomSanitizer,
    public invoiceService: InvoiceService,
    public invoiceActionsService: InvoiceActionsService,
    public resourceService: ResourceService,
    public pentadService: PentadService,
    public bsModalRef: BsModalRef,
    public loadingService: LoadingService,
    public userService: UserService,
    private bsModalService: BsModalService,
    private errorHandler: ErrorHandler,
  ) {
  }

  async ngOnInit() {
    try {
      this.invoice = await this.invoiceService.updateInvoice().pipe(take(1)).toPromise();
    } catch (error) {
      this.errorHandler.handleError(error);
      const message = [];
      for (const err of error.error.errors) {
        message.push(err.detail);
      }
      this.loadingService.refreshIsLoading(false);
      this.bsModalRef.hide();
      this.bsModalRef                  = this.bsModalService.show(WarningModalComponent);
      this.bsModalRef.content.warnings = message;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public sendToShipping(confirmed: boolean = false) {

    const loader = this.loadingService.newLoader().start();
    this.invoiceActionsService.sendToShipping(this.invoice.attributes.InvoiceID, confirmed, this.confirmDate, this.confirmTerms)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe((response) => {
        loader.stop();
        if (response.confirmMessage) {

          if (response.confirmField == 'date') {
            this.confirmDate = true;
          }

          if (response.confirmField == 'terms') {
            this.confirmTerms = true;
          }

          this.modalMessage.nativeElement.innerHTML = response.confirmMessage;

          return;
        }

        if (response.errorMessage) {
          this.bsModalRef.hide();
          this.bsModalRef                  = this.bsModalService.show(WarningModalComponent);
          this.bsModalRef.content.warnings = [`Error sending invoice to shipping: ${response.errorMessage}`];
          loader.stop();

          return;
        }

        // Fetch invoice in case it has changed
        this.invoiceService.getInvoice(this.invoice.attributes.InvoiceID, true);
        this.bsModalRef.hide();
      },
      (error) => {
        this.bsModalRef.hide();
        loader.stop();
        this.bsModalRef                  = this.bsModalService.show(WarningModalComponent);
        this.bsModalRef.content.warnings = [error.message];
      }
    )
    ;
  }

  private hideModal() {
    this.bsModalRef.hide();
  }
}
