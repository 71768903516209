import {CustomerActionsService} from './../../../../services/customer-actions.service';
import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {Customer} from '../../../../models/customer.interface';
import {copyObject} from '../../../../utils/json.util';
import {DEFAULT_INDIVIDUAL_CUSTOMER} from '../../../../mocks/customer.mock';
import {WarningModalComponent} from '../../../shared/components/warning-modal/warning-modal.component';
import {CustomerService} from '../../../../services/observables/customer.service';
import {LoadingService} from '../../../../services/observables/loading.service';
import {DEFAULT_WORK_ADDRESS} from '../../../../mocks/address.mock';
import {
  WORK_EMAIL_CONTACT,
  WORK_FAX_CONTACT,
  WORK_PHONE_CONTACT,
  WORK_URL_CONTACT
} from '../../../../mocks/contacts.mock';
import {CONTACT_TYPE_EMAIL, CONTACT_TYPE_FAX, CONTACT_TYPE_PHONE,} from '../../../../utils/constants.util';
import {Contact} from '../../../../models/contact.interface';
import {firstValueFrom, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {ResourceService} from "../../../../services/resource.service";
import {LoggingService} from "../../../../services/logging.service";
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';


const defaultWorkContacts = {
  [CONTACT_TYPE_PHONE]: WORK_PHONE_CONTACT,
  [CONTACT_TYPE_FAX]: WORK_FAX_CONTACT,
  [CONTACT_TYPE_EMAIL]: WORK_EMAIL_CONTACT,
};

@Component({
  selector: 'jet-customer-contacts',
  templateUrl: './customer-contacts.component.html',
  styleUrls: ['./customer-contacts.component.scss']
})
export class CustomerContactsComponent implements OnInit, OnDestroy {
  @Input() public individuals: Customer[] = [];
  @Input() public parentCustomerId        = 0;
  @Input() public parentCustomerType      = 0;
  public editIndividual: Customer         = null;
  public showEditForm                     = false;
  public bsModalRef: BsModalRef;
  private ngUnsubscribe                   = new Subject();


  constructor(private bsModalService: BsModalService,
              private customerService: CustomerService,
              private loadingService: LoadingService,
              private resourceService: ResourceService,
              private customerActionsService: CustomerActionsService,
              private logService: LoggingService,
              @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
  ) {
  }

  public readonly phone = CONTACT_TYPE_PHONE;
  public readonly fax   = CONTACT_TYPE_FAX;
  public readonly email = CONTACT_TYPE_EMAIL;

  public console = console;

  public ngOnInit() {
    this.individuals.forEach((individual: any) => {
      individual.editing = false;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public showEditFormOnClick(event, individual?: Customer) {
    event.stopPropagation();
    this.showEditForm = false;
    let allReadyEditing = false;
    this.individuals.forEach((theIndividual: any) => {
      if (theIndividual.editing) {
        allReadyEditing = true;

        return;
      }
    });

    if (allReadyEditing) {
      return;
    }

    if (!individual) {
      return;
    }

    individual.editing = true;

    if (individual.relationships.addresses.length === 0) {

      individual.relationships.addresses                       = [
        copyObject(DEFAULT_WORK_ADDRESS),
      ];
      individual.relationships.addresses[0].attributes.Country = this.config.defaultCounty;
    }

    if (individual.relationships.contacts.length === 0) {
      individual.relationships.contacts = [
        copyObject(WORK_PHONE_CONTACT),
        copyObject(WORK_FAX_CONTACT),
        copyObject(WORK_EMAIL_CONTACT),
        copyObject(WORK_URL_CONTACT),
      ];
    }

    individual.editing = true;
  }

  public createNewContact(event) {
    event.stopPropagation();
    this.showEditForm = !this.showEditForm;
    if (!this.showEditForm) {
      return;
    }
    this.editIndividual                         = copyObject(DEFAULT_INDIVIDUAL_CUSTOMER);
    this.editIndividual.relationships.addresses = [
      copyObject(DEFAULT_WORK_ADDRESS),
    ];

    this.editIndividual.relationships.addresses[0].attributes.Country = this.config.defaultCounty;

    this.editIndividual.relationships.contacts = [
      copyObject(WORK_PHONE_CONTACT),
      copyObject(WORK_FAX_CONTACT),
      copyObject(WORK_EMAIL_CONTACT),
      copyObject(WORK_URL_CONTACT),
    ];
  }

  public getContactByType(type: number, individual?: Customer): Contact {
    if (!individual.relationships.contacts) {
      individual.relationships.contacts = [];
    }
    let cont: Contact = individual.relationships.contacts.find((contact: Contact) => contact.attributes.ContactType === type);
    if (!cont) {
      cont = copyObject(defaultWorkContacts[type]);
      individual.relationships.contacts.push(cont);
    }

    return cont;
  }

  public save(individual: any) {
    if (!individual.attributes.FirstName && !individual.attributes.LastName) {
      this.bsModalRef                  = this.bsModalService.show(WarningModalComponent);
      this.bsModalRef.content.warnings = ['First name or Last name is required.'];
      return;
    }
    this.loadingService.refreshIsLoading(true);
    this.customerService.saveIndividualCustomer(individual, this.parentCustomerId, this.parentCustomerType, this.parentCustomerId, true)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe((data) => {
      this.loadingService.refreshIsLoading(false);
      individual.editing = false;
    }, (error) => {
      this.loadingService.refreshIsLoading(false);
    })
    ;
  }

  public cancel(individual?: Customer) {
    individual.editing = false;
    this.showEditForm = false;
  }

  public async remove(individual: Customer) {
    const shouldDelete = await this.showConfirmationModal(individual);
    if (shouldDelete) {
      this.loadingService.refreshIsLoading(true);
      this.customerService.removeCustomerLink(individual.attributes.CustomerToCustomerID, this.parentCustomerId)
        .subscribe((data) => {
          this.loadingService.refreshIsLoading(false);
        }, (error) => {
          this.loadingService.refreshIsLoading(false);
        });
    }
  }

  public async showConfirmationModal(individual: Customer) {
    const initialState = {
      enableConfirm: true,
      warnings: [`Do you really want to remove ${individual.attributes.FirstName} ${individual.attributes.LastName} from Contacts?`],
      title: 'Confirm Deletion',
      confirmMessage: "Delete Customer Information",
    };

    this.bsModalRef = this.bsModalService.show(WarningModalComponent, {class: 'modal-lg', initialState});

    return (await firstValueFrom(this.bsModalRef.content.onClose.pipe(take(1))))["confirm"];
  }

  public swap(direction: number, contactIndex: number) {
    const swapContactIndex = contactIndex + direction;
    if (swapContactIndex < 0 || swapContactIndex >= this.individuals.length) {
      return;
    }
    const customerToMoveId = +this.individuals[contactIndex].id;
    const customerToSwapId = +this.individuals[swapContactIndex].id;
    this.loadingService.refreshIsLoading(true);
    this.customerActionsService.swapCustomerContacts(this.parentCustomerId, customerToMoveId, customerToSwapId)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe((data) => {
      const contact     = this.individuals[contactIndex];
      const swapContact = this.individuals[swapContactIndex];
      this.individuals.splice(contactIndex, 1, swapContact);
      this.individuals.splice(swapContactIndex, 1, contact);
      this.loadingService.refreshIsLoading(false);
    }, (error) => {
      this.loadingService.refreshIsLoading(false);
    })
    ;
  }

  public drop(event: CdkDragDrop<string[]>) {
    let customerToSwapId = +this.individuals[event.currentIndex].id;
    moveItemInArray(this.individuals, event.previousIndex, event.currentIndex);
    let customerToMovedId = +this.individuals[event.currentIndex].id;
    this.customerActionsService.swapCustomerContacts(this.parentCustomerId, customerToMovedId, customerToSwapId)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(
        (data) => {},
        (error) => {
          this.loadingService.refreshIsLoading(false);
        }
      )
    ;
  }
}
