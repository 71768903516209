import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthService} from '../../../../services/auth.service';
import {LoadingService} from '../../../../services/observables/loading.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {WarningModalComponent} from '../../../shared/components/warning-modal/warning-modal.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {combineLatest} from "rxjs";
import {PentadService} from "../../../../services/pentad.service";
import {UserService} from "../../../../services/observables/user.service";

@Component({
  selector: 'jet-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {

  public email          = '';
  public password       = '';
  public show           = false;
  public bsModalRef: BsModalRef;
  private ngUnsubscribe = new Subject();
  private params: Params;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private bsModalService: BsModalService,
    private pentadService: PentadService,
    private userService: UserService
  ) {
  }

  async ngOnInit() {
    const loader = this.loadingService.newLoader().start();

    combineLatest([
      this.authService.isAuthenticated(),
      this.route.queryParams
    ]).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((values) => {
      loader.stop();

      this.params = values[1];

      if (this.params.logout) {
        this.logout();
        return;
      }

      if (!values[0]) {
        this.show = true;
        return;
      }

      this.handleLogin();
    });
  }

  private logout() {
    this.authService.logout();
    this.router.navigate(['login']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  submit() {
    const loader = this.loadingService.newLoader().start();

    this.authService.login(this.email, this.password).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async (data) => {
        await this.handleLogin();
        loader.stop();
      },
      (err) => {
        if (!err) {
          return; // TODO: Need correct format for errors
        }

        let messages = [];
        if (typeof err.error === 'undefined') {
          return; // TODO: Need correct format for errors
        }

        if (typeof err.error.error !== 'undefined') {
          messages.push(err.error.error.message);
        } else {
          messages.push(err.error.message);
        }

        this.bsModalRef                  = this.bsModalService.show(WarningModalComponent);
        this.bsModalRef.content.warnings = messages;
        loader.stop();
      }
    );
  }

  private handleLogin() {
    if (this.params.PageTarget) {
      var path = "/internal/" + decodeURI(this.params.PageTarget);
      path = path.split("|")[0];
      path = path + "?PentadSecurity";

      this.userService.getResource();
      return this.pentadService.setPentadSecurityAndRedirect(path);
    }

    return this.router.navigate(['/invoice/customer-search']);
  }
}
