<div class="card">
  <div class="card-heading" data-toggle="collapse" data-target="#invInformation">
    <h3 class="caps card-title">
      <span>information</span>
      <i class="fa fa-info-circle fa-lg"></i>
      <a class="caret"></a>
    </h3>
    <div class="card-subheading float-right">
      <div class="text-right">
        <button [attr.disabled]="!customer && !codes ? true : null" (click)="toggleEditableOnClick($event)" type="button" id="customer_edit" class="btn btn-xs btn-success">
          Edit
        </button>
      </div>
    </div>
  </div>
  <div class="card-body collapse show fivepad" id="invInformation">
    <ng-container *ngIf="!editable">
      <div class="row pad">
        <label class="col-md-5 col-form-label">products</label>
        <div class="col-md-7 zeropad">
          <ng-container *ngFor="let product of customer?.relationships?.productLines">
            {{ product.attributes.CodeAlt }}<br>
          </ng-container>
        </div>
      </div>
      <div class="row pad">
        <label class="col-md-5 col-form-label">status</label>
        <div class="col-md-7 zeropad">
          {{ customer?.attributes.CustomerActive ? 'Active' : 'Inactive'}}
        </div>
      </div>
      <div class="row pad">
        <label class="col-md-5 col-form-label">dealer</label>
        <div class="col-md-7 zeropad">
          {{ customer?.attributes.Organization }}
        </div>
      </div>
      <!-- TODO: LC Rep is for what???-->
      <!--<div class="row pad">-->
        <!--<label class="col-md-5 col-form-label">lc rep</label>-->
        <!--<div class="col-md-7 zeropad">-->
          <!--None-->
        <!--</div>-->
      <!--</div>-->
      <div class="row pad">
        <label class="col-md-5 col-form-label">terms</label>
        <div class="col-md-7 zeropad">
          {{ customer?.relationships?.paymentTerms ? customer.relationships.paymentTerms.attributes.CodeDesc : 'None' }}
        </div>
      </div>
      <div *ngFor="let additionalValue of customer?.relationships?.additionalValues" class="row pad">
        <label class="col-md-5 col-form-label">{{ fieldName(additionalValue.attributes.FieldName) }}</label>
        <div class="col-md-7 zeropad">
          {{ actualValue(additionalValue.attributes.FieldName, additionalValue.attributes.Value) }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="editable">
      <div class="form-subgroup">
        <div class="row row2">
          <div class="col-md-4">
            <input [(ngModel)]="editCustomer?.attributes.CustomerActive" type="checkbox" checked> Active<br>
            <ng-container *ngFor="let code of productLineCodes">
              <input [(ngModel)]="code.ProductLineActive" type="checkbox" checked> {{ code.CodeAlt }}<br>
            </ng-container>
          </div>
          <div class="col-md-8 onepad">

            <label class="form-control-label caps"><b>dealer</b></label>
            <input [(ngModel)]="editCustomer?.attributes.Organization" type="text" class="small-form">
            <button class="btn btn-success btn-xs btn-block mt-1" (click)="updateDealerName()">Update Dealer Name</button>

            <label class="form-control-label caps"><b>terms</b></label>
            <select [(ngModel)]="editCustomer?.attributes.Codes_PaymentTermsIDfk" class="small-form">
              <option [ngValue]="0">None</option>
              <option *ngFor="let term of paymentTermCodes" [ngValue]="term.CodeID">
                {{ term.CodeDesc }}
              </option>
            </select>
          </div>
        </div>
        <!-- Customer Actions -->
        <div class="row no-gutters">
          <div class="col-md">
            <button class="btn btn-primary btn-xs btn-block" (click)="toggleShowRenameCustomerForm()">Rename Customer</button>
          </div>
          <div class="col-md-auto">
            &nbsp;
          </div>
          <div class="col-md">
            <button class="btn btn-primary btn-xs btn-block" (click)="toggleShowMergeCustomerForm()">Merge Customers</button>
          </div>
        </div>
        <!-- Rename Customer Form -->
        <div *ngIf="showRenameCustomerForm">
          <div class="row">
            <div class="col-md-10">
              <input [(ngModel)]="newName" class="small-form">
            </div>
            <div class="col-md-2">
              <i class="fa fa-lg fa-check" (click)="renameCustomer()"></i>
              <i class="fa fa-lg fa-times" (click)="cancelRenameCustomer()"></i>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <input [(ngModel)]="showPreviousStoreHistory" type="checkbox"> Show Previous Store History<br>
            </div>
          </div>
        </div>
        <!-- Merge Customers Form -->
        <div *ngIf="showMergeCustomerForm">
          <div class="row no-gutters">
            <div class="col-md-5">
             MERGE CUSTOMER ID
            </div>
            <div class="col-md-5">
              <input [(ngModel)]="mergeCustomerID" type="number" class="small-form">
            </div>
            <div class="col-md-2">
              <i class="fa fa-lg fa-check" (click)="mergeCustomers()"></i>
              <i class="fa fa-lg fa-times" (click)="cancelMergeCustomer()"></i>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<ng-container *ngIf="editable">
<div class="card">
  <div class="card-heading" data-toggle="collapse" data-target="#customer-additional-values">
    <h3 class="caps card-title">
      additional values
      <a class="caret"></a>
    </h3>
    <div class="card-subheading float-right">
      <div class="text-right">
      </div>
    </div>
  </div>
  <div class="card-body collapse show fivepad" id="customer-additional-values">
   <div class="form-subgroup">
      <ng-container *ngFor="let additionalValue of editCustomer?.relationships?.additionalValues">
        <ng-container [ngSwitch]="additionalValue.attributes.FieldName">
          <ng-container  *ngSwitchCase="priceListFieldName">
            <div class="row">
              <label class="col-md-5 col-form-label"><b>{{ fieldName(additionalValue.attributes.FieldName) }}</b></label>
              <div class="col-md-7 zeropad">
                <select [(ngModel)]="additionalValue.attributes.Value" class="small-form">
                  <option *ngFor="let code of priceListCodes" [ngValue]="stringified(code.CodeID)">
                    {{ code.CodeDesc }}
                  </option>
                </select>
              </div>
            </div>
          </ng-container>
          <ng-container  *ngSwitchCase="taxStatusFieldName">
            <div class="row">
              <label class="col-md-5 col-form-label"><b>{{ fieldName(additionalValue.attributes.FieldName) }}</b></label>
              <div class="col-md-7 zeropad">
                <select [(ngModel)]="additionalValue.attributes.Value" class="small-form">
                  <option *ngFor="let code of taxStatusCodes" [ngValue]="stringified(code.CodeID)">
                    {{ code.CodeDesc }}
                  </option>
                </select>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="deliveryInstructionsFieldName">
            <div class="row row-stacked">
              <label class="col-md-12 col-form-label zeropad"><b>{{ fieldName(additionalValue.attributes.FieldName) }}</b></label>
              <div class="col-md-12 zeropad">
                <textarea [(ngModel)]="additionalValue.attributes.Value" class="form-control" style="height:60px !important;"></textarea>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="borderBrokerFieldName">
            <div class="row row-stacked">
              <label class="col-md-12 col-form-label zeropad"><b>{{ fieldName(additionalValue.attributes.FieldName) }}</b></label>
              <div class="col-md-12 zeropad">
                <textarea [(ngModel)]="additionalValue.attributes.Value" class="form-control" style="height:60px !important;"></textarea>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="taxRateFieldName">
              <div class="row">
                <label class="col-md-5 col-form-label"><b>{{ fieldName(additionalValue.attributes.FieldName) }}</b></label>
                <div class="col-md-7 zeropad">
                  <input [(ngModel)]="additionalValue.attributes.Value" type="number" class="small-form">
                </div>
              </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div class="row">
              <label class="col-md-5 col-form-label"><b>{{ fieldName(additionalValue.attributes.FieldName) }}</b></label>
              <div class="col-md-7 zeropad">
                <input [(ngModel)]="additionalValue.attributes.Value" type="text" class="small-form">
              </div>
            </div>
          </ng-container>
        </ng-container><!-- End Switch-->
      </ng-container><!-- End For -->
       <button class="btn btn-primary btn-xs btn-block" (click)="assignPriceListToChildren()">Assign Price List to Children</button>
    </div>
  </div>
</div>
</ng-container>
