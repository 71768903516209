import {from as observableFrom, Observable, of as observableOf} from 'rxjs';
import {catchError, filter, mergeMap, takeUntil} from 'rxjs/operators';
import { Input, OnChanges, OnDestroy, OnInit, SimpleChanges, Directive } from '@angular/core';
import {Invoice} from '../../../models/invoice.interface';
import {Customer} from '../../../models/customer.interface';
import {Contact} from '../../../models/contact.interface';
import {Country} from '../../../models/country.interface';
import {Address} from '../../../models/address.interface';
import {copyObject, getMultipleResourceRelation, getSingleResourceRelation} from '../../../utils/json.util';
import {DEFAULT_FAX_CONTACT, DEFAULT_PHONE_CONTACT} from '../../../mocks/contacts.mock';
import {DEFAULT_ADDRESS} from '../../../mocks/address.mock';
import {CUSTOMER_TYPE_CUSTOMER, INVOICE_STATUS_IN_PROGRESS} from '../../../utils/constants.util';
import {CountryService} from '../../../services/observables/country.service';
import {ResourceService} from '../../../services/resource.service';
import {CustomerService} from "../../../services/observables/customer.service";
import {Subject} from 'rxjs';
import {LoggingService} from "../../../services/logging.service";
import {DomainConfigInterface} from "../../../config/domain.interface";
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Directive()
export class ToSection implements OnInit, OnChanges, OnDestroy {
  @Input() public invoice: Invoice = null;
  public customer: Customer = null;

  public contacts: Customer[] = [];
  public countries: Country[] = null;
  public defaultPhone: Contact = copyObject(DEFAULT_PHONE_CONTACT);
  public currentPhone: Contact = null;
  public defaultFax: Contact = copyObject(DEFAULT_FAX_CONTACT);
  public currentFax: Contact = null;
  public defaultAddress: Address = copyObject(DEFAULT_ADDRESS);
  public currentAddress: Address = null;

  public customerChanged = false;
  public customerFullName: string;
  public typeaheadLoading: boolean;
  public typeaheadNoResults: boolean;
  public dataSource: Observable<Customer[]>;

  public toCustomer: string;
  public toPhone: string;
  public toFax: string;
  public toAddress: string;
  public ngUnsubscribe = new Subject();

  // TODO: to - Must be passed in by Child component for logic to work
  constructor(
    public to: string,
    private _countryService: CountryService,
    private _resourceService: ResourceService,
    private _customreService: CustomerService,
    private _logService: LoggingService,
    private _config: DomainConfigInterface,
  ) {
    this.toCustomer = `${this.to}ToCustomer` ;
    this.toPhone = `${this.to}ToPhone`;
    this.toFax = `${this.to}ToFax`;
    this.toAddress =  `${this.to}ToAddress`;
    this.defaultAddress.attributes.Country = this._config.defaultCounty
  }

  ngOnInit() {
    this._customreService.customer.pipe(
        filter(data => data !== null),
        takeUntil(this.ngUnsubscribe)
    ).subscribe((customer: Customer) => {
      this.customer = customer;
    });
    this.dataSource = Observable.create((observer: any) => {
      observer.next(this.customerFullName);
    })
      .pipe(
        mergeMap((text: string) => {
          if (this.to === 'Bill') {
            return this.customerSearch(text);
          }
          return observableFrom(new Promise(async (resolve) => {
            let results: Customer[] = [];
            if (this.customer) {
              results = getMultipleResourceRelation(this.customer.relationships, 'stores');
            }
            if (text === '') {
              resolve(results);
              return;
            }
            const allCustomers: Customer[] = await this.customerSearch(text).toPromise();
            // const customers = allCustomers.filter((customer: Customer) => results.some((store: Customer) => store.id === customer.id) === false);
            resolve(allCustomers);
          }));
        }),
        catchError(error => observableOf([]))
      )
    ;
    this._countryService.countries.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((countries: Country[]) => {
      this.countries = countries;
    });
    this.assignments();
    this.customInit();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  protected customInit() {
    // TODO: Implemented by child class
  }

  private customerSearch(text): Observable<Customer[]> {
    if (text === '' || text === undefined || text === null) {
      return observableOf([]);
    }
    const params = {
      criteria: {
        fullName: text,
        customerType: CUSTOMER_TYPE_CUSTOMER,
        customerActive: 1
      },
      sorting: {'Customers.FullName': 'asc'}
    };

    return this._resourceService.getResources<Customer>('customers', params);
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  typeaheadOnSelect(match: TypeaheadMatch): void {
    this.customerChanged = true;
    const toCap = this.to[0].toUpperCase() + this.to.slice(1);
    // NOTE: Phone, Fax, Address, Contact Person, and Customer all have to be changed
    const customer: Customer = match.item;
    this.invoice.relationships[this.toCustomer] = customer;
    this.invoice.attributes[`Customers_${toCap}ToCustomerIDfk`] = +customer.id;
    this.assignFirstOfModelRelationToReceiver(this.contactChange, this.invoice, this.toPhone, customer, `default${toCap}ToPhoneNumbers`, copyObject(DEFAULT_PHONE_CONTACT));
    this.assignFirstOfModelRelationToReceiver(this.contactChange, this.invoice, this.toFax, customer, `default${toCap}ToFaxNumbers`, copyObject(DEFAULT_FAX_CONTACT));
    const defaultAddress = copyObject(DEFAULT_ADDRESS);
    defaultAddress['attributes']['Country'] = this._config.defaultCounty;
    this.assignFirstOfModelRelationToReceiver(this.addressChange, this.invoice, this.toAddress, customer, `default${toCap}ToAddresses`, defaultAddress);
    const individuals = getMultipleResourceRelation<Customer>(customer.relationships, 'individuals');
    this.invoice.attributes[`Customers_${toCap}ToContactPersonIDfk`] = individuals[0] ? +individuals[0].id : 0;
    this.contacts = getMultipleResourceRelation<Customer>(customer.relationships, 'individuals');
    this.removeDuplicates(this.contacts);
    this.sortContacts();
  }

  assignContact(customer: Customer): void {
    this.customerChanged = true;
    const toCap = this.to[0].toUpperCase() + this.to.slice(1);
    // NOTE: Phone, Fax, Address, Contact Person, and Customer all have to be changed
    this.invoice.relationships[this.toCustomer] = customer;
    this.invoice.attributes[`Customers_${toCap}ToCustomerIDfk`] = +customer.id;
    this.assignFirstOfModelRelationToReceiver(this.contactChange, this.invoice, this.toPhone, customer, `default${toCap}ToPhoneNumbers`, copyObject(DEFAULT_PHONE_CONTACT));
    this.assignFirstOfModelRelationToReceiver(this.contactChange, this.invoice, this.toFax, customer, `default${toCap}ToFaxNumbers`, copyObject(DEFAULT_FAX_CONTACT));
    const defaultAddress = copyObject(DEFAULT_ADDRESS);
    defaultAddress['attributes']['Country'] = this._config.defaultCounty;
    this.assignFirstOfModelRelationToReceiver(this.addressChange, this.invoice, this.toAddress, customer, `default${toCap}ToAddresses`, defaultAddress);
    const individuals = getMultipleResourceRelation<Customer>(customer.relationships, 'individuals');
    this.invoice.attributes[`Customers_${toCap}ToContactPersonIDfk`] = individuals[0] ? +individuals[0].id : 0;
    this.contacts = getMultipleResourceRelation<Customer>(customer.relationships, 'individuals');
    this.removeDuplicates(this.contacts);
    this.sortContacts();
  }

  private assignFirstOfModelRelationToReceiver(changeFunc, receiver, receiverRelation, model, modelRelation, defaultValue) {
    const relationModels = getMultipleResourceRelation(model.relationships, modelRelation);
    if (relationModels.length === 0) {
      relationModels.push(defaultValue);
    }
    // TODO: Receiver's related model is guaranteed to be populated with data.
    changeFunc(receiver.relationships[receiverRelation], relationModels[0]);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['invoice']) {
      this.assignments();
    }
  }

  comparebyId(obj1: any, obj2: any) {
    if (obj1 && obj2) {
      return obj1.id == obj2.id;
    }

    return false;
  }

  contactChange(receiver: Contact, model) {
    receiver.attributes.Description = model.attributes.Description;
    receiver.attributes.ContactValue = model.attributes.ContactValue;
  }

  addressChange(receiver: Address, model) {
    receiver.attributes.Description = model.attributes.Description;
    receiver.attributes.Line1 = model.attributes.Line1;
    receiver.attributes.Line2 = model.attributes.Line2;
    receiver.attributes.City = model.attributes.City;
    receiver.attributes.State = model.attributes.State;
    receiver.attributes.ZIP = model.attributes.ZIP;
    receiver.attributes.Plus4 = model.attributes.Plus4;
    receiver.attributes.Country = model.attributes.Country;
  }

  private assignments() {
    if (!this.invoice) {

      this._logService.logger.info('info', {'to': this.to, 'message' : 'empty invoice'});

      return;
    }

    this.customerFullName = this.invoice.relationships[this.toCustomer].attributes.FullName;

    // Defaults - for when relationship doesn't exist
    const toPhone = getSingleResourceRelation<Contact>(this.invoice.relationships, this.toPhone);
    if (!toPhone) {
      this.invoice.relationships[this.toPhone] = copyObject(DEFAULT_PHONE_CONTACT);
    }
    this.currentPhone = copyObject(this.invoice.relationships[this.toPhone]);

    const toFax = getSingleResourceRelation<Contact>(this.invoice.relationships, this.toFax);
    if (!toFax) {
      this.invoice.relationships[this.toFax] = copyObject(DEFAULT_FAX_CONTACT);
    }
    this.currentFax = copyObject(this.invoice.relationships[this.toFax]);

    const toAddress = getSingleResourceRelation<Address>(this.invoice.relationships, this.toAddress);
    if (!toAddress) {
      const defaultAddress = copyObject(DEFAULT_ADDRESS);
      defaultAddress['attributes']['Country'] = this._config.defaultCounty;
      this.invoice.relationships[this.toAddress] = defaultAddress;
    }
    this.currentAddress = copyObject(this.invoice.relationships[this.toAddress]);

    // // If the invoice is in progress, update with source customer info
    // if (this.invoice.attributes.Codes_StatusIDfk === INVOICE_STATUS_IN_PROGRESS) {
    //   if (this.invoice.relationships[this.toCustomer].relationships.addresses.length > 0) {
    //     this.currentAddress = copyObject(this.invoice.relationships[this.toCustomer].relationships.addresses.find(address => address.attributes.AddressSequence >= 1));
    //     this.invoice.relationships[this.toAddress] = copyObject(this.currentAddress);
    //   }
    //
    //   if (this.invoice.relationships[this.toCustomer].relationships.faxNumbers.length > 0) {
    //     this.currentFax = copyObject(this.invoice.relationships[this.toCustomer].relationships.faxNumbers.find(fax => fax.attributes.ContactSequence >= 1));
    //     this.invoice.relationships[this.toFax] = copyObject(this.currentFax);
    //   }
    //
    //   if (this.invoice.relationships[this.toCustomer].relationships.phoneNumbers.length > 0) {
    //     this.currentPhone = copyObject(this.invoice.relationships[this.toCustomer].relationships.phoneNumbers.find(phone => phone.attributes.ContactSequence >= 1));
    //     this.invoice.relationships[this.toPhone] = copyObject(this.currentPhone);
    //   }
    // }

    this.contacts = getMultipleResourceRelation<Customer>(this.invoice.relationships[this.toCustomer].relationships, 'individuals');
    this.removeDuplicates(this.contacts);
    this.sortContacts();

    this.customAssignments();

    if (typeof (this.invoice.relationships[this.toAddress].attributes.Line1) == 'undefined' || !this.invoice.relationships[this.toAddress].attributes.Line1) {
      this._logService.logger.info('info', {'to': this.to, 'message' : 'Empty: this.invoice.relationships['+ this.toAddress+'].attributes.Line1', 'invoice': this.invoice});
    }
  }

  protected customAssignments() {
    // TODO: child class should implement as needed
  }

  private sortContacts()
  {
    // Need to sort contacts by full name
    this.contacts = this.contacts
      .sort((a, b) => {

        // Need to show default contact first
        if (a.attributes.CustomerToCustomerSequence == 1) {
          return -1;
        }

        if (b.attributes.CustomerToCustomerSequence == 1) {
          return 1;
        }

        return a.attributes.FullName.localeCompare(b.attributes.FullName)
      });


  }

  private removeDuplicates(contacts) {
    let cleanContactList = [];
    contacts.forEach(function (contact) {
      let duplicate = cleanContactList.find(element => element.attributes.FullName == contact.attributes.FullName);
      if (!duplicate) {
        cleanContactList.push(contact)
      }
    });
    return cleanContactList;
  }

  public getAddressString(address: Address) {

    return address.attributes.Description + ' (' +
      address.attributes.Line1 +
      (address.attributes.Line2? ' ' + address.attributes.Line2: '') +
      ', ' + address.attributes.City + ' ' + address.attributes.State + ', ' + address.attributes.ZIP + ')';
  }

}
