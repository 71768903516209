import {ResourceService} from "./resource.service";
import {Injectable} from "@angular/core";
import {MenuItem} from "../models/menu-item.interface";
import {HistoryItem} from "../models/history-item.interface";
import {RecordChange} from "../models/record-change.interface";
import {HistoryWrapper} from "../models/history-wrapper";
import {Invoice} from "../models/invoice.interface";

@Injectable()
export class HistoryService {

  private data: {
    [key: string]: {
      [key: number]: {
        lineItemHistory: HistoryItem[],
        history: HistoryItem[],
        recordChanges: RecordChange[],
        modifications: HistoryWrapper[]
      }
    }
  } = {};

  constructor(private resourceService: ResourceService) {
  }

  getHistory(resource: string, invoice: Invoice) {

    if (!this.data[resource]) {
      this.data[resource] = {};
    }

    let id = Number(invoice.id).valueOf();

    if (!this.data[resource][id]) {
      this.data[resource][id] = {
        lineItemHistory: null,
        history: null,
        recordChanges: null,
        modifications: null
      };
    }

    return new Promise((resolve, reject) => {

      this.data[resource][id].lineItemHistory = [];
      for (let i = 0; i < invoice.relationships.lineItems.length; i++) {
        const lineItem = invoice.relationships.lineItems[i];
        for (let j = 0; j < lineItem.relationships.histories.length; j++) {
          const historyItem = lineItem.relationships.histories[j];
          historyItem.attributes.messageArray['key'] = lineItem.attributes.Description + ' ' + historyItem.attributes.messageArray['key'];
          this.data[resource][id].lineItemHistory.push(historyItem);
        }
      }

      for (let i = 0; i < invoice.relationships.deletedLineItems.length; i++) {
        const lineItem = invoice.relationships.deletedLineItems[i];
        for (let j = 0; j < lineItem.relationships.histories.length; j++) {
          const historyItem = lineItem.relationships.histories[j];
          historyItem.attributes.messageArray['key'] = lineItem.attributes.Description + ' ' + historyItem.attributes.messageArray['key'];
          this.data[resource][id].lineItemHistory.push(historyItem);
        }
      }

      this.resourceService.getResources<HistoryItem>(resource + '/' + id + '/history', {paginate: 10000}).subscribe((items: HistoryItem[]) => {

        this.data[resource][id].history = items;

        if (this.consolidateHistory(resource, id)) {
          resolve(this.data[resource][id].modifications);
        }
      });

      this.resourceService.getResources<RecordChange>('record-changes', {
        criteria: {
          recordIDfk: id,
          RecordTableName: resource
        }
      }).subscribe((items: RecordChange[]) => {

        this.data[resource][id].recordChanges = items;

        if(this.consolidateHistory(resource, id)) {
          resolve(this.data[resource][id].modifications);
        }
      });
    });
  }

  private consolidateHistory(resource: string, id: number):boolean {
    if (!this.data[resource][id].history || !this.data[resource][id].recordChanges) {
      return false;
    }

    const historyMap = new Map();
    const history = this.data[resource][id].history;
    const lineItemHistory = this.data[resource][id].lineItemHistory;

    for (let i = 0; i < history.length; i++) {
      if (historyMap.get(history[i].attributes.created_at)) {
        historyMap.get(history[i].attributes.created_at).push(history[i]);

        continue;
      }

      historyMap.set(history[i].attributes.created_at, new HistoryWrapper([history[i]]));
    }

    for (let i = 0; i < lineItemHistory.length; i++) {
      if (historyMap.get(lineItemHistory[i].attributes.created_at)) {
        historyMap.get(lineItemHistory[i].attributes.created_at).push(lineItemHistory[i]);

        continue;
      }

      historyMap.set(lineItemHistory[i].attributes.created_at, new HistoryWrapper([lineItemHistory[i]], 'Items'));
    }

    let recordChanges = this.data[resource][id].recordChanges;
    for (let i = 0; i < recordChanges.length; i++) {
      if (historyMap.get(recordChanges[i].attributes.ChangeDate)) {
        historyMap.get(recordChanges[i].attributes.ChangeDate).push(recordChanges[i]);

        continue;
      }

      historyMap.set(recordChanges[i].attributes.ChangeDate, new HistoryWrapper([recordChanges[i]]));
    }

    let keys = Array.from(historyMap.keys()).sort().reverse();

    this.data[resource][id].modifications = [];
    for (let i = 0; i < keys.length; i++) {
      this.data[resource][id].modifications.push(historyMap.get(keys[i]));
    }

    return true;
  }
}
