<div class="card">
  <div class="card-heading" data-toggle="collapse" data-target="#ypes">
    <h3 class="caps card-title">
      <span>payment types</span>
      <i class="fa fa-lg fa-credit-card-alt"></i><br>
      <a class="caret"></a>
    </h3>
    <div class="card-subheading float-right">
      <div class="text-right">
        <i class="fa fa-lg fa-plus" (click)="toggleShowAddFormOnClick($event)"></i>
      </div>
    </div>
  </div>
  <div class="card-body collapse show fivepad" id="custPaymentTypes">

    <table class="small table table-striped table-hover table-borderless" style="margin-bottom: 0;">
      <thead>
      <tr>
        <th></th>
        <th>Desc.</th>
        <th>Last 4</th>
        <th>Exp.</th>
        <th></th>
      </tr>
      </thead>
      <tbody cdkDropList (cdkDropListDropped)="drop($event)" class="jet-table">
        <tr *ngFor="let paymentMethod of paymentTypes" jet-payment-method [parentType]="'customer'"
            [customerId]="customerId" [paymentMethod]="paymentMethod" [state]="STATE_VIEW" class="jet-row" cdkDrag>
          <div slot="drag-handle" class="jet-column-reorder" cdkDragHandle>
            <i class="fas fa-grip-vertical"></i>
          </div>
        </tr>
      <tr *ngIf="paymentTypes?.length === 0">
        <td colspan="12">No records to display</td>
      </tr>
      <tr *ngIf="showAddForm" jet-payment-method  [customerId]="customerId" [paymentMethod]="emptyPaymentMethod" [state]="STATE_ADD"
        (onAdd)="newPaymentMethodAddHandler($event)" (onCancel)="newPaymentMethodCancelHandler($event)">
      </tr>
      </tbody>
    </table>
  </div>
</div>
