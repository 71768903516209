import { Note } from './note.interface';
import { NOTE_TYPES_GENERAL } from '../utils/constants.util';
export const DEFAULT_GENERAL_NOTE: Note = {
  id: '0',
  type: 'Note',
  attributes: {
    NoteID: 0,
    SourceTable: '',
    SourceIDfk: 0,
    Codes_NoteTypeIDfk: NOTE_TYPES_GENERAL,
    NoteDate: '',
    Note: '',
    NoteSequence: 0,
    Employees_AuthorIDfk: 0,
    NoteActive: 1,
    DateInactive: '',
    Category: '',
  }
};
