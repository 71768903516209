import {interval as observableInterval} from 'rxjs';
import {Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot} from "@angular/router";
import {QueryService} from "../../../../services/observables/query.service";
import {INVOICE_STATUS_CANCELLED, INVOICE_STATUS_COMPLETED} from "../../../../utils/constants.util";
import {Customer} from '../../../../models/customer.interface';
import {IndexService} from '../../../../services/observables/index.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";

@Component({
  selector: 'jet-history-invoices',
  templateUrl: './history-invoices.component.html',
  styleUrls: ['./history-invoices.component.scss']
})
export class HistoryInvoicesComponent implements OnInit, OnChanges, OnDestroy  {

  public invoices: any[] = [];
  private snapshot: ActivatedRouteSnapshot;
  @Input() public customer: Customer;
  public dateFormat:string;
  private sub;
  private ngUnsubscribe = new Subject();

  constructor(
      private route: ActivatedRoute,
      private queryService: QueryService,
      private indexService: IndexService,
      @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
      ) {
    this.snapshot = route.snapshot;
    this.dateFormat = this.config.dateFormat;
  }

  ngOnInit() {
    if (this.customer !== null) {
      this.queryService.getCustomerInvoiceHistory(this.customer.attributes.CustomerID)
          .pipe(
              takeUntil(this.ngUnsubscribe)
          ).subscribe(
              (data) => {
                this.invoices = data;
              })
      ;
    }
    this.sub = observableInterval(5000).pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      if (this.customer !== null && this.invoices.length > 0) {
        this.queryService.refreshInvoiceHistory(this.customer.attributes.CustomerID);
      }
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    let cur;
    let prev;
    let name;
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        const chng = changes[propName];
        name = propName;
        cur = chng.currentValue;
        prev = chng.previousValue;
      }
    }
    if (cur !== prev && name === 'customer' && this.customer !== null) {
      this.queryService.getCustomerInvoiceHistory(this.customer.attributes.CustomerID)
        .pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe(
        (data) => {
          this.invoices = data;
        })
      ;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  isLoading() {
    const isInvoiceLoading = this.queryService.isInvoiceHistoryLoading();
    const isProductLoading = this.queryService.isProductHistoryLoading();
    // have to wait for the names to load before the history tab starts loading
    if (!this.indexService.getWaitingForNamesAndNotes()) {
      // do not enable arrows until both history tabs have loaded
      this.indexService.setLoadingManual(isInvoiceLoading || isProductLoading);
    }
    return isInvoiceLoading;
  }

  recordClass(invoice: any) {
    switch (invoice.Status) {
      case INVOICE_STATUS_COMPLETED:
      case INVOICE_STATUS_CANCELLED:
        return ''; // no table class applied for completed/cancelled
      default:
        return 'invoice-history-highlight'; // all other statuses are highlighted
    }
  }

}
