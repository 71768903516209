import {Component, Inject, OnInit} from '@angular/core';
import {SmartopOrderSubmissionService} from "../../../services/observables/smartop-order-submission.service";
import {filter, tap} from "rxjs/operators";
import {LoadingService} from "../../../services/observables/loading.service";
import {ResourceService} from "../../../services/resource.service";
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../config/domain.interface";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import {MomentDatePipe} from "../../../pipes/moment-date.pipe";

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  contestResults = [];
  public dateFormat: string;
  public startDate: string = '';
  public endDate: string = '';

  constructor(
    private smartopOrderService: SmartopOrderSubmissionService,
    private loadingService: LoadingService,
    private resourceService: ResourceService,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
    private dateP: BsDatepickerConfig,
    private momentDatePipe: MomentDatePipe,
  ) {
    this.dateFormat = this.config.dateFormat;
    dateP.dateInputFormat = this.dateFormat;
  }

  async ngOnInit() {
  }

  async getContestRangeResults() {
    const loader = this.loadingService.newLoader().start();
    const results: Object | undefined = await this.smartopOrderService.salesContest(this.startDate, this.endDate)
      .pipe(
        tap(() => loader.stop())
      ).toPromise();
    this.contestResults = results['results'];
  }

  async updateResults(result) {
    const loader = this.loadingService.newLoader().start();
    await this.smartopOrderService.salesContestUpdate(result)
      .pipe(
        tap(() => loader.stop())
      ).toPromise();
    result.editing = false;
  }

  updateStartDate(startDate: Date) {
    this.startDate = this.momentDatePipe.transform(startDate, 'Y-MM-DD');
  }

  updateEndDate(endDate: Date) {
    // endDate.setDate(endDate.getDate()+1);
    this.endDate = this.momentDatePipe.transform(endDate, 'Y-MM-DD');
  }

  updateDate(date: string){
    // return date.replace(/:\d+([AP]M)$/, '$1');
    return date.replace(/\.?0*$/, '');
  }

  exportToCsv() {
    const csv = this.convertToCsv(this.contestResults);
    this.downloadCsv(csv, (Date.now().toString() + '.csv'));
  }

  editResult(result) {
    result.editing = !result.editing;
  }

  saveEdit(result) {
    this.updateResults(result);
  }

  private convertToCsv(data: any[]): string {
    const fieldOrder = [
      'salespersonLastName',
      'salespersonFirstName',
      'companyName',
      'orderDate',
      'customerName',
      'CIN',
      'style',
      'reason',
      'location',
    ];

    const fieldsToRemove = ['note', 'description', 'editing'];
    const updatedData = data.map(row => {
      const filteredRow = Object.entries(row).reduce((acc, [key, value]) => {
        if (!fieldsToRemove.includes(key)) {
          acc[key] = value;
        }
        return acc;
      }, {});
      return filteredRow;
    });

    const header = fieldOrder.join(',') + '\n';

    const rows = updatedData.map(row => {
      const orderedRow = fieldOrder.map(fieldName => {
        const value = row[fieldName];
        if (typeof value === 'string' && (value.includes(',') || value.includes('\n') || value.includes('\r'))) {
          return `"${value.replace(/"/g, '""').replace(/\n|\r/g, ' ')}"`;
        }
        return value;
      }).join(',');

      return orderedRow;
    }).join('\n');

    return header + rows;
  }

  private downloadCsv(csv: string, filename: string) {
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if ((navigator as any).msSaveBlob) { // IE 10+
      (navigator as any).msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

}
