import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import {LoadingToken} from "./loading-token";

@Injectable()
export class LoadingService {
  private isLoadingSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading: Observable<boolean> = this.isLoadingSource.asObservable();
  private flashMessage: string;
  private bcLoadingToken: LoadingToken;
  private loaders: Array<LoadingToken>;

  constructor() {

    this.loaders = [];
    this.bcLoadingToken = this.newLoader();
  }

  /**
   * @deprecated use newLoader().start() instead
   * @param value
   */
  refreshIsLoading(value: boolean) {

    if (value) {
      this.bcLoadingToken.start();
    } else {
      this.bcLoadingToken.stop();
    }
  }

  newLoader(): LoadingToken {
    const loadingToken = new LoadingToken(() => this.update());

    this.loaders.push(loadingToken);

    return loadingToken;
  }

  private update() {
    for (let i = 0; i < this.loaders.length; i++) {
      if (this.loaders[i].isLoading()) {
        this.isLoadingSource.next(true);

        return;
      }
    }

    this.isLoadingSource.next(false);
  }

  getFlash(): string {
    let message = localStorage.getItem('flash.message');

    localStorage.removeItem('flash.message');

    return message;
  }

  setFlash(message: string) {
    localStorage.setItem('flash.message', message);
  }

  public isLoadingValue() {
    return this.isLoadingSource.getValue();
  }
}

