import {Component, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {NoteModalComponent} from '../../../shared/components/note-modal/note-modal.component';
import {copyObject, getSingleResourceRelation} from '../../../../utils/json.util';
import {DEFAULT_GENERAL_NOTE} from '../../../../mocks/note.mocks';
import {NoteService} from '../../../../services/observables/note.service';
import {Note} from '../../../../mocks/note.interface';
import {ActivatedRoute} from '@angular/router';
import {User} from '../../../../models/user.interface';
import {UserService} from '../../../../services/observables/user.service';
import {Employee} from '../../../../models/employee.interface';
import {getEmployeeName} from '../../../../utils/user.util';
import {EventService} from '../../../../services/event.service';
import {LoadingService} from '../../../../services/observables/loading.service';
import {CustomerActionsService} from '../../../../services/customer-actions.service';
import {IndexService} from '../../../../services/observables/index.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";
import {Customer} from "../../../../models/customer.interface";
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

declare let jQuery: any;
declare let document: any;

@Component({
  selector: 'jet-customer-notes',
  templateUrl: './customer-notes.component.html',
  styleUrls: ['./customer-notes.component.scss']
})
export class CustomerNotesComponent implements OnInit, OnDestroy, OnChanges {
  private bsModalRef: BsModalRef;
  public generalNoteCode: number;
  public customerNoteCode: number;
  public notes: Note[]  = null;
  public user: User     = null;
  public moreNotes      = false;
  public noteEndIndex   = 5;
  public dateFormat: string;
  private ngUnsubscribe = new Subject();
  @Input() public customer: Customer;

  constructor(
    private bsModalService: BsModalService,
    private noteService: NoteService,
    private userService: UserService,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private ref: ElementRef,
    private eventService: EventService,
    private customerActionsService: CustomerActionsService,
    private indexService: IndexService,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
  ) {
    this.dateFormat       = this.config.dateFormat;
    this.generalNoteCode  = this.config.codes['noteTypes']['general'];
    this.customerNoteCode = this.config.codes['noteTypes']['customer'];
  }

  ngOnInit() {
    this.noteService.notes.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((notes: Note[]) => {
      if (!notes) {
        return;
      }

      if (Object.keys(notes).length === 0) {
        this.notes = [];
      } else {
        this.notes = notes.filter((note) => {
          return note.attributes.Codes_NoteTypeIDfk === this.generalNoteCode;
        }).sort((a: Note, b: Note) => {
          return a.attributes.NoteSequence - b.attributes.NoteSequence;
        })
      }
      this.adjustAllNotes();
      this.indexService.NotesAreDone();
    });

    this.userService.user.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((user: User) => {
      this.user = user;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public employeeName(note: Note): string {
    return getEmployeeName(note);
  }

  public compressExpandNote(noteEl: Element, note: any) {
    note.expanded = !note.expanded;
  }

  public adjustAllNotes() {
    jQuery('#customerNotes').ready(() => {
      document.querySelectorAll('.note-item').forEach((element) => {
        const id               = element.getAttribute('id');
        const currentNote: any = this.notes.find(note => note.id === id);
        this.adjustNote(element, currentNote);
      });
    });
  }

  // TODO: Need to setup different handling for onload. This handler is never called from the standard (load)="adjustNote(...)"
  public adjustNote(noteEl: Element, note: any) {
    if (note.expanded === undefined) {
      note.expanded = false;
    }
    if (noteEl.scrollHeight <= 75) {
      note.expandable = false;
    } else {
      note.expandable = true;
    }
  }

  public toggleMoreNotes() {
    this.moreNotes    = !this.moreNotes;
    this.noteEndIndex = this.moreNotes ? this.notes.length : 5;
  }

  public newNote(event: Event) {
    event.stopPropagation();
    this.bsModalRef                      = this.bsModalService.show(NoteModalComponent, {
      class: 'zIndexOverride wider-modal',
      backdrop: 'static'
    });
    const note: Note                     = copyObject(DEFAULT_GENERAL_NOTE);
    note.attributes.SourceIDfk           = this.customer.attributes.CustomerID;
    note.attributes.SourceTable          = 'Customers';
    // TODO: We assume that the user is an Admin if they don't have an associated Employee
    const employee: Employee             = getSingleResourceRelation<Employee>(this.user.relationships, 'employee');
    note.attributes.Employees_AuthorIDfk = employee ? employee.attributes.EmployeeID : -1;
    this.bsModalRef.content.note         = note;
    this.bsModalRef.content.editNote     = copyObject(note);
  }

  public viewNote(note: Note) {
    this.bsModalRef                  = this.bsModalService.show(NoteModalComponent, {class: 'zIndexOverride  wider-modal'});
    this.bsModalRef.content.note     = note;
    this.bsModalRef.content.editNote = copyObject(note);
  }

  public removeNote(note) {
    const deleteLoader = this.loadingService.newLoader();
    deleteLoader.start();

    note.attributes.Codes_NoteTypeIDfk = this.customerNoteCode;

    this.noteService.updateResource(note).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (data) => deleteLoader.stop(),
      (error) => deleteLoader.stop()
    );
  }

  onDragStart(event) {
    const noteId = event.target.getAttribute('data-note-id');
    event.dataTransfer.setData('noteId', noteId);
  }

  onDrop(event) {
    const $noteSection = event.target.closest('.note-section');
    if (!$noteSection) {
      return;
    }
    const fromNoteId                 = event.dataTransfer.getData('noteId');
    const toNoteId                   = $noteSection.getAttribute('data-note-id');
    const fromNote                   = this.notes.find((note) => note.id === fromNoteId);
    const toNoteIndex                = this.notes.findIndex((note) => note.id === toNoteId);
    fromNote.attributes.NoteSequence = this.notes[toNoteIndex].attributes.NoteSequence;
    const swapLoader                 = this.loadingService.newLoader();
    swapLoader.start();
    this.noteService.updateResource(fromNote).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (data) => {
        swapLoader.stop();
      },
      (error) => {
        swapLoader.stop();
      }
    );
  }

  onDragOver(event) {
    event.preventDefault();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('customer') && changes['customer'].currentValue) {
      this.customer = changes['customer'].currentValue;

      if (this.customer.attributes.CustomerID) {
        this.noteService.getResources(this.customer.attributes.CustomerID);
      }
    }
  }
}
