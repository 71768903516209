import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from "rxjs";
import {Button} from "./button";

@Component({
  selector: 'jet-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent implements OnInit {

  public warnings: string[] = [];
  public tableData: {head: string[], rows: Array<string[]>} = {head: [], rows: []};
  public title = '';
  public onClose: Subject<Button>;
  public enableConfirm = false;
  public confirmMessage = "Yes";
  public cancelMessage = "Cancel";
  public buttons: Array<Button> = [];
  public confirmClass = 'btn-danger';

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.onClose = new Subject();

    // Adding logic here for backwards compatibility for cancel and confirm buttons.
    const cancel = new Button();
    cancel.id = 'cancel';
    cancel.text = this.cancelMessage;
    cancel.cancel = true;
    cancel.btnClass = 'btn-primary';

    if (this.enableConfirm) {
      const confirm = new Button();
      confirm.id = 'confirm';
      confirm.text = this.confirmMessage;
      confirm.confirm = true;
      confirm.btnClass = this.confirmClass;
      this.buttons.push(confirm);
    } else {
      cancel.text = 'Ok';
      cancel.btnClass = 'btn-default';
    }

    this.buttons.push(cancel);
  }

  buttonClick(button: Button) {
    this.onClose.next(button);
    this.bsModalRef.hide();
  }
}
