<jet-menu></jet-menu>
<div class="container-fluid">

  <div class="header">

      <!--Comment this out until we know what we are doing here-->
    <!--<h1>New Invoice</h1> <h2>Limelyte - Spokane, WA - LOD:4/26/2017</h2>-->
  </div><!-- /header -->

  <div class="content row">
    <div id="sidebar" class="col-lg-2 col-custom col-sm-12 order-lg-0 order-2">
      <div class="hidden-sm hidden-xs row">
        <div class="col-md-12">
          <jet-customer-search></jet-customer-search>
          <jet-customer-notes [customer]="customer"></jet-customer-notes>

          <div class="card">
            <div class="card-heading">
              <h3 class="caps card-title">
                <span></span>JET version</h3>
            </div>
            <div class="card-body collapse show fivepad">
              <div class="col-md-12 onepad">
                {{ version.date }} <br>
                {{ version.revision.substr(0, 8) }}
              </div>
            </div>
          </div>
        </div>
      </div> <!-- Desktop sidebar - hides on mobile -->
    </div> <!-- /sidebar -->

    <div class="tabs col-lg-10 col-custom col-sm-12">

      <div class="row">
        <div class="col-sm-8">
          <ul class="nav nav-tabs">
            <li class="nav-item"><a class="nav-link" [class.disabled]="customerSearchDisabled" routerLink="customer-search" routerLinkActive="active" queryParamsHandling="preserve">Search</a></li>
            <li class="nav-item"><a class="nav-link" [class.disabled]="customerDisabled" routerLink="customer" routerLinkActive="active" queryParamsHandling="preserve">Customer</a></li>
            <li class="nav-item"><a class="nav-link" [class.disabled]="historyDisabled" routerLink="customer-notes" routerLinkActive="active" queryParamsHandling="preserve">Notes</a></li>
            <li class="nav-item"><a class="nav-link" [class.disabled]="primaryDisabled" routerLink="primary" routerLinkActive="active" queryParamsHandling="preserve">Invoice</a></li>
            <li class="nav-item"><a class="nav-link" [class.disabled]="historyDisabled" routerLink="history" routerLinkActive="active" queryParamsHandling="preserve">History</a></li>
            <li class="nav-item"><a class="nav-link" [class.disabled]="quickViewDisabled" routerLink="quick-view" routerLinkActive="active" queryParamsHandling="preserve">Quick View</a></li>
            <li class="nav-item"><a class="fa fa-arrow-left" [class.hidden]="arrowsHidden" [class.disabled]="arrowsDisabled" (click)="backCustomer()"></a></li>
            <li class="nav-item"><a class="fa fa-arrow-right" [class.hidden]="arrowsHidden" [class.disabled]="arrowsDisabled" (click)="forwardCustomer()"></a></li>
            <li class="nav-item" style="width: 0"><a class="selected" *ngIf="customer" routerLink="customer" routerLinkActive="active"
                                                     queryParamsHandling="preserve">{{ companyTitle }}</a></li>
          </ul>
        </div>
        <div class="col-sm-4">
          <div class="float-right" id="jet-invoice-buttons">
            <button type="button" *ngIf="canShowCustomerUpdate" (click)="updateCustomer()" class="btn btn-sm btn-primary">Update</button>
            <button type="button" *ngIf="canShowInvoiceReturn && !this.returns" (click)="returnInvoice()" class="btn btn-sm btn-danger">Returns</button>
            <button type="button" *ngIf="canShowInvoiceReturn && this.returns" (click)="returnInvoice()" class="btn btn-sm btn-danger">Exit Returns</button>
            <button type="button" *ngIf="canShowInvoiceReopen" (click)="reverseInvoice()" class="btn btn-sm btn-danger">Reverse</button>
            <button type="button" *ngIf="canShowInvoiceReopen" (click)="reopenInvoice()" class="btn btn-sm btn-danger">Reopen</button>
            <button type="button" *ngIf="canShowInvoiceUpdate" (click)="updateInvoice()" class="btn btn-sm btn-primary">Update</button>
            <button type="button" *ngIf="canShowAddNote" (click)="newNote()" class="btn btn-sm btn-primary">New Note</button>
            <button type="button" class="btn btn-sm btn-primary">Refresh</button>
          </div>
        </div>
      </div>

      <div class="tab-content">
        <router-outlet (activate)="onActivate($event)"></router-outlet>
      </div>
    </div> <!-- /tabs -->
  </div><!-- /row -->
</div> <!-- /container -->
