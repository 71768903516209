<script src="invoice-email/invoice-email.component.ts"></script>
<jet-menu></jet-menu>
<div class="container-fluid">

  <div class="header"></div><!-- /header -->

  <div class="content row">
    <div id="sidebar" class="col-lg-2 col-custom col-sm-12 order-lg-0 order-2">
      <div class="hidden-sm hidden-xs row">
        <div class="col-md-12">
          <jet-customer-search></jet-customer-search>
          <jet-customer-notes [customer]="customer"></jet-customer-notes>
        </div>
      </div> <!-- Desktop sidebar - hides on mobile -->
    </div> <!-- /sidebar -->

    <div class="tabs col-lg-10 col-custom col-sm-12">

      <div class="row">
        <div class="col-sm-8">
          <ul class="nav nav-tabs">
            <li class="nav-item"><span class="nav-link active">Invoice Email</span></li>
            <li class="nav-item"><button tooltip="If necessary, use this link to fallback to the legacy emailer." (click)="openLegacyEmailer()" class="nav-link">Legacy Emailer</button></li>
            <li class="nav-item" style="width: 0"><a class="selected" *ngIf="companyTitle" routerLink="customer" routerLinkActive="active"
                                                     queryParamsHandling="preserve">{{ companyTitle }}</a></li>
          </ul>
        </div>
      </div>

      <div class="tab-content" >
        <div *ngIf="isLoading">
          <div class="row justify-content-md-center">
            <div class="col-sm-3"><i class="fa fa-spinner fa-spin fa-1x"></i> Loading...</div>
          </div>
        </div>

        <form id="mainform" (ngSubmit)="submitForm($event)" *ngIf="!isLoading">
          <div class="row justify-content-sm-center">
            <div class="col-10">

              <div class="col-12">
                <div *ngIf="formError" class="alert alert-danger">{{error}}</div>
              </div>

              <div *ngIf="billToCustomer.length > 0">
                <div class="row">
                  <div class="col-3">Bill To Customer Addresses:</div>
                  <div class="col-9">
                    <div class="form-group">
                      <div class="form-check  form-check-inline" *ngFor="let email of billToCustomer">
                        <label class="form-check-label"><input type="checkbox" class="form-check-input">{{email}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="billToContact.length > 0">
                <div class="row">
                  <div class="col-3">Bill To Contact Person Addresses:</div>
                  <div class="col-9">
                    <div class="form-group">
                      <div class="form-check  form-check-inline" *ngFor="let email of billToContact">
                        <label class="form-check-label"><input type="checkbox" class="form-check-input">{{email}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="shipToCustomer.length > 0">
                <div class="row">
                  <div class="col-3">Ship To Customer Addresses:</div>
                  <div class="col-9">
                    <div class="form-group">
                      <div class="form-check  form-check-inline" *ngFor="let email of shipToCustomer">
                        <label class="form-check-label"><input type="checkbox" class="form-check-input">{{email}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="shipToContact.length > 0">
                <div class="row">
                  <div class="col-3">Ship To Contact Person Addresses:</div>
                  <div class="col-9">
                    <div class="form-group">
                      <div class="form-check  form-check-inline" *ngFor="let email of shipToContact">
                        <label class="form-check-label"><input type="checkbox" class="form-check-input">{{email}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="additionalAddresses.length > 0">
                <div class="row">
                  <div class="col-3">Additional Customer Addresses:</div>
                  <div class="col-9">
                    <div class="form-group">
                      <div class="form-check  form-check-inline" *ngFor="let email of additionalAddresses">
                        <label class="form-check-label"><input type="checkbox" class="form-check-input">{{email}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-3">Additional "To" Addresses <br><small>(Comma-separated values)</small></div>
                <div class="col-9">
                  <div class="form-group">
                    <input type="text" class="form-control" id="addresses">
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-3">Attachments</div>
                <div class="col-3">
                  <input type="file" name="attach" #attachment (change)="onFileChanged($event)" multiple>
                </div>
                <div class="col-3">
                  <div *ngIf="report">
                    <a [href]="report" target="_blank">Invoice #{{ invoice.attributes.InvoiceNumber }} <i class="fa fa-file-pdf"></i></a>
                  </div>
                </div>
                <div class="col-3">
                  <div *ngIf="report">
                    <button *ngIf="!packingList" class="btn btn-sm btn-success" (click)="addPackingList($event)"><i class="d-none fa fa-spinner fa-spin fa-1x"></i> Add Packing List</button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6"></div>
                <div class="col-6">
                  <a *ngIf="packingList" [href]="packingList" target="_blank">Packing List #{{ invoice.attributes.InvoiceNumber }} <i class="fa fa-file-pdf"></i></a>

                  <div *ngFor="let file of showFiles; let i = index">
                    {{file}} <i class="far fa-window-close text-danger" (click)="removeSelectedFile(i)"></i>
                  </div>
                </div>
              </div>

              <div *ngIf="!originalFromAddress" class="form-group">
                <label for="subject">From</label>
                <input type="text" class="form-control" name="fromAddress" [(ngModel)]="fromAddress">
              </div>

              <div class="form-group">
                <label for="subject">Subject</label>
                <input type="text" class="form-control" name="subject" [(ngModel)]="emailSubject">
              </div>

              <div class="form-group">
                <label for="subject">Body</label>
                <angular-editor [config]="editorConfig" name="emailBody" [(ngModel)]="emailBody"></angular-editor>
              </div>

              <div class="row">
                <div class="col text-center">
                  <button #submitButton type="submit" class="btn btn-primary"><i class="d-none fa fa-spinner fa-spin fa-1x"></i> Send Message</button>
                </div>
              </div>

            </div>
          </div>
        </form>
      </div>

    </div> <!-- /tabs -->

  </div><!-- /row -->
</div> <!-- /container -->
