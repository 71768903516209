
import {of as observableOf,  Subscription ,  Observable } from 'rxjs';

import {mergeMap, catchError, takeUntil} from 'rxjs/operators';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Customer } from '../../../../models/customer.interface';
import { CustomerService } from '../../../../services/observables/customer.service';
import {
  ADDITIONAL_FIELD_PREVIOUS_STORE_ID,
  CONTACT_TYPE_EMAIL, CONTACT_TYPE_FAX, CONTACT_TYPE_PHONE,
  CONTACT_TYPE_URL, CUSTOMER_TYPE_CUSTOMER, CUSTOMER_TYPE_INDIVIDUAL,
} from '../../../../utils/constants.util';
import { CustomerInfoComponent } from '../customer-info/customer-info.component';
import { CodeService } from '../../../../services/observables/code.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { CustomerToCustomer } from '../../../../models/customer-to-customer.interface';
import { LoadingService } from '../../../../services/observables/loading.service';
import { WarningModalComponent } from '../../../shared/components/warning-modal/warning-modal.component';
import { ResourceService } from '../../../../services/resource.service';
import { DEFAULT_CUSTOMER } from '../../../../mocks/customer.mock';
import { ContactValueDisplayType } from '../../../shared/components/contact/contact.component';
import {IndividualInfoComponent} from '../individual-info/individual-info.component';
import { getMultipleResourceRelation } from '../../../../utils/json.util';
import {NoteService} from "../../../../services/observables/note.service";
import {AdditionalValue} from "../../../../models/additional-value.interface";
import {Subject} from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
  selector: '[jet-customer-tab]',
  templateUrl: './customer-tab.component.html',
  styleUrls: ['./customer-tab.component.scss']
})
export class CustomerTabComponent implements OnInit, OnDestroy {

  // View Children
  @ViewChild(CustomerInfoComponent)
  public customerInfoComponent: CustomerInfoComponent;
  @ViewChild(IndividualInfoComponent)
  public individualInfoComponent: IndividualInfoComponent;
  // General Customer Stuff
  public customer: Customer = null;
  public codes: any = null;
  public customerSubscription: Subscription;
  public customerIsLoading = true;
  public previousStoreId: string;
  public stores: Customer[] = [];

  public isContactCustomer = false;
  // New Customer
  public isNewCustomer = false;

  // Contact Types
  public contactTypePhone: number = CONTACT_TYPE_PHONE;
  public contactTypeFax: number = CONTACT_TYPE_FAX;
  public contactTypeEmail: number = CONTACT_TYPE_EMAIL;
  public contactTypeUrl: number = CONTACT_TYPE_URL;

  // Add existing store as child
  public storeFullName: string;
  public selectedStore: Customer;
  public typeaheadLoading: boolean;
  public typeaheadNoResults: boolean;
  public dataSource: Observable<Customer[]>;

  // View stuff
  private bsModalRef: BsModalRef;
  public textDisplayType: ContactValueDisplayType = ContactValueDisplayType.Text;
  public hyperlinkDisplayType: ContactValueDisplayType = ContactValueDisplayType.Hyperlink;
  public maillinkDisplayType: ContactValueDisplayType = ContactValueDisplayType.Maillink;

  private ngUnsubscribe = new Subject();

  public customerId: any;
  public alert_message: string;

  constructor(
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private codeService: CodeService,
    private loadingService: LoadingService,
    private bsModalService: BsModalService,
    private resourceService: ResourceService,
    private noteService: NoteService,
  ) { }

  ngOnInit() {
    this.route.queryParams.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((params: any) => {
      if (!params.customer_id) {
        this.customerService.refreshResource(DEFAULT_CUSTOMER);
        this.noteService.refreshResource([]);
        this.isNewCustomer = true;
      }
    });

    this.dataSource = Observable.create((observer: any) => {
      observer.next(this.storeFullName);
    })
      .pipe(
          mergeMap((text: string) => this.customerSearch(text)),
          catchError(error => observableOf([]))
      );

    this.customerSubscription = this.customerService.customer.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((customer: Customer) => {
      if (!customer) {
        this.customerIsLoading = true;
        return;
      }
      this.customerIsLoading = false;
      this.customer = customer;
      const custAddValues = getMultipleResourceRelation<AdditionalValue>(customer.relationships, 'lockedAdditionalValues');
      const previousStoreIdValue = custAddValues.find((value: AdditionalValue) => value.attributes.FieldName === ADDITIONAL_FIELD_PREVIOUS_STORE_ID);
      if (previousStoreIdValue && previousStoreIdValue.attributes.Value) {
        this.previousStoreId = previousStoreIdValue.attributes.Value;
      }
      this.isNewCustomer = this.customer.id === '0';
      this.isContactCustomer = this.customer.attributes.CustomerType === CUSTOMER_TYPE_INDIVIDUAL;
      const stores = getMultipleResourceRelation<Customer>(this.customer.relationships, 'stores').map((store: any) => { store.isChild = true; return store; });
      const parentStores = getMultipleResourceRelation<Customer>(this.customer.relationships, 'parentDealers').map((store: any) => { store.isChild = false; return store; });
      this.stores = parentStores.concat(stores);
    });

    this.codeService.codes.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((codes) => {
      if (!codes) { return; }
      this.codes = codes;
    });

    this.route.queryParams.subscribe(params => {
      this.customerId = params['customer_id'];
    });

    const bc = new BroadcastChannel('customer_' + this.customerId + '_invoice');
    bc.onmessage = (ev) => {
      this.alert_message = ev.data['message'];
    };
  }

  private customerSearch(text): Observable<Customer[]> {
    if (text === '' || text === undefined || text === null) {
      return observableOf([]);
    }
    const params = {
      criteria: {
        fullName: text,
        customerType: CUSTOMER_TYPE_CUSTOMER,
        customerActive: 1
      },
      sorting: {'Customers.FullName': 'asc'}
    };

    return this.resourceService.getResources<Customer>('customers', params);
  }

  public addStoreAsChild() {
    if (!this.selectedStore) {
      return;
    }

    if (this.customer.attributes.CustomerID === this.selectedStore.attributes.CustomerID) {
      this.bsModalRef = this.bsModalService.show(WarningModalComponent);
      this.bsModalRef.content = ['Cannot make this company a child of itself'];
      return;
    }

    this.loadingService.refreshIsLoading(true);
    this.customerService.addAffiliate(
        this.selectedStore,
        this.customer.attributes.CustomerID,
        this.customer.attributes.CustomerType,
        this.customer.attributes.CustomerID
      ).pipe(
      mergeMap((customerLink: CustomerToCustomer) => this.customerService.getResource(this.customer.attributes.CustomerID, true)))
      .pipe(
          takeUntil(this.ngUnsubscribe)
      ).subscribe((data) => {
        this.storeFullName = undefined;
        this.selectedStore = null;
        this.loadingService.refreshIsLoading(false);
      }, (error) => {
        this.storeFullName = undefined;
        this.selectedStore = null;
        this.loadingService.refreshIsLoading(false);
      })
    ;
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  typeaheadOnSelect(match: TypeaheadMatch): void {
    this.selectedStore = match.item;
  }

  ngOnDestroy() {
    this.customerSubscription.unsubscribe();
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
