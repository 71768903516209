import {filter, first, takeUntil} from 'rxjs/operators';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {PaymentApplication} from '../../../../models/payment-application.interface';
import {LoadingService} from '../../../../services/observables/loading.service';
import {LoadingToken} from '../../../../services/observables/loading-token';
import {InvoiceService} from '../../../../services/observables/invoice.service';
import {ComputedFreightInfo} from '../../../../models/computed-freight-info.interface';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'jet-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: []
})
export class InvoicePaymentPopupComponent implements OnInit, OnDestroy {

  public title = 'Payment Credit';
  public payment: PaymentApplication;
  public credit: number;
  private url: string;
  public returnContent: [];
  private loader: LoadingToken;
  private ngUnsubscribe = new Subject();

  constructor(
      public bsModalRef: BsModalRef,
      private httpClient: HttpClient,
      private loadingService: LoadingService,
      private invoiceService: InvoiceService,
      @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
  ) {
    this.url = this.config.apiUrl;
    this.loader = this.loadingService.newLoader();
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  setPayment(payment: PaymentApplication, computed: ComputedFreightInfo) {
    this.payment = payment;
    this.credit = Math.abs(computed.balance);
  }

  process() {

    if (this.isLoading()) {
      return;
    }

    this.loader.start();

    this.httpClient.post<any>(this.url + 'payment-applications/' + this.payment.id + '/credit', {
      credits: this.credit
    }).pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((data) => {

      this.updateInvoice(this.payment.attributes.SourceIDfk).pipe(
          takeUntil(this.ngUnsubscribe)
      ).subscribe(() => {
        this.loader.stop();
      }, () => this.loader.stop());

      this.payment = null;
      this.credit = null;

      this.returnContent = data;
    }, () => this.loader.stop());
  }

  isLoading() {
    return this.loader.isLoading();
  }

  private updateInvoice(invoiceId: number) {
    return this.invoiceService
      .getInvoice(invoiceId, true).pipe(
      filter(data => data !== null),
      first(),
    );
  }
}
