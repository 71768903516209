import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {Note} from "../../../../mocks/note.interface";
import {User} from "../../../../models/user.interface";
import {NoteService} from "../../../../services/observables/note.service";
import {UserService} from "../../../../services/observables/user.service";
import {ActivatedRoute} from "@angular/router";
import {LoadingService} from "../../../../services/observables/loading.service";
import {EventService} from "../../../../services/event.service";
import {CustomerActionsService} from "../../../../services/customer-actions.service";
import {IndexService} from "../../../../services/observables/index.service";
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";
import {takeUntil} from "rxjs/operators";
import {getEmployeeName} from "../../../../utils/user.util";
import {NoteModalComponent} from "../../../shared/components/note-modal/note-modal.component";
import {copyObject} from "../../../../utils/json.util";
import {Subject} from "rxjs";
import {CodeService} from "../../../../services/observables/code.service";
import {Code} from "../../../../models/code.interface";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

declare let jQuery: any;
declare let document: any;

@Component({
  selector: 'app-customer-notes',
  templateUrl: './customer-notes.component.html',
  styleUrls: ['./customer-notes.component.scss']
})
export class CustomerNotesComponent implements OnInit {

  private bsModalRef: BsModalRef;
  public generalNoteCode: number;
  public customerNoteCode: number;
  public noteCodes: Code[];
  public codes: [];
  public notes: Note[] = null;
  public user: User = null;
  public customerId: number;
  public dateFormat: string;
  private ngUnsubscribe = new Subject();
  public filter: string;
  public catAsc = false;
  public dateAsc = false;
  public userAsc = false;

  constructor(
    private bsModalService: BsModalService,
    private codeService: CodeService,
    private noteService: NoteService,
    private userService: UserService,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private ref: ElementRef,
    private eventService: EventService,
    private customerActionsService: CustomerActionsService,
    private indexService: IndexService,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
  ) {
    this.dateFormat = this.config.dateFormat;
  this.generalNoteCode = this.config.codes['noteTypes']['general'];
  this.customerNoteCode = this.config.codes['noteTypes']['customer'];
  }

  ngOnInit() {
    this.getCustomerNotes();

    this.userService.user.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((user: User) => {
      this.user = user;
    });

    this.route.queryParams.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((queryParams: any) => {
      if (queryParams.customer_id) {
        this.customerId = queryParams.customer_id;
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public employeeName(note: Note): string {
    return getEmployeeName(note);
  }

  public viewNote(note: Note) {
    this.bsModalRef = this.bsModalService.show(NoteModalComponent, { class: 'zIndexOverride  wider-modal'});
    this.bsModalRef.content.note = note;
    this.bsModalRef.content.editNote = copyObject(note);
  }

  public removeNote(note) {
    const deleteLoader = this.loadingService.newLoader();
    deleteLoader.start();
    this.noteService.deleteResource(note).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (data) => deleteLoader.stop(),
      (error) => deleteLoader.stop()
    );
  }

  public toggleNoteType(note) {
    const noteLoader = this.loadingService.newLoader();
    noteLoader.start();

    if (note.attributes.Codes_NoteTypeIDfk === this.generalNoteCode) {
      note.attributes.Codes_NoteTypeIDfk = this.customerNoteCode;
    } else {
      note.attributes.Codes_NoteTypeIDfk = this.generalNoteCode;
    }

    this.noteService.updateResource(note).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      (data) => { noteLoader.stop(); },
      (error) => { noteLoader.stop(); }
    );
  }

  public filterNotes(notes, filter) {
    if (filter === '') {
      this.getCustomerNotes();

      return;
    }

    this.notes = notes.filter(note => {
      if (note.attributes.Category !== null) {

        return note.attributes.Category.includes(filter.toUpperCase());
      }
    });
  }

  public orderByAsc(column) {
    this.toggleFlag(column);

    this.notes.sort(function(a, b) {
      if (a.attributes[column] === null || b.attributes[column] === null) {

        return;
      }

      if (column === 'User') {

        return getEmployeeName(a).localeCompare(getEmployeeName(b));
      }

      return a.attributes[column].localeCompare(b.attributes[column]);
    });
  }

  public orderByDec(column) {
    this.toggleFlag(column);

    this.notes.sort(function(a, b) {
      if (a.attributes[column] === null || b.attributes[column] === null) {

        return;
      }

      if (column === 'User') {

        return getEmployeeName(b).localeCompare(getEmployeeName(a));
      }

      return b.attributes[column].localeCompare(a.attributes[column]);
    });
  }

  public toggleFlag(column) {
    if (column === 'Category') {
      this.catAsc = !this.catAsc;
    }

    if (column === 'NoteDate') {
      this.dateAsc = !this.dateAsc;
    }

    if (column === 'User') {
      this.userAsc = !this.userAsc;
    }
  }

  private getCustomerNotes() {
    this.noteService.notes.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((notes: Note[]) => {
      if (!notes) { return; }
      if (Object.keys(notes).length === 0) {
        this.notes = [];
      } else {
        this.notes = notes;
      }
      this.indexService.NotesAreDone();
    });
  }
}
