import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import { ResourceService } from '../resource.service';
import { InventoryItem } from '../../models/inventory-item.interface';


@Injectable()
export class InventoryItemService {
  private inventoryItemSources: any = {};
  private inventoryItemObservables: any = {};

  constructor(private resourceService: ResourceService) {}

  loadInventoryItemsByType(type: string): Observable<InventoryItem[]> {

    if (this.typeIsDefined(type)) {
      return this.inventoryItemObservables[type];
    }

    if (!this.typeIsSetup(type)) {
      this.setupType(type);
    }

    const params = {
      sorting: {
      },
      criteria: {
        type
      }
    };
    this.resourceService.getResources<InventoryItem>('inventory-items', params).subscribe((inventoryItems: InventoryItem[]) => {
      this.inventoryItemSources[type].next(inventoryItems);
    });

    return this.inventoryItemObservables[type];
  }

  getObservableByType(type): Observable<InventoryItem[]> {
    if (!this.typeIsSetup(type)) {
      this.setupType(type);
    }
    return this.inventoryItemObservables[type];
  }

  private setupType(type: string): void {
    const subject = new BehaviorSubject<InventoryItem[]>(null);
    this.inventoryItemSources[type] = subject;
    this.inventoryItemObservables[type] = subject.asObservable();
  }

  private typeIsDefined(type: string): boolean {
    if (!this.typeIsSetup(type)) {
      return false;
    }
    return this.inventoryItemSources[type].getValue() !== null;
  }

  private typeIsSetup(type: string): boolean {
    return this.inventoryItemSources[type];
  }
}
