<div class="card">
  <div class="card-heading" data-toggle="collapse" data-target="#invMods">
    <h3 class="caps card-title">
      <span>modifications</span>
      <i class="fa fa-history fa-lg"></i>
      <a class="caret"></a>
    </h3>
    <div class="card-subheading float-right">
      <div class="text-right">

      </div>
    </div>
  </div>
  <div class="card-body collapse show fivepad" id="invMods">

      <div *ngIf="!modifications" class="col-md-12 onepad">
        <i class="fa fa-spinner fa-spin fa-1x"></i> Loading ...
      </div>

      <div *ngIf="modifications" class="col-md-12 onepad">
        <table class="small  table">
          <thead>
          <tr>
            <th>Change Type</th>
            <th>Editor</th>
            <th>Date</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of modifications" (click)="popup(item)" style="cursor: pointer" tooltip="Click to View Changes" placement="left">
            <td>{{ item.changeType() }}</td>
            <td>{{ item.editor() }}</td>
            <td>{{ item.date()|momentDate: dateFormat + ' h:mm a' }}</td>
          </tr>
          </tbody>
        </table>
      </div>

  </div>
</div>
