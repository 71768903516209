import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CustomerResourceComponent} from '../customer-resource.component';
import {Code} from '../../../../models/code.interface';
import {ResourceService} from '../../../../services/resource.service';
import {LoadingService} from '../../../../services/observables/loading.service';
import {CustomerService} from '../../../../services/observables/customer.service';
import {WarningModalComponent} from '../warning-modal/warning-modal.component';
import {toDateString} from '../../../../utils/date.util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import {firstValueFrom} from "rxjs";
import {take} from "rxjs/operators";

@Component({
  selector: 'jet-dealer-type',
  templateUrl: './dealer-type.component.html',
  styleUrls: ['./dealer-type.component.scss']
})
export class DealerTypeComponent extends CustomerResourceComponent implements OnInit, OnChanges {
  private resourceName = 'customers-to-codes';
  @Input() public dealerType: Code;
  @Input() public codes: any = null;
  @Input() public productLines: Code[] = null;
  public dealerTypeCodes: any[] = [];
  public selectedDealerType: any = null;

  public bsModalRef: BsModalRef;

  //typeahead needs to have this even though it is not used.
  public tempSelected: any;

  constructor(
    private resourceService: ResourceService,
    private loadingService: LoadingService,
    private customerService: CustomerService,
    private bsModalService: BsModalService,
  ) {
    super(loadingService, customerService);
  }
  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.codes !== null && this.productLines !== null) {
      this.dealerTypeCodes = [];
      this.productLines.forEach((productCode: Code) => {
        const dealerTypeCodes = this.codes[`DEALER_TYPES_${productCode.attributes.CodeAlt}`];
        if (!dealerTypeCodes) { return; }
        dealerTypeCodes.forEach((dealerTypeCode: any) => {
          dealerTypeCode.Name = `${productCode.attributes.CodeAlt} - ${dealerTypeCode.CodeDesc}`;
          this.dealerTypeCodes.push(dealerTypeCode);
        });
      });
    }
  }

  public edit() {
    this.defaultSelectedDealerType();
    this.state = this.STATES.EDIT;
    this.onEdit.emit(true);
  }

  public cancel() {
    this.selectedDealerType = null;
    this.state = this.STATES.VIEW;
    this.onCancel.emit(true);
  }

  public update() {
    const body: any = this.customerToCodeBody(this.selectedDealerType, this.dealerType.attributes.CustomerToCodeID);
    this.resourceChange(this.resourceService.updateResource, [this.resourceName, this.dealerType.attributes.CustomerToCodeID, body], this.onUpdate);
  }

  public async remove() {
    const shouldDelete = await this.showConfirmationModal();
    if(shouldDelete){
      const body: any = this.customerToCodeBody(this.dealerType.attributes, this.dealerType.attributes.CustomerToCodeID);
      const date = new Date();
      body.data.attributes.EndDate = toDateString(date);
      this.resourceChange(this.resourceService.updateResource, [this.resourceName, this.dealerType.attributes.CustomerToCodeID, body], this.onRemove, () => {
        this.hide = true;
      });
    }
  }

  public async showConfirmationModal() {
    const initialState = {
      enableConfirm: true,
      warnings: [`Do you really want to remove ${this.dealerType.attributes.ProductCodeAlt} - ${this.dealerType.attributes.CodeDesc} from Dealer Types?`],
      title: 'Confirm Deletion',
      confirmMessage: "Delete Customer Information",
    };

    this.bsModalRef = this.bsModalService.show(WarningModalComponent, {class: 'modal-lg', initialState});

    return (await firstValueFrom(this.bsModalRef.content.onClose.pipe(take(1))))["confirm"];
  }

  public add() {
    if (this.selectedDealerType === null) {
      this.bsModalRef = this.bsModalService.show(WarningModalComponent);
      this.bsModalRef.content.warnings = ['Please select a Dealer Type.'];
      return;
    }

    const body: any = this.customerToCodeBody(this.selectedDealerType);
    this.resourceChange(this.resourceService.createResource, [this.resourceName, body], this.onAdd, () => {
      this.selectedDealerType = null;
    });
  }

  public defaultSelectedDealerType() {
    this.selectedDealerType = this.dealerTypeCodes.find((dealerTypeCode: any) => {
      return this.dealerType.attributes.CodeID === dealerTypeCode.CodeID;
    });

    this.tempSelected = this.selectedDealerType.Name;
  }

  public customerToCodeBody(codeAttrs, customerToCodeID: number = 0) {

    return {
      data: {
        id: `${customerToCodeID}`,
        type: 'CustomersToCode',
        attributes: {
          CustomerToCodeID: customerToCodeID,
          CustomerIDfk: this.customerId,
          CodeIDfk: codeAttrs.CodeID,
          Codes_LinkTypeIDfk: codeAttrs.CodeIDfk
        }
      }
    };
  }

  onSelect(event: TypeaheadMatch): void {
    this.selectedDealerType = event.item;
  }
}
