import {DomainConfigInterface} from "./domain.interface";
import {environment} from "../environments/environment";

export const UK_CONFIG: DomainConfigInterface = {
  isUk: true,
  defaultCounty: "GB",
  strings: {
    "Taxed": "VAT",
    "Tax": "VAT",
    "tax": "VAT",
    "lbs.": "kg",
    "lbs": "kg",
    "lb": "kg",
    "$": "&pound",
  },
  apiUrl: environment.uk.apiUrl,
  baseUrl: environment.uk.baseUrl,
  dateFormat: "DD/MM/YYYY",
  codes: environment.uk.codes
};
