<div class="tab-pane">
    <form>

        <div *ngIf="isLoading()">
            <div class="row justify-content-md-center">
                <div class="col-sm-3"><i class="fa fa-spinner fa-spin fa-1x"></i> Searching ...</div>
            </div>
        </div>

      <table class="small table  table-striped table-hover table-borderless" style="margin-bottom: 0;">
          <thead>
          <tr>
              <th></th>
              <th (click)="sortByColumn('FullName', sorting?.FullName || 'DESC')" class="sort-dir-{{ sorting?.FullName | lowercase }}">Name</th>
              <th (click)="sortByColumn('FirstAddress', sorting?.FirstAddress || 'DESC')" class="sort-dir-{{ sorting?.FirstAddress | lowercase }}">Addresses</th>
              <th>Shipping Addresses</th>
              <th (click)="sortByColumn('ContactValue', sorting?.ContactValue || 'DESC')" class="sort-dir-{{ sorting?.ContactValue | lowercase }}">Phone</th>
              <th>Fax</th>
              <th (click)="sortByColumn('DateEntered', sorting?.DateEntered || 'DESC')" class="sort-dir-{{ sorting?.DateEntered | lowercase }}">Invoice / Shipped</th>
          </tr>
          </thead>
          <tbody>

          <tr [class.customer-selected]="isSelectedCustomer(customer)" *ngFor="let customer of customers" (click)="setCustomerIndex(customer)">
              <td [innerHTML]="productLines(customer)"></td>
              <td>
                  <span style="font-size: 14px;" [style.font-weight] = "customer.attributes.IsTopLevelParent ? 'bold' : 'normal'">{{ customer.attributes.FullName }}</span><br>
                  <span>{{ spaTypes(customer) }}</span>
              </td>
              <td [innerHTML]="billingAddress(customer)"></td>
              <td [innerHTML]="shippingAddress(customer)"></td>
              <td>{{ phone(customer) }}</td>
              <td>{{ fax(customer) }}</td>
              <td>
                  <a *ngIf="mostRecentInvoice(customer)"
                     href="javascript:void(0)"
                     (click)="gotoInvoice(customer); $event.stopPropagation()"
                  >
                    {{ invoice(customer) }}
                  </a>
              </td>
          </tr>
          <tr *ngIf="!customers?.length">
              <td colspan="12">No records to display</td>
          </tr>
          </tbody>
      </table>
    </form>
    <ng-container *ngIf="pagination.totalPages > 1">
        <jet-pagination [pagination]="pagination" (nextPage)="onNextPage($event)"></jet-pagination>
    </ng-container>
</div>