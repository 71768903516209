import { User } from './../models/user.interface';
import { UserService } from './observables/user.service';
import { Injectable } from '@angular/core';

@Injectable()
export class PentadService {

  constructor(
		private userService: UserService
	) {}

	public setPentadSecurityAndRedirect(partialUrl: string) {
    return this
      .setPentadSecurity(partialUrl)
      .then((url: string) => {
        window.location.href = url;
      });
  }

  public setPentadSecurityAndNewTab(partialUrl: string) {
    this
      .setPentadSecurity(partialUrl)
      .then((url: string) => {
        window.open(url);
      });
  }

  public setPentadSecurity(partialUrl: string): Promise<string> {

    return new Promise((resolve, reject) => {
      this.userService.getResource().subscribe((user: User) => {

        if (user === null || user.relationships === null) {
          return;
        }

        const employeeId = user.relationships.employee.attributes.EmployeeID;
        const security = 'EmpID=' + employeeId;

        resolve(partialUrl.replace('PentadSecurity', security));
      });
    });
  }
}
