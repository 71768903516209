import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
declare let jQuery:any;
declare let window:any;

@Component({
  selector: 'app-pentad-router',
  templateUrl: './pentad-router.component.html',
  styleUrls: ['./pentad-router.component.scss']
})
export class PentadRouterComponent implements OnInit, OnDestroy {

  public url: string;
  private ngUnsubscribe = new Subject();

  constructor (private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    if (this.router.url.indexOf('CustomerSearch.aspx') > -1) {
        this.router.navigate(['/invoice/customer-search']);
    }

    if (this.router.url.indexOf('Invoices.aspx') > -1) {
        this.route.queryParams.pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe( (params: any) => {

          let queryParams = {
            customer_id: params.CustomerID as string,
            invoice_id: params.InvoiceID as string,
          };

          if (params.WebOrderID) {
            queryParams['weborder_id'] = params.WebOrderID as string;
          }

          this.router.navigate(['/invoice/primary'], {
            queryParams: queryParams
          });
        });
    }

    this.url = this.router.url;

    this.satisfyUpsIframe();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  protected satisfyUpsIframe() {
      jQuery(function(){
        window.parent.postMessage({
          page: 'placeholder',
          height: this.body.offsetHeight + 5 + 'px'
        }, '*');
      });
  }

}
