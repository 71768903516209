import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import { ResourceService } from '../resource.service';
import { Employee } from '../../models/employee.interface';

@Injectable()
export class EmployeeService {
  private employeesSource: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public employees: Observable<any> = this.employeesSource.asObservable();

  constructor(private resourceService: ResourceService) {}

  /**
   * Will attempt to fetch Employees of a type that has yet to be fetched
   * @param types
   * @returns {Observable<any>}
   */
  loadEmployeesByTypes(types: string[]): Observable<any> {
    if (this.areAllEmployeeTypesDefined(types)) {
      return this.employees;
    }

    const employeeTypes = this.employeeTypesNotDefined(types);

    employeeTypes.forEach((type) => {
      const params = {
        sorting: {
          FullName: 'asc',
        },
        criteria: {
          [type]: 1
        }
      };
      this.resourceService.getResources<Employee>('employees', params).subscribe((employees: Employee[]) => {
        let currentEmployees = this.employeesSource.getValue();
        if (currentEmployees === null) {
          currentEmployees = {};
        }
        currentEmployees[type] = employees;
        this.employeesSource.next(currentEmployees);
      });
    });

    return this.employees;
  }

  clearEmployees(): void {
    this.refreshEmployees(null);
  }

  areEmployeesDefined() {
    return this.employeesSource.getValue() !== null;
  }

  refreshEmployees(employees: Employee[]): void {
    this.employeesSource.next(employees);
  }

  /**
   * Checks to see if all the Employee types have data already fetched
   * @param types
   * @returns {boolean}
   */
  private areAllEmployeeTypesDefined(types: string[]): boolean {
    const employees = this.employeesSource.getValue();
    if (!employees) {
      return false;
    }
    for (const key of types) {
      if  (!employees[key]) {
        return false;
      }
    }
    return true;
  }

  /**
   * Determines which Employee types that don't have data fetched
   * @param types
   * @returns {boolean}
   */
  private employeeTypesNotDefined(types: string[]): string[] {
    const employees = this.employeesSource.getValue();
    if (!employees) {
      return types;
    }
    const employeeTypesNotDefined = [];
    for (const key of types) {
      if  (!employees[key]) {
        employeeTypesNotDefined.push(key);
      }
    }
    return employeeTypesNotDefined;
  }
}
