<div class="card">
    <div class="card-heading" data-toggle="collapse" data-target="#invDetails">
        <h3 class="caps card-title">
            <span>details</span> <i class="fa fa-bar-chart-o fa-lg"></i> <a class="caret"></a>
        </h3>
        <div class="card-subheading float-right">
            <div class="text-right">

            </div>
        </div>
    </div>
    <div class="card-body collapse show fivepad" id="invDetails">
        <div class="row">
            <div class="col-md-2 pt-2" >
                <div style="text-align:center;padding: 2px 0 0 20px;font-size: 14px;white-space: nowrap;overflow: visible;">Invoice Number <div style="font-size: 30px">{{ invoice?.attributes.InvoiceNumber }}</div></div>
            </div>

            <div class="col-md-10">
                <div class="form-group row">
                    <div class="col col-md-3 col-sm-12">
                        <div class="row pad">
                            <label class="col-md-5 col-form-label">status</label>
                            <div class="col-md-7 zeropad">
                                <ng-container *ngIf="parentInvoice">
                                    <a (click)="refreshInvoice(parentInvoice.attributes.InvoiceID)" [routerLink]="['/invoice/primary']" [queryParams]="{ invoice_id: parentInvoice.attributes.InvoiceID }" queryParamsHandling="merge">
                                        Reversal of {{ parentInvoice.attributes.InvoiceNumber }} </a>
                                </ng-container>
                                <ng-container *ngIf="reversedInvoice && !parentInvoice">
                                    <a (click)="refreshInvoice(reversedInvoice.attributes.InvoiceID)" [routerLink]="['/invoice/primary']" [queryParams]="{ invoice_id: reversedInvoice.attributes.InvoiceID }" queryParamsHandling="merge">
                                        Reverse({{ reversedInvoice.attributes.InvoiceNumber }}) </a>
                                </ng-container>
                                <ng-container *ngIf="!parentInvoice && !reversedInvoice && !invoice.attributes.InReturnMode">
                                    <select #invoiceStatus [disabled]="invoiceComplete" [(ngModel)]="invoice?.attributes.Codes_StatusIDfk" (ngModelChange)="statusChange($event)" class="small-form">
                                        <option *ngFor="let status of statusCodes" [ngValue]="status.CodeID" [disabled]="status.CodeDesc === 'Sent To Shipping'">{{ status.CodeDesc }}</option>
                                    </select>
                                </ng-container>
                                <ng-container *ngIf="!parentInvoice && !reversedInvoice && invoice.attributes.InReturnMode">
                                    Return Mode
                                </ng-container>
                            </div>
                        </div>

                        <div class="row pad">
                            <label class="col-md-5 col-form-label">pending</label>
                            <div class="col-md-7 zeropad">
                                <select [(ngModel)]="invoice?.attributes.Codes_PendingReasonIDfk" class="small-form">
                                    <option [ngValue]="0">None</option>
                                    <option *ngFor="let pendingReason of pendingReasonCodes" [ngValue]="pendingReason.CodeID">
                                        {{ pendingReason.CodeDesc }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col col-md-4 col-sm-12">
                        <div class="form-subgroup">
                            <div class="row pad">
                                <label class="col-md-5 col-form-label">date invoiced</label>
                                <div class="col-md-7 zeropad">
                                    <input placeholder="Pick date" type="text" class="small-form" #datePicker bsDatepicker [bsConfig]="{ dateInputFormat: dateFormat }" [(bsValue)]="dateInvoiced" (bsValueChange)="onDateInvoicedChange($event)">
                                </div>
                            </div>

                            <div class="row pad">
                                <label class="col-md-5 col-form-label">po number</label>
                                <div class="col-md-7 zeropad">
                                    <input [(ngModel)]="invoice?.attributes.PurchaseOrderNumber" type="text" class="small-form">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-md-5 col-sm-12 lastpad">
                        <div class="row">
                            <div class="col-md-2 onepad text-right">
                              <label class="col-form-label onepad"
                                     (click)="showNotesModal()"
                                     style="cursor:pointer;text-decoration:underline;"
                                     title="Show Larger Notes">notes</label>
                                <br/>
                              <button (click)="updateNotes()" class="btn btn-xs btn-primary" type="button">Update</button>
                            </div>
                            <div class="col-md-10 zeropad">
                                <textarea [(ngModel)]="invoice?.attributes.Notes" class="form-control" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
