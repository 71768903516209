import { ComputedFreightInfo } from './../../../../models/computed-freight-info.interface';
import {Component, OnInit, Input, OnChanges, OnDestroy} from '@angular/core';
import {Invoice} from '../../../../models/invoice.interface';
import {PaymentMethod} from '../../../../models/payment-method.interface';
import {copyObject, getMultipleResourceRelation} from '../../../../utils/json.util';
import {PaymentMethodComponent} from '../../../shared/components/payment-method/payment-method.component';
import {Customer} from '../../../../models/customer.interface';
import {HistoryItem} from '../../../../models/history-item.interface';
import {ResourceService} from '../../../../services/resource.service';
import {DEFAULT_PAYMENT_METHOD} from '../../../../mocks/payment-method.mock';
import {SOURCE_TYPE_CUSTOMER} from '../../../../utils/constants.util';
import { ComputedFreightInfoService } from '../../../../services/observables/computed-freight-info.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CustomerService} from '../../../../services/observables/customer.service';

@Component({
  selector: 'jet-invoice-payment-types',
  templateUrl: './invoice-payment-types.component.html',
  styleUrls: ['./invoice-payment-types.component.scss']
})
export class InvoicePaymentTypesComponent implements OnInit, OnChanges, OnDestroy {
  private customerId: number;

  public STATE_VIEW = PaymentMethodComponent.STATIC_STATES.VIEW;
  public STATE_ADD = PaymentMethodComponent.STATIC_STATES.ADD;
  public customer: Customer;
  public showAddForm = false;
  public emptyPaymentMethod: PaymentMethod = null;
  public computedFreightInfo: ComputedFreightInfo = null;
  @Input() public invoice: Invoice;
  public paymentMethods: PaymentMethod[] = [];
  private ngUnsubscribe = new Subject();

  constructor(
      private resourceService: ResourceService,
      private computedFreightInfoService: ComputedFreightInfoService,
      private customerService: CustomerService
  ) {
    const resource: PaymentMethod = copyObject(DEFAULT_PAYMENT_METHOD);
    resource.attributes.SourceType = SOURCE_TYPE_CUSTOMER;
    this.emptyPaymentMethod = resource;
  }

  ngOnInit() {
    this.computedFreightInfoService.computedFreightInfo.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((computed: ComputedFreightInfo) => {
      this.computedFreightInfo = computed;
    });
    this.assignments();
    this.customerService.customer.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((customer: Customer) => {
          if (!customer) {
            return;
          }
          this.invoice.relationships.paymentTypes = this.paymentMethods = customer.relationships.paymentTypes;
        });
  }

  ngOnChanges() {
    this.assignments();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private assignments() {
    if (!this.invoice) {
      return;
    }

    this.paymentMethods = getMultipleResourceRelation<PaymentMethod>(this.invoice.relationships, 'paymentTypes');

    this.customerId = this.invoice.attributes.Customers_BillToCustomerIDfk;
    this.customer = this.invoice.relationships.billToCustomer;
  }

  public toggleShowAddFormOnClick(event) {
    event.stopPropagation();
    this.toggleShowAddForm();
  }

  private toggleShowAddForm() {
    this.showAddForm = !this.showAddForm;
  }

  public newPaymentMethodAddHandler(success) {
    if (success) {
      this.toggleShowAddForm();
    }
  }

  public newPaymentMethodCancelHandler(success) {
    this.toggleShowAddForm();
  }
}
