import {Component, Inject, OnInit} from '@angular/core';
import {
  CONTACT_TYPE_EMAIL,
  CONTACT_TYPE_FAX,
  CONTACT_TYPE_PHONE,
  CONTACT_TYPE_URL,
  CUSTOMER_PAYMENT_TERMS,
  PRODUCT_LINES
} from '../../../../utils/constants.util';
import {Customer} from '../../../../models/customer.interface';
import {Address} from '../../../../models/address.interface';
import {DEFAULT_BILLING_ADDRESS, DEFAULT_SHIPPING_ADDRESS} from '../../../../mocks/address.mock';
import {Contact} from '../../../../models/contact.interface';
import {
  DEFAULT_EMAIL_CONTACT,
  DEFAULT_FAX_CONTACT,
  DEFAULT_PHONE_CONTACT,
  DEFAULT_URL_CONTACT
} from '../../../../mocks/contacts.mock';
import {copyObject} from '../../../../utils/json.util';
import {PaymentMethod} from '../../../../models/payment-method.interface';
import {DEFAULT_PAYMENT_METHOD} from '../../../../mocks/payment-method.mock';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {WarningModalComponent} from '../../../shared/components/warning-modal/warning-modal.component';
import {convertExpirationDateToDateTimeString} from '../../../../utils/date.util';
import {DEFAULT_CUSTOMER} from '../../../../mocks/customer.mock';
import {CodeService} from '../../../../services/observables/code.service';
import {CustomerService} from '../../../../services/observables/customer.service';
import {Router} from '@angular/router';
import {CountryService} from '../../../../services/observables/country.service';
import {Country} from '../../../../models/country.interface';
import {LoadingService} from '../../../../services/observables/loading.service';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";
import {filter} from "rxjs/operators";

@Component({
  selector: 'jet-new-customer',
  templateUrl: './new-customer.component.html',
  styleUrls: ['./new-customer.component.scss']
})
export class NewCustomerComponent implements OnInit {
  public customer: Customer = copyObject(DEFAULT_CUSTOMER);

  // Customer Related Resources
  public productLineCodes: any[]      = [];
  public paymentTermCodes: any[]      = [];
  public addresses: Address[]         = [
    copyObject(DEFAULT_BILLING_ADDRESS),
    copyObject(DEFAULT_SHIPPING_ADDRESS),
  ];
  public countries: Country[]         = [];
  public contactTypes: Contact[]      = [
    copyObject(DEFAULT_PHONE_CONTACT),
    copyObject(DEFAULT_FAX_CONTACT),
    copyObject(DEFAULT_EMAIL_CONTACT),
    copyObject(DEFAULT_URL_CONTACT),
  ];
  public paymentMethod: PaymentMethod = copyObject(DEFAULT_PAYMENT_METHOD);

  public bsModalRef: BsModalRef;

  constructor(
    private router: Router,
    private bsModalService: BsModalService,
    private codeService: CodeService,
    private customerService: CustomerService,
    private countryService: CountryService,
    private loadingService: LoadingService,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
  ) {
    this.addresses[0].attributes.Country = this.config.defaultCounty;
    this.addresses[1].attributes.Country = this.config.defaultCounty;
  }

  ngOnInit() {
    this.countryService.countries.subscribe((countries: Country[]) => {
      this.countries = countries;
    });

    this.codeService.codes
      .pipe(
        filter(data => data !== null),
        filter(data => data[PRODUCT_LINES]),
      )
      .subscribe((codes) => {
        this.productLineCodes = codes[PRODUCT_LINES].map((code) => {
          code.ProductLineActive = false;
          return code;
        });
        this.paymentTermCodes = codes[CUSTOMER_PAYMENT_TERMS];
      });
  }

  public contactTypeName(contactType: number) {
    const contactTypeNames = {
      [CONTACT_TYPE_PHONE]: 'Phone',
      [CONTACT_TYPE_FAX]: 'Fax',
      [CONTACT_TYPE_EMAIL]: 'Email',
      [CONTACT_TYPE_URL]: 'Website'
    };
    return contactTypeNames[contactType];
  }

  private showWarningModal(message) {
    this.bsModalRef                  = this.bsModalService.show(WarningModalComponent);
    this.bsModalRef.content.warnings = [message];
  }

  public addCustomer() {
    const dateTimeString = convertExpirationDateToDateTimeString(this.paymentMethod.attributes.Date);
    if (this.paymentMethod.attributes.Date && dateTimeString === false) {
      this.showWarningModal('Please enter a date of the following format: 01/91.');
      return;
    }
    const copyOfPaymentMethod = copyObject(this.paymentMethod);
    if (dateTimeString !== false) {
      copyOfPaymentMethod.attributes.Date = dateTimeString;
    }

    const numOfActiveProductLines = this.productLineCodes.filter(product => product.ProductLineActive).length;
    if (!numOfActiveProductLines) {
      this.showWarningModal('Please select a Product Line.');
      return;
    }

    if (!this.customer.attributes.Organization) {
      this.showWarningModal('Please enter name for Dealer.');
      return;
    }

    this.loadingService.refreshIsLoading(true);
    this.customerService.saveCustomer(this.customer, this.productLineCodes, this.addresses, this.contactTypes, [copyOfPaymentMethod])
      .subscribe((data: Customer) => {
        this.loadingService.refreshIsLoading(false);
        this.router.navigate(['invoice/customer'], {
          queryParams: {customer_id: +data.id},
        });
      }, () => {
        this.loadingService.refreshIsLoading(false);
      })
    ;
  }

}
