<h4 class="quick-view-title"><span>Details</span><i class="fa fa-bar-chart-o fa-lg"></i></h4>
<hr>
<table>
  <tr>
    <th valign="top">Terms:</th>
    <td>{{ terms ? terms : 'None' }}</td>
  </tr>
  <tr>
    <th>{{ 'Tax'|jetTranslate }} Status:</th>
    <td>{{ taxStatus }}</td>
  </tr>
  <tr>
    <th>{{ 'Tax'|jetTranslate }} Rate:</th>
    <td>{{ taxRate.toFixed(2) }}%</td>
  </tr>
  <ng-container *ngIf="vatNumber !== undefined">
    <tr>
        <th>VAT no:</th>
        <td>{{ vatNumber }}</td>
    </tr>
  </ng-container>
  <tr>
    <th>Sales Person:</th>
    <td>{{ salesPerson }}</td>
  </tr>
  <tr>
    <th>Processor:</th>
    <td>{{ processor }}</td>
  </tr>
  <tr>
    <th>Marketing Source:</th>
    <td>{{ marketingSource ? marketingSource : 'None' }}</td>
  </tr>
</table>
