<div class="card">
  <div class="card-heading" data-toggle="collapse" data-target="#quickviewPayments">
    <h3 class="caps card-title">
      <span>payments</span>
      <i class="fa fa-dollar fa-lg"></i>
      <a class="caret"></a>
    </h3>
    <div class="card-subheading float-right">
      <div class="text-right">

      </div>
    </div>
  </div>
  <div class="card-body collapse show fivepad" id="quickviewPayments">
    <table class="small table  table-striped table-hover table-borderless" style="margin-bottom: 0;">
      <thead>
      <tr>
        <th>Date</th>
        <th>Card #</th>
        <th>Exp. Date</th>
        <th>Amt</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let payment of payments">
        <td>{{ payment.attributes.PaymentDate }}</td>
        <td>x{{ payment.attributes.CreditCardNumber.slice(-4) }}</td>
        <td>{{ payment.attributes.CreditCardExpirationDate }}</td>
        <td>{{ payment.attributes.PaymentAmount | jetCurrency }}</td>
      </tr>
      <tr *ngIf="!payments || !payments?.length">
        <td colspan="12">No records to display</td>
      </tr>
      </tbody>
    </table>

  </div>
</div>
