import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Address } from '../../../../models/address.interface';
import { copyObject, singleResourceBody } from '../../../../utils/json.util';
import { CountryService } from '../../../../services/observables/country.service';
import { Country } from '../../../../models/country.interface';
import { LoadingService } from '../../../../services/observables/loading.service';
import { ResourceService } from '../../../../services/resource.service';
import { CustomerService } from '../../../../services/observables/customer.service';
import { CustomerResourceComponent } from '../customer-resource.component';
import {firstValueFrom, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {WarningModalComponent} from "../warning-modal/warning-modal.component";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'jet-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent extends CustomerResourceComponent implements OnInit, OnDestroy {
  private resourceName = 'addresses';
  @Input() public address: Address;
  public editAddress: Address;
  public countries: Country[] = [];
  private ngUnsubscribe = new Subject();
  public bsModalRef: BsModalRef;

  constructor(
    private countryService: CountryService,
    private resourceService: ResourceService,
    private loadingService: LoadingService,
    private customerService: CustomerService,
    private bsModalService: BsModalService,
  ) {
    super(loadingService, customerService);
  }

  ngOnInit() {
    this.editAddress = copyObject(this.address);
    this.countryService.countries.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((countries: Country[]) => {
      this.countries = countries;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public edit() {
    this.state = this.STATES.EDIT;
    this.onEdit.emit(true);
  }

  public cancel() {
    this.editAddress = copyObject(this.address);
    this.state = this.STATES.VIEW;
    this.onCancel.emit(true);
  }

  public update() {
    const bc = new BroadcastChannel('customer_' + this.customerId + '_customer');
    bc.postMessage({
      message: 'Source Customer Has Been Updated. Please Refresh Your Webpage',
      customerId: this.customerId,
    });

    const body: any = singleResourceBody(this.editAddress.attributes);
    this.resourceChange(this.resourceService.updateResource, [this.resourceName, +this.editAddress.id, body], this.onUpdate);
  }

  public async remove() {
    const shouldDelete = await this.showConfirmationModal();
    if(shouldDelete){
      const body = singleResourceBody(this.address.attributes);
      body.data.attributes.AddressActive = 0;
      this.resourceChange(this.resourceService.updateResource, [this.resourceName, +this.address.id, body], this.onRemove, () => {
        this.hide = true;
      });
    }
  }

  public async showConfirmationModal() {
    const initialState = {
      enableConfirm: true,
      warnings: [`Do you really want to remove ${this.address.attributes.Description} from Addresses?`],
      title: 'Confirm Deletion',
      confirmMessage: "Delete Customer Information",
    };

    this.bsModalRef = this.bsModalService.show(WarningModalComponent, {class: 'modal-lg', initialState});

    return (await firstValueFrom(this.bsModalRef.content.onClose.pipe(take(1))))["confirm"];
  }

  public add() {
    const body: any = singleResourceBody(this.editAddress.attributes);
    body.data.attributes.SourceIDfk = this.customerId;
    this.resourceChange(this.resourceService.createResource, [this.resourceName, body], this.onAdd);
  }
}
