import { Component, Input } from '@angular/core';
import { InvoiceLineItem } from '../../../../models/invoice-line-item.interface';

@Component({
  selector: 'jet-quick-view-smartop-items',
  templateUrl: './quick-view-smartop-items.component.html',
  styleUrls: ['./quick-view-smartop-items.component.scss']
})
export class QuickViewSmartopItemsComponent {
  @Input() public items: InvoiceLineItem[] = [];
  constructor() {}
}
