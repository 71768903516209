<div class="container-fluid" *ngIf="show">
    <div class="row justify-content-center no-gutters">
        <div class="col-12 col-md-8 col-lg-6 col-xl-3">
            <img class="" src="assets/images/logo.svg" alt="Leisure Concepts">
            <div class="card">
                <div class="card-heading tenpad">
                    <h3 class="card-title">
                        Leisure Concepts
                    </h3>
                    <div class="card-subheading float-right">
                        <div class="text-right">
                        </div>
                    </div>
                </div>
                <div id="login-wrapper" class="card-body">
                    <form action="">
                        <div class="mb-3 row no-gutters justify-content-center">
                            <div class="col">
                                <div class="btn btn-primary">
                                    <i class="fa fa-user text-white"></i>
                                </div>
                                <input [(ngModel)]="email" name="email" type="text" placeholder="Username" tabindex="1" autofocus/>
                            </div>
                        </div>
                        <div class="mb-3 row no-gutters justify-content-center">
                            <div class="col">
                                <div class="btn btn-primary">
                                    <i class="fa fa-lock text-white"></i>
                                </div>
                                <input [(ngModel)]="password" name="password" type="password" placeholder="Password" tabindex="2"/>
                            </div>
                        </div>
                        <div class="row no-gutters justify-content-end">
                            <button (click)="submit()" class="btn btn-primary submit" type="submit" tabindex="3">
                                <i class="fa fa-sign-in text-white"></i>
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</div>
