<ng-container *ngIf="!hide">
  <ng-container *ngIf="state === STATES.EDIT || state === STATES.ADD">
    <div class="form-subgroup">
      <div class="row">
        <div class="col-md-5 onepad">
          <input [(ngModel)]="editContact.attributes.Description" type="text" class="small-form"
                 placeholder="Description">
        </div>
        <div class="col-md-5 onepad">
          <input [(ngModel)]="editContact.attributes.ContactValue" type="text" class="small-form" placeholder="Value">
        </div>
        <div class="col-md-2 p-0">
          <ng-container *ngIf="state === STATES.EDIT">
            <div class="float-right align-self-center">
              <button class="btn btn-success btn-sm" (click)="update()" tooltip="Save" placement="bottom" delay="500">
                <i tabindex="0" class="fa fa-check"></i>
              </button>
              <button class="btn btn-default btn-sm ml-1" (click)="cancel()" tooltip="Cancel" placement="bottom"
                      delay="500">
                <i class="fa fa-times"></i>
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="state === STATES.ADD">
            <div class="float-right align-self-center">
              <button class="btn btn-success btn-sm" (click)="add()" tooltip="Save" placement="bottom" delay="500">
                <i tabindex="0" class="fa fa-check"></i>
              </button>
              <button class="btn btn-default btn-sm ml-1" (click)="cancel()" tooltip="Cancel" placement="bottom"
                      delay="500">
                <i class="fa fa-times"></i>
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="state === STATES.VIEW">
    <div class="container-fluid p-0">
      <div class="row mx-0">
        <div class="col-md-11 p-0">
          <ng-content select="[slot=drag-handle]"></ng-content>
          <div class="d-inline-block">
            <b>{{ contact.attributes.Description }}</b><br>
            <ng-container [ngSwitch]="contactValueDisplayType">
              <ng-container *ngSwitchCase="textDisplayType">
                {{ contact.attributes.ContactValue }}<br>
              </ng-container>
              <ng-container *ngSwitchCase="hyperlinkDisplayType">
                <a target="_blank" href="http://{{ contact.attributes.ContactValue }}">
                  {{  contact.attributes.ContactValue }}
                </a><br>
              </ng-container>
              <ng-container *ngSwitchCase="maillinkDisplayType">
                <a href="mailto:{{ contact.attributes.ContactValue }}">
                  {{  contact.attributes.ContactValue }}
                </a><br>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="col-md-1 p-0">
          <button class="btn p-0"  (click)="edit()">
            <i class="fa fa-pencil-alt"></i>
          </button>
          <div class="btn-group">
            <button class="btn btn-secondary btn-sm dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false"
                    style="background-color:transparent;color:black;border:none;box-shadow:none;"></button>
            <div class="dropdown-menu" style="min-width: 125px;">
              <a class="dropdown-item" (click)="remove()" style="cursor: pointer;"><i class="fa fa-trash-alt"></i>
                Delete</a>
              <a class="dropdown-item" (click)="edit()" style="cursor: pointer;"><i class="fa fa-pencil-alt"></i>
                Edit</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
