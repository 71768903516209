import { Component, Input } from '@angular/core';
import { InvoiceLineItem } from '../../../../models/invoice-line-item.interface';

@Component({
  selector: 'jet-quick-view-other-items',
  templateUrl: './quick-view-other-items.component.html',
  styleUrls: ['./quick-view-other-items.component.scss']
})
export class QuickViewOtherItemsComponent {
  @Input() public items: InvoiceLineItem[] = [];
  constructor() {}
}
