<div class="row">
  <div class="col-md-1">
    <div class="form-subgroup">
      <input [(ngModel)]="customer?.attributes.CustomerActive" type="checkbox" checked> Active<br>
      <ng-container *ngFor="let code of productLineCodes">
        <input [(ngModel)]="code.ProductLineActive" type="checkbox" checked> {{ code.CodeAlt }}<br>
      </ng-container>
    </div>
  </div>

  <!--TODO Customer Info-->
  <div class="col-md">
    <div class="form-subgroup small">
      <div class="row row2">
        <div class="col-md-4 caps onepad">
          <b>dealer</b>
        </div>
        <div class="col-md-8 onepad">
          <input [(ngModel)]="customer.attributes.Organization" type="text" class="small-form">
        </div>
      </div>

      <div class="row row2">
        <div class="col-md-4 caps onepad">
          <b>first</b>
        </div>
        <div class="col-md-8 onepad">
          <input [(ngModel)]="customer.attributes.FirstName" type="text" class="small-form">
        </div>
      </div>

      <div class="row row2">
        <div class="col-md-4 caps onepad">
          <b>middle</b>
        </div>
        <div class="col-md-8 onepad">
          <input [(ngModel)]="customer.attributes.MiddleName" type="text" class="small-form">
        </div>
      </div>
      <div class="row row2">
        <div class="col-md-4 caps onepad">
          <b>last</b>
        </div>
        <div class="col-md-8 onepad">
          <input [(ngModel)]="customer.attributes.LastName" type="text" class="small-form">
        </div>
      </div>
      <div class="row row2">
        <div class="col-md-4 caps onepad">
          <b>title</b>
        </div>
        <div class="col-md-8 onepad">
          <input [(ngModel)]="customer.attributes.Title" type="text" class="small-form">
        </div>
      </div>
      <div class="row row2">
        <div class="col-md-4 caps onepad">
          <b>terms</b>
        </div>
        <div class="col-md-8 onepad">
          <select [(ngModel)]="customer.attributes.Codes_PaymentTermsIDfk" class="small-form">
            <option [ngValue]="0">None</option>
            <option *ngFor="let term of paymentTermCodes" [ngValue]="term.CodeID">
              {{ term.CodeDesc }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <!--TODO Addresses-->
  <div class="col-md">
    <div class="form-subgroup">
      <ng-container *ngFor="let address of addresses">
        <div class="row row2">
          <div class="col-md-6 onepad">
            <input [(ngModel)]="address.attributes.Description" type="text" class="small-form" placeholder="Location">
          </div>

          <div class="col-md-6 onepad">
            <select [(ngModel)]="address.attributes.Description" class="small-form">
              <option value="">--Select--</option>
              <option value="Home">Home</option>
              <option value="Office">Office</option>
              <option value="Shipping">Shipping</option>
              <option value="Billing">Billing</option>
            </select>
          </div>
        </div>

        <div class="row row2">
          <div class="col-md-12 onepad">
            <input [(ngModel)]="address.attributes.Line1" type="text" class="small-form" placeholder="Line 1">
          </div>
        </div>

        <div class="row row2">
          <div class="col-md-12 onepad">
            <input [(ngModel)]="address.attributes.Line2" type="text" class="small-form" placeholder="Line 2">
          </div>
        </div>

        <div class="row row2">
          <div class="col-md-10 onepad">
            <input [(ngModel)]="address.attributes.City"
                   type="text" class="small-form" placeholder="City"
            >
          </div>

          <div class="col-md-2 onepad">
            <input [(ngModel)]="address.attributes.State"
                   type="text" class="small-form" placeholder="State"
            >
          </div>
        </div>
        <div class="row row2">
          <div class="col-md-9 onepad">
            <input [(ngModel)]="address.attributes.ZIP"
                   type="text" class="small-form" placeholder="Zip"
            >
          </div>

          <div class="col-md-3 onepad">
            <input [(ngModel)]="address.attributes.Plus4"
                   type="text" class="small-form" placeholder="+4"
            >
          </div>
        </div>

        <div class="row row2">
          <div class="col-md-12 onepad">
            <select [(ngModel)]="address.attributes.Country" class="small-form">
              <option *ngFor="let country of countries"
                      [ngValue]="country.attributes.CountryCode"
              >
                {{ country.attributes.CountryName }}
              </option>
            </select>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!--TODO Contact Types-->
  <div class="col-md">
    <div class="form-subgroup">
      <ng-container *ngFor="let contact of contactTypes">
        <div class="row">
          <div class="col-md-2 onepad">
            <label>{{ contactTypeName(contact.attributes.ContactType) }}</label>
          </div>
          <div class="col-md-5 onepad">
            <input [(ngModel)]="contact.attributes.Description" type="text" class="small-form" placeholder="Description">
          </div>
          <div class="col-md-5 onepad">
            <input [(ngModel)]="contact.attributes.ContactValue" type="text" class="small-form" placeholder="Value">
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- TODO: Payment Method-->
  <div class="col-md">
    <div class="form-subgroup small">
      <div class="row row2">
        <div class="col-md-4 caps onepad">
          <b>description</b>
        </div>
        <div class="col-md-8 onepad">
          <input [(ngModel)]="paymentMethod.attributes.Description" type="text" class="small-form">
        </div>
      </div>

      <div class="row row2">
        <div class="col-md-4 caps onepad">
          <b>name on card</b>
        </div>
        <div class="col-md-8 onepad">
          <input [(ngModel)]="paymentMethod.attributes.NameOn" type="text" class="small-form">
        </div>
      </div>

      <div class="row row2">
        <div class="col-md-4 caps onepad">
          <b>card number</b>
        </div>
        <div class="col-md-8 onepad">
          <input [(ngModel)]="paymentMethod.attributes.Number" type="text" class="small-form">
        </div>
      </div>
      <div class="row row2">
        <div class="col-md-4 caps onepad">
          <b>expiration date</b>
        </div>
        <div class="col-md-8 onepad">
          <input [(ngModel)]="paymentMethod.attributes.Date" type="text" class="small-form" placeholder="01/91">
        </div>
      </div>
      <div class="row row2">
        <div class="col-md-4 caps onepad">
          <b>security code</b>
        </div>
        <div class="col-md-8 onepad">
          <input [(ngModel)]="paymentMethod.attributes.CreditCardSecurityCode" type="text" class="small-form" placeholder="111">
        </div>
      </div>
      <div class="row row2">
        <div class="col-md-4 caps onepad">
          <b>address</b>
        </div>
        <div class="col-md-8 onepad">
          <input [(ngModel)]="paymentMethod.attributes.AddressLine1" type="text" class="small-form">
        </div>
      </div>
      <div class="row row2">
        <div class="col-md-4 caps onepad">
          <b>zip</b>
        </div>
        <div class="col-md-8 onepad">
          <input [(ngModel)]="paymentMethod.attributes.AddressZip" type="text" class="small-form" placeholder="Zip">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md">
    <button class="btn btn-primary" (click)="addCustomer()">Add</button>
  </div>
</div>
