import {JetCurrencyPipe} from './../../../../pipes/jet-currency-pipe';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Invoice} from '../../../../models/invoice.interface';
import {ComputedFreightInfo} from '../../../../models/computed-freight-info.interface';
import {getComputedFreightInfo} from '../../../../utils/invoice.util';
import {CodeService} from '../../../../services/observables/code.service';
import {
  FREIGHT_BUCKETS,
  FREIGHT_COMPANIES,
  INVOICE_STATUS_COMPLETED,
  INVOICE_TYPE_SMARTOP
} from '../../../../utils/constants.util';
import {TaxWarningService} from '../../../../services/tax-warning.service';
import {TaxableService} from '../../../../services/observables/taxable.service';
import {LoadingService} from '../../../../services/observables/loading.service';
import {InvoiceService} from '../../../../services/observables/invoice.service';
import {InvoiceActionsService} from '../../../../services/invoice-actions.service';
import {ComputedFreightInfoService} from '../../../../services/observables/computed-freight-info.service';
import {MomentDatePipe} from "../../../../pipes/moment-date.pipe";
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {WarningModalComponent} from '../../../shared/components/warning-modal/warning-modal.component';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';

declare let jQuery: any;
declare let window: any;

@Component({
  selector: 'jet-invoice-freight',
  templateUrl: './invoice-freight.component.html',
  styleUrls: ['./invoice-freight.component.scss']
})
export class InvoiceFreightComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public invoice: Invoice = null;
  public freightCompanies: any[] = [];
  public freightBuckets: any[] = [];
  @ViewChild('datePicker', { static: true })
  public datePicker: ElementRef;
  public dateShipped: Date = null;
  private ngUnsubscribe = new Subject();

  public taxable = false;
  public invoiceCompleted = false;
  public bsModalRef: BsModalRef;


  public computedFreightInfo: ComputedFreightInfo = null;

  public isNotSmartopInvoice: boolean;

  public dateFormat: string;

  @Output() clickPayments: EventEmitter<Event> = new EventEmitter();

  constructor(
    private invoiceService: InvoiceService,
    private codeService: CodeService,
    private taxWarningService: TaxWarningService,
    private taxableService: TaxableService,
    private currencyPipe: JetCurrencyPipe,
    private loadingService: LoadingService,
    private invoiceActionsService: InvoiceActionsService,
    private computedFreightInfoService: ComputedFreightInfoService,
    private momentDatePipe: MomentDatePipe,
    private dateP: BsDatepickerConfig,
    private bsModalService: BsModalService,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
  ) {
    this.dateFormat = this.config.dateFormat;
    dateP.dateInputFormat = this.dateFormat;
  }

  ngOnInit() {
    this.assignments();
    this.codeService.codes.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((codes: any) => {
      if (!codes) {
        return;
      }
      this.freightBuckets = codes[FREIGHT_BUCKETS];
      this.freightCompanies = codes[FREIGHT_COMPANIES];
    });
    this.taxableService.taxable.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((taxable) => {
      this.taxable = taxable;
    });
    this.computedFreightInfoService.computedFreightInfo.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((computed: ComputedFreightInfo) => {
      this.computedFreightInfo = computed;
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    // Only perform if Invoice has changed
    if (changes.invoice) {
      this.assignments();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private assignments() {
    if (!this.invoice) {
      return;
    }

    this.isNotSmartopInvoice = this.invoice.attributes.InvoiceType !== INVOICE_TYPE_SMARTOP;

    if (this.invoice.attributes.DateShipped) {
      this.dateShipped  = new Date(this.momentDatePipe.transform(this.invoice.attributes.DateShipped));
    }

    this.invoiceCompleted = this.invoice.attributes.Codes_StatusIDfk === INVOICE_STATUS_COMPLETED;

  }

  public setInvoiceAttribute(event: any, field: string) {
    this.invoice.attributes[field] = event.target.value;
  }

  onDateShippedChange(dateShipped: Date) {
    if (dateShipped === null) {
      this.invoice.attributes.DateShipped = null;
    } else {
      this.invoice.attributes.DateShipped = this.momentDatePipe.transform(this.dateShipped, 'Y-MM-DD');
    }
  }

  compareByNumber(num1, num2) {
    return num1 === num2;
  }

  chargeOnBlur() {
    this.taxWarningService.warnIfNotTaxable();
  }

  /**
   * Returns the appropriate tax amount and saves it
   * @param charge
   * @param taxField
   * @returns {string|null}
   */
  chargeTax(charge, taxField) {
    let rawCharge = 0;
    if (this.taxable && this.invoice.attributes.TaxPercentage > 0) {
      rawCharge = charge * (this.invoice.attributes.TaxPercentage / 100);
    }
    return this.usDollarFormat(rawCharge);
  }

  shippingCredit() {
    return Math.abs(this.invoice.attributes.ShippingCredit);
  }

  updateFreightInfo() {
    this.computedFreightInfo = getComputedFreightInfo(this.invoice, this.config.isUk);
  }

  usDollarFormat(value) {
    return this.currencyPipe.transform(value);
  }

  updateTrackingNumber() {
    this.loadingService.refreshIsLoading(true);
    this.invoiceActionsService.updateTrackingNumber(this.invoice.attributes.InvoiceID, this.invoice.attributes.FreightTrackingNumber)
      .pipe(
          takeUntil(this.ngUnsubscribe)
      ).subscribe(
        (data) => this.loadingService.refreshIsLoading(false),
        (error) => this.loadingService.refreshIsLoading(false)
      )
    ;
  }

  updateInvoice() {
    this.loadingService.refreshIsLoading(true);
    this.invoiceService.updateInvoice()
      .pipe(
          takeUntil(this.ngUnsubscribe)
      ).subscribe((data) => {
        this.loadingService.refreshIsLoading(false);

        this.assignments();

      }, (error) => {
        const message = [];
        for (const err of error.error.errors)  {
          message.push(err.detail);
        }
        this.loadingService.refreshIsLoading(false);
        this.bsModalRef = this.bsModalService.show(WarningModalComponent);
        this.bsModalRef.content.warnings = message;
      })
    ;
  }

}
