import {AuthService, STORAGE_TOKEN} from './../services/auth.service';
import {Component, OnInit, HostListener, OnDestroy, Inject} from '@angular/core';
import {LoadingService} from '../services/observables/loading.service';
import {UserService} from "../services/observables/user.service";
import {takeUntil} from 'rxjs/operators';
import {LoggingService} from "../services/logging.service";
import {StaticLogger} from "../utils/static-logger";
import {DOMAIN_CONFIG, DomainConfigInterface} from "../config/domain.interface";
import {User} from "../models/user.interface";
import {CookieService} from "ngx-cookie-service";
import {Subject} from "rxjs";

@Component({
  selector: 'jet-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  public isLoading = true;
  private ngUnsubscribe = new Subject();

  constructor(
    private loadingService: LoadingService,
    private authService: AuthService,
    private userService: UserService,
    private logService: LoggingService,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
    private cookieService: CookieService
  ) {
    StaticLogger.set(logService);
  }

  ngOnInit() {
    this.loadingService.isLoading.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((value: boolean) => {
      setTimeout(() => {
        this.isLoading = value;
      }, 0);
    });

    this.userService.getResource().subscribe((user: User) => {

      if (user) {
        this.cookieService.set("Secure", user.attributes.pentad_token, null, '/');
      }

    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event) {
    // Do not want to try and get user if the token does not exist
    if (!this.authService.tokenExists()) {
      return;
    }

    const tokenIsValid = this.userService.pentadTokenIsValid();
    if (!tokenIsValid) {
      this.userService.getResource(true);
    }
  }
}
