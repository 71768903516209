import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'jet-shipping-table',
  templateUrl: './shipping-table.component.html',
  styleUrls: ['./shipping-table.component.scss']
})
export class ShippingTableComponent implements OnInit {

  @Input() shippingList;
  @Input() label;
  @Input() user;

  public showProNumber: boolean;
  public showPrintLabels: boolean;

  constructor() { }

  ngOnInit() {
    if (this.label === 'Truck') {
      this.showProNumber = true;
      this.showPrintLabels = true;
    }

    if (this.label === 'Ground') {
      this.showProNumber = false;
      this.showPrintLabels = true;
    }

    if (this.label === 'Other') {
      this.showProNumber = false;
      this.showPrintLabels = false;
    }
  }

}
