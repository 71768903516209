<div id="invoiceTabPane" class="tab-pane">
  <div *ngIf="invoiceIsLoading">
    <div class="row justify-content-md-center">
      <div class="col-sm-3"><i class="fa fa-spinner fa-spin fa-1x"></i> Loading ...</div>
    </div>
  </div>
  <ng-container *ngIf="!invoiceIsLoading">
    <form id="invoiceTabForm">
      <!-- This whole section is a big form -->

        <div class="alert alert-info alert-dismissible" role="alert" *ngIf="alert_message">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span> <span class="sr-only">Close</span>
            </button>
            <strong>{{alert_message}}</strong>
        </div>

      <div class="row">
        <div class="col-md-3"></div>

        <div class="col-md-9 text-right">
          <button *ngIf="isSmartopInvoice" type="button" class="btn btn-xs btn-primary" (click)="emailCTA()">Email CTA</button>
          <span *ngIf="confirmation" style="color: #AC3233; font-weight: bold; font-style: italic;">
            <span *ngIf="false == confirmation.attributes.Notes.startsWith('PENDING')">
              Confirmed: {{ confirmation.attributes.OrderConfirmationDate | date:'MM/dd/yy hh:mm:ss' }}
            </span>
            <span *ngIf="confirmation.attributes.Notes.startsWith('PENDING')">
              {{ confirmation.attributes.Notes.replace('PENDING - CREATED:', 'CTA Sent ') }}
            </span>
          </span>
          <button *ngIf="isSmartopInvoice" type="button" class="btn btn-xs btn-primary" (click)="matchOrders()">Match Orders</button>
          <button *ngIf="sendToShippingService.showSendToShipping()" type="button" class="btn btn-xs btn-primary" (click)="sendToShipping()">Send To Shipping</button>
          <button type="button" class="btn btn-xs btn-primary" (click)="emailInvoice()">Email Invoice</button>
          <button type="button" class="btn btn-xs btn-primary" (click)="printInvoice()">Print Invoice</button>
          <button type="button" class="btn btn-xs btn-primary" (click)="printPackingList()">Print Packing List</button>
          <button type="button" class="btn btn-xs btn-primary" (click)="copyInvoice()">Copy Invoice</button>
        </div><!-- Invoice option buttons -->
      </div>

      <div class="row">
        <div id="jet-invoice" class="col-xl-8 col-custom col-sm-12 col">
          <jet-invoice-bill-to [invoice]="invoice">
          </jet-invoice-bill-to>
          <jet-invoice-ship-to [invoice]="invoice" [confirmation]="confirmation"></jet-invoice-ship-to>
          <jet-invoice-details [invoice]="invoice"></jet-invoice-details>
          <div *ngIf="isSmartopInvoice" text-left>
              <button *ngIf="hasWebOrder()" (click)="webOrder()" type="button" class="btn btn-sm btn-primary">Web Order</button>
              <button (click)="printTravelers()" type="button" class="btn btn-sm btn-primary">Print Travelers</button>
              <button (click)="printSmartopLabels()" type="button" class="btn btn-sm btn-primary">Print Labels</button>
              <button (click)="printSomeSmartopLabels()" type="button" class="btn btn-sm btn-primary">Choose Labels</button>
              <button (click)="printSmartopCPL()" type="button" class="btn btn-sm btn-primary">Print CPL</button>
              <button (click)="printSmartopAll()" type="button" class="btn btn-sm btn-primary" *ngIf="!this.invoiceModificationsComponent.invoiceHasPrintAll">Print All</button>
              <button (click)="printSmartopAll()" type="button" class="btn btn-sm btn-danger" *ngIf="this.invoiceModificationsComponent.invoiceHasPrintAll">Print All (previously done)</button>
          </div>
          <jet-invoice-line-items *ngIf="returns" [invoice]="invoice" [lineItemType]="returnedLineItemType"></jet-invoice-line-items>
          <jet-invoice-line-items *ngIf="isSmartopInvoice" [invoice]="invoice" [lineItemType]="smartopLineItemType"></jet-invoice-line-items>
          <jet-invoice-line-items [invoice]="invoice" [lineItemType]="inventoryLineItemType"></jet-invoice-line-items>
          <jet-invoice-line-items [invoice]="invoice" [lineItemType]="otherLineItemType"></jet-invoice-line-items>
          <!--<jet-invoice-shipping [invoice]="invoice"></jet-invoice-shipping>-->
          <div class="row">
            <div class="col-md-5">
              <jet-invoice-shipping [invoice]="invoice"></jet-invoice-shipping>
            </div>

            <div class="col-md-7">
              <jet-invoice-ups *ngIf="!isUk" id="invoiceUPSComponent"></jet-invoice-ups>
            </div>
          </div>
        </div>

        <div id="jet-freight" class="col-xl-4 col-custom col-sm-12 col">
          <jet-invoice-freight [invoice]="invoice" (clickPayments)="onClickPayments($event)"></jet-invoice-freight>
          <jet-invoice-payments [invoice]="invoice"></jet-invoice-payments>
          <jet-invoice-payment-types [invoice]="invoice"></jet-invoice-payment-types>
          <jet-invoice-confirmations *ngIf="isSmartopInvoice" [confirmations]="confirmations"></jet-invoice-confirmations>
          <jet-invoice-modifications #invoiceModificationsComponent [invoice]="invoice"></jet-invoice-modifications>
        </div>

        <ng-container *ngIf="invoice?.relationships?.status && invoice?.relationships?.status.attributes.CodeDesc === 'Cancelled'">
            <div  id="canceled_order">Cancelled</div>
            <div  id="disabled-overlay"></div>
          </ng-container>
      </div>
    </form>
  </ng-container>
</div>
