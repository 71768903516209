<dl *ngIf="address">
  <dt>Company</dt>
  <dd>{{ address.company }}</dd>

  <dt>First Name</dt>
  <dd>{{ address.fname }}</dd>

  <dt>Last Name</dt>
  <dd>{{ address.lname }}</dd>

  <dt>Address Line 1</dt>
  <dd>{{ address.address }}</dd>

  <dt>Address Line 2</dt>
  <dd>{{ address.address2 }}</dd>

  <dt>City</dt>
  <dd>{{ address.city }}</dd>

  <dt>State</dt>
  <dd>{{ address.state }}</dd>

  <dt>Postal Code</dt>
  <dd>{{ address.zip }}</dd>

  <dt>Country</dt>
  <dd>{{ address.country }} - {{ country }}</dd>

  <dt>Phone</dt>
  <dd>{{ address.phone }}</dd>

  <dt>Email Address</dt>
  <dd>{{ address.email }}</dd>
</dl>
