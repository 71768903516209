export const HuebeeConfig = {
  // options

  // hues: 1,
    // number of hues of the color grid
    // default: 12

    // hue0: 210,
    // the first hue of the color grid
    // default: 0

    // shades: 7,
    // number of shades of colors and shades of gray between white and black
    // default: 5

    saturations: 1,
    // number of sets of saturation of the color grid
    // 3 saturations => [ 100% saturation, 66% saturation, 33% saturation ]
    // default: 3

    notation: 'hex',
    // the text syntax of colors
    // values: shortHex, hex, hsl
    // shortHex => #F00, hex => #FF0000, hsl => hsl(0, 100%, 50%)
    // default: shortHex

    setText: true,
    // sets text of elements to color, and sets text color
    // true => sets text of anchor
    // string, '.color-text' => sets elements that match selector
    // default: true

    setBGColor: '#hexInput',
    // sets background color of elements
    // and text color so text is visible on light or dark colors
    // true => sets background color of anchor
    // string, '.color-bg' => sets elements that match selector
    // default: true

    // customColors: [ '#19F', '#E5A628', 'darkgray', 'hsl(210, 90%, 55%)' ]
  // custom colors added to the top of the grid

    staticOpen: false,
    // displays open and stays open
    // default: false

    className: 'color-input-picker',
  // class added to Huebee element, useful for CSS
};
