<div class="card">
    <div class="card-heading" data-toggle="collapse" data-target="#custPaymentTypes">
        <h3 class="caps card-title">
          <span>payment types</span>
          <i class="fa fa-lg fa-credit-card"></i><br>
          <small>{{ customer?.relationships?.paymentTerms?.attributes?.CodeDesc }}</small>
        </h3>

        <div class="card-subheading float-right">
            <div class="text-right">
                <i class="fa fa-lg fa-plus" (click)="toggleShowAddFormOnClick($event)"></i>
            </div>
        </div>
    </div>
    <div class="card-body collapse show fivepad" id="custPaymentTypes">
        <table class="small table  table-striped table-hover table-borderless" style="margin-bottom: 0;">
            <thead>
            <tr>
                <th>Desc.</th>
                <th>Last 4</th>
                <th>Exp.</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr
                    *ngFor="let paymentMethod of paymentMethods" jet-payment-method

                    [customerId]="customerId" [paymentMethod]="paymentMethod" [state]="STATE_VIEW" [charge]="computedFreightInfo?.balance" [enableToggle]="true"
                    [invoice]="invoice"
            >

            </tr>
            <tr *ngIf="paymentMethods.length === 0">
                <td colspan="5">No records to display</td>
            </tr>
            <tr *ngIf="showAddForm" jet-payment-method  [customerId]="customerId" [paymentMethod]="emptyPaymentMethod" [state]="STATE_ADD" [charge]="computedFreightInfo?.balance" [invoice]="invoice"
                (onAdd)="newPaymentMethodAddHandler($event)" (onCancel)="newPaymentMethodCancelHandler($event)">
            </tr>
            </tbody>
        </table>
    </div>
</div>
