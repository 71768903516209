<jet-menu></jet-menu>
<div class="container-fluid">

  <div class="header"></div><!-- /header -->

  <div class="content row">
<!--    <div id="sidebar" class="col-lg-2 col-custom col-sm-12 order-lg-0 order-2">-->
<!--      <div class="hidden-sm hidden-xs row">-->
<!--        <div class="col-md-12">-->
<!--          <jet-customer-search></jet-customer-search>-->
<!--&lt;!&ndash;          <jet-customer-notes></jet-customer-notes>&ndash;&gt;-->
<!--        </div>-->
<!--      </div> &lt;!&ndash; Desktop sidebar - hides on mobile &ndash;&gt;-->
<!--    </div> &lt;!&ndash; /sidebar &ndash;&gt;-->

    <div class="tabs col-lg-10 col-custom col-sm-12">

      <div class="row">


        <div class="col-sm-4">
          <div class="float-right" id="jet-invoice-buttons">
            <button class="btn btn-sm btn-primary" (click)="getTableData()">Refresh</button>
            <button class="btn btn-sm btn-primary" (click)="endOfDayReport()">End Of Day Report</button>
          </div>
        </div>
      </div>


      <div >
        <div *ngIf="!isUK" class="container-fluid">
          <jet-shipping-table [label]="truckLabel" [shippingList]="truckList" [user]="user"></jet-shipping-table>
          <jet-shipping-table [label]="groundLabel" [shippingList]="groundList" [user]="user"></jet-shipping-table>
          <jet-shipping-table [label]="otherLabel" [shippingList]="otherList" [user]="user"></jet-shipping-table>
        </div> <!-- US container -->
        <div *ngIf="isUK" class="container-fluid">
          <jet-shipping-table [label]="ukLabel" [shippingList]="ukList" [user]="user"></jet-shipping-table>
        </div> <!-- UK container -->
      </div><!-- content -->

    </div> <!-- tabs -->
  </div> <!-- row -->
</div> <!-- container -->
