import {Injectable} from '@angular/core';
import {singleResourceBody} from "../utils/json.util";
import {convertExpirationDateToDateTimeString} from "../utils/date.util";
import {PaymentMethod} from "../models/payment-method.interface";
import {Observable} from "rxjs";
import {ResourceService} from "./resource.service";
import {Invoice} from "../models/invoice.interface";

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {

  public previousPaymentMethodId: string = '';
  public previousInvoiceId: string = '';
  public previousCharge = -1;

  constructor(private resourceService: ResourceService) { }

  add(paymentMethod: PaymentMethod, customerId) {
    const body: any = singleResourceBody(paymentMethod.attributes);
    body.data.attributes.SourceIDfk = customerId;

    let enteredDate = body.data.attributes.Date;
    if(enteredDate.length == 4 && !enteredDate.includes('/')) {
      body.data.attributes.Date = enteredDate.slice(0,2) + "/" + enteredDate.slice(2);
    }

    const dateTimeString = convertExpirationDateToDateTimeString(body.data.attributes.Date);
    if (dateTimeString === false) {
      return new Observable((sub) => {
        sub.error(['Please enter a date of the following formats: dd/yy or ddyy eg 01/25 or 0125 .']);
      });
    }
    body.data.attributes.Date = dateTimeString;

    if (!body.data.attributes.AddressZip) {
      return new Observable((sub) => {
        sub.error(['Zip Code is Required']);
      });
    }

    return this.resourceService.createResource('payment-methods', body)
  }

  update(paymentMethod: PaymentMethod, invoice: Invoice) {

    const body: any = singleResourceBody(paymentMethod.attributes);

    let enteredDate = body.data.attributes.Date;
    if(enteredDate.length == 4 && !enteredDate.includes('/')) {
      body.data.attributes.Date = enteredDate.slice(0,2) + "/" + enteredDate.slice(2);
    }

    const dateTimeString = convertExpirationDateToDateTimeString(body.data.attributes.Date);
    if (dateTimeString === false) {
      return new Observable((sub) => {
        sub.error(['Please enter a date of the following formats: dd/yy or ddyy eg 01/25 or 0125 .']);
      });
    }
    if (this.previousCharge == paymentMethod.charge && this.previousPaymentMethodId == paymentMethod.id && this.previousInvoiceId == invoice.id) {
      return new Observable((sub) => {
        sub.error(['Duplicate Transaction. Please intentionally refresh page or alter charge amount if you wish to proceed.']);
      });
    }
    body.data.attributes.Date = dateTimeString;

    this.previousPaymentMethodId = paymentMethod.id;
    this.previousInvoiceId = invoice.id;
    this.previousCharge = paymentMethod.charge;

    let path = 'invoices/' + invoice.id + '/' + 'payment-methods';

    return this.resourceService.updateResource(path, +paymentMethod.id, body);
  }
}
