<ng-container *ngIf="inViewMode">
    <ng-content select="[slot=drag-handle]"></ng-content>
    <div class="jet-col jet-column-description-smartop">
        {{ editItem.attributes.InventoryItemNumber }} - {{ editItem.attributes.Description }}<br>
        <ng-container *ngIf="editItem.attributes.IsWarranty">
            War. {{ editItem.attributes.WarrantyDepartmentAtFaultText }}
            - {{ editItem.attributes.WarrantyReasonText }}</ng-container>
        <br>
        <b class="caps">measurement a</b> - {{ editItem.attributes.CustomLength.toFixed(2) }}<br>
        <b class="caps">measurement b</b> - {{ editItem.attributes.CustomWidth.toFixed(2) }}<br>
        <b class="caps">hinge len.</b> - {{ editItem.attributes.HingeLength.toFixed(2) }}<br>
        <b class="caps">Radius</b> - {{ editItem.attributes.RadiusText }}<br>
        <b class="caps">acrylic ht.</b> - {{ editItem.attributes.SkirtingText }}<br>
        <b class="caps">mount</b> - {{ editItem.attributes.MountText }}
    </div>
    <div class="jet-col center-col jet-column-avail-smartop">{{ editItem.attributes.InventoryItemAvailableQuantity }}</div>
    <div class="jet-col jet-column-note-smartop" style="padding-top: 2%">
        <b>Completion Date</b>:<br *ngIf="editItem.attributes.completion_option">
        <span>{{ editItem.attributes.completion_option }} {{ editItem.attributes.completion_date }}</span><br>
        <b>Note</b>: <span>{{ editItem.attributes.Note }}</span><br>
        <b>CIN</b>: {{ editItem.attributes.CIN }}<br>
      <b>Production Notes</b>:
      <div [innerHtml]="editItem?.attributes.ProductionNotes | safeHtml ">
      </div>
        <div *ngIf="editItem.relationships.webOrderLineItem && editItem.relationships.webOrderLineItem.attributes.ProductionNotes">
            <b>Customer Notes</b>: {{ editItem.relationships.webOrderLineItem.attributes.ProductionNotes }}
        </div>
        <b>CONSUMER</b>: {{ editItem.attributes.ConsumerFirstName }} {{ editItem.attributes.ConsumerLastName }}
    </div>
    <div class="jet-col jet-column-international-smartop" style="padding-top: 3%">
        Code: <b>{{ editItem.attributes.CommodityCode }}</b><br>
        Value: <b>{{ editItem.attributes.CustomsValue | jetCurrency }}</b>
    </div>
    <div class="jet-col center-col jet-column-wt-smartop" title="{{ editItem.attributes.LineWeightCalcText}}">
        {{ editItem.attributes.LineItemWeightText|jetTranslate:isNotSmartopInvoice }}<br>
        {{ editItem.attributes.LineItemWeightEachText|jetTranslate:isNotSmartopInvoice }} Ea.
    </div>
    <div *ngIf="editItem.calculatedQuantity === undefined"
         class="jet-col center-col jet-column-qty-smartop">{{ editItem.attributes.Quantity }}</div>
    <div *ngIf="editItem.calculatedQuantity !== undefined" class="jet-col center-col jet-column-qty-smartop"><s
            class="mr-1">{{ editItem.attributes.Quantity }}</s>{{editItem.calculatedQuantity}}</div>
    <div class="jet-col center-col jet-column-price-smartop">{{ editItem.attributes.OriginalPriceEach | jetCurrency }}</div>
    <div class="jet-col center-col jet-column-subtotal-smartop">{{ subtotal(editItem) | jetCurrency }}</div>
    <div class="jet-col center-col jet-column-taxed-smartop">
        {{ editItem.attributes.TaxPercentage }}%<br>
        {{ taxAmount(editItem) | jetCurrency }}
    </div>
    <div class="jet-col center-col jet-column-total">{{ total(editItem) | jetCurrency}}</div>
    <div *ngIf="!returns && isEditable" class="jet-col center-col jet-column-buttons">
        <button class="btn p-0" (click)="edit()" tooltip="Edit" delay="500">
            <i class="fa fa-pencil-alt"></i>
        </button>
        <div class="btn-group">
            <button class="btn btn-secondary btn-sm dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false"
                    style="background-color:transparent;color:black;border:none;box-shadow:none;"></button>
            <div class="dropdown-menu" style="min-width: 125px;">
                <a class="dropdown-item" (click)="duplicate()" style="cursor: pointer;"><i class="fa fa-clone"></i>
                    Clone</a>
                <a class="dropdown-item" (click)="remove()" style="cursor: pointer;"><i class="fa fa-trash-alt"></i>
                    Delete</a>
                <a class="dropdown-item" (click)="edit()" style="cursor: pointer;"><i class="fa fa-pencil-alt"></i> Edit</a>
            </div>
        </div>
    </div>
    <div *ngIf="returns" class="jet-col center-col jet-column-buttons">
        <button class="btn btn-danger btn-xs" (click)="return()">Return</button>
    </div>
</ng-container>
<ng-container *ngIf="!inViewMode">
    <div class="jet-column-reorder"></div>
    <div class="jet-col jet-column-description-smartop">
        <div class="row">
            <div class="col-md-12">
                <select class="small-form zeropad"
                        [(ngModel)]="editItem.attributes.InventoryItemIDfk"
                        #firstInput
                        (focus)="adjustScreen()"
                        (ngModelChange)="editItem.attributes.Weight = 0"
                >
                    <option [ngValue]="0">--Choose--</option>
                    <option *ngFor="let inventoryItem of smartopInventoryItems"
                            [ngValue]="inventoryItem.attributes.InventoryItemID"
                    >
                        {{ inventoryItem.attributes.Description }}
                    </option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label class="col-md-12 col-form-label zeropad">M. A</label>
                <div class="col-md-12 zeropad">
                    <input [(ngModel)]="editItem.attributes.CustomLength"
                           (typeaheadOnSelect)="setLength(editItem, $event)"
                           [typeahead]="lengthMeasurements"
                           [typeaheadOptionsInScrollableView]="10"
                           class="small-form"
                    />
                </div>
            </div>

            <div class="col-md-4">
                <label class="col-md-12 col-form-label zeropad">M. B</label>
                <div class="col-md-12 zeropad">
                    <input [(ngModel)]="editItem.attributes.CustomWidth"
                           (typeaheadOnSelect)="setWidth(editItem, $event)"
                           [typeahead]="widthMeasurements"
                           [typeaheadOptionsInScrollableView]="10"
                           class="small-form"
                    />
                </div>
            </div>

            <div class="col-md-4">
                <label class="col-md-12 col-form-label zeropad">Hinge
                    Len.</label>
                <div class="col-md-12 zeropad">
                    <input [(ngModel)]="editItem.attributes.HingeLength"
                           [typeahead]="hingeMeasurements"
                           [typeaheadOptionsInScrollableView]="10"
                           class="small-form"
                    />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <label class="col-md-12 col-form-label zeropad">Radius</label>
                <div class="col-md-12 zeropad">
                    <input [(ngModel)]="selectedRadius"
                           [typeahead]="smartopRadiiCodes"
                           [typeaheadOptionsInScrollableView]="10"
                           [typeaheadOptionField]="'CodeDesc'"
                           (typeaheadOnSelect)="setRadius($event)"
                           class="small-form"
                    />
                </div>
            </div>

            <div class="col-md-4">
                <label class="col-md-12 col-form-label zeropad">Acrylic
                    ht</label>
                <div class="col-md-12 zeropad">
                    <input [(ngModel)]="selectedSkirting"
                           [typeahead]="smartopSkirtingCodes"
                           [typeaheadOptionsInScrollableView]="10"
                           [typeaheadOptionField]="'CodeDesc'"
                           (typeaheadOnSelect)="setSkirting($event)"
                           class="small-form"
                    />
                </div>
            </div>

            <div class="col-md-4">
                <label class="col-md-12 col-form-label zeropad">mount</label>
                <div class="col-md-12 zeropad">
                    <input [(ngModel)]="selectedMount"
                           [typeahead]="smartopMountCodes"
                           [typeaheadOptionsInScrollableView]="10"
                           [typeaheadOptionField]="'CodeDesc'"
                           (typeaheadOnSelect)="setMount($event)"
                           class="small-form"
                    />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <input type="checkbox" tabindex="-1" [(ngModel)]="editItem.attributes.IsWarranty"> War.
            </div>
            <div class="col-md-8">
                <ng-container *ngIf="editItem.attributes.IsWarranty">
                    <select tabindex="-1" class="small-form" [(ngModel)]="editItem.attributes.Codes_WarrantyReasonIDfk">
                        <option [ngValue]="0">Reason</option>
                        <option *ngFor="let code of warrantyReasonCodes" [ngValue]="code.CodeID">
                            {{ code.CodeDesc }}
                        </option>
                    </select>
                    <select tabindex="-1" class="small-form"
                            [(ngModel)]="editItem.attributes.Codes_WarrantyDepartmentAtFaultIDfk">
                        <option [ngValue]="0">Department</option>
                        <option *ngFor="let code of warrantyDepartmentChargedCodes" [ngValue]="code.CodeID">
                            {{ code.CodeDesc }}
                        </option>
                    </select>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="jet-col jet-column-avail-smartop"></div>
    <div class="jet-col jet-column-note-smartop">
        <div class="row">
            <label class="col-md-12 col-form-label">Note:</label>
            <div class="col-md-12">
                <input [(ngModel)]="editItem.attributes.Note" type="text" class="small-form"/>
            </div>
        </div>
      <div *ngIf="!isNewItem" class="row">
        <label class="col-md-12 col-form-label">CIN:</label>
        <div class="col-md-12">
          <input [(ngModel)]="editItem.attributes.CIN" type="text" class="small-form"/>
        </div>
      </div>

        <div class="row">
          <label class="col-md-12 col-form-label">Production Notes:</label>
            <div class="col-md-12 ">
              <angular-editor (focus)="makeVisible()" [config]="editorConfig" [(ngModel)]="editItem?.attributes.ProductionNotes"></angular-editor>
            </div>
        </div>

        <div class="row"
             *ngIf="editItem.relationships.webOrderLineItem && editItem.relationships.webOrderLineItem.attributes.ProductionNotes">
            <label class="col-md-12 col-form-label">customer notes:</label>
            <div class="col-md-12">
                {{ editItem.relationships.webOrderLineItem.attributes.ProductionNotes }}
            </div>
        </div>

        <div class="row">
            <label class="col-md-12 col-form-label">consumer:</label>
            <div class="col-md-12 ">
                <input [(ngModel)]="editItem.attributes.ConsumerFirstName" type="text" class="small-form"
                       placeholder="First Name">
            </div>
            <div class="col-md-12 ">
                <input [(ngModel)]="editItem.attributes.ConsumerLastName" type="text" class="small-form"
                       placeholder="Last Name">
            </div>
        </div>
    </div>
    <div class="jet-col jet-column-international-smartop">
        <div class="row row-stacked">
            <label class="col-md-12 col-form-label">commodity code</label>
            <div class="col-md-12">
                <input tabindex="-1" [(ngModel)]="selectedCommodityCode"
                       [typeahead]="commodityCodes"
                       [typeaheadOptionsInScrollableView]="10"
                       [typeaheadWaitMs]="300"
                       [typeaheadOptionField]="'CodeDesc'"
                       (typeaheadOnSelect)="setCommodityCode($event)"
                       class="small-form"
                />
            </div>
        </div>

        <div class="row row-stacked">
            <label class="col-md-12 col-form-label">customs value</label>
            <div class="col-md-12">
                <input tabindex="-1" [(ngModel)]="editItem.attributes.CustomsValue" type="number" class="small-form">
            </div>
        </div>
    </div>
    <div class="jet-col jet-column-wt-smartop">
        <div class="row">
            <div class="col-md-12">
                <input [(ngModel)]="editItem.attributes.Weight" type="number" class="small-form">
            </div>
        </div>
    </div>
    <div class="jet-col jet-column-qty-smartop">
        <div class="row">
            <div class="col-md-12">
                <input [(ngModel)]="editItem.attributes.Quantity" type="number" class="small-form">
            </div>
        </div>
    </div>
    <div class="jet-col jet-column-price-smartop">
        <div class="row">
            <div class="col-md-12">
                <input [(ngModel)]="editItem.attributes.OriginalPriceEach" type="number" class="small-form">
            </div>
        </div>
    </div>
    <div class="jet-col jet-column-subtotal-smartop">{{ subtotal(editItem) | jetCurrency }}</div>
    <div class="jet-col jet-column-taxed-smartop">
        <div class="row">
            <div class="col-md-12">
                <input tabindex="-1" (change)="onTaxedChange($event)" [(ngModel)]="taxed"
                       type="checkbox">{{ 'Taxed'|jetTranslate }}
                <div>{{ taxAmount(editItem) | jetCurrency }}</div>
            </div>
        </div>
    </div>
    <div class="jet-col jet-column-total">{{ total(editItem) | jetCurrency}}</div>
    <div class="text-right jet-col center-col vert-col jet-column-buttons">
        <button *ngIf="isNewItem" class="btn btn-sm p-0" (click)="added();add()" tabindex="0" tooltip="Add" delay="500">
            <i class="fa fa-2x fa-plus"></i>
        </button>
        <ng-container *ngIf="!isNewItem">
            <button class="btn btn-success btn-sm btn-block" (click)="update()" tooltip="Save" placement="right"
                    delay="500">
                <i tabindex="0" class="fa fa-check"></i>
            </button>
            <button class="btn btn-default btn-sm btn-block" (click)="cancel()" tooltip="Cancel" placement="right"
                    delay="500">
                <i class="fa fa-times"></i>
            </button>
        </ng-container>
    </div>
</ng-container>
