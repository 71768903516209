import {filter, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SmartopOrderSubmissionService} from '../../services/observables/smartop-order-submission.service';
import {Subject} from 'rxjs';
import {SmartopOrderSubmission} from '../../models/smartop-order-submission.interface';
import {LoadingService} from "../../services/observables/loading.service";
import {combineLatest} from "rxjs";
import {WarningModalComponent} from "../shared/components/warning-modal/warning-modal.component";
import {Button} from "../shared/components/warning-modal/button";
import {BsModalService} from "ngx-bootstrap/modal";


@Component({
  selector: 'jet-smartop-routing',
  templateUrl: './smartop-routing.component.html',
  styleUrls: ['./smartop-routing.component.scss']
})
export class SmartopRoutingComponent implements OnInit, OnDestroy {

  public orderSubmission: SmartopOrderSubmission;
  public country: string;
  public isRouted: boolean = false;
  private ngUnsubscribe    = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private smartopOrderSubmissionService: SmartopOrderSubmissionService,
    private loadingService: LoadingService,
    private bsModal: BsModalService,
  ) {
  }

  ngOnInit() {

    combineLatest([
      this.route.queryParams, this.route.params
    ]).pipe(
      takeUntil(this.ngUnsubscribe),
      filter(([queryParams, routeParams]) => {
        if (queryParams['smartop_order_submission_id']) {
          this.router.navigate(
            ['smartop', 'route', queryParams['smartop_order_submission_id']],
            {queryParams: {'country': queryParams['country']}}
          );

          return false;
        }

        return routeParams['id'] !== '0';
      }),
      switchMap(([queryParams, routeParams]) => {
        this.country = queryParams.country;
        return this.smartopOrderSubmissionService.fetchSmartopOrderSubmission(parseInt(routeParams['id']));
      })
    ).subscribe((data) => {
      this.orderSubmission = data;
      this.isRouted        = data.attributes.status == 'routed';
    })
  }

  routeOrder(country: string) {
    if (this.loadingService.isLoadingValue()) {
      return;
    }

    const initialState = {
      enableConfirm: true,
      warnings: [`Do you really want to route the smartop order to: ${country.toUpperCase()} ?`],
      title: 'Confirm Routing',
      confirmMessage: "Route Smartop Order",
      confirmClass: 'btn-success',
    };

    const loader = this.loadingService.newLoader();
    this.bsModal
      .show(WarningModalComponent, {class: 'modal-lg', initialState})
      .content.onClose
      .pipe(
        take(1),
        filter((button: Button) => button.confirm),
        tap(() => loader.start()),
        switchMap(() => this.smartopOrderSubmissionService.routeSmartopOrderSubmission(this.orderSubmission, country))
      )
      .subscribe((response) => {
        loader.stop();
        if (!response.success) {
          return;
        }

        this.isRouted = true;
      })
    ;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
