import {zip as observableZip} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {AdditionalValue} from '../../../../models/additional-value.interface';
import {Customer} from '../../../../models/customer.interface';
import {CustomerService} from '../../../../services/observables/customer.service';
import {Component, Inject, OnDestroy, ViewChild} from '@angular/core';
import {Employee} from '../../../../models/employee.interface';
import {CountryService} from '../../../../services/observables/country.service';
import {CodeService} from '../../../../services/observables/code.service';
import {EmployeeService} from '../../../../services/observables/employee.service';
import {
  ADDITIONAL_FIELD_VAT_REGISTRATION_NUMBER,
  ADMINISTRATIVE_EMPLOYEES,
  CUSTOMER_PAYMENT_TERMS,
  INVOICE_TAX_STATUS,
  MARKETING_SOURCE_INVOICE
} from '../../../../utils/constants.util';
import {ToSection} from '../../common/to-section';
import {ResourceService} from '../../../../services/resource.service';
import {TaxableService} from '../../../../services/observables/taxable.service';
import {TaxWarningService} from '../../../../services/tax-warning.service';
import {UserService} from '../../../../services/observables/user.service';
import {User} from '../../../../models/user.interface';
import {getMultipleResourceRelation} from '../../../../utils/json.util';
import {Contact} from '../../../../models/contact.interface';
import {LoggingService} from "../../../../services/logging.service";
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";
import {Address} from "../../../../models/address.interface";
import {LoadingService} from '../../../../services/observables/loading.service';

@Component({
  selector: 'jet-invoice-bill-to',
  templateUrl: './invoice-bill-to.component.html',
  styleUrls: ['./invoice-bill-to.component.scss']
})
export class InvoiceBillToComponent extends ToSection implements OnDestroy {
  @ViewChild('rateHelpText') rateHelpText;

  public marketingSourceCodes: any[] = null;
  public taxStatusCodes: any[] = null;
  public paymentTermCodes: any[] = null;

  public salesEmployees: Employee[] = null;
  public adminSalesEmployee: any = null;
  public processorEmployees: Employee[] = null;
  public adminProcessorEmployee: any = null;

  public fieldVatRegistrationNumber = ADDITIONAL_FIELD_VAT_REGISTRATION_NUMBER;

  @ViewChild('contactSelect') contactSelect;
  public billToContactPhoneNumbers: Contact[];
  public billToContactAddresses: Address[];

  public parentCustomer: Customer;

  constructor(
    private countryService: CountryService,
    private resourceService: ResourceService,
    private codeService: CodeService,
    private userService: UserService,
    private employeeService: EmployeeService,
    private taxableService: TaxableService,
    private taxWarningService: TaxWarningService,
    private customerService: CustomerService,
    private logService: LoggingService,
    private loadingService: LoadingService,
    @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
  ) {
    super('bill', countryService, resourceService, customerService, logService, config);
  }

  protected customInit() {
    observableZip(this.employeeService.employees.pipe(filter(data => data !== null)), this.userService.user.pipe(filter(data => data !== null))).pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((res) => {
        const employees = res[0];
        const user: User = res[1];
        if (!employees || !employees['sales'] || !employees['processor']) {
          return;
        }
        this.salesEmployees = employees['sales'];
        this.processorEmployees = employees['processor'];

        // Admin Employees are not in the database
        const salesEmployeeId = this.invoice.attributes.Employees_SalesPersonIDfk;
        const processorEmployeeId = this.invoice.attributes.Employees_ProcessorIDfk;
        if (salesEmployeeId < 0) {
          this.adminSalesEmployee = ADMINISTRATIVE_EMPLOYEES.find((employee) => {
            return employee.employeeId === salesEmployeeId;
          });
        }
        if (processorEmployeeId < 0) {
          this.adminProcessorEmployee = ADMINISTRATIVE_EMPLOYEES.find((employee) => {
            return employee.employeeId === processorEmployeeId;
          });
        }
    });

    this.codeService.codes.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((codes: any) => {
      if (!codes) {
        return;
      }
      this.paymentTermCodes = codes[CUSTOMER_PAYMENT_TERMS];
      this.taxStatusCodes = codes[INVOICE_TAX_STATUS];
      this.marketingSourceCodes = codes[MARKETING_SOURCE_INVOICE];
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public customAssignments() {
    this.customerService.customer.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((customer: Customer) => {
      if (!customer) {
        return customer;
      }

      if (!this.config.isUk) {
        return;
      }
      const custAddValues = getMultipleResourceRelation<AdditionalValue>(customer.relationships, 'additionalValues');
      const invAddValues = getMultipleResourceRelation<AdditionalValue>(this.invoice.relationships, 'additionalValues');
      const custVatNumberValue = custAddValues.find((value: AdditionalValue) => value.attributes.FieldName === this.fieldVatRegistrationNumber);
      const invVatNumberValue = invAddValues.find((value: AdditionalValue) => value.attributes.FieldName === this.fieldVatRegistrationNumber);
      if (custVatNumberValue && custVatNumberValue.attributes.Value && invVatNumberValue && !invVatNumberValue.attributes.Value) {
        invVatNumberValue.attributes.Value = custVatNumberValue.attributes.Value;
      }
    });
    // Add current billToContact's phone numbers
    if (this.invoice.relationships.billToContactPerson) {
      this.billToContactPhoneNumbers = this.invoice.relationships.billToContactPerson.relationships.phoneNumbers;
      this.billToContactAddresses = this.invoice.relationships.billToContactPerson.relationships.addresses;
    }
    // Add invoices billToContact individual to contacts list
    if (this.invoice.relationships.billToContactPerson !== undefined && this.contacts.indexOf(this.invoice.relationships.billToContactPerson) === -1) {
      this.contacts.push(this.invoice.relationships.billToContactPerson);
    }
  }

  public changeTaxStatus(taxStatusId) {
    this.invoice.attributes.Codes_TaxStatusIDfk = taxStatusId;
    const taxStatusCode = this.taxStatusCodes.find((code) => code.CodeID === taxStatusId);
    this.taxableService.refreshTaxableByCodeAttrs(taxStatusCode);
    this.taxWarningService.warnIfNotTaxable();
  }

  public taxRateOnBlur() {
    let warning = this.taxWarningService.getWarningsIfNotTaxable();
    this.rateHelpText.nativeElement.innerHTML = warning;
  }

  public async updateContact($event) {
    let newCustomer = await this.customerService.getOneCustomer($event).toPromise();
    this.billToContactPhoneNumbers = newCustomer.relationships.phoneNumbers;
    this.billToContactAddresses = newCustomer.relationships.addresses;
  }

  async saveCustomer(id) {
    const loader = this.loadingService.newLoader();
    loader.start();
    await this.getCustomer(id);

    if (this.parentCustomer) {
      for (let i = 0; i < this.parentCustomer.relationships.addresses.length; i++) {
        if (this.parentCustomer.relationships.addresses[i].attributes.AddressID === this.invoice.relationships.billToAddress.attributes.AddressID) {
          this.parentCustomer.relationships.addresses[i] = this.invoice.relationships.billToAddress;
        }
      }

      for (let i = 0; i < this.parentCustomer.relationships.phoneNumbers.length; i++) {
        if (this.parentCustomer.relationships.phoneNumbers[i].attributes.ContactID === this.invoice.relationships.billToPhone.attributes.ContactID) {
          this.parentCustomer.relationships.phoneNumbers[i] = this.invoice.relationships.billToPhone;
        }
      }

      for (let i = 0; i < this.parentCustomer.relationships.faxNumbers.length; i++) {
        if (this.parentCustomer.relationships.faxNumbers[i].attributes.ContactID === this.invoice.relationships.billToFax.attributes.ContactID) {
          this.parentCustomer.relationships.faxNumbers[i] = this.invoice.relationships.billToFax;
        }
      }

      this.customerService.saveCustomer(this.parentCustomer, this.parentCustomer.relationships.productLines, this.parentCustomer.relationships.addresses, [], [], this.parentCustomer.relationships.phoneNumbers, this.parentCustomer.relationships.faxNumbers).pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(resp => {
        loader.stop();
        const bc = new BroadcastChannel('customer_' + id + '_invoice');
        bc.postMessage({
          message: 'Source Customer Has Been Updated From an Invoice. Please Refresh Your Webpage',
          customerId: id,
        });
        window.location.reload();
      }, error => {
        loader.stop();
      });
    }
  }

  async getCustomer(id) {
    this.customerService.getResource(parseInt(id)).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(data => {
      this.parentCustomer = data;
    });
  }

}
