<div class="container-fluid">
    <div class="row">
        <div class="col-12" *ngIf="!orderSubmission">
            Loading...
        </div>
        <div class="col-sm-12" *ngIf="orderSubmission">
            <br>
            <h5>Submitted {{ orderSubmission.attributes.created_at | date }}</h5>

            <table class="table table-borderless" style="font-size: 12px;width: 800px;">
                <colgroup>
                    <col style="width:50%">
                    <col style="width:50%">
                </colgroup>
                <thead>
                <tr>
                    <th>Billing</th>
                    <th>Shipping</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <jet-smartop-address
                                [address]="orderSubmission.attributes.data?.billAddress"
                                [country]="orderSubmission.attributes.billToCountry"
                        ></jet-smartop-address>
                    </td>
                    <td>
                        <jet-smartop-address
                                [address]="orderSubmission.attributes.data?.shipAddress"
                                [country]="orderSubmission.attributes.billToCountry"
                        ></jet-smartop-address>
                    </td>
                </tr>
                </tbody>
            </table>

            <div>
                <h5>Items: </h5>
                <table class="table table-hover table-striped table-borderless">
                    <thead>
                    <tr>
                        <th>Completion Date</th>
                        <th>Style</th>
                        <th>Deco</th>
                        <th>Options</th>
                        <th>Customer</th>
                        <th>Price</th>
                        <th>Spa</th>
                        <th>Reason</th>
                        <th>A</th>
                        <th>B</th>
                        <th>Hinge</th>
                        <th>Height</th>
                        <th>Radius</th>
                        <th>Comments</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of orderSubmission.attributes.data?.items">
                        <td>{{ item.completionOption | uppercase }} {{ item.completionDate}}</td>
                        <td>{{ item.style }}</td>
                        <td>{{ item.decoShield }}</td>
                        <td>
                            <ul class="m-0">
                                <li *ngFor="let option of item.options">
                                    <b>{{ option.name }}: </b>{{ option.value }}
                                </li>
                            </ul>
                        </td>
                        <td>{{ item.customerName }}</td>
                        <td>{{ item.totalPrice | jetCurrency }}</td>
                        <td>{{ item.spaYear }} {{ item.spaBrand }} {{ item.spaModel }}</td>
                        <td>{{ item.spaReason }}</td>
                        <td>{{ item.measurementA }}</td>
                        <td>{{ item.measurementB }}</td>
                        <td>{{ item.hingeLength }}</td>
                        <td>{{ item.acrylicHeight }}</td>
                        <td>{{ item.cornerRadius }}</td>
                        <td>{{ item.comments }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="row">
                <div class="col-12" *ngIf="!isRouted">
                    <button
                            class="btn btn-primary m-1"
                            (click)="routeOrder(country)"
                            *ngIf="country"
                    >Confirm Routing to {{ country|uppercase }}</button>

                    <button
                            class="btn btn-primary m-1"
                            (click)="routeOrder('us')"
                            *ngIf="!country"
                    >Confirm Routing to US</button>

                    <button
                            class="btn btn-primary m-1"
                            (click)="routeOrder('uk')"
                            *ngIf="!country"
                    >Confirm Routing to UK</button>
                </div>
                <div class="col-12" *ngIf="isRouted">
                    <span class="alert alert-success">Order has been routed. {{ orderSubmission.attributes.created_at | date }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
