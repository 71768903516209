
import {mergeMap, tap, filter} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Invoice} from '../../models/invoice.interface';
import {ResourceService} from '../resource.service';
import {singleResourceBody} from '../../utils/json.util';
import {updateSourceToInvoice} from '../../utils/invoice.util';
import {unsetFieldWithZero} from '../../utils/object.util';
import {AdditionalValue} from '../../models/additional-value.interface';
import {InvoiceActions} from "../../interfaces/invoice-actions.interface";
import {DEFAULT_INVOICE_ACTIONS} from "../../mocks/invoice-actions.mock";

@Injectable()
export class InvoiceService {
  private invoiceSource: BehaviorSubject<Invoice> = new BehaviorSubject<Invoice>(null);
  public invoice: Observable<Invoice>             = this.invoiceSource.asObservable();

  private iframeSource: BehaviorSubject<object> = new BehaviorSubject<object>(null);
  public iframe: Observable<object>             = this.iframeSource.asObservable();

  public enableLog = false;

  constructor(private resourceService: ResourceService) {
  }

  createInvoice(customerId: number, invoiceType: number): Observable<Invoice> {
    const resourcePath = ['customers', customerId, 'invoices'];
    const resource     = resourcePath.join('/');
    const body         = singleResourceBody({InvoiceType: invoiceType});
    return this.resourceService.createResource<Invoice>(resource, body);
  }

  /**
   * Updates the invoice and refetches it to refresh the observable.
   * @returns {Observable<T>}
   */

  updateInvoice(invoiceActions: InvoiceActions = DEFAULT_INVOICE_ACTIONS, fields: string[] = []) {
    invoiceActions      = Object.assign({}, DEFAULT_INVOICE_ACTIONS, invoiceActions);
    const invoice       = this.currentInvoice();
    let attributes: any = invoice.attributes;

    // Delete this because api form doesnt want it
    if(attributes.InReturnMode) {
      delete attributes.InReturnMode;
    }

    if (fields.length) {
      attributes = {};

      for (const field of fields) {
        attributes[field] = invoice.attributes[field];
      }
    }

    const body: any = {
      data: {attributes}
    };

    body.data.attributes.Reverse            = invoiceActions.reverse;
    body.data.attributes.Reopen             = invoiceActions.reopen;
    body.data.attributes.FixPricingType     = invoiceActions.fixPricingType;
    body.data.attributes.KeepExistingPrices = invoiceActions.keepExistingPrices;
    body.data.attributes.UpdateFieldsOnly   = invoiceActions.updateFieldsOnly;

    if (!invoiceActions.updateFieldsOnly) {
      const billToAddress                = updateSourceToInvoice(invoice.relationships.billToAddress.attributes, +invoice.id);
      body.data.attributes.billToAddress = unsetFieldWithZero(billToAddress, 'AddressID');
      const billToPhone                  = updateSourceToInvoice(invoice.relationships.billToPhone.attributes, +invoice.id);
      body.data.attributes.billToPhone   = unsetFieldWithZero(billToPhone, 'ContactID');
      const billToFax                    = updateSourceToInvoice(invoice.relationships.billToFax.attributes, +invoice.id);
      body.data.attributes.billToFax     = unsetFieldWithZero(billToFax, 'ContactID');
      const shipToAddress                = updateSourceToInvoice(invoice.relationships.shipToAddress.attributes, +invoice.id);
      body.data.attributes.shipToAddress = unsetFieldWithZero(shipToAddress, 'AddressID');
      const shipToPhone                  = updateSourceToInvoice(invoice.relationships.shipToPhone.attributes, +invoice.id);
      body.data.attributes.shipToPhone   = unsetFieldWithZero(shipToPhone, 'ContactID');
      const shipToFax                    = updateSourceToInvoice(invoice.relationships.shipToFax.attributes, +invoice.id);
      body.data.attributes.shipToFax     = unsetFieldWithZero(shipToFax, 'ContactID');

      body.data.attributes.additionalValues = invoice.relationships.additionalValues.map((value: AdditionalValue) => value.attributes);
    }

    invoice.ngState = 'update';

    return this.resourceService.updateResource<Invoice>('invoices', +invoice.id, body).pipe(
      mergeMap(() => {
        return this.loadInvoice(+invoice.id);
      }),
        filter(data => data !== null)
      );
  }

  /**
   * Used to get a brand new invoice instance.
   * Does not make any assumptions about caching it within this service.
   * Used when needing an invoice that's not the main "invoice" being edited.
   * Also used internally to make the same http request.
   *
   * @param id
   */
  fetchInvoice(id: number) {
    return this.resourceService.getResource<Invoice>('invoices', id, this.enableLog);
  }

  /**
   * Creates the HTTP API call for invoice but returns the HTTP observable.
   * Call is not made until subscribed to.
   *
   * @param id
   * @returns {Observable<Invoice>}
   */
  loadInvoice(id: number): Observable<Invoice> {

    return this.fetchInvoice(id).pipe(tap((invoice: Invoice) => {

      const upsRatesIframe: any = document.getElementById('ups-iframe');
      if (upsRatesIframe) {

        upsRatesIframe.contentWindow.location = upsRatesIframe.contentWindow.location.href;
      }

      invoice.ngState = 'load';

      this.invoiceSource.next(invoice);
    }));
  }

  /**
   * Creates the HTTP API call and automatically subscribes to trigger the call.
   *
   * @param id
   * @param forceRefresh
   */
  getInvoice(id: number, forceRefresh: boolean = false): Observable<Invoice> {

    if (!forceRefresh && this.isInvoiceDefined() && +this.invoiceSource.value.id === id) {
      return this.invoice;
    }

    this.loadInvoice(id).subscribe((invoice: Invoice) => {});

    return this.invoice;
  }

  clearInvoice(): void {
    this.refreshInvoice(null);
  }

  refreshInvoice(invoice: Invoice): void {

    invoice.ngState = 'refresh';

    this.invoiceSource.next(invoice);
  }

  isInvoiceDefined(): boolean {
    return this.invoiceSource.getValue() !== null;
  }

  currentInvoice(): Invoice {
    return this.invoiceSource.getValue();
  }

  upateIframe(iframe: object) {
    this.iframeSource.next(iframe);
  }
}
