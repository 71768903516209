<div class="card">
  <div class="card-heading">
    <h3 class="caps card-title" style="position: relative">
      <span>customer search</span>
      <i class="fa fa-lg fa-search" style="position: absolute"></i><br>
      <a class="float-right caret"></a>
    </h3>
    <div class="card-subheading float-right">
      <div class="text-right">

      </div>
    </div>
  </div>
  <div class="card-body show fivepad" id="customerSearch">
    <form class="search-btns">
      <div class="row no-gutters">
        <div class="col-md">
          <input #phone (keyup.enter)="search('phone', phone.value)" type="text" placeholder="Search Phone Number"
                 class="small-form">
        </div>
        <div class="col-md-auto">
          <button type="button" class="btn btn-xs btn-primary" (click)="search('phone', phone.value)">
            <span class="fa fa-search"></span>
          </button>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md">
          <input #name
                 #customerNameTypeahead="bs-typeahead"
                 class="small-form"
                 placeholder="Search Customer Name"
                 (keyup.enter)="search('name', {'term': name.value, 'dealer_type': dealerType } )"
                 [(ngModel)]="customerFullName"
                 [typeahead]="dataSource"
                 (typeaheadLoading)="changeTypeaheadLoading($event)"
                 (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                 (typeaheadOnSelect)="typeaheadOnSelect($event)"
                 [typeaheadOptionsLimit]="25"
                 [typeaheadScrollable]="true"
                 [typeaheadOptionsInScrollableView]="10"
                 [typeaheadMinLength]="3"
                 [typeaheadWaitMs]="300"
                 [typeaheadOptionField]="'attributes.FullName'"
                 [ngModelOptions]="{standalone: true}"
                 [typeaheadIsFirstItemActive]="false"
                 [typeaheadHideResultsOnBlur]="true"
          >
        </div>
        <div class="col-md-auto">
          <button type="button" class="btn btn-xs btn-primary" id="search-options-btn" data-toggle="collapse"
                  data-target="#search-options">
            <span class="fa fa-cog"></span>
          </button>
        </div>
        <div class="col-md-auto">
          <button type="button" class="btn btn-xs btn-primary"
                  (click)="search('name', {'term': name.value, 'dealer_type': dealerType } )">
            <span class="fa fa-search"></span>
          </button>
        </div>
      </div>
      <div class="row collapse" id="search-options">
        <div class="col-md-4">
          <input type="radio" name="type" [(ngModel)]="dealerType" value="Dealer"> Dealer
        </div>
        <div class="col-md-5">
          <input type="radio" name="type" [(ngModel)]="dealerType" value="Individual"> Individual
        </div>
        <div class="col-md-3">
          <input type="radio" name="type" [(ngModel)]="dealerType" value="All"> All
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md">
          <input #zip (keyup.enter)="search('zip', zip.value)" type="text" placeholder="Search Zipcode"
                 class="small-form">
        </div>
        <div class="col-md-auto">
          <button type="button" class="btn btn-xs btn-primary" (click)="search('zip', zip.value)">
            <span class="fa fa-search"></span>
          </button>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md">
          <input #state (keyup.enter)="search('state', state.value)" type="text" placeholder="Search State"
                 class="small-form">
        </div>
        <div class="col-md-auto">
          <button type="button" class="btn btn-xs btn-primary" (click)="search('state', state.value)">
            <span class="fa fa-search"></span>
          </button>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md">
          <input #city (keyup.enter)="search('city', city.value)" type="text" placeholder="Search City"
                 class="small-form">
        </div>
        <div class="col-md-auto">
          <button type="button" class="btn btn-xs btn-primary" (click)="search('city', city.value)">
            <span class="fa fa-search"></span>
          </button>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md">
          <input #invoiceNumber (keyup.enter)="search('invoice_number',invoiceNumber.value)" type="text"
                 placeholder="Search Invoice #" class="small-form">
        </div>
        <div class="col-md-auto">
          <button type="button" class="btn btn-xs btn-primary" (click)="search('invoice_number', invoiceNumber.value)">
            <span class="fa fa-search"></span>
          </button>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md">
          <select name="selectedSpaLine" class="small-form" [(ngModel)]="selectedSpaLine">
            <option
              *ngFor="let spaLineCode of spaLineCodes"
              [ngValue]="spaLineCode"
            >
              {{ spaLineCode.CodeDesc }}
            </option>
          </select>
        </div>
        <div class="col-md-auto">
          <button type="button" class="btn btn-xs btn-primary" (click)="search('spaline', selectedSpaLine?.CodeID)">
            <span class="fa fa-search"></span>
          </button>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md">
          <input #cin (keyup.enter)="search('cin', cin.value)" type="text" placeholder="Search CIN" class="small-form">
        </div>
        <div class="col-md-auto">
          <button type="button" class="btn btn-xs btn-primary" (click)="search('cin', cin.value)">
            <span class="fa fa-search"></span>
          </button>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md">
          <input #consumer (keyup.enter)="search('consumer', consumer.value)" type="text" placeholder="Search Consumer"
                 class="small-form">
        </div>
        <div class="col-md-auto">
          <button type="button" class="btn btn-xs btn-primary" (click)="search('consumer', consumer.value)">
            <span class="fa fa-search"></span>
          </button>
        </div>
      </div>

      <div class="row no-gutters" style="margin-top: 5px;" *ngIf="selectedCustomer?.attributes?.CustomerType != -1">
        <div class="col-md">
          <button type="button" (click)="newCustomer()" class="btn btn-primary btn-xs btn-block search-btns">New
            Customer
          </button>
        </div>
        <div class="col-md-auto">&nbsp;</div>
        <div *ngIf="customerId" class="col-md">
          <button type="button" (click)="newInvoice()" class="btn btn-primary btn-xs btn-block search-btns">New
            Invoice
          </button>
        </div>
        <div *ngIf="customerId" class="col-md-12">
          <button type="button" (click)="newSmartopInvoice()" class="btn btn-danger btn-xs btn-block search-btns">New
            Smartop Invoice
          </button>
        </div>
      </div>
    </form>

    <b>Selected:</b> <a *ngIf="selectedCustomer" routerLink="customer" routerLinkActive="active"
                        queryParamsHandling="preserve">{{ selectedCustomer?.attributes.FullName }}</a><br>
    <b>Affiliates:</b> <span>{{ affiliates }}</span><br>
    <b>Dealer Types:</b> <span *ngFor="let dealerType of selectedCustomer?.relationships?.dealerTypes; last as isLast">
            {{ dealerType.attributes.ProductCodeAlt+'-'+dealerType.attributes.CodeDesc }}{{ isLast ? '' : ', '}}
        </span>

  </div>
</div>
