import {RecordChange} from "./record-change.interface";

export class HistoryWrapper {

  constructor(private items: Array<any>, private label?: string) {

  }

  push(item){
    this.items.push(item);
  }

  changeType() {
    if (this.label) {
      return this.label;
    }

    return this.items[0].attributes.ChangeType;
  }

  editor() {
      return this.items[0].attributes.source;
  }

  date() {
      return this.items[0].attributes.ChangeDate;
  }

  has(changeType: string) {
    for (let item of this.items) {
      if (item.attributes.ChangeType === changeType) {
        return true;
      }
    }

    return false;
  }
}
