import {Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { QueryService } from '../../../../services/observables/query.service';
import {environment} from '../../../../environments/environment';
import {Customer} from '../../../../models/customer.interface';
import {Observable, Subject, Subscription} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import { PopoverModule } from 'ngx-bootstrap/popover';
import {DOMAIN_CONFIG, DomainConfigInterface} from "../../../../config/domain.interface";

@Component({
  selector: 'jet-history-products',
  templateUrl: './history-products.component.html',
  styleUrls: ['./history-products.component.scss']
})
export class HistoryProductsComponent implements OnInit, OnChanges, OnDestroy {

  public products: any[] = [];
  private snapshot: ActivatedRouteSnapshot;
  public dateFormat: string;
  @Input() public customer: Customer;
  private ngUnsubscribe = new Subject();
  public invoiceNumbers: string;
  public observable: Subscription;

  constructor(
      private route: ActivatedRoute,
      private queryService: QueryService,
      @Inject(DOMAIN_CONFIG) private config: DomainConfigInterface,
      ) {
    this.snapshot = route.snapshot;
    this.dateFormat = config.dateFormat;
  }

  ngOnInit() {
    if (this.customer !== null) {
      this.queryService.getCustomerProductHistory(this.customer.attributes.CustomerID).pipe(
          map((data) => {
            for (const product of data) {
              product.popUpOpen = false;
            }
            return data;
          }),
          takeUntil(this.ngUnsubscribe)
      ).subscribe(
      (data) => {
        this.products = data;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let cur;
    let prev;
    let name;
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        const chng = changes[propName];
        name = propName;
        cur = chng.currentValue;
        prev = chng.previousValue;
      }
    }
    if (cur !== prev && name === 'customer' && this.customer !== null) {
      this.queryService.getCustomerProductHistory(this.customer.attributes.CustomerID).pipe(
          takeUntil(this.ngUnsubscribe)
      ).subscribe(
      (data) => {
        this.products = data;
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  isLoading() {
    return this.queryService.isProductHistoryLoading();
  }

  public getInvoiceNumbers(product) {
    this.observable = this.queryService.getProductHistoryLastInvoices(product.CustomerIDfk, product.InventoryItemIDfk).pipe(
        map((data) => {
          let temp = ``;
          for (const number of data) {
            temp += `<div>${number.InvoiceNumber}</div>`;
          }
          return temp;
        }),
    ).subscribe((data) => {
      this.invoiceNumbers = data;
      product.popUpOpen = true;
    });
  }

  public cancelRequest(product) {
    this.observable.unsubscribe();
    product.popUpOpen = false;
  }
}
