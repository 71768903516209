export function getEmployeeName(resource): string {
  if (!resource) {
    return '';
  }
  // TODO: assuming that existing resource is Admin if the employee relationship doesn't exist.
  if (!resource.relationships || !resource.relationships.employee) {
    return 'Admin';
  }
  return resource.relationships.employee.attributes.FullName;
}
